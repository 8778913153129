import jsc from 'js-cookie';
import { Campaign } from 'backend/api/general/generalModel';
import { env } from 'environments/environment';

class CookieBasedCampaignStorage {
  private readonly CAMPAIGN_NAME = 'CAMPAIGN_NAME';

  private readonly CAMPAIGN_NAME_TRIVAGO = 'CAMPAIGN_NAME_TRIVAGO';

  private readonly CAMPAIGN_NAME_TRIVAGO_BNG = 'CAMPAIGN_NAME_TRIVAGO_BNG';

  set(isTrivago: boolean, isTrivagoBng: boolean, campaign: Campaign): void {
    jsc.set(this.getCookieName(isTrivago, isTrivagoBng), JSON.stringify(campaign), {
      expires: new Date(new Date().getTime() + env.times.campaignMaxAge),
      sameSite: 'Lax',
    });
  }

  remove(isTrivago: boolean, isTrivagoBng: boolean): void {
    jsc.remove(this.getCookieName(isTrivago, isTrivagoBng));
  }

  getAsString(isTrivago: boolean, isTrivagoBng: boolean): string | undefined {
    return jsc.get(this.getCookieName(isTrivago, isTrivagoBng));
  }

  get(isTrivago: boolean, isTrivagoBng: boolean): Campaign | undefined {
    const campaignStr = this.getAsString(isTrivago, isTrivagoBng);

    try {
      return campaignStr && JSON.parse(campaignStr);
    } catch (err) {
      return undefined;
    }
  }

  private getCookieName(isTrivago: boolean, isTrivagoBng: boolean) {
    if (isTrivagoBng) {
      return this.CAMPAIGN_NAME_TRIVAGO_BNG;
    }

    if (isTrivago) {
      return this.CAMPAIGN_NAME_TRIVAGO;
    }

    return this.CAMPAIGN_NAME;
  }
}

const cookieBasedCampaignStorage = new CookieBasedCampaignStorage();

export default cookieBasedCampaignStorage;
