import useDatepickerDate from 'common/hooks/useDatepickerDate';
import { parseDate } from 'common/utils/date';
import React, { useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import arrowLeftIcon from 'assets/images/icons/trivagoV2/mobile-back-icon.svg';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import { CustomDatepicker } from 'components/datePicker/customDatepicker/CustomDatepicker';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import { isPeriodOverMaxNights, shiftDays, stringifyDate } from 'utils/dateUtils';
import Styled from 'views/offscreens/TrivagoOffscreenDatepickerMenu.styled';

const ERROR_MESSAGE_TIMEOUT = 2000;

const TrivagoOffscreenDatepickerMenu: React.FC = () => {
  const { i18n } = useTranslation();
  const { goToPreviousOffscreenMode, setOffscreenMode } = useContext(OffscreenContext);

  const { checkin, checkout, setDates, submitForm } = useContext(SearchFormContext);

  const [showError, setShowError] = useState(false);

  const strStart = useDatepickerDate({
    lang: i18n.language,
    date: parseDate(checkin) || null,
    dateType: 'checkin',
  });
  const strEnd = useDatepickerDate({
    lang: i18n.language,
    date: parseDate(checkout) || null,
    dateType: 'checkout',
  });

  const submit = useCallback(() => {
    if (isPeriodOverMaxNights(parseDate(checkin), parseDate(checkout))) {
      setShowError(true);
      setTimeout(() => setShowError(false), ERROR_MESSAGE_TIMEOUT);

      return;
    }

    hotjarEvent(HotjarEvent.SearchClicked);
    submitForm(true);
    setOffscreenMode(OffscreenMode.hidden);
  }, [checkin, checkout, submitForm, setOffscreenMode]);

  const onBackButton = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      goToPreviousOffscreenMode();
    },
    [goToPreviousOffscreenMode],
  );

  const okButtonClick = useCallback(() => {
    if (!checkin || !checkout) {
      const fixedCheckin = checkin || stringifyDate(new Date());
      const fixedCheckout = checkout || stringifyDate(shiftDays(new Date(), 1));

      setDates(fixedCheckin, fixedCheckout);

      return;
    }

    submit();
  }, [checkin, checkout, setDates, submit]);

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.HeaderAction>
          <a id="backLink" href="/" onClick={onBackButton}>
            <img src={arrowLeftIcon} alt="Close" className="" />
          </a>
        </Styled.HeaderAction>
        <Styled.HeaderContent>
          <Styled.HeaderLabel>
            <Trans i18nKey={'trivago.v2.search-bar.date-picker.header-label'}>When will you be there?</Trans>
          </Styled.HeaderLabel>
          <Styled.HeaderText>
            {strStart.desktop} - {strEnd.desktop}
          </Styled.HeaderText>
        </Styled.HeaderContent>
      </Styled.Header>
      <Styled.Content>
        <CustomDatepicker />
      </Styled.Content>
      <Styled.ButtonSection>
        <Styled.Button onClick={okButtonClick}>
          <Trans i18nKey={'trivago.v2.search-bar.date-picker.check-availability'}>Check availability</Trans>
        </Styled.Button>
      </Styled.ButtonSection>
      <Styled.ErrorToast show={showError}>
        <Trans i18nKey={'trivago.v2.search-bar.30-days-error-message'}>
          Check out date must be within 30 days of check in date. Please select new dates.
        </Trans>
      </Styled.ErrorToast>
    </Styled.Container>
  );
};

export default TrivagoOffscreenDatepickerMenu;
