import { RateDisplayModel } from 'common/backend/api/general/generalModel';
import React from 'react';
import { Campaign, PriceRoundingMethod } from 'backend/api/general/generalModel';
import { User } from 'backend/api/user/userModel';
import {
  LoginOauthRequest,
  LoginRequest,
  SignUpOauthRequest,
  SignUpRequest,
  UpdatePasswordRequest,
} from 'backend/api/user/userRequest';
import SplittySession from 'backend/authModelSession';
import { ToastType } from 'components/common/Toast/Toast.types';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { env } from 'environments/environment';

export interface SplittySessionHolder {
  session: SplittySession;
  login: (payload: LoginRequest) => Promise<void>;
  signUp: (payload: SignUpRequest) => Promise<void>;
  logout: () => Promise<void>;
  updateUserDetails: (payload: User) => Promise<void>;
  updatePassword: (payload: UpdatePasswordRequest, userId?: number) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  signUpOauth: (payload: SignUpOauthRequest) => Promise<void>;
  loginOauth: (payload: LoginOauthRequest) => Promise<void>;
  campaign: Campaign | undefined;
  loading: boolean;
}

export const SignInDialogContext = React.createContext<{
  signInDialogMode: SignInDialogMode | undefined;
  setSignInDialogMode: (mode: SignInDialogMode | undefined) => void;
  tripId?: number;
}>({
  signInDialogMode: undefined,
  setSignInDialogMode: () => undefined,
  tripId: undefined,
});

/**
 * Global context that holds debug mode flag
 */
export const DebugContext = React.createContext<{
  isDebugMode: boolean;
  setDebugMode: (m: boolean) => boolean;
}>({
  isDebugMode: false,
  setDebugMode: () => false,
});

export const ToastContext = React.createContext<(message: string | undefined, type?: ToastType) => void>(
  () => undefined,
);

export const SettingsContext = React.createContext<{
  countryCode: string;
  stateCode: string | undefined;
  languageCode: string;
  campaignName: string;
  currencyCode: string;
  rateDisplayModel: RateDisplayModel;
  priceRoundingMethod: PriceRoundingMethod;
  setCountryCode: (countryCode: string) => void;
  setStateCode: (stateCode: string) => void;
  setCurrencyCode: (currencyCode: string, manually?: boolean) => void;
  setLanguageCode: (languageCode: string, manually?: boolean) => void;
}>({
  countryCode: env.location.fallbackCountryCode,
  stateCode: undefined,
  languageCode: env.i18n.fallbackLanguage,
  campaignName: env.campaign.fallbackName,
  currencyCode: env.currencies.defaultCurrencyCode,
  rateDisplayModel: env.campaign.fallbackRateDisplayModel,
  priceRoundingMethod: env.campaign.fallbackPriceRoundingMethod,
  setCountryCode: () => undefined,
  setStateCode: () => undefined,
  setCurrencyCode: () => undefined,
  setLanguageCode: () => undefined,
});

export const MetaContext = React.createContext<{
  setTitle: (title: string | undefined) => void;
  setDescription: (description: string) => void;
  setKeywords: (keywords: string[]) => void;
}>({
  setTitle: () => undefined,
  setDescription: () => undefined,
  setKeywords: () => undefined,
});

export const SuspenseContext = React.createContext<{
  notifyReady: () => void;
  setProlongedReady: (ready: boolean) => void;
}>({
  notifyReady: () => undefined,
  setProlongedReady: () => undefined,
});
