import styled, { css } from 'styled-components';
import { disabledBackgroundGray } from 'style/variables';

const Divider = styled.hr<{ vertical?: boolean }>`
  ${(props) =>
    props.vertical
      ? css`
          width: 1px;
          height: 60px;
          border-left: 1px solid ${disabledBackgroundGray};
          border-top: none;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        `
      : css`
          width: 100%;
          height: 1px;
          border-top: 1px solid ${disabledBackgroundGray};
          margin-left: 0 !important;
          margin-right: 0 !important;
        `}
`;

export default {
  Divider,
};
