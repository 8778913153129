import styled, { css } from 'styled-components';
import DefaultSelectInputStyled from 'components/common/SelectInput/SelectInputStyled/DefaultSelectInput.styled';
import {
  SelectInputStyledModel,
  DisplayValueProps,
} from 'components/common/SelectInput/SelectInputStyled/SelectInput.styled-model';
import media from 'style/media';

const Select = styled(DefaultSelectInputStyled.Select)`
  padding: ${({ hasLabel }) => (hasLabel ? '20px' : '0')} 35px 0 18px;
  font-size: 16px;

  ${media.mobile} {
    font-size: 14px;
  }

  &:focus {
    border-color: black;
  }
`;

const SelectField = styled(DefaultSelectInputStyled.SelectField)`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;
`;

const Label = styled(DefaultSelectInputStyled.Label)`
  padding-left: 18px;
  font-size: 14px;
`;

const DisplayValue = styled.span<DisplayValueProps>`
  ${({ overwrite }) =>
    !overwrite &&
    css`
      position: absolute;
      left: 18px;
      top: 26px;

      ${media.mobile} {
        margin-top: 0;
      }
    `}

  color: ${({ isDarkHider }) => (isDarkHider ? 'white' : 'black')};
  font-size: 18px;
  line-height: 18px;
`;

const Styled: SelectInputStyledModel = {
  ...DefaultSelectInputStyled,
  Select,
  Label,
  DisplayValue,
  SelectField,
};

export default Styled;
