import React from 'react';

export enum SearchButtonLabels {
  search,
  updateSearch,
}

export interface RouteData {
  path: string | string[];
  exact?: boolean;
  pageType: string;
  title?: string;
  searchButtonLabel?: SearchButtonLabels;
  needSearchBar?: boolean;
  staticSearchBar?: boolean;
  hideCurrencySelector?: boolean;
  hideLanguageSelector?: boolean;
  children?: React.ReactNode;
}

export type RouteList = {
  home: RouteData;
  destination: RouteData;
  booking: RouteData;
  faq: RouteData;
  aboutUs: RouteData;
  terms: RouteData;
  privacyPolicy: RouteData;
  priceGuarantee: RouteData;
  hotelsRedirect: RouteData;
  destinationsRedirect: RouteData;
  hotels: RouteData;
  checkout: RouteData;
  confirmation: RouteData;
  additionalPayment: RouteData;
  additionalPaymentConfirmation: RouteData;
  userProfile: RouteData;
  resetPassword: RouteData;
  errorWithId: RouteData;
  errorNotFound: RouteData;
  i18debug: RouteData;
};

export enum TRIVAGO_TYPE {
  TRIVAGO = 'trivago',
  TRIVAGO_BNG = 'trivago_bng',
}

export const TRIVAGO_ROUTE = `/:trivago(${TRIVAGO_TYPE.TRIVAGO}|${TRIVAGO_TYPE.TRIVAGO_BNG})?`;

export const DESTINATIONS_ROUTE = '/destinations/';
export const HOTELS_ROUTE = '/hotels/';
export const HOTELS_ROUTE_REDIRECT = '/hotels/redirect';
export const DESTINATIONS_ROUTE_REDIRECT = '/redirect';
export const ERRORS_ROUTE_CODE = '/error/:id/:type(code)/:code/:message?';
export const ERRORS_ROUTE_MESSAGE = '/error/:id/:type(msg)/:message?/:internalMessage?';
export const ERRORS_ROUTE_KEY = '/error/:id/:type(key)/:message?/:param?';
export const ERRORS_ROUTE_NOT_FOUND = '/error/404';
export const DEBUG_I18N_ROUTE = '/debug/i18n';

export const ABOUTUS_ROUTE = '/about-us';
export const FAQ_ROUTE = '/faq';
export const TERMS_ROUTE = '/terms-and-conditions';
export const PRIVACY_ROUTE = '/privacy-policy';
export const PRICE_GUARANTEE_ROUTE = '/price-guarantee';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const BOOKINGS_ROUTE = '/bookings';
export const USER_PROFILE_ROUTE = '/account';
export const CHECKOUT_ROUTE = '/checkout';
export const CONFIRMATION_ROUTE = '/confirmation';
export const ADDITIONAL_PAYMENT_ROUTE = '/trips/:encryptedTripId/payment-requests/:paymentRequestId';
export const ADDITIONAL_PAYMENT_CONFIRMATION_ROUTE =
  '/trips/:encryptedTripId/payment-requests/:paymentRequestId/confirmation';

export const routes: RouteList = {
  home: {
    path: '/',
    exact: true,
    pageType: 'homePage',
    title: 'Algotels: Hotel Search',
  },
  destination: {
    path: DESTINATIONS_ROUTE,
    pageType: 'destinationsPage',
    searchButtonLabel: SearchButtonLabels.updateSearch,
    needSearchBar: true,
  },
  booking: {
    path: BOOKINGS_ROUTE,
    exact: true,
    pageType: 'bookingsPage',
    title: 'My Booking page',
    needSearchBar: true,
  },
  faq: {
    path: FAQ_ROUTE,
    exact: true,
    pageType: 'faq',
    title: 'FAQ',
    needSearchBar: true,
  },
  aboutUs: {
    path: ABOUTUS_ROUTE,
    exact: true,
    pageType: 'aboutUs',
    title: 'About us',
    needSearchBar: true,
  },
  terms: {
    path: TERMS_ROUTE,
    exact: true,
    pageType: 'terms',
    title: 'Terms and conditions',
    needSearchBar: true,
  },
  privacyPolicy: {
    path: PRIVACY_ROUTE,
    exact: true,
    pageType: 'privacy',
    title: 'Privacy policy',
    needSearchBar: true,
  },
  priceGuarantee: {
    path: PRICE_GUARANTEE_ROUTE,
    exact: true,
    pageType: 'priceGuarantee',
    title: 'Price guarantee',
    needSearchBar: true,
  },
  hotelsRedirect: {
    path: `${TRIVAGO_ROUTE}${HOTELS_ROUTE_REDIRECT}`,
    pageType: 'hotelsRedirect',
  },
  destinationsRedirect: {
    path: DESTINATIONS_ROUTE_REDIRECT,
    pageType: 'destinationsRedirect',
  },
  hotels: {
    path: `${TRIVAGO_ROUTE}${HOTELS_ROUTE}`,
    pageType: 'hotels',
    needSearchBar: true,
    staticSearchBar: true,
  },
  checkout: {
    path: `${TRIVAGO_ROUTE}${CHECKOUT_ROUTE}/:checkoutId`,
    exact: true,
    pageType: 'checkout',
    title: 'Algotels: Your details',
    hideCurrencySelector: true,
    hideLanguageSelector: true,
  },
  confirmation: {
    path: `${TRIVAGO_ROUTE}${CONFIRMATION_ROUTE}/:encryptedTripId`,
    exact: true,
    pageType: 'confirmation',
    title: 'Confirmation page',
    hideCurrencySelector: true,
    hideLanguageSelector: true,
  },
  additionalPayment: {
    path: ADDITIONAL_PAYMENT_ROUTE,
    exact: true,
    pageType: 'additionalPayment',
    title: 'Additional payment page',
    hideCurrencySelector: true,
    hideLanguageSelector: true,
  },
  additionalPaymentConfirmation: {
    path: ADDITIONAL_PAYMENT_CONFIRMATION_ROUTE,
    exact: true,
    pageType: 'additionalPaymentConfirmation',
    title: 'Additional payment confirmation page',
    hideCurrencySelector: true,
  },
  userProfile: {
    path: USER_PROFILE_ROUTE,
    exact: true,
    pageType: 'userProfile',
    title: 'My account page',
    needSearchBar: true,
  },
  resetPassword: {
    path: RESET_PASSWORD_ROUTE,
    exact: true,
    pageType: 'resetPassword',
    title: 'Algotels Hotel Deals',
    needSearchBar: true,
  },
  errorWithId: {
    path: [ERRORS_ROUTE_CODE, ERRORS_ROUTE_KEY, ERRORS_ROUTE_MESSAGE],
    exact: true,
    pageType: 'error',
    needSearchBar: true,
  },
  errorNotFound: {
    path: ERRORS_ROUTE_NOT_FOUND,
    exact: true,
    pageType: 'error404',
    needSearchBar: true,
  },
  i18debug: {
    path: DEBUG_I18N_ROUTE,
    exact: true,
    pageType: 'debug',
  },
};
