/* eslint-disable no-magic-numbers */

import styled from 'styled-components';
import backgroundImage from 'assets/images/footer/rectangle-copy-10.png';
import { List } from 'components/common/List/List';
import { Text } from 'components/common/Text/Text';
import { charcoalGrey, tinyText, veryDarkGray } from 'style/variables';

const Footer = styled.footer`
  padding: 40px 0 15px;
  background-image: url(${backgroundImage});
`;

const SectionHeader = styled.h3`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: ${charcoalGrey};
  margin-bottom: 7px;
`;

const SectionLabel = styled.h3`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${veryDarkGray};
`;

const SectionImage = styled.img`
  height: 70px;
  width: 70px;

  margin-right: 25px;
`;

const SectionText = styled.div`
  line-height: 22px;
`;

const Section = styled.div<{ light?: boolean; gridArea: string }>`
  display: flex;
  grid-area: ${(props) => props.gridArea};
  box-sizing: border-box;
  padding: 30px;
  border-radius: 26px;
  margin-left: 26px;
  margin-top: 21px;

  color: ${charcoalGrey};
  font-weight: normal;

  background-color: rgba(${(props) => (props.light ? '255, 255, 255' : '223, 223, 223')}, 0.35);
`;

const Grid = styled.div`
  display: grid;
  margin-left: -26px;
  margin-top: -21px;

  grid-template-areas:
    'grantee verified free-risk'
    'company info info';
`;

const XSmallTitle = styled(Text)`
  font-size: ${tinyText};
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 94px;
  }
`;

const FooterList = styled(List)`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;

  & + ${SectionLabel} {
    margin-top: 26px;
  }
`;

const FooterListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 0 !important;
  height: 31px;
`;

const Copyright = styled.div`
  margin-top: 15px;
  font-size: 12px;
  line-height: 18px;

  img {
    margin-right: 22px;
  }
`;

export default {
  Footer,
  Section,
  SectionHeader,
  SectionLabel,
  SectionImage,
  SectionText,
  ImageWrapper,
  Grid,
  XSmallTitle,
  FooterList,
  FooterListItem,
  Copyright,
};
