import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TERMS_ROUTE } from 'routeList';

interface TermsAndConditionsLinkProps {
  onClick: () => void;
}

const TermsAndConditionsLink: React.FC<TermsAndConditionsLinkProps> = ({ onClick }) => (
  <Link id="tocLink" to={TERMS_ROUTE} onClick={onClick}>
    <Trans i18nKey="footer.terms">Terms and Conditions</Trans>
  </Link>
);

export default TermsAndConditionsLink;
