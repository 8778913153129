export const grassGreen = '#3f8700';
export const trivagoGreen = '#32a22f';
export const seaWave = '#0d869b';
export const triColor = '#0081b0';
export const vaColor = '#f38f00';
export const goColor = '#cd4933';
export const parisianBlue = '#35464d';
export const trivagoBlack = '#0a1121';

export const trivagoBackground = '#35464de2';
export const trivagoBackgroundLight = '#e9edee';
export const trivagoBackgroundTransparent = '#FFFFFFCC';

export const landingBngFooterHeaderBackgroundColor = '#0a1120';

export const landingBngPageBottomPaddingDesktop = '200px';
export const landingBngPageBottomPaddingMobile = '56px';

export const landingBngGreenButtonColor = '#197e1c';
export const landingBngGreenHoveredButtonColor = '#295e17';
export const landingBngBlueButtonColor = '#1d7fbe';
export const landingBngBlueHoveredButtonColor = '#285e9e';

// trivago v2
export const trivagoBlackV2 = '#05131e';
export const trivagoDarkGrey = '#595d60';
export const trivagoGrey = '#ddd';
export const trivagoDarkGreen = '#408601';
export const trivagoTransparentGrassGreen = '#3f87006d';
export const trivagoPaleGreen = '#f1f8eb';
export const trivagoRed = '#af0c25';
export const trivagoError = '#eb001b';
export const trivagoDarkBlue = '#333a48';
export const hotelGalleryBackground = '#263237';
export const placeholderBackground = '#c2cfd6';
