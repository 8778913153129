import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { USER_PROFILE_ROUTE } from 'routeList';

interface AccountLinkProps {
  onClick?: () => void;
}

const AccountLink: React.FC<AccountLinkProps> = ({ onClick }) => (
  <Link id="accountLink" to={USER_PROFILE_ROUTE} onClick={onClick}>
    <Trans i18nKey="common.menu.account">Account Details</Trans>
  </Link>
);

export default AccountLink;
