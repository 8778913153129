import { useCallback } from 'react';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { CURRENT_COUNTRY } from 'backend/localStorageKeys';
import { env } from 'environments/environment';
import useLocalStorage from 'utils/useLocalStorage';

export const useCountrySettings = () => {
  const { data: location } = useUserLocation();

  const [countryCode, setCountryToStorage] = useLocalStorage<string>(
    CURRENT_COUNTRY,
    location.countryCode || env.location.fallbackCountryCode,
  );

  const setCountryCode = useCallback(
    (country: string) => {
      setCountryToStorage(country);
    },
    [setCountryToStorage],
  );

  return { countryCode, setCountryCode };
};
