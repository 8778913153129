/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import ButtonStyled from 'components/common/Button/ButtonStyled/DefaultButton.styled';

import StyledCardComponents from 'components/common/Card/Card.styled';
import LabelStyled from 'components/common/Label/Label.styled';
import { rem } from 'style/functions';
import {
  black,
  borderRadius,
  brandColor,
  colorGrey4,
  colorPurple2,
  colorPurple3,
  primaryBackground,
  purple,
  tinyText,
  white,
} from 'style/variables';

const TinyText = styled.div`
  font-size: ${tinyText};
`;

const BaseTextColor = styled.div`
  color: ${black};

  &:active,
  &:hover {
    color: ${black};
  }
`;

const BaseLineHeight = styled.div`
  line-height: 1.5;
`;

const BaseTextSize = styled(BaseLineHeight)`
  font-size: 1rem;
`;

const ButtonActive = styled(ButtonStyled.PrimaryButton)`
  padding: 0 48px;
  border-radius: 3px;
  font-weight: 900;
  font-size: 15px;
`;

const MediumText = styled.div`
  font-weight: 500;
`;

const LabelPrimary = styled(LabelStyled.Label)`
  background-color: ${primaryBackground};
  color: ${brandColor};
  border: 0;
  font-size: 1rem;
`;

const LabelPurple = styled(LabelStyled.Label)`
  flex-shrink: 0;
  border-radius: ${borderRadius};
  border: 1px solid ${colorPurple2};
  background-color: ${colorPurple3};
  padding: ${rem(2)} ${rem(8)};
  color: ${purple};
  font-size: ${rem(10)};

  svg {
    margin-right: 5px;
  }
`;

const CardLabelPrimary = styled(LabelPurple)`
  flex-shrink: 0;
  border-radius: ${borderRadius};
  border: 1px solid ${brandColor};
  background-color: ${primaryBackground};
  padding: ${rem(2)} ${rem(8)};
  color: ${brandColor};
  font-size: ${rem(12)};

  svg {
    margin-right: ${rem(4)};
  }
`;

const LabelPurpleBorderless = styled(LabelPurple)`
  padding-top: ${rem(4)};
  padding-bottom: ${rem(4)};
  padding-right: ${rem(4)};
  font-size: ${rem(12)};
  border: 0;
`;

const LabelWarning = styled(LabelStyled.Label)`
  display: flex;
  padding-top: ${rem(4)};
  padding-bottom: ${rem(4)};
  border: 0;
`;

const CheckboxLarge = styled.input`
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: ${rem(3)};
  border: 1px solid ${brandColor};

  &:focus {
    border-color: ${brandColor};
  }
`;

const SearchSmallInput = styled.input`
  border-radius: ${rem(3)};
`;

const SearchSmall = styled.form`
  position: relative;

  & > ${SearchSmallInput}, & > ${SearchSmallInput}:focus {
    border-color: ${black};
    height: 40px;
    padding-left: 15px;
  }

  svg {
    width: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const RatingStars = styled.div`
  position: relative;
  top: -2px;
`;

const AlignBaseline = styled.div`
  align-items: baseline;
`;

const Text = styled.div`
  padding: ${rem(80)} 0;
  background: transparent linear-gradient(180deg, ${colorGrey4} 0%, ${white} 100%);
  line-height: 1.75;

  p {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: ${rem(8)};
  }

  h1 {
    margin-bottom: ${rem(40)};
    font-size: 20px;
    line-height: 1.3;
  }

  h2 {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(8)};
    font-size: ${rem(14)};
    font-weight: 700;
    line-height: 1.75;
  }

  ul {
    margin-top: 0;
    margin-left: 16px;
    padding-left: ${rem(15)};
  }
`;

const BottomSticky = styled.div`
  bottom: 0;
  left: 0;
`;

const BottomStickyFixed = styled(BottomSticky)`
  position: fixed;
  padding: 0;
  z-index: 4;
`;

const PaddingTiny = styled.div`
  padding: ${rem(10)};
`;

const PaddingExtraTiny = styled.div`
  padding: ${rem(8)};
`;

const MarginTinyTop = styled.div`
  margin-top: ${rem(4)};
`;

const MarginTinyVertical = styled.div`
  margin-top: ${rem(4)};
  margin-bottom: ${rem(4)};
`;

const MarginTinyRight = styled.div`
  margin-right: ${rem(4)};
`;

const MarginTinyBottom = styled.div`
  margin-bottom: ${rem(4)};
`;

const Card = styled(StyledCardComponents.StyledCard)`
  display: block;
  height: 176px;
  border-radius: 0.3rem;

  &.flex {
    display: flex;
  }

  &.height-1-1 {
    height: 100%;
  }
`;

const GoogleMap = styled.div`
  width: 100%;
  height: 100%;

  .gm-style-iw {
    padding: 12px !important;
    min-height: 265px !important;
    border-radius: 6px !important;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
    max-height: 100% !important;
  }

  .gm-ui-hover-effect {
    top: -7px !important;
    right: -7px !important;
  }
`;

const CardShadow = styled(StyledCardComponents.StyledCard)`
  box-shadow: 0 0 10px rgba(204, 204, 204, 0.25);
`;

export default {
  MarginTinyTop,
  MarginTinyVertical,
  MarginTinyRight,
  MarginTinyBottom,
  CardLabelPrimary,
  GoogleMap,
  Card,
  PaddingTiny,
  BottomSticky,
  BottomStickyFixed,
  Text,
  AlignBaseline,
  BaseLineHeight,
  TinyText,
  MediumText,
  ButtonActive,
  BaseTextSize,
  LabelPrimary,
  LabelPurple,
  LabelPurpleBorderless,
  CheckboxLarge,
  SearchSmall,
  SearchSmallInput,
  RatingStars,
  LabelWarning,
  BaseTextColor,
  PaddingExtraTiny,
  CardShadow,
};
