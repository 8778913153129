import React from 'react';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';

interface Context {
  offscreenMode: OffscreenMode;
  setOffscreenMode: (mode: OffscreenMode) => void;
  goToPreviousOffscreenMode: () => void;
}

export const OffscreenContext = React.createContext<Context>({
  offscreenMode: OffscreenMode.hidden,
  setOffscreenMode: () => undefined,
  goToPreviousOffscreenMode: () => undefined,
});
