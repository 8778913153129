import useOutsideClick from 'common/hooks/useOutsideClick';
import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';
import Styled from 'components/common/Dropdown/Dropdown.styled';

export interface DropdownProps {
  buttonRef: React.MutableRefObject<HTMLElement | HTMLInputElement | null>;
  position?: string;
  className?: string | undefined;
  style?: CSSProperties | undefined;
  isOpen: boolean;
  onClose?: () => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  children,
  buttonRef,
  position,
  className,
  style,
  onClose,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen || !dropdownRef.current) {
      return;
    }
    if (position?.includes('bottom')) {
      dropdownRef.current.style.top = `${
        (buttonRef?.current?.offsetTop || 0) + (buttonRef?.current?.offsetHeight || 0)
      }px`;
    }
    if (position?.includes('top')) {
      // not supported yet
    }
    if (position?.includes('left')) {
      dropdownRef.current.style.left = `${buttonRef?.current?.offsetLeft || 0}px`;
    }
    if (position?.includes('right')) {
      // not supported yet
    }
  }, [buttonRef, isOpen, position]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useOutsideClick(dropdownRef, handleClose, false, !isOpen);

  return (
    <Styled.Dropdown ref={dropdownRef} isOpen={isOpen} position={position} className={className} style={style}>
      {children}
    </Styled.Dropdown>
  );
};
