import jsc from 'js-cookie';
import { env } from 'environments/environment';

/**
 * Name of the cookie to store redirect id
 */
const REDIRECT_ID = 'REDIRECT_ID';

class CookieBasedRedirectIdStorage {
  set(redirectId: string): void {
    jsc.set(REDIRECT_ID, redirectId, {
      expires: new Date(new Date().getTime() + env.times.campaignMaxAge),
      sameSite: 'Lax',
    });
  }

  remove(): void {
    jsc.remove(REDIRECT_ID);
  }

  get(): string | undefined {
    return jsc.get(REDIRECT_ID);
  }
}

const cookieBasedRedirectIdStorage = new CookieBasedRedirectIdStorage();

export default cookieBasedRedirectIdStorage;
