import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import barcelona from 'assets/images/cities/Barcelona.webp';
import cancun from 'assets/images/cities/Cancun.webp';
import lasvegas from 'assets/images/cities/LasVegas.webp';
import london from 'assets/images/cities/London.webp';
import miami from 'assets/images/cities/Miami.webp';
import ny from 'assets/images/cities/NewYork.webp';
import orlando from 'assets/images/cities/Orlando.webp';
import paris from 'assets/images/cities/Paris.webp';
import rome from 'assets/images/cities/Rome.webp';
import sf from 'assets/images/cities/SF.webp';
import { useSearchStateCallbacks } from 'atoms/hooks/useSearchStateCallbacks';
import { getDataProvider } from 'backend/dataProvider';
import { ToastType } from 'components/common/Toast/Toast.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/home/HomeLocationWidget/HomeLocationWidget.styled';

interface HomeLocation {
  image: string;
  id: string;
  big?: boolean;
}

interface CitiesHash {
  [city: string]: HomeLocation;
}

export const cityLocations: CitiesHash = {
  paris: {
    image: paris,
    id: 'R2734',
    big: true,
  },
  sf: {
    image: sf,
    id: 'R3132',
  },
  lasvegas: {
    image: lasvegas,
    id: 'R2008',
  },
  miami: {
    image: miami,
    id: 'R2297',
  },
  orlando: {
    image: orlando,
    id: 'R2693',
  },
  rome: {
    image: rome,
    id: 'R3023',
  },
  barcelona: {
    image: barcelona,
    id: 'R513',
  },
  london: {
    image: london,
    id: 'R2114',
  },
  newYork: {
    image: ny,
    id: 'R2621',
    big: true,
  },
  cancun: {
    image: cancun,
    id: 'R179995',
  },
};

interface HomeLocationWidgetPrp {
  locationData: HomeLocation;
  cityName: string;
  children?: React.ReactNode;
}

const HomeLocationWidget: React.FC<HomeLocationWidgetPrp> = ({ locationData, cityName, children }) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const { submitDestination } = useSearchStateCallbacks();
  const [t] = useTranslation();
  const setToast = useContext(ToastContext);
  const onWidgetClick = useCallback(() => {
    const fetchDestination = async () => {
      try {
        const destination = await getDataProvider().then((dp) => dp.getDestination(locationData.id));

        submitDestination(destination);
      } catch (e) {
        setToast(t('home.error.destination', 'Sorry, this destination is not currently available'), ToastType.Error);
      }
    };

    fetchDestination();
  }, [locationData?.id, setToast, submitDestination, t]);

  return (
    <Styled.Container
      href={'/'}
      onClick={(e) => {
        e.preventDefault();
        onWidgetClick();
      }}
    >
      <Styled.Label>{cityName}</Styled.Label>
      <Styled.Image
        src={locationData?.image}
        big={locationData?.big && !isMobileLayout}
        className={isMobileLayout ? 'width-expand' : ''}
      />
      {children}
    </Styled.Container>
  );
};

export default HomeLocationWidget;
