import styled, { css } from 'styled-components';
import {
  SelectInputStyledModel,
  SelectProps,
  DisplayValueProps,
} from 'components/common/SelectInput/SelectInputStyled/SelectInput.styled-model';
import CommonStyled from 'style/Common.styled';
import StyledInputField from 'style/FlexInput.styles';
import media from 'style/media';
import { borderColorLight, lightRoyalBlue, strongPink, white } from 'style/variables';

const Label = styled(StyledInputField.InputLabel)`
  transform: translateY(8px);
  padding-left: 13px;
  font-size: 14px;

  ${media.mobile} {
    font-size: 13px;
  }
`;

const ErrorLabel = CommonStyled.ErrorLabel;

const Select = styled.select<SelectProps>`
  position: relative;
  width: 100%;
  height: 55px;
  z-index: 1;

  outline: 0;
  border-radius: 3px;
  padding: ${({ hasLabel }) => (hasLabel ? '20px' : '0')} 35px 0 13px;
  border: 1px solid ${borderColorLight};

  color: black;
  font-size: 18px;
  line-height: 18px;
  background-color: transparent;
  appearance: none;

  ${media.mobile} {
    font-size: 18px;
  }

  &:focus {
    border-color: ${lightRoyalBlue};
  }

  ${({ error }) => error && `border-color: ${strongPink};`}

  ${({ fieldOverwrite }) =>
    fieldOverwrite &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;

      outline: 0;
      border: none;

      color: transparent;
      appearance: none;
      cursor: pointer;

      option {
        color: black;
        background-color: ${white};
      }

      optgroup {
        color: black;
        background-color: ${white};
      }
    `}
`;

const SelectArrow = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 20px;
  right: 16px;
  background-image: url('/images/drop-down.svg');
  background-repeat: no-repeat;
`;

const SelectField = styled(StyledInputField.Container)`
  display: block;
  background-color: ${white};

  & > select:not(${Select}) {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
  }
`;

const DisplayValue = styled.span<DisplayValueProps>`
  ${({ overwrite }) =>
    !overwrite &&
    css`
      position: absolute;
      left: 13px;
      top: 26px;

      ${media.mobile} {
        margin-top: 0;
      }
    `}

  color: ${({ isDarkHider }) => (isDarkHider ? 'white' : 'black')};
  font-size: 18px;
  line-height: 18px;
`;

const Styled: SelectInputStyledModel = {
  Label,
  ErrorLabel,
  SelectField,
  Select,
  DisplayValue,
  SelectArrow,
};

export default Styled;
