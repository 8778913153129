import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import savingIcon from 'assets/images/home/saving-icon.svg';
import { LayoutContext } from 'components/contexts/LayoutContext';
import BenefitSectionStyled from 'components/home/BenefitSection.styled';
import Styled from 'components/home/SavingsCounterPanel/SavingsCounterPanel.styled';

export const SavingsCounterPanel = () => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);

  const description = (
    <div>
      <Styled.BenefitLabel>{t('home.savings.counter', 'Savings Counter')}</Styled.BenefitLabel>
      <Styled.Text>
        {t('home.savings.total', 'Our unique algorithm has saved 10+ million dollars and counting for our customers')}
      </Styled.Text>
    </div>
  );

  const counter = (
    <Styled.Counter>
      <span className={'counter-separator'}>$</span>
      <span className={'counter-number'}>1</span>
      <span className={'counter-number'}>0</span>
      <span className={'counter-separator'}>,</span>
      <span className={'counter-number'}>9</span>
      <span className={'counter-number'}>6</span>
      <span className={'counter-number'}>8</span>
      <span className={'counter-separator'}>,</span>
      <span className={'counter-number'}>7</span>
      <span className={'counter-number'}>9</span>
      <span className={'counter-number'}>5</span>
    </Styled.Counter>
  );

  if (isMobileLayout) {
    return (
      <BenefitSectionStyled.SavingItem>
        <div>
          <div className={'flex'}>
            <BenefitSectionStyled.BenefitImage src={savingIcon} />
            {description}
          </div>
          <div className={'margin-medium-top margin-auto-left margin-auto-right'}>{counter}</div>
        </div>
      </BenefitSectionStyled.SavingItem>
    );
  }

  return (
    <BenefitSectionStyled.SavingItem>
      <BenefitSectionStyled.BenefitImage src={savingIcon} />
      {description}
      {counter}
    </BenefitSectionStyled.SavingItem>
  );
};
