/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import arrowCheckboxCheckedDisabled from 'assets/images/icons/ui-kit/arrow-checkbox-checked-disabled.svg';
import arrowCheckboxChecked from 'assets/images/icons/ui-kit/arrow-checkbox-checked.svg';
import arrowCheckboxIndeterminateDisabled from 'assets/images/icons/ui-kit/arrow-checkbox-indeterminate-disabled.svg';
import arrowCheckboxIndeterminate from 'assets/images/icons/ui-kit/arrow-checkbox-indeterminate.svg';
import { InputCheckboxStyledModel } from 'components/common/InputCheckbox/InputCheckboxStyled/InputCheckbox.styled-model';
import { rem } from 'style/functions';
import {
  battleshipGrey,
  strongPink,
  brandColor,
  disabledBackgroundGray,
  disabledInputBackgroundColor,
} from 'style/variables';

const radius = 2;
const defaultSize = 16;

const Checkbox = styled.input`
  min-width: ${defaultSize}px;
  min-height: ${defaultSize}px;
  margin: 0 8px 0 0;
  box-sizing: border-box;
  font: inherit;
  display: inline-block;
  height: 16px;
  width: 16px;
  overflow: hidden;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  border-radius: ${rem(radius)};
  border: 1px solid ${brandColor};

  &:focus {
    outline: none;
    border: 1px solid ${brandColor};
    background-color: ${strongPink};
  }

  &:checked,
  &:indeterminate,
  &:checked:focus,
  &:indeterminate:focus {
    background-color: ${strongPink};
  }

  &:checked {
    background-image: url(${arrowCheckboxChecked});
  }

  &:indeterminate {
    background-image: url(${arrowCheckboxIndeterminate});
  }

  &:disabled {
    background-color: ${disabledInputBackgroundColor};
    border-color: ${disabledBackgroundGray};
  }

  &:disabled:checked {
    background-image: url(${arrowCheckboxCheckedDisabled});
  }

  &:disabled:indeterminate {
    background-image: url(${arrowCheckboxIndeterminateDisabled});
  }

  &:not(:disabled) {
    cursor: pointer;
    border: 1px solid ${battleshipGrey};
  }
`;

const Styled: InputCheckboxStyledModel = {
  Checkbox,
};

export default Styled;
