/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * @category Search Form
 * @packageDocumentation
 */

import { Place } from 'common/backend/api/place/placeModel';
import useDebounce from 'common/hooks/useDebounce';
import useOutsideClick from 'common/hooks/useOutsideClick';
import { useUnmountAbortController } from 'common/hooks/useUnmountAbortController';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getDataProvider } from 'backend/dataProvider';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import { DestinationSearchField } from 'components/searchForm/DestinationSearchField/DestinationSearchField';
import Styled from 'components/searchForm/SuggestionField/SuggestionField.styled';
import { SuggestionList } from 'components/searchForm/SuggestionList/SuggestionsList';
import { env } from 'environments/environment';

export interface SuggestionFieldProps {
  /**
   * Callback which is called when the suggestion is already here but user presses Enter while focus is within this component.
   * It is for form to be able to submit itself on that case
   */
  onEnter(): void;
}

export const SuggestionField = ({ onEnter }: SuggestionFieldProps) => {
  const { onSearchTermChange, onSuggestion, onAutoSuggestion, destinationTerm, place } = useContext(SearchFormContext);

  const [isTyping, setIsTyping] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState<Place | undefined>(place);
  const [destinations, setDestinations] = useState<Place[]>();

  const [searchTerm, setSearchTerm] = useState(destinationTerm || '');
  const debounceDelay = useMemo(() => (!isTyping ? 0 : env.times.suggestionsDebounce), [isTyping]);
  const [debouncedSearchTerm] = useDebounce(searchTerm, debounceDelay);

  const getDestinationsController = useRef<AbortController>();
  const unmountAbortController = useUnmountAbortController();

  const containerRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = useCallback(() => setIsTyping(false), []);

  useOutsideClick(containerRef, handleOutsideClick, !isTyping);

  const handleSearchTermChange = useCallback(
    (value: string) => {
      setSelectedDestination(undefined);
      onSuggestion(undefined);
      setSearchTerm(value);
      onSearchTermChange(value);
      setIsTyping(true);
    },
    [onSearchTermChange, onSuggestion],
  );

  const onSelect = useCallback(
    (dest: Place) => {
      setSelectedDestination(dest);
      onSuggestion(dest);
      setSearchTerm(dest.name);
      onSearchTermChange(dest.name);
      setIsTyping(false);
    },
    [onSearchTermChange, onSuggestion],
  );

  const onListEnter = useCallback(
    (_place: Place | undefined, autoSelect: boolean) => {
      if (autoSelect && selectedDestination) {
        setIsTyping(false);
        onEnter();
      } else if (_place) {
        onSelect(_place);
      }
    },
    [onEnter, onSelect, selectedDestination],
  );

  const fetchSuggestions = useCallback(
    (query: string) => {
      if (getDestinationsController.current) {
        getDestinationsController.current.abort();
      }

      getDestinationsController.current = new AbortController();
      setDestinations(undefined);

      getDataProvider()
        .then((dataProvider) =>
          dataProvider.getDestinations(
            { term: query },
            getDestinationsController.current
              ? [getDestinationsController.current.signal, unmountAbortController.signal]
              : [unmountAbortController.signal],
          ),
        )
        .then(setDestinations)
        .catch(() => {});
    },
    [unmountAbortController.signal],
  );

  // fetch new destinations when user types
  useEffect(() => {
    if (debouncedSearchTerm.length >= env.searchBar.minimalTermLength && isTyping) {
      fetchSuggestions(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, isTyping, fetchSuggestions]);

  // update search term when destination term is changed in context
  useEffect(() => {
    if (destinationTerm) {
      setSearchTerm(destinationTerm);
      setDestinations(undefined);
    }
  }, [destinationTerm]);

  // update selected destination when destination is changed in context
  useEffect(() => {
    setSelectedDestination(place);
  }, [place]);

  // auto select first destination when it is loaded
  // this { autoPlace } value is used if the { place } is not selected.
  // following link was here before I refactored this code.
  // https://www.pivotaltracker.com/story/show/175962656
  // I can't open it, but probably someone else has access to it.
  useEffect(() => {
    onAutoSuggestion(destinations?.[0]);
  }, [destinations, onAutoSuggestion]);

  return (
    <Styled.Container ref={containerRef}>
      <DestinationSearchField value={searchTerm} onChange={handleSearchTermChange} />
      <SuggestionList places={destinations} onSelect={onSelect} open={isTyping} onEnter={onListEnter} />
    </Styled.Container>
  );
};
