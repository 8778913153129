/**
 * @category Utils
 * @packageDocumentation
 */

import qs from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import throwI18nError from 'common/errors/i18nError';

export interface UrlParameters {
  [index: string]: string | string[] | undefined;
}

/**
 * Hook which listen to location change and parse search parameters using qs
 * @param requiredParameters - list of required parameter names. If one of them absents, ith throws {@link I18nError}
 * @param defaultsValues
 */
const useQuery = <T extends UrlParameters>(requiredParameters?: (keyof T)[], defaultsValues?: UrlParameters) => {
  const location = useLocation();

  return useMemo(() => {
    const object = qs.parse(location.search, { ignoreQueryPrefix: true }) as T;

    if (requiredParameters && requiredParameters.length > 0) {
      const requiredParametersCopy = requiredParameters.slice();

      Object.keys(object).forEach((key) => {
        const index = requiredParametersCopy.indexOf(key);

        if (index >= 0) {
          requiredParametersCopy.splice(index, 1);
        }
      });

      if (requiredParametersCopy.length > 0) {
        throwI18nError('validation.required-parameters-missed', 'Required parameters are missed: {missed}', {
          missed: requiredParametersCopy.join(),
        });
      }
    }

    if (defaultsValues) {
      Object.keys(defaultsValues).forEach((key) => {
        if (object[key] === undefined) {
          (object as UrlParameters)[key] = defaultsValues[key];
        }
      });
    }

    return object;
  }, [defaultsValues, location.search, requiredParameters]);
};

export default useQuery;
