import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo/logo.svg';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { LayoutContext } from 'components/contexts/LayoutContext';

const wwwDomain = window.location.hostname.startsWith('www');

interface PageHeaderLogoProps {
  onClick?: () => void;
}

export const PageHeaderLogo = ({ onClick }: PageHeaderLogoProps) => {
  const { campaign } = useCampaign();
  const { isMobileLayout } = useContext(LayoutContext);

  if (!wwwDomain && !!campaign?.uiSettings.customLogo?.largeUrl && !isMobileLayout) {
    return (
      <Link to="/" onClick={onClick}>
        <img src={campaign?.uiSettings.customLogo.largeUrl} alt="SplittyTravel logo" />
      </Link>
    );
  }

  if (!wwwDomain && !!campaign?.uiSettings.customLogo?.smallUrl && isMobileLayout) {
    return (
      <Link to="/" onClick={onClick}>
        <img src={campaign?.uiSettings.customLogo.smallUrl} alt="SplittyTravel logo" />
      </Link>
    );
  }

  return (
    <Link to="/" onClick={onClick}>
      <img src={logo} alt="SplittyTravel logo" />
    </Link>
  );
};
