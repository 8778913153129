import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize } from 'components/common/Text/Text.types';
import { ThemeContext } from 'components/contexts/ThemeContext';

export interface InputCheckboxPrp extends PropsWithChildren<Record<string, unknown>> {
  value: boolean;
  setValue: (value: boolean) => void;
  containerClassName?: string;
  inputClassName?: string;
  className?: string;
  error?: string;
  disabled?: boolean;
  message?: string;
  alignCenter?: boolean;
}

export const InputCheckbox: React.FC<InputCheckboxPrp> = ({
  children,
  value,
  setValue,
  error,
  containerClassName,
  inputClassName,
  disabled,
  message,
  alignCenter = true,
}) => {
  const { overrideTheme } = useContext(ThemeContext);
  const Styled = useMemo(() => overrideTheme.InputCheckbox, [overrideTheme]);

  return (
    <div className={containerClassName || ''}>
      <label className={`flex ${alignCenter ? 'flex-middle' : ''}`}>
        <Styled.Checkbox
          type="checkbox"
          className={inputClassName}
          checked={value}
          onChange={(e) => setValue(e.target.checked)}
          disabled={disabled}
        />
        {children}
      </label>
      {error && (
        <Text size={TextSize.Small} color={TextColor.Danger} tag="div">
          {error}
        </Text>
      )}
      {message && (
        <Text size={TextSize.Small} color={TextColor.Muted} tag="div">
          {message}
        </Text>
      )}
    </div>
  );
};
