import styled, { css } from 'styled-components';
import Button from 'components/common/Button/ButtonStyled/DefaultButton.styled';
import StyledBng from 'style/CommonTrivago.styled';
import media from 'style/media';
import { flexGapRow } from 'style/polyfills/flexGap';
import { ifTrivago } from 'style/trivago';
import { backgroundBlue2, lightRoyalBlue, strongPink, superDarkGrey } from 'style/variables';

const BANNER_MOBILE_OFFSET = 12;

const Cookie = styled.div`
  z-index: 9999;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: calc(100% - (${BANNER_MOBILE_OFFSET}px * 2));
  padding: 8px 12px;
  line-height: 15px;
  font-size: 15px;

  left: ${BANNER_MOBILE_OFFSET}px;
  right: ${BANNER_MOBILE_OFFSET}px;
  bottom: ${BANNER_MOBILE_OFFSET}px;

  justify-content: space-between;

  border-radius: 12px;
  border: 2px transparent solid;
  background-image: linear-gradient(white, white), linear-gradient(to right, ${lightRoyalBlue} 0%, ${strongPink} 97%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  ${flexGapRow('16px')};

  ${media.desktop} {
    max-width: 550px;
    padding: 12px 17px;
    line-height: 17px;
    font-size: 16px;
    left: 23px;
    right: auto;
    bottom: 16px;
    justify-content: auto;
  }

  ${ifTrivago(css`
    font-family: Lato, serif;
    background: ${backgroundBlue2};
    border: solid 2px ${backgroundBlue2};
    color: ${superDarkGrey};
    max-width: calc(100% - (${BANNER_MOBILE_OFFSET}px * 2));
    padding: 12px 8px;
    font-size: 14px;
    line-height: normal;

    ${flexGapRow('8px')};
    ${media.desktop} {
      max-width: 516px;
      padding: 12px 17px;
      font-size: 14px;
      line-height: normal;
      ${flexGapRow('26px')};
    }
  `)}
`;

const ButtonOutline = styled(Button.ButtonOutlined)`
  height: 44px;
  font-weight: bold;
  color: ${superDarkGrey};

  ${media.mobile} {
    height: 40px;
  }
`;

const TrivagoButton = styled(StyledBng.BlueButton)`
  height: 44px;
  padding: 0 40px;
  font-size: 16px;

  ${media.mobile} {
    height: 39px;
    padding: 0 26px;
  }
`;

export default { Cookie, ButtonOutline, TrivagoButton };
