import useSearchParameter from 'common/hooks/useSearchParameter';
import React, { useCallback } from 'react';
import { SignInDialogContext } from 'TopContexts';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import SignInDialogMode from 'components/signin/SignInDialogMode';

interface SignInParams {
  'sign-mode': SignInDialogMode;
  'trip-id': number;
}

const SignInDialogProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [signInDialogMode, setDialogMode] = useSearchParameter<SignInParams, SignInDialogMode>('sign-mode');
  const [tripId] = useSearchParameter<SignInParams, number>('trip-id');

  const setSignInDialogMode = useCallback(
    (mode: SignInDialogMode | undefined) => {
      setDialogMode(mode);
      switch (mode) {
        case SignInDialogMode.SignIn:
          hotjarEvent(HotjarEvent.SignInClicked);
          break;
        case SignInDialogMode.SignUp:
          hotjarEvent(HotjarEvent.SignUpClicked);
          break;
        default:
      }
    },
    [setDialogMode],
  );

  return (
    <SignInDialogContext.Provider value={{ signInDialogMode, setSignInDialogMode, tripId }}>
      {children}
    </SignInDialogContext.Provider>
  );
};

export default SignInDialogProvider;
