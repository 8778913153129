import styled from 'styled-components';

const List = styled.ul`
  padding: 0;
  list-style: none;
  /*
 * Remove margin from the last-child
 */
  > * > :last-child {
    margin-bottom: 0;
  }
  /*
 * Style
 */
  > :nth-child(n + 2),
  > * > ul {
    margin-top: 10px;
  }
`;

export default {
  List,
};
