/**
 * @category Hotel Components
 * @packageDocumentation
 */
import { Replacement, useMask } from '@react-input/mask';
import { TFunction } from 'i18next';
import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FieldState } from 'components/common/InputField/InputField.types';
import { Placeholder } from 'components/common/Placeholder/Placeholder';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize } from 'components/common/Text/Text.types';
import { ThemeContext } from 'components/contexts/ThemeContext';
import { env } from 'environments/environment';
import { focusInput } from 'utils/inputValidationUtils';
import { basicInputValidation } from 'utils/validation';

export function passwordValidationRule(password: string) {
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$*-]{8,16}$/.test(password);
}

export function passwordErrorMessage(t: TFunction) {
  return t(
    'login-popup.invalid-password',
    'The password must contain 8-16 alphanumeric characters, at least 1 lower case, 1 upper case and 1 digit',
  );
}

function isNumber(value: string) {
  return /^[\d\s]+$/.test(value);
}

interface InputFieldProps {
  /**
   * Label above the input field
   */
  label: string;
  /**
   * Name
   */
  name?: string;
  /**
   * Unique id. Required for autocomplete
   */
  id: string;
  /**
   * Value
   */
  value: string;
  /**
   * The value for which validation is ignored
   */
  ignoredValidationValue?: string;
  /**
   * Placeholder value to display
   */
  placeholder?: string;
  /**
   * Callback to trigger on every value change
   * @param str
   */
  onChange?: (str: string, event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optional error message to display if validation rule fails
   */
  errorMessage?: string;
  /**
   * Optional success message
   */
  successMessage?: string;
  /**
   * Optional validation rule (feel free to use regexp here)
   * @param str
   */
  validationRule?: (str: string) => boolean;
  /**
   * Optional style of parent container
   */
  containerStyle?: string;
  /**
   * If true, will display a pink star near the label
   */
  required?: boolean;

  /**
   * Optional message, which, if provided is used instead of standard '{fieldname} is required' message
   */
  requireMessage?: string;

  /**
   * Html input type. Text or email, for example
   */
  inputType?: string;

  /**
   * The input mode content attribute is an enumerated attribute that specifies
   * what kind of input mechanism would be most helpful for users entering content.
   */
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';

  /**
   * Pass an input autocomplete value here. Set explicitly to 'off' if you are sure autocomplete is not required
   * Values reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete: string;
  /**
   * Basic validation just checks that input is at least two symbols long
   */
  doBasicValidation: boolean;
  /**
   * If provided, called when user press enter while the field is focused
   */
  onEnter?: () => void;
  onKeyDown?: (key: string, position: number | null, event: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * If provided, called on input focus
   */
  onFocus?: () => void;
  /**
   * If provided, called on input blur
   */
  onBlur?: () => void;
  /**
   * Optional inactiveness flag. When true, the input is blocked
   */
  inactive?: boolean;

  /**
   * Optional message to be shown below the field
   */
  helpMessage?: string;
  /**
   * Optional. If true, the placeholder is being drawn instead of the input.
   */
  isLoadingExternal?: boolean;

  allowHotJarRecording: boolean;

  maxLength?: number;
  autoFocus?: boolean;
  correct?: boolean;
  mask?: string;
  maskReplacement?: Replacement;
  checkEmptyWithCss?: boolean;
}

export interface AbstractValidatedField {
  invalidate: (focus: boolean, checkForEmpty?: boolean) => string | undefined;
}

export interface InputFieldRef extends AbstractValidatedField {
  setIsError: (i18nMessage?: string, focus?: boolean) => void;
  focus?: () => void;
}

const getFieldStateStyle = (state: FieldState) => {
  switch (state) {
    case FieldState.basicError:
    case FieldState.emptyError:
    case FieldState.error:
      return 'danger';
    case FieldState.success:
      return 'success';
    default:
      return '';
  }
};

/**
 * Displays a (after design - styled) input field that can show the error messages if validationRule() returns false
 * @param label
 * @param initialValue
 * @param placeholder
 * @param onChange
 * @param validationRule
 * @param errorMessage
 * @param containerStyle
 * @param required
 * @param inputType
 * @param onEnter
 * @constructor
 */
// eslint-disable-next-line react/display-name
const InputField = forwardRef(
  (
    {
      id,
      label,
      name,
      value,
      ignoredValidationValue,
      placeholder,
      onChange,
      doBasicValidation,
      errorMessage,
      containerStyle,
      required,
      inputType,
      inputMode,
      autocomplete,
      validationRule,
      onEnter,
      onKeyDown,
      onFocus,
      onBlur,
      inactive,
      helpMessage,
      requireMessage,
      isLoadingExternal,
      allowHotJarRecording,
      maxLength,
      autoFocus,
      correct,
      mask,
      maskReplacement,
      checkEmptyWithCss,
    }: InputFieldProps,
    ref,
  ) => {
    const [t] = useTranslation();
    const [focused, setFocused] = useState(false);
    const [state, setState] = useState<FieldState>(FieldState.basic);
    const [stateStyle, setStateStyle] = useState<string>(getFieldStateStyle(state));
    const [dirty, setDirty] = useState<boolean | null>(null);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const { overrideTheme } = useContext(ThemeContext);

    const Styled = useMemo(() => overrideTheme.InputField, [overrideTheme]);

    const commonRef = useRef<HTMLInputElement>(null);
    const maskRef = useMask({ mask, replacement: maskReplacement });
    const inputRef = mask && maskReplacement ? maskRef : commonRef;

    useEffect(() => {
      setStateStyle(getFieldStateStyle(state));
    }, [state]);

    const innerValidation = useCallback(
      (checkForEmpty: boolean) => {
        let _errorText;

        if (!ignoredValidationValue || value !== ignoredValidationValue) {
          if (value.length > 0) {
            if (doBasicValidation && !basicInputValidation(value)) {
              setState(FieldState.basicError);
              _errorText = t('validation.oneSymbolError', '{field} must be longer than 1 letter', {
                field: label,
              });
            }
            if (validationRule && !validationRule(value)) {
              setState(FieldState.error);
              _errorText = errorMessage;
            }
          } else if (required && checkForEmpty) {
            setState(FieldState.emptyError);
            _errorText = requireMessage || t('validation.emptyField', '{field} is required', { field: label });
          }
        } else {
          setState(FieldState.basic);
        }

        setErrorText(_errorText);

        return _errorText;
      },
      [
        doBasicValidation,
        ignoredValidationValue,
        required,
        validationRule,
        value,
        t,
        errorMessage,
        label,
        requireMessage,
      ],
    );

    useEffect(() => {
      setState(FieldState.basic);
      setErrorText(undefined);
    }, [value, required]);

    // if dirty === null then dirty uninitialized
    // revalidation if validationRule changed
    useEffect(() => {
      setDirty((prev) => prev !== null);
    }, [validationRule]);

    useEffect(() => {
      if (dirty && !focused) {
        innerValidation(false);
        setDirty(false);
      }
    }, [dirty, focused, innerValidation]);

    useImperativeHandle(ref, () => ({
      invalidate(focus: boolean, checkForEmpty?: boolean) {
        const _errorMessage = innerValidation(checkForEmpty ?? true);

        if (_errorMessage && focus) {
          focusInput(inputRef);
        }

        if (!_errorMessage) {
          setState(FieldState.basic);
        }

        return _errorMessage;
      },
      setIsError(i18nMessage?: string, focus = true) {
        if (i18nMessage) {
          setState(FieldState.error);
          setErrorText(i18nMessage ?? errorMessage);
        }
        if (focus) {
          focusInput(inputRef);
        }
      },
      setIsSuccess() {
        setState(FieldState.success);
      },
    }));

    const onChangeValue = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (onChange && (newValue.length === 0 || inputType !== 'number' || isNumber(newValue))) {
          onChange(newValue, e);
        }

        setErrorText(undefined);
      },
      [inputType, onChange],
    );

    const onKeyDownInput = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
          onEnter();

          return;
        }

        if (onKeyDown) {
          onKeyDown(e.key, e.currentTarget.selectionStart, e);
        }
      },
      [onKeyDown, onEnter],
    );

    const onFocusInput = useCallback(() => {
      setFocused(true);
      if (onFocus) {
        onFocus();
      }
    }, [onFocus]);

    const onBlurInput = useCallback(() => {
      setFocused(false);
      innerValidation(true);
      if (onBlur) {
        onBlur();
      }
    }, [innerValidation, onBlur]);

    return (
      <Styled.Container className={`width-1-1 ${containerStyle}`} empty={!value} checkEmptyWithCss={checkEmptyWithCss}>
        <Placeholder type="text" ready={!isLoadingExternal} showLoadingAnimation>
          <Styled.InputBlock>
            <Styled.Input
              id={id}
              ref={inputRef}
              name={name}
              onFocus={onFocusInput}
              onBlur={onBlurInput}
              className={`${stateStyle} ${allowHotJarRecording ? 'data-hj-allow' : 'data-hj-suppress'}`}
              state={state}
              type={inputType === 'number' ? 'text' : inputType}
              inputMode={inputMode}
              autoCapitalize={inputMode === 'email' ? 'off' : undefined}
              placeholder={placeholder || ' '}
              value={value}
              onChange={onChangeValue}
              autoComplete={autocomplete}
              maxLength={maxLength || (doBasicValidation ? env.inputs.basicMaxLength : undefined)}
              onKeyDown={onKeyDownInput}
              autoFocus={autoFocus}
              correct={correct}
              disabled={!!inactive}
            />
            {label && <Styled.InputLabel htmlFor={id}>{label}</Styled.InputLabel>}
          </Styled.InputBlock>
        </Placeholder>
        {helpMessage && (
          <Text size={TextSize.Small} color={TextColor.Muted} tag="div">
            {helpMessage}
          </Text>
        )}
        {errorText && <Styled.ErrorText id={`${id}-${state}`}>{errorText}</Styled.ErrorText>}
      </Styled.Container>
    );
  },
);

export default InputField;
