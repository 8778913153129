import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface SignOutLinkProps {
  onClick: () => void;
}

const SignOutLink: React.FC<SignOutLinkProps> = ({ onClick }) => {
  const [t] = useTranslation();
  const text = useMemo(() => t('coomon.menu.logout', 'Sign Out'), [t]);

  return (
    <a
      id="signoutLink"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      href="/"
    >
      {text}
    </a>
  );
};

export default SignOutLink;
