import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ClientErrorCode } from 'errors/clientError';
import { AvailableErrors } from 'errors/errorData';

const useOauthSignInErrors = (): AvailableErrors => {
  const [t] = useTranslation();
  const setToast = useContext(ToastContext);

  return useMemo(
    () => ({
      known: [
        { clientCodes: [ClientErrorCode.GooglePopupClosedByUser, ClientErrorCode.FacebookLoginCancelled] },
        {
          clientCodes: [ClientErrorCode.FacebookNotLoaded],
          action: () => setToast(t('oauth.not-loaded', 'Facebook is blocked in your browser.'), ToastType.Error),
        },
      ],
      default: {
        action: () => setToast(t('oauth.fail', 'Something went wrong. Please try again later.'), ToastType.Error),
      },
    }),
    [setToast, t],
  );
};

export default useOauthSignInErrors;
