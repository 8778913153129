import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PRICE_GUARANTEE_ROUTE } from 'routeList';

interface PriceGuaranteeLinkProps {
  onClick: () => void;
}

const PriceGuaranteeLink: React.FC<PriceGuaranteeLinkProps> = ({ onClick }) => (
  <Link id="priceGuaranteeLink" to={PRICE_GUARANTEE_ROUTE} onClick={onClick}>
    <Trans i18nKey="footer.price-guarantee">Price Guarantee</Trans>
  </Link>
);

export default PriceGuaranteeLink;
