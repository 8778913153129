import { getInitialCheckin, getInitialCheckout } from 'utils/dateUtils';
import { GoogleHotelParameters, SkyScannerHotelParameters, TripAdvisorHotelParameters } from 'utils/queryParameters';

export const calculateCheckinCheckout = (
  queryCheckin: string,
  queryCheckout: string,
  timeToTravel: string | undefined,
  lengthOfStay: string | undefined,
) => {
  let ttt: number | undefined;
  let los: number | undefined;

  if (timeToTravel) {
    ttt = Number(timeToTravel);

    if (!Number.isFinite(ttt) || ttt < 0) {
      ttt = undefined;
    }
  }

  if (lengthOfStay) {
    los = Number(lengthOfStay);

    if (!Number.isFinite(los) || los <= 0) {
      los = undefined;
    }
  }

  const checkin = getInitialCheckin(queryCheckin, ttt);
  const checkout = getInitialCheckout(checkin, queryCheckout, los);

  return { checkin, checkout };
};

export function getHotelQueryParams(
  useAllQuery: boolean,
  query: TripAdvisorHotelParameters | SkyScannerHotelParameters,
): GoogleHotelParameters | TripAdvisorHotelParameters | SkyScannerHotelParameters {
  if (useAllQuery) {
    return { ...query, language: query.userLanguage };
  }

  return {
    hotelId: query.hotelId,
    campaignName: query.campaignName,
    numOfRooms: query.numOfRooms,
    priceDisplayedTotal: query.priceDisplayedTotal,
    priceDisplayedTax: query.priceDisplayedTax,
    device: query.device,
    currency: query.currency,
    userCountry: query.userCountry,
    language: query.userLanguage,
    site: query.site,
    rule: query.rule,
    externalCampaignId: query.externalCampaignId,
    promoted: query.promoted,
    defaultDate: query.defaultDate,
    adType: query.adType,
    utm_source: query.utm_source,
    trv_reference: query.trv_reference,
    redirect_id: query.redirect_id,
  };
}
