import React, { useEffect, useMemo } from 'react';
import { SettingsContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useWatchCampaign } from 'atoms/hooks/useWatchCampaign';
import { useWatchSession } from 'atoms/hooks/useWatchSession';
import { useCountrySettings } from 'contexts/settingsHooks/useCountrySettings';
import { useCurrencySettings } from 'contexts/settingsHooks/useCurrencySettings';
import { useLanguageSettings } from 'contexts/settingsHooks/useLanguageSettings';
import { useStateSettings } from 'contexts/settingsHooks/useStateSettings';
import { env } from 'environments/environment';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';

const SettingsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  useWatchSession();
  useWatchCampaign();

  const { campaign } = useCampaign();

  const { languageCode, setLanguageCode } = useLanguageSettings();
  const { currencyCode, setCurrencyCode } = useCurrencySettings();
  const { countryCode, setCountryCode } = useCountrySettings();
  const { stateCode, setStateCode } = useStateSettings();

  const [campaignName, rateDisplayModel, priceRoundingMethod] = useMemo(
    () => [
      campaign?.name || env.campaign.fallbackName,
      campaign?.rateDisplayModel || env.campaign.fallbackRateDisplayModel,
      campaign?.uiSettings.priceRoundingMethod || env.campaign.fallbackPriceRoundingMethod,
    ],
    [campaign],
  );

  useEffect(() => {
    LogInfoMemoryStorage.campaignName = campaignName;
  }, [campaignName]);

  useEffect(() => {
    LogInfoMemoryStorage.currencyCode = currencyCode;
  }, [currencyCode]);

  useEffect(() => {
    LogInfoMemoryStorage.languageCode = languageCode;
  }, [languageCode]);

  useEffect(() => {
    LogInfoMemoryStorage.countryCode = countryCode;
  }, [countryCode]);

  useEffect(() => {
    LogInfoMemoryStorage.stateCode = stateCode;
  }, [stateCode]);

  return (
    <SettingsContext.Provider
      value={{
        countryCode,
        stateCode,
        languageCode,
        campaignName,
        currencyCode,
        rateDisplayModel,
        priceRoundingMethod,
        setCountryCode,
        setStateCode,
        setCurrencyCode,
        setLanguageCode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
