import styled from 'styled-components';
import media from 'style/media';
import {
  backgroundGrey3,
  backgroundPeach,
  backgroundPink,
  brightOrange,
  colorPink,
  lightRoyalBlue,
  strongPink,
} from 'style/variables';
import HomeViewStyled from 'views/home/HomeView.styled';

const Grid = styled.div`
  display: grid;
  margin-left: -18px;
  margin-top: -18px;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'price awards mission'
    'counter counter mission';

  ${media.mobile} {
    margin: -16px 0 0 0;
    width: 100%;

    grid-template-columns: unset;
    grid-template-areas:
      'price'
      'awards'
      'counter';
  }
`;

const Section = styled.div<{ gridArea: string }>`
  display: flex;
  grid-area: ${({ gridArea }) => gridArea};
  box-sizing: border-box;

  margin-left: 18px;
  margin-top: 18px;
  flex-grow: 1;

  ${media.mobile} {
    margin: 16px 0 0 0;
  }
`;

const BenefitImage = styled.img`
  height: 70px;
  width: 70px;

  margin-right: 25px;

  ${media.mobile} {
    height: 60px;
    width: 60px;
  }
`;

const BenefitLabel = styled.h3`
  margin-bottom: 8px;
  font-weight: bold;
`;

const BenefitItem = styled.div`
  display: flex;
  padding: 25px;
  border-radius: 26px;
  flex-grow: 1;
  height: 100%;
  min-height: 179px;

  box-sizing: border-box;

  ${media.mobile} {
    min-height: 156px;
  }
`;

const GuaranteeItem = styled(BenefitItem)`
  background: ${backgroundGrey3};

  ${BenefitLabel}:first-child {
    color: ${lightRoyalBlue};
  }
`;

const LeadingItem = styled(BenefitItem)`
  background: ${backgroundPeach};

  ${BenefitLabel}:first-child {
    color: ${brightOrange};
  }
`;

const SavingItem = styled(BenefitItem)`
  background: ${backgroundPink};

  ${BenefitLabel}:first-child {
    color: ${colorPink};
  }

  ${media.desktop} {
    div:nth-child(2) {
      max-width: 300px;
    }
  }
`;

const AboutAsItem = styled(BenefitItem)`
  flex-direction: column;
  align-items: center;
  border: 2px transparent solid;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, ${lightRoyalBlue}, ${strongPink} 57%, ${brightOrange});
  background-origin: border-box;
  background-clip: padding-box, border-box;

  ${BenefitLabel}:first-child {
    margin-bottom: 36px;
    font-weight: 600;
  }
`;

export default {
  BenefitImage,
  BenefitLabel,
  GuaranteeItem,
  LeadingItem,
  AboutAsItem,
  SavingItem,
  Grid,
  Section,
  Container: HomeViewStyled.Container,
};
