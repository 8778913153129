/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { List } from 'components/common/List/List';
import StyledTrivago from 'style/CommonTrivago.styled';
import { rem } from 'style/functions';
import { flexGapColumn } from 'style/polyfills/flexGap';
import Styled from 'style/styles';

const BaseLineHeight = styled(Styled.BaseLineHeight)`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${flexGapColumn('16px')};

  padding: 24px;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: sticky;
  bottom: 0;

  width: 100%;
  padding: 14px 24px 19px;
  box-sizing: border-box;

  z-index: 100;
`;

const Button = styled(StyledTrivago.BlueButton)`
  width: 100%;
  height: 42px;
  font-size: 16px;
`;

const BurgerScrollContainer = styled.div`
  padding-top: 26px;
  width: 100%;
  overflow-y: auto;
`;

const ImageWrapper = styled.div`
  height: 130px;
`;

const NavList = styled(List)`
  li + li {
    margin-top: ${rem(16)} !important;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
`;

export default {
  Container,
  Content,
  ButtonSection,
  Button,
  BurgerScrollContainer,
  ImageWrapper,
  NavList,
  IconWrapper,
  BaseLineHeight,
};
