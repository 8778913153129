import styled from 'styled-components';
import media from 'style/media';

const Veil = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const Loader = styled.div`
  & > div {
    width: fit-content !important;
    height: fit-content !important;
  }

  svg {
    display: block;

    ${media.mobile} {
      width: 40px !important;
    }

    ${media.desktop} {
      width: 60px !important;
    }
  }
`;

const BookAndGoLogo = styled.img`
  margin-bottom: 2px;

  ${media.mobile} {
    height: 22.6px;
  }

  ${media.desktop} {
    height: 49.3px;
  }
`;

export default { Veil, Content, Loader, BookAndGoLogo };
