import styled from 'styled-components';
import StyledSelectInput from 'components/common/SelectInput/SelectInputStyled/DefaultSelectInput.styled';
import StyledTrivagoSelectInput from 'components/common/SelectInput/SelectInputStyled/TrivagoSelectInput.styled';
import media from 'style/media';
import { black, borderColorLight, charcoalGrey } from 'style/variables';

const HeaderItem = styled.div`
  position: relative !important;
  height: 100%;
`;

const DisplayValue = styled.span`
  display: flex;
  align-items: center;
  color: ${charcoalGrey};

  ${media.desktop} {
    height: 68px;
  }

  font-size: 14px;

  ${media.mobile} {
    height: 40px;
    width: 140px;
    box-sizing: border-box;
    padding: 0 20px 0 10px;

    border: 1px solid ${borderColorLight};
    border-radius: 4px;
  }
`;

const ExpandIcon = styled.span`
  pointer-events: none;

  ${media.desktop} {
    margin-left: 7px;
  }

  ${media.mobile} {
    margin-top: 10px;
  }
`;

const TrivagoDisplayValue = styled.span<{ invertColor?: boolean }>`
  color: ${({ invertColor }) => (invertColor ? 'white' : 'black')};
  font-size: 16px;
  line-height: 18px;

  ${media.mobile} {
    margin-top: 23px;
  }
`;

const DropIcon = styled.img`
  position: absolute;
  width: 24px;
  right: 2px;
  top: 50%;

  transform: translateY(-50%);
`;

const TrivagoMobileContainer = styled.div`
  ${StyledTrivagoSelectInput.SelectField} {
    ${media.mobile} {
      width: auto;
      padding: 5px 11px 8px 18px;
      box-sizing: border-box;

      outline: 0;
      border: 1px solid ${borderColorLight};
      border-radius: 4px;
    }
  }
  ${StyledTrivagoSelectInput.DisplayValue} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

const SelectWrapper = styled.div`
  ${StyledSelectInput.SelectField}:hover {
    ${DisplayValue} {
      color: ${black};
    }
  }
`;

export default {
  HeaderItem,
  DisplayValue,
  TrivagoDisplayValue,
  ExpandIcon,
  DropIcon,
  TrivagoMobileContainer,
  SelectWrapper,
};
