import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerErrorCode } from 'backend/serverError';
import { AvailableErrors } from 'errors/errorData';
import { showError } from 'errors/errorHandlers';

const useHotelInfoErrors = (): AvailableErrors => {
  const { t } = useTranslation();

  const texts = useMemo(
    () => ({
      notFound: t('errors.hotel.basicInfo', 'Hotel not found'),
      generic: t('errors.hotel.generic', 'Sorry, this booking is currently unavailable. Try something else.'),
    }),
    [t],
  );

  return useMemo(() => {
    return {
      known: [
        {
          serverCodes: [ServerErrorCode.ItemNotFound, ServerErrorCode.NotFound],
          action: (errorId, status) => showError(errorId, status, texts.notFound),
        },
      ],
      default: {
        action: (errorId, status) => showError(errorId, status, texts.generic),
      },
    };
  }, [texts.generic, texts.notFound]);
};

export default useHotelInfoErrors;
