import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import useQuery from 'common/hooks/useQuery';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchFormQuery, SearchFormUrlParameters } from 'components/searchForm/query';
import { env } from 'environments/environment';
import { processError } from 'errors/errorUtils';
import useSearchFormParametersErrors from 'errors/useSearchFormParametersErrors';
import { validateOccupancy } from 'utils/occupancyUtils';
import { isHotelOrDestinationRoute, isHotelRoute } from 'utils/uriUtils';
import { validateDates, validateDateStrong } from 'utils/validation';

const validateSearchQuery = (q: SearchFormUrlParameters, path: string): SearchFormQuery & { error: unknown } => {
  let occupancy: RoomOccupancy[] | undefined;
  let occupancyValid = false;
  let checkinValid = false;
  let checkoutValid = false;
  let error;

  try {
    occupancy = q.occupancy ? JSON.parse(q.occupancy) : undefined;
    occupancyValid = !occupancy || validateOccupancy(occupancy);
  } catch (err) {
    if (isHotelOrDestinationRoute(path)) {
      error = err;
    }
  }

  try {
    const offsetHours = isHotelRoute(path) ? env.times.offsetTimezoneHours : undefined;

    if (q.checkin && validateDateStrong(q.checkin, offsetHours)) {
      checkinValid = true;
    }
    if (q.checkout && validateDateStrong(q.checkout) && validateDates(q.checkin, q.checkout)) {
      checkoutValid = true;
    }
  } catch (err) {
    if (occupancyValid && isHotelOrDestinationRoute(path)) {
      error = err;
    }
  }

  return {
    occupancy: occupancyValid ? occupancy : undefined,
    checkin: checkinValid ? q.checkin : undefined,
    checkout: checkoutValid ? q.checkout : undefined,
    placeId: q.placeId,
    bounds: q.bounds,
    error,
  };
};

const useSearchFormParameters = () => {
  const location = useLocation();
  const errors = useSearchFormParametersErrors();
  const { placeId, checkin, checkout, occupancy, bounds } = useQuery<SearchFormUrlParameters>();

  const validatedSearchQuery = useMemo(
    () => validateSearchQuery({ placeId, checkin, checkout, occupancy, bounds }, location.pathname),
    [bounds, checkin, checkout, location.pathname, occupancy, placeId],
  );

  useEffect(() => {
    if (validatedSearchQuery.error) {
      processError(validatedSearchQuery.error, errors);
    }
  }, [errors, validatedSearchQuery.error]);

  return validatedSearchQuery;
};

export default useSearchFormParameters;
