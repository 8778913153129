/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import footerLogo from 'assets/images/footer/footer-logo.svg';
import riskFreeIcon from 'assets/images/footer/risk-free-icon.svg';
import verifiedIcon from 'assets/images/footer/verifyed-icon.svg';
import bestPrice2 from 'assets/images/home/best-price-icon.svg';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import Styled from 'components/header-footer/PageFooter/PageFooter.styled';
import TrivagoPageFooter from 'components/header-footer/TrivagoPageFooter';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import CareersLink from 'components/mobile/mobileLinks/CareersLink';
import { ABOUTUS_ROUTE, FAQ_ROUTE, PRICE_GUARANTEE_ROUTE, PRIVACY_ROUTE, TERMS_ROUTE } from 'routeList';

/**
 * Global footer for all the pages
 */
export const PageFooter = () => {
  const contactTranslations = useContactTranslations();
  const { isTrivago } = useContext(TrivagoContext);
  const { isMobileLayout } = useContext(LayoutContext);

  if (isTrivago) {
    return <TrivagoPageFooter />;
  }

  if (isMobileLayout) {
    return null;
  }

  return (
    <Styled.Footer>
      <div className="container">
        <Styled.Grid>
          <Styled.Section gridArea={'grantee'} light>
            <Styled.SectionImage src={bestPrice2} />
            <div>
              <Styled.SectionHeader>
                <Trans i18nKey="common.price-guarantee.title">Best Price Guarantee</Trans>
              </Styled.SectionHeader>

              <Styled.SectionText>
                <Trans i18nKey="common.price-guarantee.found-lower-price">Found a lower price on your deal?</Trans>
                <span> </span>
                <Trans i18nKey="common.price-guarantee.let-us-know">Let us know, get a $50 coupon AND a refund!</Trans>
              </Styled.SectionText>
            </div>
          </Styled.Section>

          <Styled.Section gridArea={'verified'} light>
            <Styled.SectionImage src={verifiedIcon} />
            <div>
              <Styled.SectionHeader>
                <Trans i18nKey="common.verified.title">Verified &amp; Secured</Trans>
              </Styled.SectionHeader>

              <Styled.SectionText>
                <Trans i18nKey="algotels.common.verified.text">
                  We will never sell your personal information, Algotels uses secure transmission to protect your
                  personal information
                </Trans>
              </Styled.SectionText>
            </div>
          </Styled.Section>

          <Styled.Section gridArea={'free-risk'} light>
            <Styled.SectionImage src={riskFreeIcon} />
            <div>
              <Styled.SectionHeader>
                <Trans i18nKey="common.risk-free.title">100% Risk Free</Trans>
              </Styled.SectionHeader>

              <Styled.SectionText>
                <Trans i18nKey="algotels.common.risk-free.text">
                  Our powerful travel algorithms allow us to cover more search options and find you the best deals.
                </Trans>
              </Styled.SectionText>
            </div>
          </Styled.Section>

          <Styled.Section gridArea={'company'}>
            <div>
              <Styled.SectionLabel>
                <Trans i18nKey="algotels.footer.sections.about-company">Company</Trans>
              </Styled.SectionLabel>
              <Styled.SectionText>
                <Trans i18nKey="algotels.common.what-is-splitty.whatIsSpliity">
                  Algotels™ is the world&lsquo;s first online hotel booking platform that allows users to take
                  advantage of amazing hotel deals available only by combining offers from multiple sources to create a
                  unique room package.
                </Trans>
              </Styled.SectionText>
            </div>
          </Styled.Section>

          <Styled.Section gridArea={'info'} className="flex-around">
            <div>
              <Styled.SectionLabel>
                <Trans i18nKey="footer.sections.company">COMPANY</Trans>
              </Styled.SectionLabel>

              <Styled.FooterList>
                <Styled.FooterListItem>
                  <Link to={ABOUTUS_ROUTE}>
                    <Trans i18nKey="footer.about">About Us</Trans>
                  </Link>
                </Styled.FooterListItem>
                <Styled.FooterListItem>
                  <CareersLink />
                </Styled.FooterListItem>
              </Styled.FooterList>

              <Styled.SectionLabel>
                <Trans i18nKey="footer.sections.legal">Legal</Trans>
              </Styled.SectionLabel>

              <Styled.FooterList>
                <Styled.FooterListItem>
                  <Link to={TERMS_ROUTE}>
                    <Trans i18nKey="footer.terms">Terms and Conditions</Trans>
                  </Link>
                </Styled.FooterListItem>
                <Styled.FooterListItem>
                  <Link to={PRIVACY_ROUTE}>
                    <Trans i18nKey="footer.privacy">Privacy Policy</Trans>
                  </Link>
                </Styled.FooterListItem>
              </Styled.FooterList>
            </div>

            <div>
              <Styled.SectionLabel>
                <Trans i18nKey="footer.sections.support">Support</Trans>
              </Styled.SectionLabel>

              <Styled.FooterList>
                <Styled.FooterListItem>
                  <Link to={FAQ_ROUTE}>
                    <Trans i18nKey="footer.faq">FAQ</Trans>
                  </Link>
                </Styled.FooterListItem>
                <Styled.FooterListItem>
                  <Link to={PRICE_GUARANTEE_ROUTE}>
                    <Trans i18nKey="footer.price-guarantee">Price Guarantee</Trans>
                  </Link>
                </Styled.FooterListItem>
              </Styled.FooterList>
            </div>

            <div>
              <Styled.SectionLabel>
                <Trans i18nKey="footer.sections.connect">CONNECT WITH US</Trans>
              </Styled.SectionLabel>

              <Styled.FooterList>
                <Styled.FooterListItem>
                  <a href={`tel:${contactTranslations.phone}`}>{contactTranslations.phone}</a>
                </Styled.FooterListItem>
                <Styled.FooterListItem>
                  <a href={`mailto:${contactTranslations.partnerWithUs}`}>
                    <Trans i18nKey="footer.partner-with-us">Partner With Us</Trans>
                  </a>
                </Styled.FooterListItem>
                <li>
                  <a href={`mailto:${contactTranslations.emailUs}`}>
                    <Trans i18nKey="footer.email-us">Email Us</Trans>
                  </a>
                </li>
              </Styled.FooterList>
            </div>
          </Styled.Section>
        </Styled.Grid>

        <Styled.Copyright className="footer-bottom flex flex-middle">
          <img src={footerLogo} alt="algotels logo" />
          <Trans
            i18nKey="footer.copyright"
            defaults="© {year} Holisto LTD. All Rights Reserved."
            values={{ year: new Date().getFullYear() }}
          />
        </Styled.Copyright>
      </div>
    </Styled.Footer>
  );
};
