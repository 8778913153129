/**
 * @category Utils
 * @packageDocumentation
 */
import { MS_IN_DAY, parseDate } from 'common/utils/date';
import { TFunction } from 'i18next';
import { env } from 'environments/environment';

// Intl.DateTimeFormat may affect performance if used too much responsively;
// use with useMemo when possible
export function getDayTranslated(date: Date, lang: string): string {
  return new Intl.DateTimeFormat(lang, { day: 'numeric' }).format(date);
}

export function getYearTranslated(date: Date, lang: string): string {
  return new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(date);
}

export function getMonthTranslated(date: Date, lang: string): string {
  return new Intl.DateTimeFormat(lang, { month: 'short' }).format(date);
}

export function formatDate(date: Date | undefined, lang: string): string {
  if (!date) {
    return 'n/a';
  }

  const m = getMonthTranslated(date, lang);
  const da = getDayTranslated(date, lang);
  const ye = getYearTranslated(date, lang);

  return `${m} ${da}, ${ye}`;
}

export function formatTime(date: Date | undefined, lang: string): string {
  if (!date) {
    return 'n/a';
  }

  return date.toLocaleTimeString(lang);
}

export function formatDateMonthAndDate(d: Date | undefined, lang: string): string {
  if (!d) {
    return 'n/a';
  }

  const m = getMonthTranslated(d, lang);
  const da = getDayTranslated(d, lang);

  return `${m} ${da}`;
}

const DATE_MIN_NUMERIC = 2;

export function startOfDay(date: Date) {
  const returnDate = new Date(date);

  returnDate.setHours(0, 0, 0, 0);

  return returnDate;
}

export const areDatesEqual = (date1: Date | null | undefined, date2: Date | null | undefined) =>
  !!date1 && !!date2 && startOfDay(date1).getTime() === startOfDay(date2).getTime();

export function startOfToday(offsetHours?: number) {
  const start = new Date();

  if (offsetHours) {
    start.setHours(start.getHours() - offsetHours, start.getMinutes() + start.getTimezoneOffset());
  }
  start.setHours(0, 0, 0, 0);

  return start;
}

export function startOfMonth(date: Date) {
  const returnDate = new Date(date);

  returnDate.setDate(1);
  returnDate.setHours(0, 0, 0, 0);

  return returnDate;
}

export function isInPast(date: string | undefined, offsetHours?: number): boolean {
  const d = parseDate(date);

  return !!d && startOfToday(offsetHours) > d;
}

export function isInFuture(date: string, todayShiftMillis = 0): boolean | undefined {
  const d = parseDate(date);

  if (!d) {
    return undefined;
  }

  return new Date().getTime() + todayShiftMillis < d.getTime();
}

/**
 * Converts date to "YYYY-MM-DD"
 * @param d date
 */
export function stringifyDate(d: Date): string {
  let month: string = (d.getMonth() + 1).toString();
  let day: string = d.getDate().toString();
  const year: string = d.getFullYear().toString();

  if (month.length < DATE_MIN_NUMERIC) {
    month = `0${month}`;
  }
  if (day.length < DATE_MIN_NUMERIC) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

export function shiftDays(date: Date, daysShift: number) {
  const d = new Date(date);

  d.setDate(d.getDate() + daysShift);

  return d;
}

export function getInitialCheckin(value?: string, ttt = env.searchBar.checkinShiftDays) {
  const parsedDate = parseDate(value);

  if (parsedDate) {
    return stringifyDate(parsedDate);
  }

  return stringifyDate(shiftDays(startOfToday(), ttt));
}

export function getInitialCheckout(checkin: string | undefined, value?: string, los = env.searchBar.lengthOfStay) {
  const date = parseDate(value);

  if (date) {
    return stringifyDate(date);
  }

  const parsedCheckin = parseDate(checkin);

  if (parsedCheckin) {
    return stringifyDate(shiftDays(parsedCheckin, los));
  }

  return stringifyDate(shiftDays(new Date(), env.searchBar.checkinShiftDays + los));
}

/**
 * Calculates amount of nights between two set dates, passed as date strings
 * @param firstDate
 * @param secondDate
 */
export const calculateNightsBetweenDates = (
  firstDate: Date | null | undefined,
  secondDate: Date | null | undefined,
) => {
  if (!firstDate || !secondDate) {
    return undefined;
  }
  const result = Math.round((secondDate.getTime() - firstDate.getTime()) / MS_IN_DAY);

  return Math.max(result, 0);
};

export const calculateDaysBetweenDates = (begin: number, end: number) => Math.floor((end - begin) / MS_IN_DAY);

export const isPeriodOverMaxNights = (start: Date | null | undefined, end: Date | null | undefined) => {
  const nights = calculateNightsBetweenDates(start, end);

  return !!nights && nights > env.searchBar.maxNights;
};

export const getPeriodOverMaxNightsMessage = (t: TFunction) =>
  t(
    'errors.search.invalid-length-of-stay',
    'Booking can only be made for a maximum of 30 nights. Please enter different dates and try again.',
  );
