/* eslint-disable no-magic-numbers */
import styled, { createGlobalStyle, css } from 'styled-components';
import { StyledCloseButton } from 'components/common/modal/ModalCloseButton.styled';
import { fade } from 'style/animations.styled';
import media from 'style/media';
import { padding, paddingSmall } from 'style/padding.styled';
import { ifTrivago } from 'style/trivago';
import { white } from 'style/variables';

const MS_IN_S = 1000;

export const MODAL_ANIMATION_MS = 200;
export const MODAL_ANIMATION_S = MODAL_ANIMATION_MS / MS_IN_S;

const AnimatedModal = styled.div`
  animation-duration: ${MODAL_ANIMATION_S}s;
`;

const AnimatedModalDialog = styled(AnimatedModal)<{
  padding?: 'small' | 'remove'; // add new variants
  isButtonOutside?: boolean;
}>`
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  max-width: calc(100% - 0.01px) !important;
  background: ${white};
  display: flow-root;
  ${(props) => {
    switch (props.padding) {
      case 'small':
        return paddingSmall;
      case 'remove':
        return css`
          padding: 0 !important;
        `;
      default:
        return css`
          padding: 30px 30px;
        `;
    }
  }}

  ${StyledCloseButton} {
    position: absolute;
    top: ${({ isButtonOutside }) => (isButtonOutside ? 0 : 15)}px;
    right: ${({ isButtonOutside }) => (isButtonOutside ? 0 : 15)}px;
  }
`;

const AbstractModalVeil = styled(AnimatedModal)<{
  updated?: boolean;
  omitPadding?: boolean;
  positionToCenter?: boolean;
  visible?: boolean;
  innerVisible?: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  ${ifTrivago(
    css`
      background: rgba(53, 70, 77, 0.7);
    `,
    css`
      background: rgba(0, 0, 0, 0.6);
    `,
  )}

  ${({ omitPadding }) => !omitPadding && padding}

  ${(props) =>
    props.updated &&
    css`
      padding-top: 60px;
      ${media.desktop} {
        padding: 60px;
      }
    `}

  ${({ positionToCenter }) =>
    positionToCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  ${({ visible, innerVisible }) =>
    !visible &&
    !innerVisible &&
    css`
      display: none !important;
    `}

  ${({ visible, innerVisible }) =>
    visible &&
    !innerVisible &&
    css`
      animation-name: ${fade};
      animation-duration: 0.3s;
      animation-timing-function: linear;
    `}

  ${({ visible, innerVisible }) =>
    !visible &&
    innerVisible &&
    css`
      animation-name: ${fade};
      animation-duration: 0.3s;
      animation-direction: reverse;
      animation-timing-function: ease-in;
    `}
`;

const BodyOverflowGlobalStyle = createGlobalStyle<{ visible: boolean }>`
  ${({ visible }) =>
    visible &&
    css`
      body {
        overflow: hidden;
      }
    `}
`;

export { BodyOverflowGlobalStyle, AbstractModalVeil, AnimatedModal, AnimatedModalDialog };
