/**
 * @category Backend
 * @packageDocumentation
 */
import { HotelExtended } from 'common/backend/api/hotel/hotelModel';
import { Place } from 'common/backend/api/place/placeModel';
import { Checkout, CreatedTripId, Trip } from 'common/backend/api/trip/tripModel';
import { ClientLocation } from 'backend/api/client/clientModel';
import { AbTest, Campaign } from 'backend/api/general/generalModel';
import { CampaignRequest } from 'backend/api/general/generalRequest';
import { HotelDeals } from 'backend/api/hotel/dealModel';
import { HotelDealsRequest, HotelInfoRequest, HotelSearchRequest } from 'backend/api/hotel/hotelRequest';
import { HotelOfferDetails, HotelsOffers, HotelsOffersQuery } from 'backend/api/hotel/hotelsOffersModel';
import { LanguageCode } from 'backend/api/model';
import { PlaceSearchRequest } from 'backend/api/place/placeRequest';
import { LanguageDependentRequest, LeadIdRequest } from 'backend/api/request';
import { CancellationFee, CancellationReason, Insurance, PaymentRequest } from 'backend/api/trip/tripModel';
import {
  CreateTripRequest,
  PaymentRequestChargeRequest,
  PaymentRequestDetailsRequest,
  SendEmailType,
} from 'backend/api/trip/tripRequest';
import Authenticator from 'backend/authenticator';
import getSplittyClient from 'backend/clientInit';
import { HotelSearchResultContainer } from 'backend/dataModel';
import { hotjarTag } from 'backend/hotjarTags';
import { SplittyClient } from 'backend/splittyClient';
import i18n from 'i18n';
import { convertLangCode } from 'utils/languageUtils';
import cookieBasedLeadStorage from 'utils/storage/cookie/CookieBasedLeadIdStorage';

/**
 * Main API for our UI which has the backend under it.
 * In addition to the backend client it has several caches
 * where it may store some data to nor overload the server with plenty of requests
 */
export class DataProvider {
  readonly client: SplittyClient;

  readonly authenticator: Authenticator;

  /**
   * @param client - REST client to the backend
   */
  constructor(client: SplittyClient) {
    this.client = client;
    this.authenticator = new Authenticator(client.userApiClient);
  }

  private static addLanguage<T extends LanguageDependentRequest>(obj: T): T {
    let query = obj;

    if (!obj.languageCode) {
      query = { ...obj };
      const langCode = convertLangCode(i18n.language);

      query.languageCode = langCode in LanguageCode ? langCode : LanguageCode.EN;
    }

    return query;
  }

  private static addLeadId<T extends LeadIdRequest>(obj: T, campaignName: string, hotelId: number): T {
    const leadId = cookieBasedLeadStorage.getLeadId(campaignName, hotelId);

    if (leadId) {
      return { ...obj, leadId };
    }

    return obj;
  }

  /**
   * Search hotels by hotel id, city, area, region or country.
   * @param payload
   * @param abortSignals
   */
  async getHotelSearchResults(
    payload: HotelSearchRequest,
    abortSignals?: AbortSignal[],
  ): Promise<HotelSearchResultContainer> {
    return this.client.getHotelSearchResult(DataProvider.addLanguage(payload), abortSignals).then(
      (response) =>
        ({
          hotels: response.data?.hotels,
          requestId: response.meta?.reqId,
          loading: !response.meta?.completed,
        }) as HotelSearchResultContainer,
    );
  }

  /**
   * Returns hotel complete information.
   * @param hotelId
   * @param payload
   * @param abortSignals
   */
  async getHotelInfo(
    hotelId: number,
    payload?: HotelInfoRequest,
    abortSignals?: AbortSignal[],
  ): Promise<HotelExtended> {
    return this.client
      .getHotelInfo(hotelId, DataProvider.addLanguage(payload || {}), abortSignals)
      .then((response) => response.data.hotel);
  }

  /**
   * Returns hotel relevant deals.
   * @param hotelId
   * @param payload
   * @param abortSignals
   */
  async getHotelDeals(hotelId: number, payload: HotelDealsRequest, abortSignals?: AbortSignal[]): Promise<HotelDeals> {
    const completePayload = DataProvider.addLeadId(payload, payload.campaignName, hotelId);

    return this.client.getHotelDeals(hotelId, completePayload, abortSignals).then((response) => {
      cookieBasedLeadStorage.setLeadId(response.meta?.leadId, payload.campaignName, hotelId);

      return response.data;
    });
  }

  async getHotelsOffers(payload: HotelsOffersQuery, abortSignals?: AbortSignal[]): Promise<HotelsOffers> {
    return this.client.getHotelsOffers(payload, abortSignals).then((response) => {
      return response.data;
    });
  }

  async trackHotelsOffers(payload: HotelOfferDetails): Promise<void> {
    return this.client.trackHotelsOffers(payload);
  }

  async getDestinations(payload: PlaceSearchRequest, abortSignals?: AbortSignal[]): Promise<Place[]> {
    return this.client
      .getPlaces(DataProvider.addLanguage(payload), abortSignals)
      .then((response) => response.data.places || []);
  }

  async getDestination(placeId: string): Promise<Place> {
    return this.client.getPlace(placeId).then((response) => response.data.place);
  }

  async getPaymentRequestDetails(payload: PaymentRequestDetailsRequest): Promise<PaymentRequest> {
    return this.client.getPaymentRequestDetails(payload).then((response) => response.data.paymentRequest);
  }

  async chargePaymentRequest(encryptedTripId: string, payload: PaymentRequestChargeRequest): Promise<CreatedTripId> {
    return this.client.chargePaymentRequest(encryptedTripId, payload).then((response) => response.data.trip);
  }

  /**
   * Creates a trip and returns the newly created object with its ID.
   * @param payload
   * @param ignoreDuplication
   */
  async createTrip(payload: CreateTripRequest, ignoreDuplication?: boolean): Promise<CreatedTripId> {
    return this.client.createTrip(payload, ignoreDuplication).then((response) => response.data.trip);
  }

  /**
   * Encrypts trip ID.
   * @param tripId
   * @param email
   */
  async encryptTripId(tripId: string, email: string): Promise<string> {
    return this.client.encryptTripId(tripId, email).then((response) => response.data.trip.encryptedTripId);
  }

  /**
   * Returns trip details.
   * @param encryptedTripId
   * @param email
   */
  async getTripDetails(encryptedTripId: string, email?: string): Promise<Trip> {
    return this.client.getTripDetails(encryptedTripId, { email }).then((response) => response.data.trip);
  }

  /**
   * Resolves client IP to location using ip-api service.
   * If IP wasn't sent from client, server side should find it based on request.
   */
  async getClientLocation(): Promise<ClientLocation> {
    return this.client.getClientLocation().then((response) => response.data.location);
  }

  /**
   * Uses checkoutId to get information about a checkout
   * @param checkoutId
   */
  async getCheckout(checkoutId: string): Promise<Checkout> {
    hotjarTag('checkout', checkoutId);

    return this.client.getCheckout(checkoutId).then((response) => response.data.checkout);
  }

  async getInsuranceQuotes(checkoutId: string, region: string, state: string): Promise<Insurance> {
    return this.client.getInsuranceQuotes(checkoutId, region, state).then((response) => response.data.insurance);
  }

  async getCampaign(payload: CampaignRequest): Promise<Campaign> {
    return this.client.getCampaign(payload).then((response) => response.data.campaign);
  }

  async getUserTrips(): Promise<Trip[]> {
    return this.client.getUserTrips().then((response) => response.data.trips);
  }

  async sendTripEmail(encryptedTripId: string, type: SendEmailType, recipient: string): Promise<void> {
    return this.client.sendTripEmail(encryptedTripId, recipient, {
      recipient,
      type,
    });
  }

  async cancelTrip(
    encryptedTripId: string,
    reason: CancellationReason | undefined,
    email: string | undefined,
  ): Promise<void> {
    return this.client.cancelTrip(encryptedTripId, reason, email);
  }

  calculateCancellationFee(encryptedTripId: string, email: string | undefined): Promise<CancellationFee> {
    return this.client
      .calculateCancellationFee(encryptedTripId, email)
      .then((response) => response.data.cancellationFee);
  }

  getABTesting(campaignId: number): Promise<AbTest[]> {
    return this.client.getABTesting(campaignId).then((response) => response.data.abTests || []);
  }

  getConvertRate(inputCurrencyCode: string, outputCurrencyCode: string): Promise<number> {
    return this.client.getConvertRate(inputCurrencyCode, outputCurrencyCode);
  }
}

let dataProvider: DataProvider;
let clientPromise: Promise<SplittyClient>;

/**
 * @returns DataProvider for everybody to use. It depends on the environment.
 * If the environment is mock, then mock splitty client used instead of the production one.
 * That is why the method is async - mock client is not a part of the distributve. It is lazy-loaded in mock configuration.
 */
export async function getDataProvider(): Promise<DataProvider> {
  if (!dataProvider) {
    // No need to make a lot of requests if we called simultaneously
    // from different async places
    if (!clientPromise) {
      clientPromise = getSplittyClient();
    }

    const client = await clientPromise;

    // Only the first thread needs to create new data provider
    // The other must use the same one
    if (!dataProvider) {
      dataProvider = new DataProvider(client);
    } else {
      return dataProvider;
    }
  }

  return dataProvider;
}
