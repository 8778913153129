import styled from 'styled-components';
import selectArrowIcon from 'assets/images/icons/trivagoV2/select-arrow-icon.svg';
import arrow from 'assets/images/icons/ui-kit/arrow-picker.svg';
import media from 'style/media';
import { trivagoBlackV2, trivagoDarkGrey, trivagoGrey } from 'style/trivagoVaribles';
import {
  white,
  disabledBackgroundGray,
  gray,
  modalBackground,
  focusSelectPeoplePickerBorderColor,
  lightRoyalBlue,
  occupancySelectBorderColor,
  occupancySelectBorderColorActive,
  darkGray,
  peoplePickerBorderColor,
  selectOptionColor,
  superDarkGrey,
} from 'style/variables';

const Button = styled.button`
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;

  svg {
    background-color: ${lightRoyalBlue};
  }
}
`;

const TrivagoButton = styled(Button)`
  :disabled {
    filter: grayscale(100%);

    & + span {
      color: ${trivagoDarkGrey};
    }
`;

const Dropdown = styled.ul`
  display: block;
  list-style-type: none;
  margin: 0;
  width: 100%;
  padding: 0;
  min-width: 0;

  li {
    padding: 5px 15px;
    transition: background-color 0.15s;
    cursor: pointer;

    &.active {
      background: ${trivagoGrey};
    }
  }
`;

const LabelBlock = styled.label`
  display: flex;
  line-height: 1;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const RowLabel = styled.label`
  color: ${superDarkGrey};
  margin-right: 30px;
`;

const SubLabel = styled.span`
  font-size: 16px;
  color: ${trivagoDarkGrey};

  ${media.mobile} {
    font-size: 12px;
  }
`;

const Content = styled.div<{ removeBorderBottom?: boolean }>`
  margin-bottom: 25px;
  ${({ removeBorderBottom }) => (removeBorderBottom ? '' : `border-bottom: solid 1px ${peoplePickerBorderColor};`)};

  ${media.mobile} {
    padding-bottom: 8px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0;
  color: ${trivagoBlackV2};

  font-size: 16px;

  ${media.desktop} {
    font-size: 18px;

    & + & {
      border-top: solid 1px ${peoplePickerBorderColor};
    }
  }
`;

const Counter = styled.div`
  display: flex;
  width: 160px;
  justify-content: space-between;
  font-size: 20px;

  ${media.mobile} {
    width: 120px;

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

const Children = styled.div``;

const Selects = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -13px;
`;

const SelectOccupancy = styled.select`
  width: 156px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px ${occupancySelectBorderColor};
  padding: 8px 30px 8px 10px;
  appearance: none;
  outline: none;
  background: url(${selectArrowIcon}) no-repeat 130px white;
  margin-left: 13px;
  margin-bottom: 12px;

  font-size: 16px;
  line-height: 1.31;

  :hover,
  :active {
    border: solid 1px ${occupancySelectBorderColorActive};
  }
`;

const SelectLabel = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: ${trivagoBlackV2};
  margin: 10px 0;
`;

const SelectChildren = styled.select`
  min-width: 8.5rem;
  width: 8.5rem;
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
  text-transform: none;
  max-width: 100%;
  border: 1px solid ${disabledBackgroundGray};
  padding: 0 10px;
  background: ${white};
  color: ${darkGray};
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: 100% 50%;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
    background-color: ${white};
    color: ${darkGray};
    border-color: ${focusSelectPeoplePickerBorderColor};
  }

  &:disabled {
    background-color: ${modalBackground};
    color: ${gray};
    border-color: ${disabledBackgroundGray};
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
  }

  option {
    color: ${selectOptionColor};
  }
`;

const Text = styled.div`
  color: ${superDarkGrey};
`;

const OccupancyNumber = styled.div`
  font-size: 20px;
  color: ${superDarkGrey};
`;

export default {
  Button,
  TrivagoButton,
  Dropdown,
  Content,
  Counter,
  Children,
  Row,
  RowLabel,
  LabelBlock,
  SubLabel,
  Selects,
  SelectOccupancy,
  SelectChildren,
  SelectLabel,
  Text,
  OccupancyNumber,
};
