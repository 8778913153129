import React from 'react';
import Lottie from 'react-lottie-player';
import trivagoLoaderJson from 'assets/lotties/loader trivago.json';
import loaderJson from 'assets/lotties/loader.json';

interface LoaderProps {
  size?: number;
  trivagoLoader?: boolean;
}

const DEFAULT_SIZE = 40;

const Loader: React.FC<LoaderProps> = ({ size = DEFAULT_SIZE, trivagoLoader }) => (
  <Lottie
    animationData={trivagoLoader ? trivagoLoaderJson : loaderJson}
    loop
    play
    style={{
      width: size,
      height: size,
      zIndex: 1,
    }}
  />
);

export default Loader;
