import React from 'react';

export enum TextSize {
  Small = 'small',
  Large = 'large',
  HeadingS = 'headingS',
  HeadingM = 'headingM',
  HeadingL = 'headingL',
  HeadingXL = 'headingXL',
  HeadingH1 = 'headingH1',
}

export enum TextWeight {
  Normal = 'normal',
  Bold = 'bold',
  SemiBold = 'semi-bold',
  Medium = 'medium',
}

export enum TextTransform {
  Uppercase = 'uppercase',
  Lowercase = 'lowercase',
}

export enum TextColor {
  Muted = 'muted',
  Emphasis = 'emphasis',
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
}

export enum TextAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
}

export enum TextWrap {
  Truncate = 'truncate',
  Nowrap = 'nowrap',
}

export enum TextDecoration {
  Underline = 'underline',
}

export interface TextProps {
  children: React.ReactNode;
  size?: TextSize;
  weight?: TextWeight;
  transform?: TextTransform;
  color?: TextColor;
  alignment?: TextAlignment;
  wrap?: TextWrap;
  decoration?: TextDecoration;
  className?: string;
  id?: string;
  tag?: 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'strong' | 'ul' | 'td' | 'section';
}
