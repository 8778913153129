import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
`;

const Text = styled.li`
  font-size: 16px;
`;

export const BurgerMenuElementStyled = {
  IconWrapper,
  Text,
};
