import { textColorDanger } from 'splitty/src/style/variables';
import styled, { css } from 'styled-components';
import { FieldState } from 'components/common/InputField/InputField.types';
import {
  ContainerProps,
  InputFieldStyledModel,
  InputProps,
} from 'components/common/InputField/InputFieldStyled/InputField.styled-model';
import {
  black,
  borderRadius,
  disabledBackgroundGray,
  disabledInputBackgroundColor,
  fontSizeSmall,
  lineHeightSmall,
  white,
  battleshipGrey,
  lightRoyalBlue,
  paleSkyBlue,
  strongPink,
  superDarkGrey,
  gray,
} from 'style/variables';

const Input = styled.input<InputProps>`
  position: relative;
  display: block;
  padding: 21px 18px 16px;
  outline: 0;
  cursor: text;

  color: ${superDarkGrey};
  height: 55px !important;
  background-color: ${white};
  line-height: 18px;

  box-sizing: border-box;
  margin: 0;
  border-radius: ${borderRadius};
  font: inherit;
  font-size: 18px;
  overflow: visible;
  max-width: 100%;
  width: 100%;
  background: ${white};
  color: ${superDarkGrey};
  border: 1px solid ${paleSkyBlue};
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  vertical-align: middle;
  -webkit-appearance: none;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  &::-moz-placeholder {
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: ${gray} !important;
  }

  &::placeholder {
    color: ${gray};
  }

  &:focus {
    outline: none;
    background-color: ${white};
    color: ${black};
    border: 1px solid ${lightRoyalBlue};
  }

  &:disabled {
    background-color: ${disabledInputBackgroundColor};
    color: ${gray};
    border-color: ${disabledBackgroundGray};
  }

  &.danger {
    border-color: ${strongPink};
  }

  ${({ state }) =>
    (state === FieldState.basicError || state === FieldState.emptyError || state === FieldState.error) &&
    css`
      border-color: ${strongPink};

      &:focus {
        border-color: ${strongPink};
      }
    `}

  ${({ error }) => error && `border: 1px solid ${strongPink}`};
`;

const TopItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const InputBlock = styled.div`
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 18px;
  line-height: 1.16;
  padding-left: 18px;
  transform: translateY(100%) scale(1);
  pointer-events: none;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transform-origin: top left;

  color: ${battleshipGrey};
`;

const Container = styled.div<ContainerProps>`
  position: relative;

  ${Input}:focus {
    border: 1px solid ${lightRoyalBlue};
  }

  ${Input}:focus + ${InputLabel},
  ${Input}:-webkit-autofill:hover + ${InputLabel},
  ${Input}:-webkit-autofill:focus + ${InputLabel},
  ${Input}:-webkit-autofill:active + ${InputLabel} {
    font-size: 14px;
    transform: translateY(25%);
  }

  input:not(:placeholder-shown) + ${InputLabel} {
    font-size: 11px;
    transform: translateY(25%);
  }

  ${({ empty, checkEmptyWithCss }) => {
    if (checkEmptyWithCss) {
      return css`
        ${Input}:not(:placeholder-shown) + ${InputLabel} {
          font-size: 14px;
          transform: translateY(25%);
        }
      `;
    }

    return (
      !empty &&
      css`
        ${Input} + ${InputLabel} {
          font-size: 14px;
          transform: translateY(25%);
        }
      `
    );
  }}
`;

const ErrorText = styled.div`
  font-size: ${fontSizeSmall};
  color: ${textColorDanger};
  line-height: ${lineHeightSmall};
`;

const Styled: InputFieldStyledModel = {
  Container,
  Input,
  TopItem,
  InputBlock,
  InputLabel,
  ErrorText,
};

export default Styled;
