/**
 * @category Utils
 * @packageDocumentation
 */

import { Trip } from 'common/backend/api/trip/tripModel';
import TagManager from 'react-gtm-module';
import { ServerError } from 'backend/serverError';
import { ClientError } from 'errors/clientError';
import { hashCode256 } from 'utils/hashCode';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';

interface DataLayerProps {
  action?:
    | 'search'
    | 'click'
    | 'sort'
    | 'chooseHotel'
    | 'view'
    | 'close'
    | 'signUp'
    | 'signIn'
    | 'selectRoom'
    | 'purchase';
  component?:
    | 'searchBar'
    | 'footer'
    | 'header'
    | 'faq'
    | 'resultsList'
    | 'roomDetails'
    | 'hotelItem'
    | 'hotelHeader'
    | 'signIn'
    | 'signUp'
    | 'reviewsArea'
    | 'confirmation';
}

function pushDataLayerInteraction(eventName: string) {
  return <T extends DataLayerProps>(payload: T) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        pageType: LogInfoMemoryStorage.pageType,
        currency: LogInfoMemoryStorage.currencyCode,
        language: LogInfoMemoryStorage.languageCode,
        campaignId: LogInfoMemoryStorage.campaignName,
        campaignName: LogInfoMemoryStorage.campaignName,
        userTimezone: LogInfoMemoryStorage.clientLocation?.timezone,
        userId: LogInfoMemoryStorage.userId,
        ...payload,
      },
    });
  };
}

export const DataLayerLogging = {
  pushConfirmationPageEvent: async (trip: Trip) => {
    const pushInteraction = pushDataLayerInteraction('confirmation');
    const email = await hashCode256(trip.guest?.email?.toLowerCase() ?? '');

    pushInteraction({
      email,
      confirmationRef: trip.encryptedTripId ?? '',
      currency: trip.deal?.currencyCode ?? '',
      amount: trip.deal?.totalPrice ?? '',
    } as DataLayerProps);
  },

  pushErrorEvent: (unknownError: ClientError | ServerError | Error | unknown, message: string | void) => {
    const pushInteraction = pushDataLayerInteraction('error');

    let error = unknownError;

    if (error instanceof ClientError || error instanceof ServerError || error instanceof Error) {
      const messages = [];

      if (error.message) {
        messages.push(error.message);
      }
      if (error instanceof ClientError && error.getMessages()) {
        messages.push(...(error.getMessages() || []));
      }
      if (message) {
        messages.push(message);
      }
      error = {
        messages,
        ...error,
      };
    }

    pushInteraction({
      error,
    } as DataLayerProps);
  },
};
