import { useCallback } from 'react';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { CURRENT_STATE } from 'backend/localStorageKeys';
import useLocalStorage from 'utils/useLocalStorage';

export const useStateSettings = () => {
  const { data: location } = useUserLocation();

  const [stateCode, setStateCodeToStorage] = useLocalStorage<string | undefined>(CURRENT_STATE, location.stateCode);

  const setStateCode = useCallback(
    (country: string) => {
      setStateCodeToStorage(country);
    },
    [setStateCodeToStorage],
  );

  return { stateCode, setStateCode };
};
