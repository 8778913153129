import {
  BrowserType,
  DeviceType,
  getBrowserType,
  getBrowserVersion,
  getDeviceType,
  getPlatformType,
  getPlatformVersion,
  PlatformType,
} from 'utils/deviceUtils';
import guid from 'utils/guid';
import cookieBasedUserIdStorage from 'utils/storage/cookie/CookieBasedUserIdStorage';

export interface Device {
  browserType: BrowserType;
  browserVersion: string;
  deviceType: DeviceType;
  platformType: PlatformType;
  platformVersion: string;
}

export class SplittyClientStore {
  private readonly sessionId = guid();

  private readonly device: Device;

  private userId: string | undefined;

  constructor() {
    this.device = {
      browserType: getBrowserType(),
      browserVersion: getBrowserVersion(),
      deviceType: getDeviceType(),
      platformType: getPlatformType(),
      platformVersion: getPlatformVersion(),
    };

    this.userId = cookieBasedUserIdStorage.get();
  }

  getSessionId(): string {
    return this.sessionId;
  }

  setUserId(userId: number | string | undefined) {
    this.userId = userId ? userId.toString() : undefined;
    if (this.userId) {
      cookieBasedUserIdStorage.set(this.userId);
    } else {
      cookieBasedUserIdStorage.remove();
    }
  }

  getUserId(): string | undefined {
    return this.userId;
  }

  getDevice(): Device {
    return this.device;
  }
}

const clientStore = new SplittyClientStore();

export default clientStore;
