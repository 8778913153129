import { useDay } from '@datepicker-react/hooks';
import React, { useContext, useMemo, useRef } from 'react';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';
import Styled from 'components/datePicker/customDatepicker/Day.styled';

interface DayProps {
  dayLabel: string;
  date: Date;
}

const isToday = (date: Date) => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

interface DayButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isToday: boolean;
}

const Day: React.FC<DayProps> = ({ dayLabel, date }) => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    isStartDateSelected,
    isEndDateSelected,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);
  const { isSelected, isWithinHoverRange, disabledDate, onClick, onKeyDown, onMouseEnter, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  const dayProps: DayButtonProps = useMemo(
    () => ({
      isToday: isToday(date),
      onClick,
      onKeyDown,
      onMouseEnter,
      tabIndex,
      type: 'button',
      ref: dayRef,
      hovered: isWithinHoverRange,
    }),
    [date, isWithinHoverRange, onClick, onKeyDown, onMouseEnter, tabIndex],
  );

  if (!dayLabel) {
    return <div />;
  }

  if (isStartDateSelected(date)) {
    return <Styled.DaySelectedFirst {...dayProps}>{dayLabel}</Styled.DaySelectedFirst>;
  }
  if (isEndDateSelected(date)) {
    return <Styled.DaySelectedLast {...dayProps}>{dayLabel}</Styled.DaySelectedLast>;
  }

  if (isSelected) {
    return <Styled.DaySelected {...dayProps}>{dayLabel}</Styled.DaySelected>;
  }

  if (disabledDate) {
    return <Styled.DayDisabled {...dayProps}>{dayLabel}</Styled.DayDisabled>;
  }

  return <Styled.DayDefault {...dayProps}>{dayLabel}</Styled.DayDefault>;
};

export default Day;
