import styled from 'styled-components';
import DefaultStyled from 'components/common/Button/ButtonStyled/DefaultButton.styled';
import { landingBngGreenHoveredButtonColor } from 'style/trivagoVaribles';
import { backgroundGreen4 } from 'style/variables';

const PrimaryButton = styled(DefaultStyled.PrimaryButton)`
  background: ${backgroundGreen4};

  :hover:not(:disabled),
  :active:not(:disabled) {
    background: ${landingBngGreenHoveredButtonColor};
  }
`;

export default {
  ...DefaultStyled,
  PrimaryButton,
};
