/**
 * @category Search Form
 * @packageDocumentation
 */
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import { useSticky } from 'common/hooks/useSticky';
import { parseDate } from 'common/utils/date';
import React, { useCallback, useContext, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { ButtonType } from 'components/common/Button/Button.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { CustomDatepicker } from 'components/datePicker/customDatepicker/CustomDatepicker';
import ToplineStyles from 'components/header-footer/TopLineStyles';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import { OccupancyPicker } from 'components/searchForm/OccupancyPicker/OccupancyPicker';
import { SearchFormStyled } from 'components/searchForm/SearchForm/SearchForm.styled';
import { SuggestionField } from 'components/searchForm/SuggestionField/SuggestionField';
import { SearchButtonLabels } from 'routeList';
import { isPeriodOverMaxNights } from 'utils/dateUtils';

export interface SearchFormPrp {
  onAfterSearch?: () => void;
  sticky?: boolean;
  showOnlyDatepickerWidget?: boolean;
}

export const TRIVAGO_SEARCH_FORM_ID = 'trivago-search-form-id';

/**
 * Hotel search form
 * @param param {@link SearchFormProps}
 */
export const SearchForm = ({ onAfterSearch, sticky, showOnlyDatepickerWidget }: SearchFormPrp) => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { isTrivago } = useContext(TrivagoContext);
  const { offscreenMode } = useContext(OffscreenContext);
  const { searchButtonLabel } = useContext(RouteDataContext);
  const searchBarRef = React.useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const isSticky = useSticky(ref);

  const { submitForm, place, checkin, checkout } = useContext(SearchFormContext);

  const submitWithAfterSearch = useCallback(
    (occupancy?: RoomOccupancy[]) => {
      if (isTrivago) {
        setTimeout(() => {
          if (searchBarRef.current) {
            searchBarRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        });
      }

      hotjarEvent(HotjarEvent.SearchClicked);
      submitForm(isTrivago, occupancy);

      if (onAfterSearch) {
        onAfterSearch();
      }
    },
    [submitForm, isTrivago, onAfterSearch],
  );

  const searchButton = (
    <ToplineStyles.SearchButton
      id="searchSubmitButton"
      tabIndex={5}
      className={'width-expand flex-center'}
      onClick={() => submitWithAfterSearch()}
      type="button"
      styleType={ButtonType.Primary}
    >
      {(() => {
        if (isTrivago) {
          return t('trivago.v2.search-bar.modify-search', 'Modify search');
        }

        switch (searchButtonLabel) {
          case SearchButtonLabels.updateSearch:
            return t('search-bar.update-search', 'Update Search');
          case SearchButtonLabels.search:
          default:
            return t('search-bar.search', 'Search');
        }
      })()}
    </ToplineStyles.SearchButton>
  );

  if (isTrivago) {
    if (!place) return null;

    return (
      <SearchFormStyled.Trivago.SearchBar id={TRIVAGO_SEARCH_FORM_ID}>
        <SearchFormStyled.Trivago.Content>
          <div className="flex-1">
            <CustomDatepicker onClose={submitWithAfterSearch} />
          </div>
          <div className="flex-1">
            <OccupancyPicker onDone={isTrivago ? submitWithAfterSearch : undefined} />
          </div>
        </SearchFormStyled.Trivago.Content>
        {isPeriodOverMaxNights(parseDate(checkin), parseDate(checkout)) && (
          <SearchFormStyled.Trivago.ErrorMessage>
            <Trans i18nKey={'trivago.v2.search-bar.30-days-error-message'}>
              Check out date must be within 30 days of check in date. Please select new dates.
            </Trans>
          </SearchFormStyled.Trivago.ErrorMessage>
        )}
      </SearchFormStyled.Trivago.SearchBar>
    );
  }

  if (isMobileLayout) {
    return (
      <SearchFormStyled.MobileForm onSubmit={(e) => e.preventDefault()}>
        {offscreenMode !== OffscreenMode.searchDatepicker && (
          <SearchFormStyled.MobileSuggestionField>
            <SuggestionField onEnter={submitWithAfterSearch} />
          </SearchFormStyled.MobileSuggestionField>
        )}
        {(offscreenMode === OffscreenMode.hidden || offscreenMode === OffscreenMode.search) && (
          <SearchFormStyled.MobileDatePicker>
            <CustomDatepicker showOnlyWidget={showOnlyDatepickerWidget} />
          </SearchFormStyled.MobileDatePicker>
        )}
        {offscreenMode === OffscreenMode.searchDatepicker && (
          <SearchFormStyled.MobileDatePicker>
            <CustomDatepicker showOnlyWidget={showOnlyDatepickerWidget} />
          </SearchFormStyled.MobileDatePicker>
        )}
        {(offscreenMode === OffscreenMode.hidden || offscreenMode === OffscreenMode.search) && (
          <SearchFormStyled.MobileOccupancyPicker>
            <OccupancyPicker />
          </SearchFormStyled.MobileOccupancyPicker>
        )}
        {(offscreenMode === OffscreenMode.hidden || offscreenMode === OffscreenMode.search) && (
          <SearchFormStyled.MobileSearchButton bottomOffset={offscreenMode === OffscreenMode.search}>
            {searchButton}
          </SearchFormStyled.MobileSearchButton>
        )}
      </SearchFormStyled.MobileForm>
    );
  }

  if (sticky === false) {
    return (
      <ToplineStyles.TopLineStyleRelative>
        <ToplineStyles.FixedSearchForm onSubmit={(e) => e.preventDefault()}>
          <ToplineStyles.FixedWidthSearchfield>
            <SuggestionField onEnter={submitWithAfterSearch} />
          </ToplineStyles.FixedWidthSearchfield>
          <ToplineStyles.FixedWidthDatepicker>
            <CustomDatepicker />
          </ToplineStyles.FixedWidthDatepicker>
          <ToplineStyles.FixedWidthPeoplepicker>
            <OccupancyPicker />
          </ToplineStyles.FixedWidthPeoplepicker>
          <ToplineStyles.FixedWidthSearchButton>{searchButton}</ToplineStyles.FixedWidthSearchButton>
        </ToplineStyles.FixedSearchForm>
      </ToplineStyles.TopLineStyleRelative>
    );
  }

  return (
    <ToplineStyles.TopLineRelativeContainer
      className="section padding-remove"
      style={{
        zIndex: 3,
      }}
    >
      <SearchFormStyled.Container ref={ref} isSticky={isSticky}>
        <ToplineStyles.TopLineStyle id="searchPanel" className="padding-small">
          <ToplineStyles.FixedSearchForm onSubmit={(e) => e.preventDefault()}>
            <ToplineStyles.FixedWidthSearchfield>
              <SuggestionField onEnter={submitWithAfterSearch} />
            </ToplineStyles.FixedWidthSearchfield>
            <ToplineStyles.FixedWidthDatepicker>
              <CustomDatepicker />
            </ToplineStyles.FixedWidthDatepicker>
            <ToplineStyles.FixedWidthPeoplepicker>
              <OccupancyPicker />
            </ToplineStyles.FixedWidthPeoplepicker>
            <ToplineStyles.FixedWidthSearchButton>{searchButton}</ToplineStyles.FixedWidthSearchButton>
          </ToplineStyles.FixedSearchForm>
        </ToplineStyles.TopLineStyle>
      </SearchFormStyled.Container>
    </ToplineStyles.TopLineRelativeContainer>
  );
};
