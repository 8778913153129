import useWindowSize from 'common/hooks/useWindowSize';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import { env } from 'environments/environment';

export const MOBILE_WIDTH_PX_BREAKPOINT = env.layouts.desktopWidth;

interface Context {
  isMobileLayout: boolean;
  isSmallMobile: boolean;
}

export const LayoutContext = React.createContext<Context>({
  isMobileLayout: isMobile,
  isSmallMobile: isMobile,
});

export const LayoutProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [width] = useWindowSize();
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(isMobile);
  const [isSmallMobile, setIsSmallMobile] = useState<boolean>(isMobile);

  useEffect(() => {
    if (width > 0) {
      setIsMobileLayout(width < MOBILE_WIDTH_PX_BREAKPOINT);
      setIsSmallMobile(width < env.layouts.smallSmartphoneWidth);
    }
  }, [width]);

  return (
    <LayoutContext.Provider
      value={{
        isMobileLayout,
        isSmallMobile,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
