/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { rem } from 'style/functions';
import { borderColorLightest, darkGray, white } from 'style/variables';

const Dropdown = styled.div<{ isOpen?: boolean; position?: string }>`
  display: none;
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  min-width: 200px;
  color: ${darkGray};

  background: ${white};
  border: 1px solid ${borderColorLightest};
  border-radius: ${rem(4)};
  padding: 25px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}

  ${({ position = '' }) =>
    position.includes('top')
      ? css`
          margin-top: -10px;
        `
      : ''}

  ${({ position = '' }) =>
    position.includes('bottom')
      ? css`
          margin-top: 10px;
        `
      : ''}
`;

export default {
  Dropdown,
};
