import styled, { css } from 'styled-components';
import { modalBackground } from 'style/variables';

export const ModalBody = styled.div<{ noPadding?: boolean }>`
  display: flow-root;
  padding: 30px 30px;
  margin: auto;
  > :last-child {
    margin-bottom: 0;
  }

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0 !important;
    `}
`;

export const ModalHeader = styled.div`
  display: flex;
  padding: 15px 30px;
  background: ${modalBackground};
  > :last-child {
    margin-bottom: 0;
  }
`;

export const ModalFooter = styled.div`
  display: flow-root;
  padding: 15px 30px;
  background: ${modalBackground};
  > :last-child {
    margin-bottom: 0;
  }
`;
