/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import Common from 'components/common/Divider/Divider.styled';
import { rem } from 'style/functions';
import media from 'style/media';
import { ifTrivago } from 'style/trivago';
import {
  borderColor,
  borderColorLight,
  boxShadowColor,
  brandColor,
  lightGray,
  superDarkGrey,
  white,
} from 'style/variables';

const Container = styled.div`
  height: 48px;
  cursor: pointer;
  background-color: ${lightGray};
  border-radius: ${rem(4)};
  border: 1px solid ${borderColor};

  &:hover {
    border-color: ${boxShadowColor};
  }
`;

const TrivagoContainer = styled.div<{ active?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: 19px 38px;
  border-right: ${borderColorLight} solid 1px;
  cursor: pointer;
  outline: none;

  ${({ active }) => active && 'background:  rgba(29, 127, 190, 0.1);'}
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  font-size: 18px;
  color: ${superDarkGrey};
`;

const Label = styled.label`
  font-weight: bold;
  line-height: 1;
  margin-right: 20px;
`;

const DateInfo = styled.div`
  display: flex;
  min-width: 150px;
  margin-right: 10px;
`;

const DateImage = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 10px;
`;

const DateText = styled.span``;

const MobileDatepickerWidgetButtonsExpanded = styled.div`
  height: ${rem(52)};
  margin: 0 16px 16px;
`;

const MobileDatepickerWidgetButtons = styled.div`
  height: ${rem(80)};
  margin: 0 20px 20px;
`;

const DatepickerWidgetDivider = styled(Common.Divider)`
  ${media.mobile} {
    margin-top: 0;
    margin-bottom: 0;
    height: 70%;
    border-color: ${brandColor};
  }

  ${media.desktop} {
    height: 3rem;
  }
`;

const DatepickerDate = styled.h4`
  margin: 0;
  font-size: 17px;
  line-height: 18px;
  font-weight: 600;
  margin-top: 6px;
`;

const WidgetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  text-align: center;
  box-sizing: border-box;
  border: 1px solid ${borderColor};
  border-radius: 3px;
  background: ${lightGray};
  color: black;
`;

const Field = styled.span`
  height: 46px;
  text-align: center;

  ${ifTrivago(css`
    border-radius: 3px;
    position: relative;
    padding-left: 10px;
    background: ${white};

    ${media.desktop} {
      margin-left: 18px;
    }
  `)}
`;

const WidgetButtonDate = styled.div`
  padding: 4px;
  width: 50%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
`;

export default {
  Container,
  TrivagoContainer,
  Content,
  Label,
  DateInfo,
  DateImage,
  DateText,
  MobileDatepickerWidgetButtons,
  MobileDatepickerWidgetButtonsExpanded,
  DatepickerWidgetDivider,
  DatepickerDate,
  WidgetButton,
  Field,
  WidgetButtonDate,
};
