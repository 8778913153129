import { MutableRefObject, useCallback } from 'react';

interface AbstractValidatedField {
  invalidate: (focus: boolean, checkForEmpty?: boolean) => string | undefined;
}

interface UseValidationCallbackProps {
  fields: MutableRefObject<AbstractValidatedField | undefined | null>[];
}

const useValidationCallback = ({ fields }: UseValidationCallbackProps) => {
  const invalidate = useCallback(
    (
      refObject: MutableRefObject<AbstractValidatedField | undefined | null> | undefined,
      doFocus: boolean,
      checkForEmpty?: boolean,
    ) => {
      if (refObject?.current) {
        return refObject.current.invalidate(doFocus, checkForEmpty);
      }

      return undefined;
    },
    [],
  );

  const validation = useCallback(
    (initialFocus: boolean) => {
      let toReturn = initialFocus;
      const errorMessages: string[] = [];

      fields.forEach((el) => {
        const message = invalidate(el, toReturn);

        toReturn = !message && toReturn;
        if (message) {
          errorMessages.push(message);
        }
      });

      return errorMessages;
    },
    [fields, invalidate],
  );

  return { invalidate, validation };
};

export default useValidationCallback;
