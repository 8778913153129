import React, { useContext } from 'react';
import logoBookAndGoDesktop from 'assets/images/logo/trivago-bng-desktop.webp';
import logoBookAndGoMobile from 'assets/images/logo/trivago-bng-mobile.webp';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/pages/checkout/TrivagoBngLoader/TrivagoBngLoader.styled';
import Loader from 'components/widgets/Loader';

export const TrivagoBngLoader = () => {
  const { isMobileLayout } = useContext(LayoutContext);

  return (
    <Styled.Veil>
      <Styled.Content>
        <div>
          <Styled.BookAndGoLogo
            src={isMobileLayout ? logoBookAndGoMobile : logoBookAndGoDesktop}
            alt={'Trivago Book And Go logo'}
          />
        </div>
        <Styled.Loader>
          <Loader trivagoLoader />
        </Styled.Loader>
      </Styled.Content>
    </Styled.Veil>
  );
};
