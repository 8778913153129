import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { env } from 'environments/environment';

const useTranslatedCurrencyList = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      env.currencies.currencies.map((currency) => ({
        ...currency,
        name: t(`common.currency.${currency.code.toLowerCase()}`, currency.name),
      })),
    [t],
  );
};

export default useTranslatedCurrencyList;
