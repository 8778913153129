import styled from 'styled-components';
import media from 'style/media';
import { battleshipGrey, errorColor } from 'style/variables';

const ErrorLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${errorColor} !important;
`;

const HelpLabel = styled.div`
  font-size: 14px;
  color: ${battleshipGrey};
`;

const Container = styled.div`
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;
  width: 100%;

  ${media.mobile} {
    padding: 12px 20px 20px;
    width: auto;
  }
`;

export default {
  ErrorLabel,
  Container,
  HelpLabel,
};
