/* eslint-disable no-magic-numbers */
/* eslint-disable max-classes-per-file */
import { ErrorResponse } from 'backend/api/response';

export enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export enum ServerErrorCode {
  MissingParameter = 40010,
  UnknownParameter = 40020,

  Unauthenticated = 401,
  InvalidCredentials = 40110,
  InactiveBlockedAccount = 40120,

  PaymentRequired = 402,
  InvalidCreditCardNumber = 40210,
  ExpiredCreditCard = 40220,
  InvalidCreditCardCVV = 40230,
  InsufficientFunds = 40240,
  CreditCardWasDeclined = 40250,
  TransactionFailed = 40260,
  NotSupportedCreditCard = 40270,

  Unauthorized = 403,
  Forbidden = 40310,

  NotFound = 404,
  ItemNotFound = 40410,
  AnotherItemNotFound = 40420,

  RecordAlreadyExists = 40910,
  RecordAlreadyUpdate = 40920,
  RecordAlreadyDeleted = 40930,

  InvalidParameterValues = 42210,

  Conflict = 409,
  InternalServerError = 500,
}

export class ServerError extends Error {
  private readonly response: ErrorResponse;

  readonly endpoint: string;

  constructor(response: ErrorResponse, endpoint: string) {
    super(response.error.message || 'Server error');
    this.response = response;
    this.endpoint = endpoint;
  }

  getResponse(): ErrorResponse {
    return this.response;
  }

  getCode(): ServerErrorCode {
    return this.response.error.code;
  }

  getStatus(): number {
    return this.response.error.status;
  }
}

export class RawServerError extends Error {
  readonly status: number;

  readonly rawData: string | undefined;

  readonly endpoint: string;

  constructor(status: number, message: string, rawData: string | undefined, endpoint: string) {
    super(message);
    this.status = status;
    this.rawData = rawData;
    this.endpoint = endpoint;
  }

  getStatus(): number {
    return this.status;
  }
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export function getHttpStatus(response: ServerError | RawServerError | any): number | undefined {
  return response instanceof ServerError || response instanceof RawServerError
    ? response.getStatus()
    : response?.status;
}
