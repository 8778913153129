/**
 * @category Mobile components
 * @packageDocumentation
 */

/**
 * States for mobile offscreens
 */
enum OffscreenMode {
  hidden = '',
  burger = 'menu',
  search = 'search',
  searchDatepicker = 'searchDatepicker',
  datepicker = 'datepicker',
  occupancy = 'occupancy',
}

export default OffscreenMode;
