import styled from 'styled-components';
import { lightRoyalBlue, superDarkGrey } from 'style/variables';

const EmailLink = styled.a`
  color: ${lightRoyalBlue};
  &:hover {
    color: ${lightRoyalBlue};
  }
`;

const Header = styled.div`
  font-size: 32px;
  line-height: normal;
  font-weight: 600;
  color: ${superDarkGrey};
  text-align: center;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

export default {
  EmailLink,
  Header,
  Text,
};
