/**
 * ^ asserts the start of the string.
 * (?!.*\.{2,}) is a negative lookahead assertion to ensure there are no consecutive periods. .*\.{2,} matches any character followed by two or more periods.
 * [A-Z0-9_%+-]+ matches one or more characters.
 * (?:\.[A-Z0-9_%+-]+)* matches zero or more occurrences of a period followed by one or more alphabetic characters.
 * $ asserts the end of the string.
 */
const MAIL_NAME_REGEXP = /^(?!.*\.{2,})[A-Z0-9_%+-]+(?:\.[A-Z0-9_%+-]+)*$/i;

/**
 *  any special characters if within quotes
 */
const MAIL_NAME_WITH_QUOTES_REGEXP = /^".+"$/i;

/**
 * ^ asserts the start of the string.
 * (?!.*\.{2,}) is a negative lookahead assertion to ensure there are no consecutive periods. .*\.{2,} matches any character followed by two or more periods.
 * (?!.*--) ensures that there are no consecutive hyphens anywhere in the string.
 * [A-Z0-9] matches any alphanumeric character.
 * (?:[A-Z0-9-]{0,61}[A-Z0-9])? matches a substring consisting of alphanumeric characters and hyphens, where the length is between 1 and 63 characters.
 * (?:\.[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?)+ matches one or more occurrences of a period followed by a substring as described above.
 * $ asserts the end of the string.
 */
const DOMAIN_REGEXP =
  /^(?!.*\.{2,})(?!.*--)[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?(?:\.[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?)+$/i;

/**
 * The top-level domain must not be all numeric.
 */
const TOP_DOMAIN_REGEXP = /[a-z]+/i;

const MAX_EMAIL_NAME_LENGTH = 64;
const MAX_EMAIL_DOMAIN_LENGTH = 252;

export const emailValidationRule = (value: string) => {
  const [name, domain] = value.split('@');
  const subDomains = domain?.split('.');
  const topDomain = subDomains?.at(-1);

  if (
    name?.length < 1 ||
    domain?.length < 1 ||
    name?.length > MAX_EMAIL_NAME_LENGTH ||
    domain?.length > MAX_EMAIL_DOMAIN_LENGTH
  ) {
    return false;
  }

  return (MAIL_NAME_WITH_QUOTES_REGEXP.test(name) || MAIL_NAME_REGEXP.test(name)) && DOMAIN_REGEXP.test(domain) && TOP_DOMAIN_REGEXP.test(topDomain || '');
};