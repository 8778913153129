import React from 'react';
import { Trans } from 'react-i18next';
import { useContactTranslations } from 'components/hooks/useContactTranslations';

const CareersLink = () => {
  const contactTranslations = useContactTranslations();

  return (
    <a href={contactTranslations.career} target="_blank" rel="noopener noreferrer">
      <Trans i18nKey="footer.career">Careers</Trans>
    </a>
  );
};

export default CareersLink;
