import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import useQuery from 'common/hooks/useQuery';
import React, { useContext, useEffect, useMemo } from 'react';
import { SettingsContext } from 'TopContexts';
import { useCurrencySettings } from 'contexts/settingsHooks/useCurrencySettings';
import { env } from 'environments/environment';
import { createOccupancyOrDefault, validateOccupancy } from 'utils/occupancyUtils';
import { HotelRedirectQueryUrlParameters, RedirectIdParameter } from 'utils/queryParameters';
import cookieBasedRedirectIdStorage from 'utils/storage/cookie/CookieBasedRedirectIdStorage';
import { validateNumber } from 'utils/validation';
import DestinationRedirect from 'views/redirect/DestinationRedirect';
import HotelRedirect from 'views/redirect/HotelRedirect';
import { calculateCheckinCheckout } from 'views/redirect/redirectUtils';

export enum RedirectLinkType {
  HOTEL = 'hotel',
  DESTINATION = 'destination',
}

interface RedirectProps {
  type: RedirectLinkType;
}

const Redirect: React.FC<RedirectProps> = ({ type }) => {
  const query = useQuery<HotelRedirectQueryUrlParameters>();
  const { setCountryCode } = useContext(SettingsContext);

  const { redirect_id } = useQuery<RedirectIdParameter>();
  const { resetCurrencyState } = useCurrencySettings();
  const { checkin, checkout } = useMemo(
    () => calculateCheckinCheckout(query.checkin, query.checkout, query.ttt, query.los),
    [query.checkin, query.checkout, query.los, query.ttt],
  );

  useEffect(() => {
    if (query.currency) {
      resetCurrencyState();
    }
  }, [query.currency, resetCurrencyState]);

  useEffect(() => {
    if (redirect_id) {
      cookieBasedRedirectIdStorage.set(redirect_id);
    }
  }, [redirect_id]);

  useEffect(() => {
    if (query.userCountry) {
      setCountryCode(query.userCountry);
    }
  }, [query.userCountry, setCountryCode]);

  const occupancy = useMemo<RoomOccupancy[]>(() => {
    try {
      const occupancyFromQuery: RoomOccupancy[] | undefined = query.occupancy ? JSON.parse(query.occupancy) : undefined;

      if (validateOccupancy(occupancyFromQuery)) {
        return occupancyFromQuery;
      }
    } finally {
      // to do  nothing
    }

    const numOfRooms = +(query.numOfRooms || 0);
    const adults = +query.adults;
    const children: number[] = Array(+query.children || 0).fill(-1);
    let ages = query.age || [];

    if (!Array.isArray(ages)) {
      ages = [ages];
    }

    ages.forEach((value) => {
      const [idx, age] = value.split('_').map((v) => +v);

      children[idx] = age;
    });

    const numOfRoomsIsValid = validateNumber(numOfRooms, 1, env.searchBar.maxOccupancy);
    const adultsIsValid = validateNumber(adults, 1, env.searchBar.maxAdults);
    const childrenIsValid =
      validateNumber(children.length, 0, env.searchBar.maxChildren) &&
      children.every((age) => validateNumber(age, env.searchBar.minChildAge, env.searchBar.maxChildAge));

    return Array(numOfRoomsIsValid ? numOfRooms : 1).fill(
      createOccupancyOrDefault(adultsIsValid ? adults : undefined, childrenIsValid ? children : undefined),
    );
  }, [query.adults, query.age, query.children, query.numOfRooms, query.occupancy]);

  if (type === RedirectLinkType.HOTEL) {
    return <HotelRedirect query={query} occupancy={occupancy} checkin={checkin} checkout={checkout} />;
  }

  return <DestinationRedirect query={query} occupancy={occupancy} checkin={checkin} checkout={checkout} />;
};

export default Redirect;
