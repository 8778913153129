import useKeyPress, { Keys } from 'common/hooks/useKeyPress';
import { useRef } from 'react';
import useOutsideClick from 'common/hooks/useOutsideClick';

export const useModalCloseHelper = (onClose: () => void, disabled?: boolean) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dialogRef, onClose, undefined, disabled);
  useKeyPress([Keys.Escape], onClose, disabled);

  return dialogRef;
};
