/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import TrivagoSelectStyled from 'components/common/SelectInput/SelectInputStyled/TrivagoSelectInput.styled';
import CurrencyStyled from 'components/header-footer/CurrencySelector/InternalCurrencySelector.styled';
import media from 'style/media';
import { borderColorLight } from 'style/variables';

const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TrivagoMobileContainer = styled.div`
  ${TrivagoSelectStyled.SelectField} {
    ${media.mobile} {
      width: auto;
      padding: 5px 11px 8px 18px;

      box-sizing: border-box;
      outline: 0;
      border-radius: 3px;
      border: 1px solid ${borderColorLight};
      font-size: 14px;
    }
  }
  ${TrivagoSelectStyled.DisplayValue} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

const Label = styled.span`
  margin-top: auto;
  margin-bottom: auto;
`;

export default { ...CurrencyStyled, MobileContainer, Label, TrivagoMobileContainer };
