import { atomWithQuery } from 'jotai-tanstack-query';
import { ClientLocation } from 'backend/api/client/clientModel';
import { getDataProvider } from 'backend/dataProvider';
import cookieBasedUserLocationStorage from 'utils/storage/cookie/CookieBasedUserLocationStorage';

export const locationAtom = atomWithQuery<ClientLocation>(() => ({
  queryKey: ['location'],
  initialData: {},
  queryFn: async () => {
    const storedLocation = cookieBasedUserLocationStorage.get();

    if (storedLocation) {
      return storedLocation;
    }

    const dataProvider = await getDataProvider();
    const clientLocation = await dataProvider.getClientLocation();

    cookieBasedUserLocationStorage.set(clientLocation);

    return clientLocation;
  },
}));
