import styled from 'styled-components';
import { lightRoyalBlue } from 'style/variables';

const AcceptPolicyLink = styled.a`
  color: ${lightRoyalBlue};
`;

const AcceptTermsLink = styled.a`
  color: ${lightRoyalBlue};
`;

export default {
  AcceptPolicyLink,
  AcceptTermsLink,
};
