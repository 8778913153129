import styled from 'styled-components';
import BenefitSectionStyled from 'components/home/BenefitSection.styled';
import media from 'style/media';
import { superDarkGrey, white } from 'style/variables';

const BenefitLabel = styled(BenefitSectionStyled.BenefitLabel)`
  font-size: 22px;
  line-height: 20px;

  ${media.mobile} {
    font-size: 20px;
  }
`;

const Counter = styled.div`
  display: flex;
  margin: auto;
  font-size: 38.4px;
  font-weight: 600;
  line-height: normal;
  color: ${superDarkGrey};
  margin-top: 30px;

  & > span.counter-number {
    box-sizing: border-box;
    padding: 5px 0 0;
    min-width: 34px;
    height: 40px;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: ${white};
    overflow: hidden;
    border-radius: 5px;

    ${media.mobile} {
      min-width: 25px;
      height: 33px;
    }
  }

  & > span.counter-separator {
    margin: 0 7px;
    align-self: end;
  }

  & > span.counter-number + span.counter-number {
    margin-left: 3px;
  }

  ${media.mobile} {
    font-size: 28.5px;
  }
`;

const Text = styled.div`
  line-height: 22px;

  ${media.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export default {
  BenefitLabel,
  Counter,
  Text,
};
