import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ContactTranslations {
  emailUs: string;
  partnerWithUs: string;
  phone: string;
  phoneTrivago: string;
  discountPhone: string;
  career: string;
  [key: string]: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useContactTranslations = (): ContactTranslations => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      emailUs: t('algotels.contact.emailUs', 'support@algotels.com'),
      partnerWithUs: t('algotels.contact.partnerWithUs', 'info@algotels.com'),
      phone: t('algotels.contact.phone', '+1 (877) 921-3348'),
      phoneTrivago: t('algotels.contact.phoneTrivago', '+1 (877) 921-3348'),
      discountPhone: t('algotels.contact.discountPhone', '+1 (844) 768-3271'),
      career: t('algotels.contact.career', 'https://www.comeet.com/jobs/holisto/76.001'),
    }),
    [t],
  );
};
