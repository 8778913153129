import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Main = styled.main`
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
`;

export const DesktopPageLayoutStyled = {
  Root,
  Main,
};
