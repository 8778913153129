import styled from 'styled-components';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import {
  borderColorLightest,
  brandColorDark,
  middleGray,
  darkGray,
  gray,
  veryLightGray,
  black,
  lightRoyalBlue,
  strongPink,
} from 'style/variables';

const PageHeaderDropdown = styled(Dropdown)`
  background-color: ${veryLightGray};
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
`;

const Navbar = styled.header`
  display: flex;
  background: white;
  border-bottom: 1px solid ${borderColorLightest};
  z-index: 1000;
`;

const NavbarContent = styled.nav`
  min-height: 72px;
  display: flex;
  position: relative;
  align-items: center;

  box-sizing: content-box;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 40px;
  padding-right: 40px;
`;
const LogoWrapper = styled.div`
  flex-grow: 1;
`;

const Menu = styled.div`
  display: flex;
  min-height: 72px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin: 0 24px;

  tab-index: 1;
  border-bottom: 2px solid transparent;

  &:hover,
  &:focus {
    border-bottom-color: ${brandColorDark};
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none !important;

    &:active {
      color: ${middleGray};
    }

    &:hover {
      color: ${black};
    }

    &:hover {
      color: ${black};
    }
  }
`;

const DropdownList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    padding: 5px 0;
    color: ${gray};

    :hover,
    :active {
      color: ${darkGray};
    }
  }
`;

const CallCenterBannerWrapper = styled.li`
  list-style-type: none;
  margin-right: 5px;
`;

const Gradient = styled.div`
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to left, ${lightRoyalBlue}, ${strongPink});
`;

export default {
  PageHeaderDropdown,
  Navbar,
  NavbarContent,
  Menu,
  MenuItem,
  DropdownList,
  CallCenterBannerWrapper,
  Gradient,
  LogoWrapper,
};
