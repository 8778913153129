/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { rem } from 'style/functions';
import { borderColor, charcoalGrey, lightGray } from 'style/variables';

const Wrapper = styled.div`
  position: relative;

  input,
  input:focus {
    outline: none;
    box-sizing: border-box;
    font: inherit;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    overflow: visible;
    vertical-align: middle;
    width: 100%;
    color: ${charcoalGrey};

    background: ${lightGray};
    border: 1px solid ${borderColor};

    border-radius: ${rem(4)};
    height: ${rem(48)};
    padding-left: ${rem(50)};
    padding-right: ${rem(24)};
  }

  span + input {
    padding-right: ${rem(50)};
  }

  > img {
    width: 25px;
    position: absolute;
    left: 12px;
    top: 12px;
  }

  span {
    width: ${rem(50)};
    align-items: center;
    bottom: 0;
    display: inline-flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
  }
`;

export default { Wrapper };
