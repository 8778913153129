import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/home/algo-logo-icon.svg';
import group9 from 'assets/images/home/group-9.svg';
import { TextSize, TextWeight } from 'components/common/Text/Text.types';
import Styled from 'components/home/DesktopHomeLocations.styled';
import HomeLocationWidget, { cityLocations } from 'components/home/HomeLocationWidget/HomeLocationWidget';

const DesktopHomeLocations = () => {
  const [t] = useTranslation();

  return (
    <Styled.Container>
      <div>
        <Styled.Header size={TextSize.HeadingL} weight={TextWeight.SemiBold} tag="h2">
          {t('home.trending-destinations', 'Trending Destinations')}
        </Styled.Header>

        <Styled.Grid>
          <Styled.Section gridArea={'paris'}>
            <HomeLocationWidget locationData={cityLocations.paris} cityName={t('home.city.paris', 'Paris')} />
          </Styled.Section>
          <Styled.Section gridArea={'sf'}>
            <HomeLocationWidget locationData={cityLocations.sf} cityName={t('home.city.sf', 'San Francisco')} />
          </Styled.Section>
          <Styled.Section gridArea={'london'}>
            <HomeLocationWidget locationData={cityLocations.london} cityName={t('home.city.london', 'London')}>
              <Styled.BackgroundImageFirst src={logo} />
            </HomeLocationWidget>
          </Styled.Section>
          <Styled.Section gridArea={'rome'}>
            <HomeLocationWidget locationData={cityLocations.rome} cityName={t('home.city.rome', 'Rome')} />
          </Styled.Section>
          <Styled.Section gridArea={'bc'}>
            <HomeLocationWidget locationData={cityLocations.barcelona} cityName={t('home.city.bc', 'Barcelona')} />
          </Styled.Section>
          <Styled.Section gridArea={'cancun'}>
            <HomeLocationWidget locationData={cityLocations.cancun} cityName={t('home.city.cancun', 'Cancun')} />
          </Styled.Section>
          <Styled.Section gridArea={'miami'}>
            <HomeLocationWidget locationData={cityLocations.miami} cityName={t('home.city.miami', 'Miami')} />
          </Styled.Section>
          <Styled.Section gridArea={'ny'}>
            <HomeLocationWidget locationData={cityLocations.newYork} cityName={t('home.city.ny', 'New York')}>
              <Styled.BackgroundImageSecond src={group9} />
            </HomeLocationWidget>
          </Styled.Section>
          <Styled.Section gridArea={'lv'}>
            <HomeLocationWidget locationData={cityLocations.lasvegas} cityName={t('home.city.lasvegas', 'Las Vegas')} />
          </Styled.Section>
          <Styled.Section gridArea={'orlando'}>
            <HomeLocationWidget locationData={cityLocations.orlando} cityName={t('home.city.orlando', 'Orlando')} />
          </Styled.Section>
        </Styled.Grid>
      </div>
    </Styled.Container>
  );
};

export default DesktopHomeLocations;
