import styled from 'styled-components';
import group2 from 'assets/images/home/group-2.svg';
import group from 'assets/images/home/group.svg';
import StyledCardComponents from 'components/common/Card/Card.styled';
import { Text } from 'components/common/Text/Text';
import media from 'style/media';
import { black } from 'style/variables';

const Hero = styled.div`
  position: relative;
  box-sizing: border-box;

  &:after {
    display: inherit;
    z-index: -1;
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    top: 0;
    width: 100%;
    height: 550px;
    background:
      url(${group2}) no-repeat left bottom,
      url(${group}) no-repeat right top;
    background-origin: inherit;
  }

  ${media.mobile} {
    display: flex;
    align-items: center;
    min-height: 160px;
    padding: 37px 0 57px;
    background: none;

    &:after {
      display: none;
    }
  }
`;

const MobileBackgroundImage = styled.img`
  position: absolute;
  right: 0;
  top: 90px;
  width: 80px;
`;

const MobileSearch = styled(StyledCardComponents.StyledCard)`
  border-radius: 16px 16px 0 0;
  padding-top: 18px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

const HomeLocationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 94px 0 143px;

  ${media.mobile} {
    padding: 30px 0 63px;
  }
`;

const Title = styled(Text)`
  color: ${black};
  margin: 97px auto 0;
  width: 820px;

  ${media.mobile} {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    width: auto;
  }
`;

const Subtitle = styled(Text)`
  margin: 28px auto;
`;

const Greeting = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  padding-left: 40px;
  padding-right: 40px;

  ${media.mobile} {
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

const BenefitWrapper = styled.div`
  margin-bottom: 113px;

  ${media.mobile} {
    margin-bottom: 73px;
  }
`;

const Container = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  ${media.mobile} {
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

export default {
  Hero,
  Greeting,
  MobileBackgroundImage,
  MobileSearch,
  HomeLocationsWrapper,
  Subtitle,
  Title,
  BenefitWrapper,
  Container,
};
