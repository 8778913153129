import {Hotel, HotelContact, HotelExtended, HotelInfo} from "../hotel/hotelModel";
import {HotelDealExtended, HotelRoom} from "../hotel/dealModel";
import {ExtrasDetails} from "./tripRequest";

export interface RoomOccupancy {
  adults: number;
  children?: number[];
}

export interface Checkout {
  id: string;
  hotel: HotelExtended;
  rooms: RoomOccupancy[];
  deal: HotelDealExtended;
  hotelRooms: HotelRoom[];
}

/* eslint-disable no-magic-numbers */
export enum TripStatus {
  Draft = 0,
  InAttempt = 1,
  InProcess = 2,
  Confirmed = 3,
  Cancelled = 4,
  Failed = 6,
  CancelledError = 9,
  RequiredSuspicious = 10,
  Blocked = 12,
  InProcessUnresolved = 14,
  CancelledPaid = 20,
  Fraud = 21,
  BookedViaHotel = 22,
  RequiredNationality = 23,
  Required = 24,
  PendingRecovery = 25,
  DuplicatedBooking = 26,
  PendingRefund = 27,
}

export interface TripHotel extends Hotel {
  contact?: HotelContact;
  info?: HotelInfo;
}

export interface GuestDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface Trip {
  id: number;
  encryptedTripId: string;
  status: TripStatus;
  specialRequests?: string;
  guest: GuestDetails;
  hotel: TripHotel;
  rooms: RoomOccupancy[];
  deal: HotelDealExtended;
  hotelRooms: HotelRoom[];
  preferences?: {
    rebook: boolean;
    resale: boolean;
  };
  extras?: ExtrasDetails;
}

export enum InsuranceSubmitStatus {
  included = 1,
  excluded = 2,
  NYorHI = 3,
}

export interface InsurancePolicy {
  status: InsuranceSubmitStatus;
  insurancePolicyId: number | undefined;
  amount: number | undefined;
}

export interface TripId {
  id: number;
  encryptedTripId: string;
  status: TripStatus;
}

export interface CreatedTripId extends TripId {
  redirectUrl?: string;
}
