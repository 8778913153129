/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import StyledBng from 'style/CommonTrivago.styled';
import media from 'style/media';
import { ifTrivago } from 'style/trivago';
import { black, charcoalGrey, loaderBackgroundColor, white } from 'style/variables';

const Month = styled.div`
  max-width: 376px;
  margin: 0 auto 0 auto;
`;

const MonthWrapper = styled.div`
  flex: 1;
  min-width: 1px;
`;

const DatePickerPanel = styled.div`
  position: absolute;
  z-index: 1020;
  display: block;
  animation: slide-down 0.3s;

  width: 750px;
  border: 0;
  padding: 16px 40px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.14);
  color: ${black};
  background: ${loaderBackgroundColor};
  border-radius: 4px;

  ${ifTrivago(css`
    width: 773px;
    box-sizing: border-box;

    border-radius: 9px;

    margin-top: 3px;
    padding: 11px 32px 31px;
    background: ${white};
  `)}
`;

const PrevNavButton = styled.div`
  position: absolute;
  top: 32px;
  left: 8px;

  img {
    position: relative;
    left: -1px;
  }

  ${ifTrivago(css`
    img {
      top: -7px;
      left: 20px;
    }

    filter: grayscale(100%) contrast(10);

    &:hover {
      filter: unset;
    }
  `)}
`;

const NextNavButton = styled.div`
  position: absolute;
  top: 32px;
  right: 8px;

  img {
    position: relative;
    right: -1px;
  }

  ${ifTrivago(css`
    img {
      top: -7px;
      right: 20px;
    }

    filter: grayscale(100%) contrast(10);

    &:hover {
      filter: unset;
    }
  `)}
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  width: 104px;
  margin: 0 auto;

  color: ${charcoalGrey};
`;

const OkButton = styled(StyledBng.BlueButton)`
  display: inherit;
  margin-left: auto;
  margin-right: 15px;
  height: 50px;
  padding: 0 35px;
`;

const MobileMonthContainer = styled.div`
  overflow-x: auto;
  padding: 0 16px;
`;

const MobileScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
`;

const MobileConfirmPanel = styled.div`
  flex: 0 0;
`;

const DatepickerContainer = styled.div`
  height: 100%;

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export default {
  MobileMonthContainer,
  MobileScrollContainer,
  MobileConfirmPanel,
  Month,
  MonthWrapper,
  DatepickerContainer,
  DatePickerPanel,
  PrevNavButton,
  NextNavButton,
  Duration,
  OkButton,
};
