/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import media from 'style/media';
import { flexGapRow } from 'style/polyfills/flexGap';
import { boxShadowColor3, charcoalGrey, gray } from 'style/variables';

const RESERVE_DESKTOP_SPACE = 500;
const RESERVE_MOBILE_SPACE = 20;

const BgWrapper = styled.div`
  font-family: Lato, serif;
  font-family: Lato, serif;
  box-shadow: 0 1px 1px 0 ${boxShadowColor3};
  background-color: inherit;
  nav {
    display: flex;
  }
`;

const StartBlock = styled.div<{ isBng?: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  width: ${({ isBng }) => (isBng ? `0` : `${RESERVE_DESKTOP_SPACE}px`)};
  max-width: ${({ isBng }) => (isBng ? '0' : `${RESERVE_DESKTOP_SPACE}px`)};

  ${media.mobile} {
    width: ${RESERVE_MOBILE_SPACE}px;
    max-width: ${RESERVE_MOBILE_SPACE}px;
  }
`;

const EndBlock = styled.div<{ isBng?: boolean }>`
  display: flex;
  justify-content: end;
  align-items: center;
  width: ${({ isBng }) => (isBng ? `100%` : `${RESERVE_DESKTOP_SPACE}px`)};
  max-width: ${({ isBng }) => (isBng ? '100%' : `${RESERVE_DESKTOP_SPACE}px`)};
  padding: 10px 0;

  ${({ isBng }) =>
    isBng
      ? css`
          ${flexGapRow('20px')}
        `
      : css`
          ${flexGapRow('40px')}
        `};

  ${media.mobile} {
    width: ${RESERVE_MOBILE_SPACE}px;
    max-width: ${RESERVE_MOBILE_SPACE}px;
  }
`;

const Content = styled.div<{ isBNG?: boolean }>`
  display: flex;
  flex-direction: ${({ isBNG }) => (isBNG ? 'row' : 'column')};
  width: 100%;
  font-size: 12px;
  line-height: 1;
  color: ${charcoalGrey};
  justify-content: ${({ isBNG }) => (isBNG ? 'space-between' : 'center')};
  align-items: center;
  ${({ isBNG }) =>
    isBNG &&
    css`
      ${media.mobile} {
        height: 57px;
      }

      ${media.desktop} {
        height: 73px;
      }
    `}
`;

const BookAndGoLogo = styled.img`
  height: 25px;
  margin: 26px 0 22px;

  ${media.mobile} {
    height: 17.1px;
    margin: 20px 0 19.9px;
  }
`;

const TrivagoLogo = styled.img`
  height: 24px;

  ${media.mobile} {
    height: 14px;
    margin-left: 4px;
  }
`;

const TrivagoLogoBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1;
  color: ${charcoalGrey};
  justify-content: center;
  align-items: start;
`;

const AlgotelsLogo = styled.img`
  ${media.desktop} {
    margin: 14px 0;
    height: 45px;
  }

  ${media.mobile} {
    height: 29px;
  }
`;

const HeaderItem = styled.div`
  position: relative !important;
  height: 100%;
`;

const Navbar = styled.nav`
  display: flex;
  z-index: 1000;
`;
const BurgerIcon = styled.a`
  display: flex;
  align-items: center;
  color: ${gray};
  min-height: 56px;
`;

export const TrivagoPageHeaderStyled = {
  StartBlock,
  EndBlock,
  Content,
  BookAndGoLogo,
  TrivagoLogo,
  TrivagoLogoBox,
  AlgotelsLogo,
  BgWrapper,
  HeaderItem,
  Navbar,
  BurgerIcon,
};
