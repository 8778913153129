// Polyfill start
import 'intersection-observer';
import 'regenerator-runtime/runtime';
import 'intl-messageformat';

import { identify, Identify, init as amplitudeInit } from '@amplitude/analytics-browser';
import React from 'react';
import { render } from 'react-dom';
import FacebookPixel from 'react-facebook-pixel';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import AppTop from 'AppTop';
import Stripe3DS from 'Stripe3DS';
import TopProviders from 'TopProviders';
import { getDataProvider } from 'backend/dataProvider';
import { RouteDataProvider } from 'components/contexts/RouteDataContext';
import { TrivagoProvider } from 'components/contexts/TrivagoContext';
import MetaProvider from 'contexts/MetaProvider';
import { SuspenseProvider } from 'contexts/SuspenseProvider';
import { env } from 'environments/environment';
import { setupErrorHandlers } from 'errors/errorHandlers';
import { NoticedError } from 'errors/errorUtils';
import * as serviceWorker from 'serviceWorker';
import { GlobalStyle } from 'style/global.styled';
import { getDeviceTypeName } from 'utils/deviceUtils';

async function initTagManager() {
  const dataProvider = await getDataProvider();

  const splittySession = dataProvider.client.getSplittySessionId();

  const tagManagerArgs: TagManagerArgs = {
    gtmId: env.googleTagManager.id,

    dataLayer: {
      'splitty-session': splittySession,
    },
  };

  TagManager.initialize(tagManagerArgs);
}

function initHotJar() {
  if (env.name === 'test') {
    return;
  }

  const domain = 'https://static.hotjar.com/c/hotjar-';
  const suffix = '.js?sv=';

  window.hj =
    window.hj ||
    ((...rest: string[]) => {
      (window.hj.q = window.hj.q || []).push(rest);
    });
  const hjSettings = {
    hjid: env.hotJar.id,
    hjsv: env.hotJar.snippetVersion,
  };
  const headElement = document.getElementsByTagName('head')[0];
  const scriptElement = document.createElement('script');

  (window as unknown as { _hjSettings: typeof hjSettings })._hjSettings = hjSettings;

  scriptElement.async = true;
  scriptElement.src = domain + hjSettings.hjid + suffix + hjSettings.hjsv;
  scriptElement.crossOrigin = '';
  if (headElement) {
    headElement.appendChild(scriptElement);
  }
}

function initFacebookPixel() {
  if (env.facebookPixel) {
    FacebookPixel.init(env.facebookPixel.id.toString());
    FacebookPixel.pageView();
  }
}

function initAmplitude() {
  if (env.amplitude) {
    amplitudeInit(env.amplitude.apiKey, undefined, {
      serverUrl: !process.env.REACT_APP_IS_LOCAL_RUN ? env.amplitude.serverUrl : '',
    }).promise.then(() => {
      const identifyObj = new Identify();

      identifyObj.set('Platform Type', getDeviceTypeName());
      // eslint-disable-next-line no-restricted-syntax
      identify(identifyObj);
    });
  }
}

async function initRiskified() {
  const dataProvider = await getDataProvider();
  const splittySession = dataProvider.client.getSplittySessionId();

  function riskifiedBeaconLoad() {
    const url = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}beacon.riskified.com?shop=${
      env.riskified.shopName
    }&sid=${splittySession}`;
    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    const x = document.getElementsByTagName('script')[0];

    if (x.parentNode) {
      x.parentNode.insertBefore(s, x);
    }
  }

  window.addEventListener('load', riskifiedBeaconLoad, false);
}

function initNewRelicErrorHandler() {
  window.addEventListener('load', () => {
    if (window.newrelic) {
      window.newrelic.setErrorHandler(
        (error: NoticedError | string) => (typeof error === 'object' && !error.isNoticed) || false,
      );
    }
  });
}

setupErrorHandlers();
initTagManager();
initHotJar();
initFacebookPixel();
initRiskified();
initAmplitude();
initNewRelicErrorHandler();

/**
 * Initializes react
 * */
render(
  <Router>
    <RouteDataProvider>
      <TrivagoProvider>
        <MetaProvider>
          <GlobalStyle />
          <SuspenseProvider>
            <Stripe3DS>
              <TopProviders>
                <AppTop />
              </TopProviders>
            </Stripe3DS>
          </SuspenseProvider>
        </MetaProvider>
      </TrivagoProvider>
    </RouteDataProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
