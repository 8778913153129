/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { rem } from 'style/functions';
import media from 'style/media';
import { ifTrivago } from 'style/trivago';
import { grassGreen, trivagoError } from 'style/trivagoVaribles';
import {
  battleshipGrey,
  borderColorLight,
  disabledInputBackgroundColor,
  lightRoyalBlue,
  paleSkyBlue,
  strongPink,
} from 'style/variables';

const TopItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const InputBlock = styled.div`
  position: relative;
`;

const Input = styled.input<{ error?: boolean; correct?: boolean }>`
  position: relative;
  display: block;
  outline: 0;
  cursor: text;

  height: 55px !important;
  border-radius: 3px;
  border-color: ${paleSkyBlue};
  background-color: white;
  color: black;
  font-size: 14px;
  line-height: 18px;

  ${ifTrivago(
    css`
      border-color: ${borderColorLight};
      border-radius: 4px;
      padding: 20px 18px 0;
      font-size: 16px;
      line-height: 1;

      ${media.mobile} {
        font-size: 14px;
      }
    `,
    css`
      padding: ${rem(18)};
    `,
  )}

  &:disabled {
    background-color: ${disabledInputBackgroundColor};
  }

  &.danger {
    border-color: ${strongPink};
  }

  ${({ error }) =>
    error &&
    ifTrivago(
      css`
        border: 1px solid ${trivagoError};
      `,
      css`
        border: 1px solid ${strongPink};
      `,
    )}

  ${({ correct, error }) =>
    correct &&
    !error &&
    ifTrivago(css`
      border: 1px solid ${grassGreen} !important;
    `)}
`;

const InputLabel = styled.label`
  font-size: 18px;
  line-height: 1.16;
  padding-left: 18px;
  transform: translateY(100%) scale(1);
  pointer-events: none;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transform-origin: top left;

  color: ${battleshipGrey};
`;

const Container = styled.div<{ empty?: boolean }>`
  width: 100%;
  position: relative;
  outline: 0;

  ${Input}:focus {
    border: 1px solid ${lightRoyalBlue};
  }

  ${Input}:focus + ${InputLabel},
  ${Input}:-webkit-autofill:hover + ${InputLabel},
  ${Input}:-webkit-autofill:focus + ${InputLabel},
  ${Input}:-webkit-autofill:active + ${InputLabel} {
    font-size: 11px;
    transform: translateY(25%);
  }

  input:not(:placeholder-shown) + ${InputLabel} {
    font-size: 14px;
    transform: translateY(25%);
  }

  ${({ empty }) =>
    !empty &&
    css`
      & > ${Input} + ${InputLabel} {
        font-size: 11px;
        transform: translateY(25%);
      }
    `}
`;

const PhoneLabel = styled(InputLabel)<{ focused: boolean; empty: boolean }>`
  margin-left: 52px;

  ${(props) =>
    (props.focused || !props.empty) &&
    css`
      font-size: 11px;
      transform: translateY(25%);
    `}
`;

const Styled = {
  Container,
  TopItem,
  Input,
  InputBlock,
  InputLabel,
  PhoneLabel,
};

export default Styled;
