/**
 * @category Search Form
 * @packageDocumentation
 */
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import useOutsideClick from 'common/hooks/useOutsideClick';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import adultsIcon from 'assets/images/icons/adults.svg';
import minusIcon from 'assets/images/icons/searchbar/feather-minus-square.svg';
import plusIcon from 'assets/images/icons/searchbar/feather-plus-square.svg';
import peopleIcon from 'assets/images/icons/trivagoV2/people-icon.svg';
import roomsIcon from 'assets/images/icons/trivagoV2/rooms-icon.svg';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextTransform, TextWeight } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { SearchFormContext, SearchFormProvider } from 'components/contexts/SearchFormContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import Styled from 'components/searchForm/OccupancyPicker/OccupancyPicker.styled';
import OccupancyPickerModal from 'components/searchForm/OccupancyPickerModal';
import PeoplePicker from 'components/searchForm/PeoplePicker/PeoplePicker';
import Icon from 'components/widgets/Icon';
import { env } from 'environments/environment';
import {
  addOccupancy,
  adultChanger,
  childrenChanger,
  removeOccupancy,
  setAge,
  sumAdults,
  sumChildren,
  sumGuests,
} from 'utils/occupancyUtils';

interface OccupancyPickerProps {
  onDone?: (occupancy?: RoomOccupancy[]) => void;
}

/**
 * Allows to select a count of rooms and people.
 */
export const OccupancyPicker: React.FC<OccupancyPickerProps> = ({ onDone }) => {
  const { t } = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { occupancy, setOccupancy } = useContext(SearchFormContext);

  const { isTrivago } = useContext(TrivagoContext);

  const [expanded, setExpanded] = useState<boolean>(false);

  const childrenCount = useMemo(() => sumChildren(occupancy), [occupancy]);

  const wrapRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useOutsideClick(wrapRef, () => setExpanded(false), true, !expanded);

  const closeWithChange = useCallback(
    (occ?: RoomOccupancy[]) => {
      setExpanded(false);
      if (onDone) {
        onDone(occ);
      }
    },
    [onDone, setExpanded],
  );

  const mainRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  const overflow = useMemo(() => isMobileLayout, [isMobileLayout]);

  useEffect(() => {
    if (overflow) {
      return () => null;
    }

    const updateOffset = () => mainRef.current && setOffset(mainRef.current.getBoundingClientRect().y);

    if (expanded) {
      updateOffset();
    }

    window.addEventListener('scroll', updateOffset);

    return () => window.removeEventListener('scroll', updateOffset);
  }, [expanded, overflow]);

  const peopleCount = useMemo(
    () => ({
      adults: t('search-bar.adults-with-param', '{ adults, plural, =1 {One adult} other {# Adults} }', {
        adults: sumAdults(occupancy),
      }),
      children: isTrivago
        ? t(
            'trivago.v2.search-bar.occupancy-picker.children-with-param',
            '{ children, plural, =1 {1 child} other {# children} }',
            {
              children: childrenCount,
            },
          )
        : t('search-bar.trivago-children-with-param', '{ children, plural, =1 {/ 1 Child} other {/ # Children} }', {
            children: childrenCount,
          }),
    }),
    [childrenCount, isTrivago, occupancy, t],
  );

  const people = useMemo(
    () => (
      <Styled.People style={!isTrivago ? { minWidth: '10rem' } : undefined}>
        {peopleCount.adults}
        {childrenCount > 0 && isTrivago && ', '}
        {childrenCount > 0 && <span>{peopleCount.children}</span>}
      </Styled.People>
    ),
    [childrenCount, isTrivago, peopleCount.adults, peopleCount.children],
  );

  const content = useMemo(
    () => (
      <Styled.Content>
        <Text weight={TextWeight.SemiBold} tag="div" className="flex flex-middle flex-between width-1-1">
          <Styled.Text className="width-1-2">
            <Trans
              i18nKey="search-bar.rooms-plural"
              defaults="{ rooms, plural, =1 {Room} other {Rooms} }"
              values={{ rooms: occupancy.length }}
            />
          </Styled.Text>
          <div
            className="width-1-2 flex flex-middle flex-between"
            style={{ maxWidth: '8rem' }}
            data-testid="occupancy-rooms-controls"
          >
            <Styled.CounterButton
              type="button"
              onClick={() => setOccupancy(removeOccupancy(occupancy.length - 1, occupancy))}
              data-testid="remove-room-button"
            >
              <Icon src={minusIcon} />
            </Styled.CounterButton>
            <Styled.OccupancyNumber>{occupancy.length}</Styled.OccupancyNumber>
            <Styled.CounterButton
              type="button"
              onClick={() => setOccupancy(addOccupancy(occupancy))}
              data-testid="add-room-button"
            >
              <Icon src={plusIcon} />
            </Styled.CounterButton>
          </div>
        </Text>

        <hr className="margin-medium-top margin-medium-bottom" />

        {occupancy.map((roomOccupancy, roomIdx) => (
          /* eslint-disable react/no-array-index-key */
          <div key={`occupancy_${roomIdx}`}>
            {occupancy.length !== 1 && (
              <>
                <Text
                  tag="div"
                  color={TextColor.Secondary}
                  className="flex flex-middle flex-between width-1-1 margin-small-bottom margin-large-top"
                >
                  <div className="width-1-2">
                    <Trans
                      i18nKey="search-bar.room-number"
                      defaults="Room {roomIdx}"
                      values={{ roomIdx: roomIdx + 1 }}
                    />
                  </div>
                </Text>

                <hr className="margin-small-top margin-medium-bottom" />
              </>
            )}
            <PeoplePicker
              roomNumber={roomIdx}
              adults={roomOccupancy.adults}
              adultChanger={(increase) => setOccupancy(adultChanger(roomIdx, increase, occupancy))}
              childrenAges={roomOccupancy.children || []}
              childrenChanger={(increase) => setOccupancy(childrenChanger(roomIdx, increase, occupancy))}
              setAge={(index, age) => setOccupancy(setAge(roomIdx, index, age, occupancy))}
            />
            {roomIdx !== occupancy.length - 1 && <hr className="margin-medium-top margin-medium-bottom" />}
          </div>
        ))}
      </Styled.Content>
    ),
    [occupancy, setOccupancy],
  );

  const contentBng = useMemo(
    () =>
      occupancy.map((roomOccupancy, roomIdx) => (
        <React.Fragment key={['occupancy', roomIdx].join('-')}>
          <Styled.RoomLabelRow>
            <Styled.RoomLabel>
              <Trans i18nKey="search-bar.room-number" defaults="Room {roomIdx}" values={{ roomIdx: roomIdx + 1 }} />
            </Styled.RoomLabel>
            <Styled.RemoveButton
              disabled={roomIdx === 0}
              onClick={() => setOccupancy(removeOccupancy(roomIdx, occupancy))}
            >
              <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.remove-room'}>Remove</Trans>
            </Styled.RemoveButton>
          </Styled.RoomLabelRow>
          <PeoplePicker
            roomNumber={roomIdx}
            adults={roomOccupancy.adults}
            adultChanger={(increase) => setOccupancy(adultChanger(roomIdx, increase, occupancy))}
            childrenAges={roomOccupancy.children || []}
            childrenChanger={(increase) => setOccupancy(childrenChanger(roomIdx, increase, occupancy))}
            setAge={(index, age) => setOccupancy(setAge(roomIdx, index, age, occupancy))}
          />
        </React.Fragment>
      )),
    [occupancy, setOccupancy],
  );

  if (isTrivago) {
    if (isMobileLayout) {
      return (
        <Styled.Container>
          {contentBng}
          {occupancy.length !== env.searchBar.maxOccupancy && (
            <Styled.Actions>
              <Styled.AddButton
                disabled={occupancy.length === env.searchBar.maxOccupancy}
                onClick={() => setOccupancy(addOccupancy(occupancy))}
              >
                <span>
                  <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.add-room'}>Add room</Trans>
                </span>
              </Styled.AddButton>
            </Styled.Actions>
          )}
        </Styled.Container>
      );
    }

    return (
      <Styled.Container active={expanded}>
        <Styled.TrivagoPickerButton data-testid="occupancy-text" tabIndex={2} onFocus={() => setExpanded(true)}>
          <Styled.Label>
            <Trans i18nKey="search-bar.guests">Guests</Trans>
          </Styled.Label>
          <Styled.Info>
            <Styled.Image src={peopleIcon} />
            {people}
          </Styled.Info>
          <Styled.Info>
            <Styled.Image src={roomsIcon} />
            <Trans
              i18nKey="trivago.v2.search-bar.occupancy-picker.rooms-with-param"
              defaults="{ rooms, plural, =1 {# room} other {# rooms} }"
              values={{ rooms: occupancy.length }}
            />
          </Styled.Info>
        </Styled.TrivagoPickerButton>
        <SearchFormProvider>
          {expanded && (
            <OccupancyPickerModal
              visible={expanded}
              onClose={() => setExpanded(false)}
              onDone={(occ: RoomOccupancy[]) => closeWithChange(occ)}
            />
          )}
        </SearchFormProvider>
      </Styled.Container>
    );
  }

  if (isMobileLayout) {
    return (
      <div className="position-relative">
        <Styled.MobileContainer className="width-expand flex-column">{content}</Styled.MobileContainer>
      </div>
    );
  }

  return (
    <div ref={wrapRef}>
      <div>
        <Styled.PickerButton
          tabIndex={4}
          onClick={() => setExpanded(true)}
          onFocus={() => setExpanded(true)}
          className="flex-left width-expand"
          type="button"
          ref={buttonRef}
        >
          <span className="flex flex-middle">
            <img className="margin-medium-right" src={adultsIcon} alt="" />
            <Text
              data-testid="occupancy-text"
              alignment={TextAlignment.Left}
              transform={TextTransform.Lowercase}
              className="flex-column flex"
            >
              <Trans
                i18nKey="search-bar.rooms-with-param"
                defaults="{ rooms, plural, =1 {# room} other {# rooms} }"
                values={{ rooms: occupancy.length }}
              />
              {', '}
              <Trans
                i18nKey="search-bar.guests-with-param-numeric"
                defaults="{ guests, plural, =1 {# guest} other {# guests} }"
                values={{ guests: sumGuests(occupancy) }}
              />
            </Text>
          </span>
        </Styled.PickerButton>
        {expanded && (
          <Styled.OccupancyDropdown isOpen={expanded} position="bottom-left">
            <Styled.Main ref={mainRef} $overflow={overflow} offset={offset}>
              {content}
            </Styled.Main>
          </Styled.OccupancyDropdown>
        )}
      </div>
    </div>
  );
};
