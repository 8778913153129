/**
 * @category Universal Components
 * @packageDocumentation
 */
import useOutsideClick from 'common/hooks/useOutsideClick';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import closeIcon from 'assets/images/icons/close-icon.svg';
import successIcon from 'assets/images/icons/notification/check.svg';
import errorIcon from 'assets/images/icons/notification/cross.svg';
import infoIcon from 'assets/images/icons/notification/info.svg';
import warnIcon from 'assets/images/icons/notification/warning.svg';
import Styled from 'components/common/Toast/Toast.styled';
import { ToastState, ToastType } from 'components/common/Toast/Toast.types';
import { MODAL_ANIMATION_MS } from 'components/common/modal/CustomAbstractModal.styles';

const SHOW_TOAST_FOR_MILLIS = 5000;
const SHOW_ERROR_TOAST_FOR_MILLIS = 5000;
const determineToastLivetime = (type: ToastType | undefined) => {
  switch (type) {
    case ToastType.Error:
      return SHOW_ERROR_TOAST_FOR_MILLIS;
    default:
      return SHOW_TOAST_FOR_MILLIS;
  }
};

const icons = new Map<ToastType, string>();

icons.set(ToastType.Error, errorIcon);
icons.set(ToastType.Info, infoIcon);
icons.set(ToastType.Success, successIcon);
icons.set(ToastType.Warn, warnIcon);

function getIcon(type?: ToastType) {
  return type ? icons.get(type) : infoIcon || infoIcon;
}

export interface ToastProps {
  message?: string;
  type?: ToastType;
  clearMessage: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, clearMessage, type }) => {
  const [state, setState] = useState(ToastState.Closed);
  const toastRef = useRef<HTMLDivElement>(null);

  useOutsideClick(toastRef, () => clearMessage(), true);

  useEffect(() => {
    switch (state) {
      case ToastState.Opening: {
        const handler = setTimeout(() => setState(ToastState.Opened), MODAL_ANIMATION_MS);

        return () => {
          clearTimeout(handler);
        };
      }
      case ToastState.Opened: {
        const lifetime = determineToastLivetime(type);

        if (lifetime !== undefined) {
          const handler = setTimeout(() => setState(ToastState.Closing), lifetime);

          return () => {
            clearTimeout(handler);
          };
        }

        return () => {
          // we do nothing here, because there is no closing handler
        };
      }
      case ToastState.Closing: {
        const handler = setTimeout(() => {
          setState(ToastState.Closed);
          clearMessage();
        }, MODAL_ANIMATION_MS);

        return () => {
          clearTimeout(handler);
        };
      }
      default:
        return undefined;
    }
  }, [state, clearMessage, type]);

  const onClose = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setState(ToastState.Closing);
  }, []);

  useEffect(() => {
    setState(ToastState.Opening);
  }, [message]);

  return (
    <Styled.Container state={state} type={type} id={'notification-message'}>
      <Styled.Content>
        <Styled.NotificationIcon className="margin-medium-right" src={getIcon(type)} alt="info" />
        <div className="margin-auto-right">{message}</div>
        <a className="margin-medium-left" href="/" onClick={onClose}>
          <img src={closeIcon} alt={'close'} width={17} />
        </a>
      </Styled.Content>
    </Styled.Container>
  );
};

export default Toast;
