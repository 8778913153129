import useDateByOptions from 'common/hooks/useDateByOptions';
import useDatepickerDate from 'common/hooks/useDatepickerDate';
import { weekdayShortFormat } from 'common/utils/dateFormats';
import React, { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import dateErrorIcon from 'assets/images/icons/date-error.svg';
import dateIcon from 'assets/images/icons/date.svg';
import { Text } from 'components/common/Text/Text';
import { TextColor } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import Styled from 'components/datePicker/customDatepicker/DatepickerWidgetButton.styled';
import { isPeriodOverMaxNights } from 'utils/dateUtils';

export const DATEPICKER_CHECKIN_ID = 'dpCheckinLink';

interface DatepickerWidgetButtonPops {
  startDate: Date | null;
  endDate: Date | null;
  onClick: React.EventHandler<React.SyntheticEvent>;
  widgetRef: React.RefObject<HTMLDivElement>;
  offscreenMode?: boolean;
  active?: boolean;
}

export const DatepickerWidgetButton: React.FC<DatepickerWidgetButtonPops> = ({
  startDate,
  endDate,
  onClick,
  widgetRef,
  offscreenMode,
  active,
}) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const [t, i18n] = useTranslation();
  const { isTrivago } = useContext(TrivagoContext);

  const startDateLabel = useDatepickerDate({
    lang: i18n.language,
    date: startDate,
    dateType: 'checkin',
  });
  const endDateLabel = useDatepickerDate({
    lang: i18n.language,
    date: endDate,
    dateType: 'checkout',
  });
  const startWeekday = useDateByOptions({
    lang: i18n.language,
    date: startDate,
    options: weekdayShortFormat as Intl.DateTimeFormatOptions,
  });
  const endWeekday = useDateByOptions({
    lang: i18n.language,
    date: endDate,
    options: weekdayShortFormat as Intl.DateTimeFormatOptions,
  });
  const wrongPeriod = useMemo(() => isPeriodOverMaxNights(startDate, endDate), [endDate, startDate]);

  const testIdStartDate = useMemo(
    () => `start-date-${startDate?.getDay() || '0'}-${startDate?.getMonth() || '0'}-${startDate?.getDate() || '0'}`,
    [startDate],
  );
  const testIdEndDate = useMemo(
    () => `end-date-${endDate?.getDay() || '0'}-${endDate?.getMonth() || '0'}-${endDate?.getDate() || '0'}`,
    [endDate],
  );

  const mobileContent = useMemo(
    () => (
      <Styled.WidgetButton onClick={onClick}>
        <Styled.WidgetButtonDate id={DATEPICKER_CHECKIN_ID}>
          <div className="width-expand flex flex-center">
            <img src={dateIcon} className="margin-medium-right" alt={''} />
            <div className="margin-auto-vertical ">
              <Text color={TextColor.Secondary} className="width-1-1" tag="div">
                {t('common.misc.startDatePick', 'Check-In')}
              </Text>
              <Styled.DatepickerDate data-testid={testIdStartDate}>{startDateLabel.mobile}</Styled.DatepickerDate>
            </div>
          </div>
        </Styled.WidgetButtonDate>
        <Styled.WidgetButtonDate id="dpCheckoutLink">
          <div className="width-expand flex flex-center">
            <img src={dateIcon} className="margin-medium-right" alt={''} />
            <div className="margin-auto-vertical">
              <Text color={TextColor.Secondary} className="width-1-1" tag="div">
                {t('common.misc.endDatePick', 'Check-Out')}
              </Text>
              <Styled.DatepickerDate data-testid={testIdEndDate}>{endDateLabel.mobile}</Styled.DatepickerDate>
            </div>
          </div>
        </Styled.WidgetButtonDate>
      </Styled.WidgetButton>
    ),
    [onClick, t, testIdStartDate, startDateLabel.mobile, testIdEndDate, endDateLabel.mobile],
  );

  if (isMobileLayout) {
    if (isTrivago) {
      return null;
    }

    if (offscreenMode) {
      return (
        <Styled.MobileDatepickerWidgetButtonsExpanded>{mobileContent}</Styled.MobileDatepickerWidgetButtonsExpanded>
      );
    }

    return <Styled.MobileDatepickerWidgetButtons>{mobileContent}</Styled.MobileDatepickerWidgetButtons>;
  }

  if (isTrivago) {
    let startText = startDateLabel?.desktop;
    let endText = t('common.misc.endDatePick', 'Check-Out');

    if (startWeekday) {
      startText = `${startWeekday}, ${startDateLabel?.desktop}`;
    }
    if (endWeekday) {
      endText = `${endWeekday}, ${endDateLabel?.desktop}`;
    }

    return (
      <Styled.TrivagoContainer
        onFocus={onClick}
        active={active}
        tabIndex={2}
        onClick={onClick}
        id={DATEPICKER_CHECKIN_ID}
      >
        <Styled.Content>
          <Styled.Label>
            <Trans i18nKey={'trivago.v2.search-bar.date-picker.dates'}>Dates:</Trans>
          </Styled.Label>
          <Styled.DateInfo>
            <Styled.DateImage src={dateIcon} />
            <Styled.DateText data-testid={testIdStartDate}>{startText}</Styled.DateText>
          </Styled.DateInfo>
          <span>{' - '}</span>
          <Styled.DateInfo>
            <Styled.DateImage src={!active && wrongPeriod ? dateErrorIcon : dateIcon} />
            <Styled.DateText data-testid={testIdEndDate}>{endText}</Styled.DateText>
          </Styled.DateInfo>
        </Styled.Content>
      </Styled.TrivagoContainer>
    );
  }

  return (
    <Styled.Container
      className={`flex height-1-1 flex-middle width-expand padding-remove-horizontal`}
      onFocus={onClick}
      id={DATEPICKER_CHECKIN_ID}
      ref={widgetRef}
      tabIndex={2}
    >
      <Styled.Field className="width-1-2 flex flex-middle height-1-1 padding-remove-horizontal">
        <img className="padding-small padding-remove-right" src={dateIcon} alt="" />
        <div className="width-expand">
          {startWeekday && <span data-testid={`start-weekday-${startDate?.getDay()}`}>{startWeekday}, </span>}
          <span data-testid={testIdStartDate}>{startDateLabel?.desktop}</span>
        </div>
      </Styled.Field>
      <Styled.DatepickerWidgetDivider vertical />
      <Styled.Field id="dpCheckoutLink" className="width-1-2 flex flex-middle height-1-1 padding-remove-horizontal">
        <img className="padding-small padding-remove-right" src={dateIcon} alt="" />
        <div className="width-expand">
          {endWeekday && <span data-testid={`end-weekday-${endDate?.getDay()}`}>{endWeekday}, </span>}
          <span data-testid={testIdEndDate}>{endDateLabel?.desktop}</span>
        </div>
      </Styled.Field>
    </Styled.Container>
  );
};
