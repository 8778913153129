import styled from 'styled-components';
import { white, borderColorLightest, gray } from 'style/variables';

const Header = styled.div`
  height: 4rem;
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.header`
  background: ${white};
  border-bottom: 1px solid ${borderColorLightest};
  z-index: 1000;
`;

const BurgerIcon = styled.a`
  display: flex;
  align-items: center;
  color: ${gray};
  min-height: 56px;
`;

export const MobilePageHeaderStyled = {
  Container,
  Header,
  BurgerIcon,
};
