import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextSize, TextWeight } from 'components/common/Text/Text.types';
import BenefitSectionStyled from 'components/home/BenefitSection.styled';
import Styled from 'components/home/HomeAboutPanel/HomeAboutPanel.styled';
import { ABOUTUS_ROUTE } from 'routeList';

export const HomeAboutPanel = () => {
  const [t] = useTranslation();

  return (
    <BenefitSectionStyled.AboutAsItem>
      <Styled.BenefitLabel>{t('home.mission.title', 'Our Mission')}</Styled.BenefitLabel>
      <Styled.Subtitle weight={TextWeight.SemiBold} tag="div">
        &ldquo;{t('algotels.about-us.title', 'Algorithms save more than humans')}&ldquo;
      </Styled.Subtitle>
      <Styled.Text1
        size={TextSize.Small}
        alignment={TextAlignment.Center}
        color={TextColor.Secondary}
        weight={TextWeight.Normal}
        tag="div"
      >
        {t(
          'algotels.about-us.under-title-1',
          'There are so many hotel savings available if you’d just let the algorithms find them for you.',
        )}
      </Styled.Text1>
      <Styled.Text2
        size={TextSize.Small}
        alignment={TextAlignment.Center}
        color={TextColor.Secondary}
        weight={TextWeight.Normal}
        className="margin-small-bottom"
        tag="div"
      >
        {t(
          'algotels.about-us.under-title-2',
          'Algotels is the world’s first algo-driven hotel booking platform that uses technology and ' +
            'constantly optimized algorithms to take advantage of all available data, parameters, and solutions to put ' +
            'together a hotel reservation that will save you a ton of…money, time, and frustration.',
        )}
      </Styled.Text2>

      <Link to={ABOUTUS_ROUTE} className={'margin-top'}>
        <Text color={TextColor.Primary}>Read more</Text>
      </Link>
    </BenefitSectionStyled.AboutAsItem>
  );
};
