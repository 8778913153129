import styled from 'styled-components';

const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 0;
  padding: 0;
  font-size: 1rem;
  outline: none;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;

  &[disabled] {
    filter: opacity(0);
    cursor: default;
  }
`;

export default { NavButton };
