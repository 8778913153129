/* eslint-disable no-magic-numbers */
export const hashCode = (s: string) =>
  s.split('').reduce((hash, char) => {
    const newHash = (hash << 5) - hash + char.charCodeAt(0);

    return newHash & newHash;
  }, 0);

export const hashCode256 = async (s: string) => {
  if (!s) {
    return '';
  }

  const utf8 = new TextEncoder().encode(s);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
};
