import React from 'react';
import Loader from 'components/widgets/Loader';

const LoadingScreen: React.FC = () => (
  <div className="flex flex-column flex-middle align-center margin-auto-vertical">
    <Loader size={200} />
  </div>
);

export default LoadingScreen;
