/**
 * @category Utils
 * @packageDocumentation
 */

import { parseDate } from 'common/utils/date';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { calculateNightsBetweenDates, startOfToday } from 'utils/dateUtils';

const DATE_FORMAT = /^(\d{4})-(\d{2})-(\d{2})$/;

/**
 * Checks if the passed parameter is a number within given boundaries
 * @param n - value to check
 * @param min - minimum (inclusive)
 * @param max - maximum (inclusive)
 * @returns true if the condition met
 */
export function validateNumber(n: number | undefined, min?: number, max?: number): boolean {
  if ((n !== 0 && !n) || Number.isNaN(n)) {
    return false;
  }
  if (min !== undefined && n < min) {
    return false;
  }
  if (max !== undefined && n > max) {
    return false;
  }

  return true;
}

/**
 * Checks if the passed parameter is integer within given boundaries
 * @param s - value to check
 * @param min - minimum (inclusive)
 * @param max - maximum (inclusive)
 * @returns true if the condition met
 */
export function validateInt(s: string, min?: number, max?: number): boolean {
  return validateNumber(Number.parseInt(s, 10), min, max);
}

/**
 * Checks if the passed parameter is float within given boundaries
 * @param s - value to check
 * @param min - minimum (inclusive)
 * @param max - maximum (inclusive)
 * @returns true if the condition met
 */
export function validateFloat(s: string, min?: number, max?: number): boolean {
  return validateNumber(parseFloat(s), min, max);
}

/**
 * Checks if passed parameter is a date
 * @param s
 * @returns true if so
 */
export function validateDate(s: string): boolean {
  return parseDate(s) !== undefined;
}

export function validateDateStrong(date: string | undefined, offsetHours?: number) {
  if (!date || !DATE_FORMAT.test(date)) {
    throw new ClientError(ClientErrorCode.InvalidSearchParams);
  }

  const d = parseDate(date);
  const now = startOfToday(offsetHours);

  if (!d) {
    throw new ClientError(ClientErrorCode.InvalidSearchParams);
  }

  if (d < now) {
    throw new ClientError(ClientErrorCode.DatesInPast);
  }

  return true;
}

export function validateDates(checkin: string | undefined, checkout: string | undefined) {
  if (checkin === checkout) {
    throw new ClientError(ClientErrorCode.DatesEquals);
  }

  const checkinDate = parseDate(checkin);
  const checkoutDate = parseDate(checkout);

  if (!checkinDate || !checkoutDate || checkoutDate < checkinDate) {
    throw new ClientError(ClientErrorCode.InvalidSearchParams);
  }

  const nights = calculateNightsBetweenDates(checkinDate, checkoutDate);

  if (!nights || nights > env.searchBar.maxNights) {
    throw new ClientError(ClientErrorCode.InvalidLengthOfStay);
  }

  return true;
}

export const basicInputValidation = (value: string) => value && value.trim().length >= env.inputs.basicMinLength;

export function isNumber(value: string) {
  return /^[\d\s-]+$/.test(value);
}
