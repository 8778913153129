import { atom } from 'jotai';
import { setSessionInfo } from 'atoms/utils/session';
import { AuthProvider } from 'backend/AuthProviderStorage';
import { User } from 'backend/api/user/userModel';
import {
  LoginOauthRequest,
  LoginRequest,
  SignUpOauthRequest,
  SignUpRequest,
  UpdatePasswordRequest,
} from 'backend/api/user/userRequest';
import { SplittyPrincipal } from 'backend/authModelPrincipal';
import SplittySession from 'backend/authModelSession';
import { getDataProvider } from 'backend/dataProvider';
import clientStore from 'backend/splittyClientStore';

export const sessionAtom = atom<SplittySession>(new SplittySession(undefined, false));

export const sessionForceUpdateFlagAtom = atom<boolean>(false);

export const restoreUserDetailsAtom = atom(null, async (get, set, provider?: AuthProvider) => {
  if (clientStore.getUserId()) {
    const dataProvider = await getDataProvider();
    const newUserDetails = await dataProvider.authenticator.getUserDetails();

    return set(sessionAtom, new SplittySession(new SplittyPrincipal(newUserDetails, provider)));
  }

  set(sessionAtom, new SplittySession(undefined));

  return Promise.resolve();
});

export const updateUserDetailsAtom = atom(null, async (get, set, payload: User) => {
  const dataProvider = await getDataProvider();
  const newUserDetails = await dataProvider.authenticator.updateUserDetails(payload);

  set(sessionAtom, new SplittySession(new SplittyPrincipal(newUserDetails)));
});

export const loginAtom = atom(null, async (get, set, payload: LoginRequest) => {
  const dataProvider = await getDataProvider();

  return dataProvider.authenticator.login(payload);
});

export const signUpAtom = atom(null, async (get, set, payload: SignUpRequest) => {
  const dataProvider = await getDataProvider();

  return dataProvider.authenticator.signUp(payload);
});

export const loginOauthAtom = atom(null, async (get, set, payload: LoginOauthRequest) => {
  const dataProvider = await getDataProvider();

  return dataProvider.authenticator.loginOauth(payload);
});

export const signUpOauthAtom = atom(null, async (get, set, payload: SignUpOauthRequest) => {
  const dataProvider = await getDataProvider();

  return dataProvider.authenticator.signUpOauth(payload);
});

export const logoutAtom = atom(null, async () => {
  const dataProvider = await getDataProvider();

  return dataProvider.authenticator.logout();
});

export const updatePasswordAtom = atom(
  null,
  async (get, set, { payload, userId }: { payload: UpdatePasswordRequest; userId?: number }) => {
    const dataProvider = await getDataProvider();
    const userToken = await dataProvider.authenticator.updatePassword(payload, userId);

    setSessionInfo(userToken);
    await set(restoreUserDetailsAtom);

    return userToken;
  },
);

export const forgotPasswordAtom = atom(null, async (get, set, email: string) => {
  const dataProvider = await getDataProvider();

  return dataProvider.authenticator.forgotPassword(email);
});
