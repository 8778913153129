import styled from 'styled-components';
import { Button as ButtonComponent } from 'components/common/Button/Button';
import { Text } from 'components/common/Text/Text';
import { lightRoyalBlue, superDarkGrey } from 'style/variables';

const AcceptPolicy = styled.a`
  color: ${lightRoyalBlue};
  &:hover {
    color: ${lightRoyalBlue};
  }
`;

const AcceptTerms = styled.a`
  color: ${lightRoyalBlue};
  &:hover {
    color: ${lightRoyalBlue};
  }
`;

const Header = styled.div`
  font-size: 32px;
  line-height: normal;
  font-weight: 600;
  color: ${superDarkGrey};
  text-align: center;
  margin-bottom: 42px;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -20px;

  & > * {
    margin-top: 20px;
  }
`;

const CheckBoxes = styled(Text)`
  width: 100%;
  margin-top: 30px;
`;

const Button = styled(ButtonComponent)`
  margin-top: 28px;
`;

export default {
  AcceptPolicy,
  AcceptTerms,
  Header,
  Fields,
  CheckBoxes,
  Button,
};
