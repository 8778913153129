import styled from 'styled-components';
import { superDarkGrey } from 'style/variables';

const Header = styled.div`
  font-size: 32px;
  line-height: normal;
  font-weight: 600;
  color: ${superDarkGrey};
  text-align: center;
`;

export default {
  Header,
};
