import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FAQ_ROUTE } from 'routeList';

interface FaqLinkProps {
  onClick?: () => void;
}

const FaqLink: React.FC<FaqLinkProps> = ({ onClick }) => (
  <Link to={FAQ_ROUTE} onClick={onClick}>
    <Trans i18nKey="common.menu.support">faq</Trans>
  </Link>
);

export default FaqLink;
