import { AUTH_3DS_COMPLETE } from 'common/utils/stripe';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Stripe3DS: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const q: { payment_status?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (q.payment_status && window.top) {
      window.top.postMessage({ type: AUTH_3DS_COMPLETE, paymentStatus: q.payment_status }, window.parent.origin);
    } else {
      setReady(true);
    }
  }, [location]);

  return <>{ready && children}</>;
};

export default Stripe3DS;
