import styled from 'styled-components';
import newArrowCheckboxChecked from 'assets/images/icons/check-line.svg';
import DefaultInputCheckboxStyled from 'components/common/InputCheckbox/InputCheckboxStyled/DefaultInputCheckbox.styled';
import { InputCheckboxStyledModel } from 'components/common/InputCheckbox/InputCheckboxStyled/InputCheckbox.styled-model';
import { lightRoyalBlue } from 'style/variables';

const size = 20;

const Checkbox = styled(DefaultInputCheckboxStyled.Checkbox)`
  min-width: ${size}px;
  min-height: ${size}px;

  border: 1px solid black;

  &:focus {
    border: 1px solid black;
    background-color: unset;
  }

  &:checked,
  &:indeterminate,
  &:checked:focus,
  &:indeterminate:focus {
    background-color: ${lightRoyalBlue};
  }

  &:checked {
    background-image: url(${newArrowCheckboxChecked});
  }

  &:disabled:checked {
    background-image: url(${newArrowCheckboxChecked});
  }
`;

const Styled: InputCheckboxStyledModel = {
  Checkbox,
};

export default Styled;
