import styled from 'styled-components';
import { lightRoyalBlue } from 'style/variables';

const Link = styled.a`
  color: ${lightRoyalBlue};
  &:hover {
    color: ${lightRoyalBlue};
  }
  svg {
    fill: currentColor;
  }
`;

export default {
  Link,
};
