/**
 * @category Configuration
 * @packageDocumentation
 *
 */

import { CurrencyCode } from 'common/backend/api/model';
import { CurrenciesConf } from 'environments/configuration';

/**
 * Configuration for currencies, which is currently shared between all the environment dependent configurations.
 * (It may change later)
 */
const currencies: CurrenciesConf = {
  defaultCurrencyCode: CurrencyCode.USD,
  debug: false,
  currencies: [
    {
      code: 'USD',
      name: 'US Dollar',
      symbol: '$',
      symbolAtRight: false,
      maxForFilterByBudget: 500,
    },
    {
      code: 'EUR',
      name: 'Euro',
      symbol: '€',
      symbolAtRight: false,
      maxForFilterByBudget: 500,
    },
    {
      code: 'GBP',
      name: 'British Pound Sterling',
      symbol: '£',
      symbolAtRight: false,
      maxForFilterByBudget: 500,
    },
    {
      code: 'AED',
      name: 'United Arab Emirates Dirham',
      symbol: 'AED',
      symbolAtRight: false,
      maxForFilterByBudget: 2000,
    },
    {
      code: 'AUD',
      name: 'Australian Dollar',
      symbol: 'AU$',
      symbolAtRight: false,
      maxForFilterByBudget: 1000,
    },
    {
      code: 'BRL',
      name: 'Brazilian Real',
      symbol: 'R$',
      symbolAtRight: false,
      maxForFilterByBudget: 3000,
    },
    {
      code: 'CAD',
      codeDisplay: 'CAD $',
      name: 'Canadian Dollar',
      symbol: 'CA$',
      symbolAtRight: false,
      maxForFilterByBudget: 1000,
    },
    {
      code: 'CHF',
      name: 'Swiss Franc',
      symbol: 'CHF',
      symbolAtRight: false,
      maxForFilterByBudget: 500,
    },
    {
      code: 'CLP',
      name: 'Chilean Peso',
      symbol: 'CLP',
      symbolAtRight: false,
      maxForFilterByBudget: 423000,
    },
    {
      code: 'CRC',
      name: 'Costa Rican Colón',
      symbol: 'CRC',
      symbolAtRight: false,
      maxForFilterByBudget: 315500,
    },
    {
      code: 'DKK',
      name: 'Danish Krone',
      symbol: 'DKK',
      symbolAtRight: false,
      maxForFilterByBudget: 3500,
    },
    {
      code: 'DOP',
      name: 'Dominican Peso',
      symbol: 'DOP',
      symbolAtRight: false,
      maxForFilterByBudget: 28500,
    },
    {
      code: 'HKD',
      codeDisplay: 'HKD $',
      name: 'Hong Kong Dollar',
      symbol: 'HK$',
      symbolAtRight: false,
      maxForFilterByBudget: 4000,
    },
    {
      code: 'HUF',
      name: 'Hungarian Forint',
      symbol: 'HUF',
      symbolAtRight: false,
      maxForFilterByBudget: 162500,
    },
    {
      code: 'ILS',
      name: 'Israeli New Sheqel',
      symbol: '₪',
      symbolAtRight: false,
      maxForFilterByBudget: 2000,
    },
    {
      code: 'INR',
      name: 'Indian Rupee',
      symbol: 'INR',
      symbolAtRight: false,
      maxForFilterByBudget: 38000,
    },
    {
      code: 'JPY',
      name: 'Japanese Yen',
      symbol: '¥',
      symbolAtRight: false,
      maxForFilterByBudget: 57000,
    },
    {
      code: 'MAD',
      name: 'Moroccan Dirham',
      symbol: 'MAD',
      symbolAtRight: false,
      maxForFilterByBudget: 5000,
    },
    {
      code: 'MXN',
      codeDisplay: 'MXN $',
      name: 'Mexican Peso',
      symbol: 'MX$',
      symbolAtRight: false,
      maxForFilterByBudget: 11000,
    },
    {
      code: 'NOK',
      name: 'Norwegian Krone',
      symbol: 'NOK',
      symbolAtRight: false,
      maxForFilterByBudget: 5000,
    },
    {
      code: 'NZD',
      codeDisplay: 'NZD $',
      name: 'New Zealand Dollar',
      symbol: 'NZ$',
      symbolAtRight: false,
      maxForFilterByBudget: 1000,
    },
    {
      code: 'SAR',
      name: 'Saudi Riyal',
      symbol: 'SAR',
      symbolAtRight: false,
      maxForFilterByBudget: 2000,
    },
    {
      code: 'SEK',
      name: 'Swedish Krona',
      symbol: 'SEK',
      symbolAtRight: false,
      maxForFilterByBudget: 5000,
    },
    {
      code: 'SGD',
      name: 'Singapore Dollar',
      symbol: 'SGD',
      symbolAtRight: false,
      maxForFilterByBudget: 1000,
    },
    {
      code: 'TRY',
      name: 'Turkish Lira',
      symbol: 'TRY',
      symbolAtRight: false,
      maxForFilterByBudget: 7000,
    },
    {
      code: 'TWD',
      codeDisplay: 'NT $',
      name: 'New Taiwan Dollar',
      symbol: 'NT$',
      symbolAtRight: false,
      maxForFilterByBudget: 14000,
    },
    {
      code: 'ZAR',
      name: 'South African Rand',
      symbol: 'ZAR',
      symbolAtRight: false,
      maxForFilterByBudget: 8000,
    },
  ],
};

export default currencies;
