import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ClientErrorCode } from 'errors/clientError';
import { AvailableErrors } from 'errors/errorData';

const useUpdatePasswordErrors = (
  invalidateInputPanelCallback: ((reason: ServerErrorCode | ClientErrorCode, message: string) => void) | undefined,
): AvailableErrors => {
  const [t] = useTranslation();
  const setToast = useContext(ToastContext);

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.Unauthenticated],
          action: () =>
            setToast(
              t('user-profile.unauthenticated', 'Your session has expired. Please log in and try again.'),
              ToastType.Error,
            ),
        },
        {
          serverCodes: [ServerErrorCode.InvalidCredentials],
          action: () => {
            if (invalidateInputPanelCallback) {
              invalidateInputPanelCallback(
                ServerErrorCode.InvalidCredentials,
                t('user-profile.wrong-current-password', 'The current password is wrong.'),
              );
            } else {
              setToast(t('errors.token-expired', 'Your token has expired. Please try again.'), ToastType.Error);
            }
          },
        },
        {
          serverCodes: [ServerErrorCode.Unauthorized],
          action: () => setToast(t('user-profile.unauthorized', 'Access denied.'), ToastType.Error),
        },
        {
          clientCodes: [ClientErrorCode.SamePassword],
          action: () => {
            if (invalidateInputPanelCallback) {
              invalidateInputPanelCallback(
                ClientErrorCode.SamePassword,
                t('user-profile.same-password', 'The new password matches the current one.'),
              );
            }
          },
        },
        {
          serverCodes: [ServerErrorCode.ItemNotFound],
          action: () =>
            setToast(t('errors.user-not-found', 'User not found, please log in and try again'), ToastType.Error),
        },
      ],
      default: {
        action: () =>
          setToast(
            t(
              'account.password-unchanged',
              'Sorry, we failed to update the password. Please try again later or contact support team.',
            ),
            ToastType.Error,
          ),
      },
    }),
    [invalidateInputPanelCallback, setToast, t],
  );
};

export default useUpdatePasswordErrors;
