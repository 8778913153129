/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { rem } from 'style/functions';
import media from 'style/media';
import { ifTrivago } from 'style/trivago';
import { landingBngBlueButtonColor, trivagoDarkGrey } from 'style/trivagoVaribles';
import {
  backgroundGreen2,
  battleshipGrey,
  lightRoyalBlue,
  loaderBackgroundColor,
  occupancySelectBorderColor,
  superDarkGrey,
  white,
} from 'style/variables';

const DayMeta = styled.button<{ isToday?: boolean }>`
  position: relative;

  height: 47px;
  border: 0;
  outline: 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  ${ifTrivago(
    css`
      background: ${loaderBackgroundColor};
    `,
    css`
      background: ${white};
    `,
  )}
  margin: 0 !important;

  ${(props) =>
    props.isToday &&
    css`
      &:before {
        content: '';
        z-index: 1;
        position: absolute;
        width: ${rem(7)};
        height: ${rem(7)};
        bottom: 0;
        right: 50%;
        transform: translateY(-75%) translate(50%);
        border-radius: 50%;
        background: ${backgroundGreen2};
      }
    `}

  ${ifTrivago(css`
    font-size: 16px;
    background: ${white};
    color: ${trivagoDarkGrey};
    font-weight: 500;
  `)}
`;

const DayDefault = styled(DayMeta)<{ hovered?: boolean }>`
  ${ifTrivago(
    css`
      color: ${trivagoDarkGrey};
    `,
    css`
      color: ${superDarkGrey};
    `,
  )}

  ${({ hovered }) =>
    hovered &&
    css`
      ${ifTrivago(
        css`
          background: rgba(29, 127, 190, 0.2);
        `,
        css`
          background: rgba(255, 0, 110, 0.1);
        `,
      )}

      ${media.mobile} {
        background: ${white};
      }
    `}
`;

const DaySelected = styled(DayMeta)`
  position: relative;
  z-index: 0;
  color: ${superDarkGrey};
  background: rgba(255, 0, 110, 0.1);

  ${ifTrivago(css`
    background: rgba(29, 127, 190, 0.2);
    color: ${trivagoDarkGrey};
  `)}
`;

const DaySelectedMeta = styled(DayMeta)`
  z-index: 1;
  color: ${white};
  background: transparent;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
  }
`;

const DaySelectedFirst = styled(DaySelectedMeta)`
  z-index: 2;
  background: linear-gradient(to right, white 50%, rgba(255, 0, 110, 0.1) 50%);

  &:after {
    width: ${rem(47)};
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%);
    background: ${lightRoyalBlue};
  }

  ${ifTrivago(css`
    background: linear-gradient(to right, white 50%, rgba(29, 127, 190, 0.2) 50%);
    font-weight: 600;

    &:after {
      background: ${landingBngBlueButtonColor};
    }
  `)}
`;

const DaySelectedLast = styled(DaySelectedMeta)`
  background: linear-gradient(to left, white 50%, rgba(255, 0, 110, 0.1) 50%);

  &:after {
    width: ${rem(47)};
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%);
    background: ${lightRoyalBlue};
  }

  ${ifTrivago(css`
    background: linear-gradient(to left, white 50%, rgba(29, 127, 190, 0.2) 50%);
    font-weight: 600;

    &:after {
      background: ${landingBngBlueButtonColor};
    }
  `)}
`;

const DayDisabled = styled(DayMeta)`
  ${ifTrivago(
    css`
      color: ${occupancySelectBorderColor};
    `,
    css`
      color: ${battleshipGrey};
    `,
  )}
`;

export default { DayDisabled, DaySelectedLast, DaySelectedFirst, DaySelected, DayDefault };
