import React, { PropsWithChildren } from 'react';
import Styled from 'components/datePicker/customDatepicker/NavButton.styled';

interface NavButtonProps extends PropsWithChildren<Record<string, unknown>> {
  onClick: () => void;
  disabled?: boolean;
}

const NavButton = ({ children, onClick, disabled }: NavButtonProps) => (
  <Styled.NavButton type="button" onClick={onClick} disabled={disabled}>
    {children}
  </Styled.NavButton>
);

export default NavButton;
