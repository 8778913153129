/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import ButtonStyled from 'components/common/Button/ButtonStyled/DefaultButton.styled';
import media from 'style/media';
import { borderColorLight, white } from 'style/variables';

const Container = styled.div`
  width: 100%;
`;

const Modal = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 15px;
  width: 600px;
  max-width: calc(100% - 0.01px) !important;
  background: ${white};

  border: 1px solid ${borderColorLight};
  border-radius: 3px;
`;

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  font-size: 14px;
`;

const Actions = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 30px;
`;

const Button = styled(ButtonStyled.PrimaryButton)`
  height: 40px;
  margin-left: auto;
  font-size: 14px;
  padding: 0 40px;
`;

const ButtonOutlined = styled(ButtonStyled.ButtonOutlined)`
  height: 40px;
  font-size: 14px;
  padding: 0 40px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${media.mobile} {
    flex-direction: column;
    gap: 10px;
  }
`;

export default {
  Container,
  Modal,
  Content,
  Actions,
  Button,
  ButtonOutlined,
  ButtonsContainer,
};
