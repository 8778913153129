/**
 * @category Views
 * @packageDocumentation
 */
import React, { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import expandIcon from 'assets/images/icons/select-expand-icon.svg';
import arrowIcon from 'assets/images/icons/trivagoV2/select-arrow-icon.svg';
import SelectInput, { Items, Key } from 'components/common/SelectInput/SelectInput';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import Styled from 'components/header-footer/LanguageSelector/InternalLanguageSelector.styled';
import Icon from 'components/widgets/Icon';
import useTranslatedLanguageList from 'utils/translate/useTranslatedLanguageList';

/**
 * Shows current language.
 * Clicking on it opens dropdowns where the language can be changed
 * (the component only calls it and does nothing else)
 */
interface InternalLanguageSelectorProps {
  isInDarkHeader?: boolean;
  languageCode: string;
  onLanguageChange: (value: Key) => void;
}

export const InternalLanguageSelector: React.FC<InternalLanguageSelectorProps> = ({
  isInDarkHeader,
  languageCode,
  onLanguageChange,
}) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const { isTrivago } = useContext(TrivagoContext);

  const languageList = useTranslatedLanguageList();
  const options = useMemo<Items>(() => languageList.map((el) => ({ value: el.name, key: el.code })), [languageList]);

  const lang = useMemo(() => languageList.find((el) => el.code === languageCode), [languageCode, languageList]);

  const trivagoDisplayValue = useMemo(
    () => (
      <>
        <Styled.TrivagoDisplayValue invertColor={isInDarkHeader && !isMobileLayout}>
          {lang?.name}
        </Styled.TrivagoDisplayValue>
        <Styled.ExpandIcon>
          <Icon src={arrowIcon} color={isInDarkHeader && !isMobileLayout ? 'white' : 'black'} width={17} height={17} />
        </Styled.ExpandIcon>
      </>
    ),
    [isInDarkHeader, isMobileLayout, lang],
  );

  const onSelect = useCallback(
    (value: Key) => {
      const foundLanguage = languageList.find((el) => el.code === value);

      if (foundLanguage) {
        onLanguageChange(foundLanguage.key);
      }
    },
    [onLanguageChange, languageList],
  );

  if (isMobileLayout) {
    if (isTrivago) {
      const label = <Trans i18nKey={'trivago.v2.burger-menu.language'}>Language</Trans>;

      return (
        <Styled.TrivagoMobileContainer>
          <SelectInput
            testId="language-selector"
            label={label}
            id="id-language-dropdown"
            displayValue={trivagoDisplayValue}
            selectedKey={languageCode}
            options={options}
            onSelect={onLanguageChange}
          />
        </Styled.TrivagoMobileContainer>
      );
    }

    return (
      <Styled.MobileContainer>
        <Styled.Label>Language</Styled.Label>
        <div>
          <SelectInput
            testId="language-selector"
            id={'langSelectorDropdown'}
            selectedKey={languageCode}
            displayValue={
              <Styled.DisplayValue>
                {lang?.name} <Styled.DropIcon src={expandIcon} />
              </Styled.DisplayValue>
            }
            options={options}
            onSelect={onSelect}
          />
        </div>
      </Styled.MobileContainer>
    );
  }

  if (isTrivago) {
    return (
      <Styled.HeaderItem>
        <SelectInput
          testId="language-selector"
          displayValue={trivagoDisplayValue}
          id="id-language-dropdown"
          selectedKey={languageCode}
          options={options}
          onSelect={onLanguageChange}
        />
      </Styled.HeaderItem>
    );
  }

  return (
    <Styled.SelectWrapper>
      <SelectInput
        id="id-language-selector"
        selectedKey={languageCode}
        displayValue={<Styled.DisplayValue>{lang?.name}</Styled.DisplayValue>}
        options={options}
        onSelect={onSelect}
        testId="language-selector"
      />
    </Styled.SelectWrapper>
  );
};
