/* eslint-disable no-magic-numbers */
import { css } from 'styled-components';
import { rem } from 'style/functions';
import media from 'style/media';

/* ========================================================================
   Component: Padding
 ========================================================================== */
export const padding = css`
  padding: ${rem(20)};
  /* Desktop and bigger */
  ${media.desktop} {
    & {
      padding: 40px;
    }
  }
`;

/* Small
 ========================================================================== */
export const paddingSmall = css`
  padding: 15px;
`;

/* Large
 ========================================================================== */
export const paddingLarge = css`
  padding: 30px;
  /* Desktop and bigger */
  ${media.desktop} {
    & {
      padding: 70px;
    }
  }
`;
