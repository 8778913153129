import styled, { css } from 'styled-components';
import media from 'style/media';
import { superDarkGrey } from 'style/variables';

const TRENDING_GAP = 16;
const IMAGE_HEIGHT = 176;

const Container = styled.a`
  position: relative;
  display: block;
`;

const Image = styled.img<{ big?: boolean }>`
  width: 392px;
  height: ${IMAGE_HEIGHT}px;
  border-radius: 0 20px 20px 20px;

  object-fit: cover;

  ${(props) =>
    props.big &&
    css`
      height: calc(${IMAGE_HEIGHT}px * 2 + ${TRENDING_GAP}px);
    `};

  ${media.mobile} {
    width: 100%;
  }
`;

const Label = styled.div`
  z-index: 1;
  position: absolute;
  padding: 4px 24px 7px 0;
  top: 0;
  left: 0;

  background: white;
  color: ${superDarkGrey};
  border-radius: 0 0 30px/110% 0;

  font-size: 25px;
  font-weight: 600;

  ${media.mobile} {
    font-size: 23px;
  }
`;

export default { Container, Image, Label };
