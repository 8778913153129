import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export interface RouteScrollingProps {
  preventScrollUp?: boolean;
}

const useOnRouteScrollingRestore = () => {
  const history = useHistory<RouteScrollingProps | undefined>();

  useEffect(() => {
    const unregisterCallback = history.listen((location, action) => {
      if (action === 'PUSH' && !location.state?.preventScrollUp) {
        window.scrollTo(0, 0);
      }
    });

    return () => unregisterCallback();
  }, [history]);
};

export default useOnRouteScrollingRestore;
