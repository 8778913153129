import styled from 'styled-components';
import DefaultInputCheckboxStyled from 'components/common/InputCheckbox/InputCheckboxStyled/DefaultInputCheckbox.styled';
import { InputCheckboxStyledModel } from 'components/common/InputCheckbox/InputCheckboxStyled/InputCheckbox.styled-model';
import { landingBngBlueButtonColor } from 'style/trivagoVaribles';
import { superDarkGrey } from 'style/variables';

const size = 20;

const Checkbox = styled(DefaultInputCheckboxStyled.Checkbox)`
  min-width: ${size}px;
  min-height: ${size}px;

  &:focus {
    background-color: unset;
    border-color: ${superDarkGrey};
  }

  &:checked,
  &:indeterminate,
  &:checked:focus,
  &:indeterminate:focus {
    background-color: ${landingBngBlueButtonColor};
  }
`;

const Styled: InputCheckboxStyledModel = {
  Checkbox,
};

export default Styled;
