import React, { RefObject } from 'react';
import Styled from 'components/common/Card/Card.styled';
import { CardTypes } from 'components/common/Card/types';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: CardTypes;
  hover?: boolean;
  small?: boolean;
  ref?: RefObject<HTMLDivElement>;
  tag?: 'section' | 'article';
}
export const Card: React.FC<CardProps> = (props) => {
  return <Styled.StyledCard {...props} as={props.tag} />;
};
