/**
 * @category Utils
 * @packageDocumentation
 */
import { RefObject, useEffect } from 'react';

/**
 * Alert if clicked on outside of element
 */
function handleClickOutside(element: Element | null, event: MouseEvent, onOutsideClick: () => void) {
  const target = event.target;
  let outside = false;

  if (target instanceof Element) {
    if (element && !element.contains(target)) {
      outside = true;
    }
  } else {
    outside = true;
  }

  if (outside) {
    onOutsideClick();
  }
}

function handleFocusOutside(element: Element | null, event: KeyboardEvent, onOutsideClick: () => void) {
  if (event.code !== 'Tab') {
    if (event.key === 'Escape') {
      onOutsideClick();
    }

    return;
  }

  const target = event.target;
  let outside = false;

  if (target instanceof Element) {
    if (element && element.contains(target)) {
      outside = true;
    }
  } else {
    outside = true;
  }

  if (outside) {
    onOutsideClick();
  }
}

/**
 * Hook that alerts clicks outside the passed ref
 * @param ref - a reference for div element, outside which we should listen for clicks
 * @param onOutsideClick - callback to call when user clicks outside this component
 * @param withFocus - add listener on focus change
 * @param disabled - state when the hook is disabled
 */
const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  onOutsideClick: () => void,
  withFocus?: boolean,
  disabled?: boolean,
) => {
  useEffect(() => {
    if (disabled) {
      return () => null;
    }

    const handleClickOutsideInner = (event: MouseEvent) => {
      handleClickOutside(ref.current, event, onOutsideClick);
    };

    const handleFocusOutsideInner = (event: KeyboardEvent) => {
      handleFocusOutside(ref.current, event, onOutsideClick);
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutsideInner);
    if (withFocus) {
      document.addEventListener('keydown', handleFocusOutsideInner);
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutsideInner);
      if (withFocus) {
        document.removeEventListener('keydown', handleFocusOutsideInner);
      }
    };
  }, [ref, onOutsideClick, withFocus, disabled]);
};

/**
 * Hook that alerts clicks outside the passed ref
 * @param element - an element, outside which we should listen for clicks
 * @param onOutsideClick - callback to call when user clicks outside this component
 * @param withFocus - add listener on focus change
 * @param disabled - state when the hook is disabled
 */
export const useOutsideElementClick = (
  element: Element | null,
  onOutsideClick: () => void,
  withFocus?: boolean,
  disabled?: boolean,
) => {
  useEffect(() => {
    if (disabled) {
      return () => null;
    }

    const handleClickOutsideInner = (event: MouseEvent) => {
      handleClickOutside(element, event, onOutsideClick);
    };

    const handleFocusOutsideInner = (event: KeyboardEvent) => {
      handleFocusOutside(element, event, onOutsideClick);
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutsideInner);
    if (withFocus) {
      document.addEventListener('keydown', handleFocusOutsideInner);
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutsideInner);
      if (withFocus) {
        document.removeEventListener('keydown', handleFocusOutsideInner);
      }
    };
  }, [element, onOutsideClick, withFocus, disabled]);
};

export default useOutsideClick;
