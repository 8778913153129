import useDateCallback from 'common/hooks/useDateCallback';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {dayNumericFormat, monthShortFormat } from '../utils/dateFormats';

interface UseFormatDatePrp {
  lang: string;
  date: Date | null;
  dateType: 'checkin' | 'checkout';
}

const DATEPICKER_YEAR_SLICED = -2;
const useDatepickerDate = ({ lang, date, dateType }: UseFormatDatePrp) => {
  const { t } = useTranslation();
  const monthCallback = useDateCallback({ lang, options: monthShortFormat });
  const dayCallback = useDateCallback({ lang, options: dayNumericFormat });

  return useMemo(() => {
    if (!date) {
      if (dateType === 'checkin') {
        return {
          desktop: t('common.misc.startDatePick', 'Check-In'),
          mobile: t('common.select', 'Select'),
          empty: true,
        };
      }

      return {
        desktop: t('common.misc.endDatePick', 'Check-Out'),
        mobile: t('common.select', 'Select'),
        empty: true,
      };
    }

    const m = monthCallback(date);
    const da = dayCallback(date);

    return {
      desktop: `${m} ${da}`,
      mobile: `${da} ${m} '${date.getFullYear().toString(10).slice(DATEPICKER_YEAR_SLICED)}`,
    };
  }, [date, monthCallback, dayCallback, dateType, t]);
};

export default useDatepickerDate;
