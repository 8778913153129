import styled from 'styled-components';
import DefaultSelectInputStyled from 'components/common/SelectInput/SelectInputStyled/DefaultSelectInput.styled';
import { SelectInputStyledModel } from 'components/common/SelectInput/SelectInputStyled/SelectInput.styled-model';

const Select = styled(DefaultSelectInputStyled.Select)`
  padding: 24px 13px 13px;
`;

const Styled: SelectInputStyledModel = {
  ...DefaultSelectInputStyled,
  Select,
};

export default Styled;
