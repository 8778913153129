/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { Text } from 'components/common/Text/Text';
import HomeViewStyled from 'views/home/HomeView.styled';

const Header = styled(Text)`
  height: 58px;
  margin-bottom: 15px;
`;

const Grid = styled.div`
  display: grid;
  margin-left: -12px;
  margin-top: -16px;

  grid-template-areas:
    'paris sf london'
    'paris rome bc'
    'cancun miami ny'
    'lv orlando ny';
`;

const Section = styled.div<{ gridArea: string }>`
  position: relative;
  grid-area: ${({ gridArea }) => gridArea};
  box-sizing: border-box;

  margin-left: 12px;
  margin-top: 16px;
`;

const BackgroundImageSecond = styled.img`
  z-index: -1;
  position: absolute;
  top: -75px;
  right: 0;
  transform: rotate(180deg) translateX(-100%);
  width: 240px;
`;

const BackgroundImageFirst = styled.img`
  z-index: -1;
  position: absolute;
  top: -82px;
  right: -65px;
  width: 120px;
`;

export default {
  Header,
  Grid,
  Section,
  BackgroundImageFirst,
  BackgroundImageSecond,
  Container: HomeViewStyled.Container,
};
