import React, { useCallback, useContext } from 'react';
import { SettingsContext } from 'TopContexts';
import { Key } from 'components/common/SelectInput/SelectInput';
import { InternalCurrencySelector } from 'components/header-footer/CurrencySelector/InternalCurrencySelector';

interface CurrencySelectorProps {
  isInDarkHeader?: boolean;
}

interface HandledCurrencySelectorProps extends CurrencySelectorProps {
  handledCurrencyCode: string;
  handledSetCurrency: (value: string) => void;
}

interface AutoCurrencySelectorProps extends CurrencySelectorProps {
  handledCurrencyCode?: never;
  handledSetCurrency?: never;
}

export const CurrencySelector: React.FC<HandledCurrencySelectorProps | AutoCurrencySelectorProps> = ({
  handledSetCurrency,
  handledCurrencyCode,
  isInDarkHeader,
}) => {
  const { currencyCode, setCurrencyCode } = useContext(SettingsContext);

  const onCurrencyChange = useCallback(
    (langCode: Key) => {
      if (!langCode) {
        return;
      }

      if (handledSetCurrency) {
        handledSetCurrency(langCode);

        return;
      }

      setCurrencyCode(langCode, true);
    },
    [handledSetCurrency, setCurrencyCode],
  );

  return (
    <InternalCurrencySelector
      isInDarkHeader={isInDarkHeader}
      currencyCode={handledCurrencyCode || currencyCode}
      onCurrencyChange={onCurrencyChange}
    />
  );
};
