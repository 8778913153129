import styled, { css } from 'styled-components';
import { backgroundGrey6, colorRed, white } from 'style/variables';

const MobileForm = styled.form`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1 1;
`;

const MobileSuggestionField = styled.div`
  margin: 0 20px 18px;
`;

const MobileDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const MobileOccupancyPicker = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1 1;
  margin: 0 20px;
`;

const MobileSearchButton = styled.div<{ bottomOffset?: boolean }>`
  margin: 12px 20px 0;
  ${({ bottomOffset }) => bottomOffset && 'margin-bottom: 20px'};
`;

// trivago

const SearchBar = styled.div`
  position: relative;

  padding: 25px 19px 24px;
  box-sizing: border-box;

  border-radius: 18px;
  background-color: ${backgroundGrey6};
  z-index: 10;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
  background-color: ${white};
  overflow: hidden;
`;

const ErrorMessage = styled.div`
  position: absolute;
  margin: 2px;
  color: ${colorRed};
  font-size: 12px;
  line-height: 1.5;
`;

const Container = styled.div<{ isSticky: boolean }>`
  z-index: 980;
  box-sizing: border-box;
  margin: 0;
  backface-visibility: hidden;
  top: 0;
  width: 100%;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: fixed;
    `}
`;

export const SearchFormStyled = {
  Container,
  MobileForm,
  MobileSuggestionField,
  MobileOccupancyPicker,
  MobileDatePicker,
  MobileSearchButton,
  Trivago: {
    SearchBar,
    Content,
    ErrorMessage,
  },
};
