import { identify, Identify, track } from '@amplitude/analytics-browser';
import { RateDisplayModel } from 'common/backend/api/general/generalModel';
import { HotelDeal, HotelDealExtended } from 'common/backend/api/hotel/dealModel';
import { Hotel, HotelExtended } from 'common/backend/api/hotel/hotelModel';
import { Place } from 'common/backend/api/place/placeModel';
import { RoomOccupancy, Trip } from 'common/backend/api/trip/tripModel';
import { calculateNights } from 'common/utils/date';
import { CancellationReason } from 'backend/api/trip/tripModel';
import { SendEmailType } from 'backend/api/trip/tripRequest';
import { OauthProvider } from 'backend/api/user/userModel';
import { HotelMapData } from 'backend/dataModel';
import { ServerErrorCode } from 'backend/serverError';
import { env } from 'environments/environment';
import { ClientErrorCode } from 'errors/clientError';
import { stringifyDate } from 'utils/dateUtils';
import { sumAdults, sumChildren } from 'utils/occupancyUtils';
import { findCheapestDeal } from 'utils/roomUtils';

const durationCount = (time: number) => Date.now() - time;

const identifyWrapper = (...rest: Parameters<typeof identify>) => {
  if (env.amplitude) {
    // eslint-disable-next-line no-restricted-syntax
    identify(...rest);
  }
};

const trackWrapper = (...rest: Parameters<typeof track>) => {
  if (env.amplitude) {
    // eslint-disable-next-line no-restricted-syntax
    track(...rest);
  }
};

const hotelProperties = (hotel: Hotel | HotelMapData) => ({
  name: hotel.name,
  starRating: hotel.starRating,
  popularity: hotel.popularity,
  score: hotel.rating?.score,
  'amenities ID': hotel.amenities?.map((a) => a.id),
  countryName: hotel.location.countryName,
  cityName: hotel.location.cityName,
});

const dealProperties = (deal: HotelDeal) => ({
  'cancellation type': deal.cancellation.type,
  dealType: deal.dealType,
  boardCode: deal.boardCode,
  nightlyprice: deal.nightlyPrice,
  totalPrice: deal.totalPrice,
  totalTaxes: deal.totalTaxes,
  totalFees: deal.totalFees,
  totalHotelFees: deal.totalHotelFees,
  totalRoomsPrice: deal.totalRoomsPrice,
  totalPriceWithHotelFees: deal.totalPriceWithHotelFees,
});

interface SettingsProperties {
  rateDisplayModel: RateDisplayModel;
  campaignName: string;
}

interface SessionAndSettingsProperties extends SettingsProperties {
  currencyCode: string;
}

const sessionAndSettingsProperties = ({
  rateDisplayModel,
  currencyCode,
  campaignName,
}: SessionAndSettingsProperties) => ({
  rateDisplayModel,
  currencyCode,
  campaignname: campaignName,
});

interface SearchQueryProperties {
  checkin: string;
  checkout: string;
  occupancy: RoomOccupancy[];
}

const getOccupancyProperties = (occupancy: RoomOccupancy[]) => ({
  rooms: occupancy.length,
  adults: sumAdults(occupancy),
  children: sumChildren(occupancy),
});

const searchQueryProperties = ({ checkin, checkout, occupancy }: SearchQueryProperties) => {
  const { rooms, adults, children } = getOccupancyProperties(occupancy);

  return {
    'checkin date': checkin,
    'checkout date': checkout,
    'nights count': calculateNights(checkin, checkout),
    'guests adult': adults,
    'guests children': children,
    'room total': rooms,
  };
};

const getProviderName: Record<OauthProvider | 'email', string> = {
  email: 'email',
  [OauthProvider.Google]: 'google',
  [OauthProvider.Facebook]: 'facebook',
};

const destinationProperties = (destination: Place | undefined) => ({
  'place type': destination?.type,
  'place name': destination?.name,
});

export const AmplitudeLogging = {
  pushAccountCreatedEvent: (provider: 'email' | OauthProvider) => {
    const identifyObj = new Identify();

    const now = Date.now();
    const [day, month, year] = [
      Intl.DateTimeFormat('en', { day: '2-digit' }).format(now),
      Intl.DateTimeFormat('en', { month: '2-digit' }).format(now),
      Intl.DateTimeFormat('en', { year: 'numeric' }).format(now),
    ];

    identifyObj.set('account creation date', `${year}-${month}-${day}`);
    identifyObj.set('account type', getProviderName[provider]);

    identifyWrapper(identifyObj);

    trackWrapper('account created', {
      'account creation date': `${year}-${month}-${day}`,
      'account type': getProviderName[provider],
    });
  },

  pushSearchInitiatedEvent: (
    props: SearchQueryProperties & {
      destination: Place | undefined;
    },
  ) => {
    const properties = {
      ...searchQueryProperties(props),
      ...destinationProperties(props.destination),
      timetotravel: calculateNights(stringifyDate(new Date()), props.checkin),
    };

    trackWrapper('search initiated', properties);
  },

  pushResultsViewedEvent: (
    props: SearchQueryProperties &
      SessionAndSettingsProperties & {
        destination: Place | undefined;
        startRequestTime: number;
        numberOfHotels: number;
      },
  ) => {
    const properties = {
      ...searchQueryProperties(props),
      ...sessionAndSettingsProperties(props),
      ...destinationProperties(props.destination),
      duration: durationCount(props.startRequestTime),
      'number of hotels': props.numberOfHotels,
      timetotravel: calculateNights(stringifyDate(new Date()), props.checkin),
    };

    trackWrapper('results viewed', properties);
  },

  pushResultsModifiedEvent: (
    props: SearchQueryProperties &
      SessionAndSettingsProperties & {
        destination: Place | undefined;
        aborted?: boolean;
      },
  ) => {
    const properties = {
      ...searchQueryProperties(props),
      ...sessionAndSettingsProperties(props),
      ...destinationProperties(props.destination),
      aborted: props.aborted,
      timetotravel: calculateNights(stringifyDate(new Date()), props.checkin),
    };

    trackWrapper('results modified', properties);
  },

  pushHotelMapSelectedEvent: (
    props: SearchQueryProperties &
      SessionAndSettingsProperties & {
        hotel: HotelMapData;
      },
  ) => {
    const properties = {
      ...searchQueryProperties(props),
      ...sessionAndSettingsProperties(props),
      ...hotelProperties(props.hotel),
      timetotravel: calculateNights(stringifyDate(new Date()), props.checkin),
    };

    trackWrapper('hotel map selected', properties);
  },

  pushHotelListSelectedEvent: (
    props: SearchQueryProperties &
      SessionAndSettingsProperties & {
        hotel: Hotel;
        hotelRank: number;
      },
  ) => {
    const properties = {
      ...searchQueryProperties(props),
      ...sessionAndSettingsProperties(props),
      ...hotelProperties(props.hotel),
      timetotravel: calculateNights(stringifyDate(new Date()), props.checkin),
      hotelRank: props.hotelRank,
    };

    trackWrapper('hotel list selected', properties);
  },

  pushDealsViewedEvent: (
    props: SearchQueryProperties &
      SessionAndSettingsProperties & {
        prevPageLowestPrice: number | undefined;
        hotel: HotelExtended;
        deals: HotelDeal[];
      },
  ) => {
    const cheapestDeal = findCheapestDeal(props.deals);
    const properties = {
      ...searchQueryProperties(props),
      ...sessionAndSettingsProperties(props),
      ...hotelProperties(props.hotel),
      'number of deals': props.deals.length || 0,
      'price accuracy': (cheapestDeal?.nightlyPrice || 0) - (props.prevPageLowestPrice || 0),
      timetotravel: calculateNights(stringifyDate(new Date()), props.checkin),
    };

    trackWrapper('deals viewed', properties);
  },

  pushDealSelectedEvent: ({
    position = 0,
    ...props
  }: SearchQueryProperties &
    SessionAndSettingsProperties & {
      hotel: Hotel;
      deal: HotelDealExtended;
      position?: number;
    }) => {
    const properties = {
      ...searchQueryProperties(props),
      ...sessionAndSettingsProperties(props),
      ...hotelProperties(props.hotel),
      ...dealProperties(props.deal),
      'deal rank': position,
      timetotravel: calculateNights(stringifyDate(new Date()), props.checkin),
    };

    trackWrapper('deal selected', properties);
  },

  pushReservationReviewedEvent: (
    props: SessionAndSettingsProperties & {
      hotel: Hotel;
      deal: HotelDealExtended;
      occupancy: RoomOccupancy[];
    },
  ) => {
    const properties = {
      ...searchQueryProperties({ ...props.deal, occupancy: props.occupancy }),
      ...sessionAndSettingsProperties(props),
      ...hotelProperties(props.hotel),
      ...dealProperties(props.deal),
      timetotravel: calculateNights(stringifyDate(new Date()), props.deal.checkin),
    };

    trackWrapper('reservation reviewed', properties);
  },

  pushReservationSubmittedEvent: (
    props: SettingsProperties & {
      tripDetails: Trip;
      vipIncluded: boolean | undefined;
      agreeMarketing: boolean | undefined;
    },
  ) => {
    const { hotel, deal, rooms: occupancy } = props.tripDetails;

    const properties = {
      ...searchQueryProperties({ ...deal, occupancy }),
      ...sessionAndSettingsProperties({ ...props, currencyCode: deal.currencyCode }),
      ...hotelProperties(hotel),
      ...dealProperties(deal),
      rebook: !!props.tripDetails.preferences?.rebook,
      agreeMarketing: props.agreeMarketing,
      'trip status': props.tripDetails.status,
      tripid: props.tripDetails.id,
      VIPincluded: props.vipIncluded,
    };

    trackWrapper('reservation submitted', properties);
  },

  pushVipPackageEvent: (packageType: 'VIP Package' | 'Standard Package', amount: number) => {
    const properties = {
      'VIP Package': packageType,
      Amount: amount,
    };

    trackWrapper('VIP Package', properties);
  },

  pushErrorEncounteredEvent: (location: string, message: string, code: ServerErrorCode | ClientErrorCode | 'unset') => {
    const properties = {
      'error location': location,
      'error name': message,
      'error code': code,
    };

    trackWrapper('error encountered', properties);
  },

  pushEmailsSentEvent: (type: SendEmailType) => {
    trackWrapper('emails sent', { 'email type': type });
  },

  pushCancelReservationEvent: (
    props: SettingsProperties & {
      trip: Trip;
      cancellationFee: number | undefined;
      reason: CancellationReason | undefined;
    },
  ) => {
    const { hotel, deal, rooms } = props.trip;
    const properties = {
      ...searchQueryProperties({ ...deal, occupancy: rooms }),
      ...sessionAndSettingsProperties({ ...props, currencyCode: deal.currencyCode }),
      ...hotelProperties(hotel),
      ...dealProperties(deal),
      cancellationFee: props.cancellationFee,
      rebook: !!props.trip.preferences?.rebook,
      deadline: deal.cancellation.deadline,
      reasonID: props.reason,
    };

    trackWrapper('cancel reservation', properties);
  },

  pushDealsModifiedEvent: (
    props: SearchQueryProperties &
      SessionAndSettingsProperties & {
        hotel: HotelMapData;
      },
  ) => {
    const properties = {
      ...searchQueryProperties(props),
      ...sessionAndSettingsProperties(props),
      ...hotelProperties(props.hotel),
      timetotravel: calculateNights(props.checkin, props.checkout),
      'platform type': 'web',
    };

    trackWrapper('deals modified', properties);
  },

  pushCurrencyModifiedEvent: (newCurrencyCode: string) =>
    trackWrapper('currency modified', { newCurrencyCode: newCurrencyCode.toUpperCase() }),

  pushLanguageModifiedEvent: (newLanguageCode: string) =>
    trackWrapper('language modified', { newLanguageCode: newLanguageCode.toUpperCase() }),

  pushInsuranceAddedEvent: (variant: number, numberOfGuests: number) => {
    const properties = {
      variant,
      'number of guests': numberOfGuests,
    };

    trackWrapper('insurance added', properties);
  },
};
