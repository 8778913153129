import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PRIVACY_ROUTE } from 'routeList';

interface PrivacyPolicyLinkProps {
  onClick: () => void;
}

const PrivacyPolicyLink: React.FC<PrivacyPolicyLinkProps> = ({ onClick }) => (
  <Link id="policyLink" to={PRIVACY_ROUTE} onClick={onClick}>
    <Trans i18nKey="footer.privacy">Privacy Policy</Trans>
  </Link>
);

export default PrivacyPolicyLink;
