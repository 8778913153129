import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeLocationWidget, { cityLocations } from 'components/home/HomeLocationWidget/HomeLocationWidget';
import Styled from 'components/home/MobileHomeLocations/MobileHomeLocations.styled';

export const MobileHomeLocations = () => {
  const [t] = useTranslation();

  return (
    <Styled.Container>
      <Styled.Title>{t('home.trending-destinations', 'Trending Destinations')}</Styled.Title>

      <Styled.Content>
        <Styled.Item>
          <HomeLocationWidget locationData={cityLocations.paris} cityName={t('home.city.paris', 'Paris')} />
        </Styled.Item>
        <Styled.Item>
          <HomeLocationWidget locationData={cityLocations.sf} cityName={t('home.city.sf', 'San Francisco')} />
        </Styled.Item>
        <Styled.Item>
          <HomeLocationWidget locationData={cityLocations.london} cityName={t('home.city.london', 'London')} />
        </Styled.Item>
        <Styled.Item>
          <HomeLocationWidget locationData={cityLocations.barcelona} cityName={t('home.city.bc', 'Barcelona')} />
        </Styled.Item>
        <Styled.Item>
          <HomeLocationWidget locationData={cityLocations.miami} cityName={t('home.city.miami', 'Miami')} />
        </Styled.Item>
        <Styled.Item>
          <HomeLocationWidget locationData={cityLocations.rome} cityName={t('home.city.rome', 'Rome')} />
        </Styled.Item>
      </Styled.Content>
    </Styled.Container>
  );
};
