import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export const useSticky = (ref: MutableRefObject<HTMLElement | null>) => {
  const [isSticky, setIsSticky] = useState(false);
  const [initialOffsetTop, setInitialOffsetTop] = useState<number>();

  const onScroll = useCallback(() => {
    if (initialOffsetTop === undefined) {
      const offsetTop = ref.current?.getBoundingClientRect().top || 0;

      // need to add scroll position because getBoundingClientRect().top is relative to the viewport
      setInitialOffsetTop(offsetTop + document.documentElement.scrollTop);
    }

    if (!initialOffsetTop) {
      return;
    }

    setIsSticky(initialOffsetTop < document.documentElement.scrollTop);
  }, [initialOffsetTop, ref]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return isSticky;
};
