import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import { useCallback } from 'react';
import { useSession } from 'atoms/hooks/useSession';
import authProviderStorage from 'backend/AuthProviderStorage';
import { OauthProvider } from 'backend/api/user/userModel';
import { processError } from 'errors/errorUtils';
import useAuthenticatorErrors from 'errors/useAuthenticatorErrors';
import { log } from 'utils/logger';

const useSignOutCallback = () => {
  const { logout } = useSession();
  const errors = useAuthenticatorErrors();

  return useCallback(() => {
    switch (authProviderStorage.get()) {
      case OauthProvider.Facebook:
        FacebookLoginClient.logout((res) => log(`Facebook logout successful: ${JSON.stringify(res)}`));
        break;
      default:
    }

    logout().catch((reason) => processError(reason, errors));
  }, [errors, logout]);
};

export default useSignOutCallback;
