import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ModalAlertContext } from 'contexts/ModalAlertProvider';
import { AvailableErrors } from 'errors/errorData';
import { showError } from 'errors/errorHandlers';
import { isHotelRoute } from 'utils/uriUtils';

const usePlaceErrors = (backDestinationsUrl: string | undefined): AvailableErrors => {
  const { setAlert } = useContext(ModalAlertContext);
  const [t] = useTranslation();
  const history = useHistory();
  const isHotelPage = useMemo(() => isHotelRoute(history.location.pathname), [history.location.pathname]);

  return useMemo(
    () => ({
      known: [],
      default: {
        action: (errorId, status) => {
          if (isHotelPage) {
            setAlert(
              t(
                'errors.place.hotel-default',
                "Sorry, we couldn't find the hotel requested.<br> Please try again or go back to hotel results.",
              ),
              undefined,
              {
                ok: {
                  label: t('common.misc.try-again', 'Try again'),
                  cb: () => window.location.reload(),
                },
                cancel: {
                  label: t('errors.place.back-hotel-results', 'Back to hotel results'),
                  cb: () => history.push(backDestinationsUrl || '/'),
                },
              },
            );
          } else {
            showError(errorId, status, t('errors.place.default', 'An error has occurred. Please try again later.'));
          }
        },
      },
    }),
    [backDestinationsUrl, history, isHotelPage, setAlert, t],
  );
};

export default usePlaceErrors;
