import styled, { css } from 'styled-components';
import { ButtonSize } from 'components/common/Button/Button.types';
import {
  lightRoyalBlue,
  black,
  borderColor,
  boxShadowColor,
  disabledBackgroundGray,
  lightGray,
  violet,
  battleshipGrey,
  paleSkyBlue,
  charcoalGrey,
  strongPink,
  grey2,
  disabledInputBackgroundColor,
} from 'style/variables';

const Button = styled.button<{ size?: ButtonSize; fullWidth?: boolean }>`
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 54px;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 0;
  cursor: pointer;

  ${({ fullWidth }) => fullWidth && 'width: 100%'};

  &:focus {
    outline: 0;
  }

  :disabled {
    background: ${disabledBackgroundGray} !important;
  }

  :not(:disabled) {
    cursor: pointer;
  }

  :hover {
    text-decoration: none;
  }

  :focus {
    outline: none;
  }

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  ${({ size }) => {
    switch (size) {
      case ButtonSize.Small:
        return css`
          height: 36px;
          white-space: nowrap;
          padding: 0 20px;
          font-size: 14px;
          line-height: 18px;
        `;
      case ButtonSize.Large:
        return css`
          height: 48px;
          padding: 0 48px;
          font-size: 18px;
          line-height: 20px;
        `;
      case ButtonSize.XLarge:
        return css`
          height: 54px;
          padding: 0 48px;
          font-size: 18px;
          line-height: 20px;
        `;
      case ButtonSize.Normal:
      default:
        return css`
          height: 54px;
          padding: 0 24px;
          box-sizing: border-box;
          font-weight: 900;
          font-size: 20px;
        `;
    }
  }}
`;

const DefaultButton = styled(Button)`
  background-color: ${lightGray};
  color: ${black};
  border-radius: 4px;
  border: 1px solid ${borderColor};

  :hover,
  :focus {
    background-color: ${lightGray};
    color: ${black};
    border-color: ${boxShadowColor};
  }
`;

const PrimaryButton = styled(Button)`
  border-radius: 4px;
  background: linear-gradient(to right, ${lightRoyalBlue} 0%, ${violet} 97%);
  color: white;

  :hover:not(:disabled) {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)),
      linear-gradient(to right, ${lightRoyalBlue} 0%, ${violet} 97%);
  }

  &:disabled {
    color: ${battleshipGrey};
    background: ${paleSkyBlue};
  }
`;

const TextButton = styled(Button)`
  padding: 0;
  line-height: 1.5;
  background: none;
  height: auto;
  margin: 0;
  color: inherit;
  text-transform: none;
  border-radius: 0;
  height: auto;
  font-size: 14px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :hover,
  :focus {
    color: black;
  }
`;

const ButtonOutlined = styled(Button)<{ backgroundColor?: string; textColor?: string }>`
  color: ${(props) => (props.textColor ? props.textColor : `${charcoalGrey}`)};
  border: 2px solid transparent;
  background-image: linear-gradient(
      ${(props) => props.backgroundColor || 'white'},
      ${(props) => props.backgroundColor || 'white'}
    ),
    linear-gradient(to right, ${lightRoyalBlue} 0%, ${violet} 97%);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  &:hover {
    background-image: linear-gradient(
        ${(props) => props.backgroundColor || 'white'},
        ${(props) => props.backgroundColor || 'white'}
      ),
      linear-gradient(to right, ${lightRoyalBlue} 0%, ${strongPink} 97%);
  }

  &:disabled {
    border-color: ${grey2};
    background: ${disabledInputBackgroundColor} unset unset;
  }
`;

export default {
  Button,
  DefaultButton,
  PrimaryButton,
  TextButton,
  ButtonOutlined,
};
