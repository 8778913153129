import styled from 'styled-components';
import BenefitSectionStyled from 'components/home/BenefitSection.styled';
import media from 'style/media';
import { lightRoyalBlue } from 'style/variables';

const BenefitLabel = styled(BenefitSectionStyled.BenefitLabel)`
  color: ${lightRoyalBlue};
  line-height: 20px;
  font-size: 22px;

  ${media.mobile} {
    font-size: 20px;
  }
`;

const Text = styled.div`
  line-height: 22px;

  ${media.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export default {
  BenefitLabel,
  Text,
};
