import FacebookLogin from '@greatsumini/react-facebook-login';
import useValidationCallback from 'common/hooks/useValidationCallback';
import { emailValidationRule } from 'common/utils/validation/emailValidationRule';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import facebookIcon from 'assets/images/icons/socials/facebook.svg';
import googleIcon from 'assets/images/icons/socials/google.svg';
import { useSession } from 'atoms/hooks/useSession';
import { OauthProvider } from 'backend/api/user/userModel';
import { LoginOauthRequest, LoginRequest } from 'backend/api/user/userRequest';
import { ServerError, ServerErrorCode } from 'backend/serverError';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import InputField, {
  InputFieldRef,
  passwordErrorMessage,
  passwordValidationRule,
} from 'components/common/InputField/InputField';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextTransform, TextWeight } from 'components/common/Text/Text.types';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ModalCloseButton } from 'components/common/modal/ModalCloseButton';
import { ModalBody } from 'components/common/modal/ModalSections.styled';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OAuthContext } from 'components/contexts/OAuthContext';
import { getOauthProviderName } from 'components/signin/OauthSignupForm/OauthSignUpForm';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import StyledInner from 'components/signin/SignInForm/SignInForm.styled';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { processError } from 'errors/errorUtils';
import useOauthSignInErrors from 'errors/useOauthSignInErrors';
import useSignInErrors from 'errors/useSignInErrors';
import guid from 'utils/guid';

export interface SignInFormProps {
  onForgotPassword: (email: string) => void;
  children?: React.ReactNode;
}

export const SignInForm: React.FC<SignInFormProps> = ({ onForgotPassword, children }) => {
  const [t] = useTranslation();
  const title = useMemo(() => t('algotels.login-popup.title', 'Login to Algotels'), [t]);
  const loginBtnText = useMemo(() => t('login-popup.login', 'Login'), [t]);
  const emailRef = useRef<InputFieldRef>();
  const passwordRef = useRef<InputFieldRef>();
  const { onSuccessFacebook, onFailFacebook, googleLogin, googleOAuthLoaded, oauthPayload, clearOAuthPayload } =
    useContext(OAuthContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<string>('');

  const [loginError, setLoginError] = useState<string>();
  const errors = useSignInErrors(setLoginError);

  const [working, setWorking] = useState(false);

  const { validation } = useValidationCallback({ fields: [emailRef, passwordRef] });

  const setToast = useContext(ToastContext);
  const { login, loginOauth } = useSession();
  const { isSmallMobile } = useContext(LayoutContext);

  const emailChanged = useCallback(
    (changedEmail: string) => {
      setLoginError(undefined);
      setEmail(changedEmail);
    },
    [setLoginError, setEmail],
  );

  const passwordChanged = useCallback(
    (p: string) => {
      setLoginError(undefined);
      setPassword(p);
    },
    [setLoginError, setPassword],
  );

  const signIn = useCallback(() => {
    const errorMessages = validation(true);

    if (errorMessages.length > 0) {
      processError(new ClientError(ClientErrorCode.ValidationError, errorMessages), errors);

      return;
    }

    setWorking(true);
    const payload: LoginRequest = {
      username: email,
      password,
      recaptcha: guid(), // TODO
    };

    login(payload)
      .then(() => {
        setSignInDialogMode(undefined);
        setToast(t('login-popup.successful-login', 'You have logged in successfully!'), ToastType.Success);
      })
      .catch((reason) => {
        processError(reason, errors);
        setWorking(false);
      });
  }, [validation, email, password, login, errors, setSignInDialogMode, setToast, t]);

  const setOauthSignUpMode = useCallback(() => {
    setSignInDialogMode(SignInDialogMode.SignUp);
  }, [setSignInDialogMode]);

  const errorsSignIn = useOauthSignInErrors(setOauthSignUpMode);

  useEffect(() => {
    if (oauthPayload) {
      const payload: LoginOauthRequest = {
        ...oauthPayload,
        recaptcha: guid(), // TODO
      };

      setWorking(true);
      loginOauth(payload)
        .then(() => {
          setToast(t('login-popup.successful-login', 'You have logged in successfully!'), ToastType.Success);
          setSignInDialogMode(undefined);
          clearOAuthPayload();
        })
        .catch((reason) => {
          processError(reason, errorsSignIn);
          if (!(reason instanceof ServerError) || reason.getCode() !== ServerErrorCode.ItemNotFound) {
            setWorking(false);
            clearOAuthPayload();
          }
        });
    }
  }, [errorsSignIn, loginOauth, oauthPayload, setToast, t, setSignInDialogMode, clearOAuthPayload]);

  const facebookOauth = (
    <FacebookLogin
      appId={env.oauth.facebookAppId}
      fields="name,email,picture"
      onSuccess={onSuccessFacebook}
      onFail={onFailFacebook}
      render={({ onClick }) => (
        <StyledInner.OauthButton className={'width-1-1'} onClick={onClick} disabled={working}>
          <img src={facebookIcon} alt="facebook icon" />
          {isSmallMobile
            ? getOauthProviderName(OauthProvider.Facebook)
            : `${t('login-popup.social.desktop', 'Continue with {provider}', {
                provider: getOauthProviderName(OauthProvider.Facebook),
              })}`}
        </StyledInner.OauthButton>
      )}
    />
  );

  const googleOauth = (
    <StyledInner.OauthButton
      className={'width-1-1'}
      onClick={() => googleLogin()}
      disabled={!googleOAuthLoaded || working}
    >
      <img src={googleIcon} alt="google icon" />
      {isSmallMobile
        ? getOauthProviderName(OauthProvider.Google)
        : `${t('login-popup.social.desktop', 'Continue with {provider}', {
            provider: getOauthProviderName(OauthProvider.Google),
          })}`}
    </StyledInner.OauthButton>
  );

  return (
    <ModalBody>
      <ModalCloseButton
        onClick={() => {
          setSignInDialogMode(undefined);
        }}
      />

      <StyledInner.Header className="margin-remove-top">{title}</StyledInner.Header>

      <StyledInner.Field>
        <InputField
          id={'id-signin-email'}
          ref={emailRef}
          containerStyle={'margin-top'}
          required
          inputType={'text'}
          inputMode={'email'}
          label={t('login-popup.email', 'Email Address')}
          value={email}
          onChange={emailChanged}
          errorMessage={t('login-popup.invalid-email', 'Invalid email')}
          autocomplete={'email'}
          doBasicValidation={true}
          validationRule={emailValidationRule}
          allowHotJarRecording={true}
        />
      </StyledInner.Field>
      <StyledInner.Field>
        <InputField
          id={'id-signin-password'}
          ref={passwordRef}
          containerStyle={'margin-top'}
          required
          inputType={'password'}
          label={t('login-popup.password', 'Password')}
          value={password}
          onChange={passwordChanged}
          autocomplete={'off'}
          doBasicValidation={false}
          onEnter={signIn}
          errorMessage={passwordErrorMessage(t)}
          validationRule={passwordValidationRule}
          allowHotJarRecording={false}
        />
      </StyledInner.Field>

      {loginError && (
        <Text color={TextColor.Danger} className="margin-top" tag="div">
          {loginError}
        </Text>
      )}

      <StyledInner.PasswordForgot>
        <a
          href="/"
          className="link-heading"
          onClick={(e) => {
            e.preventDefault();
            onForgotPassword(email);
          }}
        >
          <Text weight={TextWeight.Medium} color={TextColor.Primary}>
            <Trans i18nKey="login-popup.forgot-password">Forgot your password?</Trans>
          </Text>
        </a>
      </StyledInner.PasswordForgot>

      <Button styleType={ButtonType.Primary} className="width-1-1" onClick={signIn} disabled={working}>
        {loginBtnText}
      </Button>

      <StyledInner.Or>
        <Text color={TextColor.Secondary} weight={TextWeight.Medium} transform={TextTransform.Uppercase} tag="div">
          <Trans i18nKey="login-popup.or">OR</Trans>
        </Text>
      </StyledInner.Or>

      {facebookOauth}
      {googleOauth}

      <Text alignment={TextAlignment.Center} tag="div">
        {children}
      </Text>
    </ModalBody>
  );
};
