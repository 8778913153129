import useDateCallback from 'common/hooks/useDateCallback';
import { useMemo } from 'react';

interface UseFormatDatePrp {
  lang: string;
  options: Intl.DateTimeFormatOptions;
  date: Date | null;
}

const useDateByOptions = ({ lang, options, date }: UseFormatDatePrp) => {
  const dateCallback = useDateCallback({ lang, options });

  return useMemo(() => {
    if (!date) {
      return undefined;
    }

    return dateCallback(date);
  }, [date, dateCallback]);
};

export default useDateByOptions;
