export enum ClientErrorCode {
  DealIsExpired = 'deal_is_expired',
  InputPanelError = 'input_panel_error',
  ValidationError = 'validation_error',
  SamePassword = 'same_password',
  DatesEquals = 'dates_equals',
  DatesInPast = 'dates_in_past',
  UnsupportedDestination = 'unsupported_destination',
  InvalidSearchParams = 'invalid_search_params',
  InvalidLengthOfStay = 'invalid_length_of_stay',
  AlreadyCompleted = 'already_completed',
  UnexpectedCreateTripError = 'unexpected_create_trip_error',
  GooglePopupClosedByUser = 'popup_closed',
  GooglePopupFailedToOpen = 'popup_failed_to_open',
  GoogleUnknownError = 'unknown',
  GoogleResponseError = 'google_response_error',
  FacebookLoginCancelled = 'loginCancelled',
  FacebookNotLoaded = 'facebookNotLoaded',
  InvalidLink = 'invalidLink',
  PaymentFailed = 'payment_failed',
  PaymentCancelled = 'payment_cancelled',
  UnexpectedTripStatus = 'unexpected_trip_status',
}

export class ClientError extends Error {
  private readonly code: ClientErrorCode;

  private readonly messages: string[] | undefined;

  constructor(code: ClientErrorCode, messages?: string[]) {
    super(code);
    this.code = code;
    this.messages = messages;
  }

  getCode(): ClientErrorCode {
    return this.code;
  }

  getMessages(): string[] | undefined {
    return this.messages;
  }
}
