import styled from 'styled-components';
import { Button } from 'components/common/Button/Button';
import StyledPeoplePicker from 'components/searchForm/PeoplePicker/PeoplePicker.styled';
import { landingBngBlueButtonColor, landingBngBlueHoveredButtonColor } from 'style/trivagoVaribles';
import { white } from 'style/variables';

// trivago v2 styles

const Actions = styled.div`
  padding: 25px 43px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Modal = styled.div`
  width: 600px;
  max-height: 590px;
  background: ${white};

  display: flex;
  flex-direction: column;

  position: relative;
  padding: 60px 0 30px 0;
  box-sizing: border-box;
  border-radius: 10px;
`;

const ModalCloseAction = styled.img`
  width: 24px;
  height: 24px;

  position: absolute;
  right: 20px;
  top: 20px;

  cursor: pointer;
`;

const DoneButton = styled(Button)`
  height: 50px;
  min-width: 142px;

  border-radius: 6px;
  background: ${landingBngBlueButtonColor};

  :hover,
  :active {
    background: ${landingBngBlueHoveredButtonColor} !important;
  }
`;

const Scrollable = styled.div`
  overflow-y: auto;
`;

const Occupancy = styled.div`
  &:last-child {
    ${StyledPeoplePicker.Content} {
      margin-bottom: 0;
    }
  }
`;

export default {
  Actions,
  Modal,
  ModalCloseAction,
  DoneButton,
  Scrollable,
  Occupancy,
};
