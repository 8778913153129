import useSearchParameter from 'common/hooks/useSearchParameter';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { ClientLocation } from 'backend/api/client/clientModel';
import { Campaign } from 'backend/api/general/generalModel';
import { LANGUAGE_STATE, MEMORIZED_URL_LANGUAGE } from 'backend/localStorageKeys';
import { ChangeMode } from 'contexts/settingsHooks/ChangeModeModel';
import { env } from 'environments/environment';
import { isCurrentLanguage, supportsLanguage } from 'i18n';
import { convertLangCode } from 'utils/languageUtils';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';
import useLocalStorage from 'utils/useLocalStorage';

type UrlParams = {
  language?: string;
};

const urlParamOption = { raw: true, replace: true };

const getDefaultLanguage = (
  languageUrlParam: string | undefined,
  campaign: Campaign | undefined,
  location: ClientLocation,
) => {
  const languageUrl = supportsLanguage(languageUrlParam || '')?.code;
  const languageCampaign = supportsLanguage(campaign?.languageCode || '')?.code;
  const languageLocation = supportsLanguage(location.languageCode || '')?.code;

  return languageUrl || languageCampaign || languageLocation || env.i18n.fallbackLanguage;
};

export const useLanguageSettings = () => {
  const { i18n } = useTranslation();
  const { campaign } = useCampaign();
  const { data: location } = useUserLocation();

  const [languageUrlParam, setLanguageUrlParam] = useSearchParameter<UrlParams, string | undefined>(
    'language',
    urlParamOption,
  );

  const [memorizedLanguageUrlParam, setMemorizedLanguageUrlParam] = useLocalStorage(
    MEMORIZED_URL_LANGUAGE,
    languageUrlParam,
  );

  const [languageState, setLanguageState] = useLocalStorage(LANGUAGE_STATE, ChangeMode.Automatically);
  const [languageCode, setLanguage] = useState<string>(
    convertLangCode(
      languageState === ChangeMode.Manually
        ? i18n.language
        : getDefaultLanguage(memorizedLanguageUrlParam, campaign, location),
    ),
  );

  const setLanguageCode = useCallback(
    (_languageCode: string, manually?: boolean) => {
      const language = supportsLanguage(_languageCode);

      if (language) {
        if (languageUrlParam) {
          setLanguageUrlParam(language.code);
          setMemorizedLanguageUrlParam(language.code);
        }

        setLanguage(language.code);

        if (manually) {
          if (!languageUrlParam) {
            setMemorizedLanguageUrlParam(undefined);
          }

          setLanguageState(ChangeMode.Manually);
          AmplitudeLogging.pushLanguageModifiedEvent(language.code);
        }
      }
    },
    [languageUrlParam, setLanguageState, setLanguageUrlParam, setMemorizedLanguageUrlParam],
  );

  // applies whenever if url contains language parameter different from current.
  // and user has changed the language manually at least once.
  // it's a separate effect since we don't want to be depended on campaign or location
  // and trigger it every time them are changed.
  useEffect(() => {
    const urlLang = languageUrlParam || memorizedLanguageUrlParam;

    if (languageState !== ChangeMode.Automatically && urlLang && urlLang !== languageCode) {
      const language = supportsLanguage(urlLang);

      if (language) {
        setLanguageCode(language.code);
      } else {
        setLanguageUrlParam(undefined);
        setMemorizedLanguageUrlParam(undefined);
      }
    }
  }, [
    languageCode,
    languageState,
    languageUrlParam,
    memorizedLanguageUrlParam,
    setLanguageCode,
    setLanguageUrlParam,
    setMemorizedLanguageUrlParam,
  ]);

  // applies only if user has never changed language manually
  // the language is selected automatically by priority: URL > Campaign > Location > default
  useEffect(() => {
    if (languageState === ChangeMode.Automatically) {
      setLanguageCode(getDefaultLanguage(languageUrlParam || memorizedLanguageUrlParam, campaign, location));
    }
  }, [campaign, languageState, languageUrlParam, location, memorizedLanguageUrlParam, setLanguageCode]);

  useEffect(() => {
    if (!isCurrentLanguage(languageCode)) {
      i18n.changeLanguage(languageCode);
    }
    // changeLanguage is working asynchronously, pls do not remove i18n.language from dependencies
  }, [i18n, languageCode, i18n.language]);

  useLayoutEffect(() => {
    if (!supportsLanguage(i18n.language)) {
      setLanguage(env.i18n.fallbackLanguage);
    }
  }, [i18n]);

  return { languageCode, setLanguageCode };
};
