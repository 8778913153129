export interface UserToken {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
}

export interface UserDetails extends User {
  id: number;
  username: string;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  phoneDetails?: PhoneDetails;
  countryCode?: string;
  stateCode?: string;
  address?: string;
  postalCode?: string;
  agreeMarketing?: boolean;
}

export type PhoneDetails = {
  phone: string;
  callingCode: string;
  countryCode: string;
};

export enum OauthProvider {
  Google = 'google',
  Facebook = 'facebook',
}
