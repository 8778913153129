import { env } from 'environments/environment';

const media = {
  largeDesktop: `@media (min-width: 1920px)`,
  mediumDesktop: `@media (min-width: 1440px)`,
  desktop: `@media (min-width: ${env.layouts.desktopWidth}px)`,
  mobile: `@media (max-width: ${env.layouts.desktopWidth - 1}px)`,
  smallMobile: `@media (max-width: ${env.layouts.smallSmartphoneWidth - 1}px)`,
};

export default media;
