import styled, { css } from 'styled-components';
import { FieldState } from 'components/common/InputField/InputField.types';
import DefaultStyled from 'components/common/InputField/InputFieldStyled/DefaultInputField.styled';
import { InputFieldStyledModel } from 'components/common/InputField/InputFieldStyled/InputField.styled-model';
import media from 'style/media';
import { grassGreen, trivagoDarkBlue, trivagoError } from 'style/trivagoVaribles';
import { borderColorLight, colorMuted } from 'style/variables';

const Input = styled(DefaultStyled.Input)`
  border-color: ${borderColorLight};
  border-radius: 4px;
  padding: 20px 18px 0;
  font-size: 16px;
  line-height: 1;

  ${media.mobile} {
    font-size: 14px;
  }

  &.danger {
    border-color: ${trivagoError};
  }

  ${({ state }) =>
    (state === FieldState.basicError || state === FieldState.emptyError || state === FieldState.error) &&
    css`
      border-color: ${trivagoError};

      &:focus {
        border-color: ${trivagoError};
      }
    `}

  ${({ error }) => error && `border: 1px solid ${trivagoError}`};

  ${({ correct, error }) => correct && !error && `border: 1px solid ${grassGreen} !important`};
`;

const InputLabel = styled(DefaultStyled.InputLabel)`
  font-size: 16px;
  color: ${colorMuted};

  ${media.mobile} {
    font-size: 18px;
  }
`;

const notEmptyStyle = css`
  font-size: 14px;
  transform: translateY(40%);
`;

const Container = styled(DefaultStyled.Container)`
  ${Input}:focus {
    border: 1px solid ${trivagoDarkBlue};
  }

  ${Input}:focus + ${InputLabel},
  ${Input}:-webkit-autofill:hover + ${InputLabel},
  ${Input}:-webkit-autofill:focus + ${InputLabel},
  ${Input}:-webkit-autofill:active + ${InputLabel} {
    ${notEmptyStyle};
  }

  input:not(:placeholder-shown) + ${InputLabel} {
    ${notEmptyStyle}
  }

  ${({ empty, checkEmptyWithCss }) => {
    if (checkEmptyWithCss) {
      return css`
        ${Input}:not(:placeholder-shown) + ${InputLabel} {
          ${notEmptyStyle}
        }
      `;
    }

    return (
      !empty &&
      css`
        ${Input} + ${InputLabel} {
          ${notEmptyStyle}
        }
      `
    );
  }}
`;

const Styled: InputFieldStyledModel = {
  Input,
  TopItem: DefaultStyled.TopItem,
  InputBlock: DefaultStyled.InputBlock,
  InputLabel,
  Container,
  ErrorText: DefaultStyled.ErrorText,
};

export default Styled;
