import { env } from 'environments/environment';
import { getInitialCheckin, getInitialCheckout } from 'utils/dateUtils';
import { isHotelRoute } from 'utils/uriUtils';
import { validateDates, validateDateStrong } from 'utils/validation';

export const calculateCheckinCheckoutValues = (
  pathname: string,
  checkinFromUrl: string | undefined,
  checkoutFromUrl: string | undefined,
  checkinFromStorage: string | undefined,
  checkoutFromStorage: string | undefined,
) => {
  if (isHotelRoute(pathname)) {
    return {
      checkin: checkinFromUrl && checkoutFromUrl ? checkinFromUrl : undefined,
      checkout: checkinFromUrl && checkoutFromUrl ? checkoutFromUrl : undefined,
    };
  }

  if (checkinFromUrl) {
    const checkout = getInitialCheckout(checkinFromUrl, checkoutFromUrl);

    return { checkin: checkinFromUrl, checkout };
  }

  try {
    const offsetHours = isHotelRoute(pathname) ? env.times.offsetTimezoneHours : undefined;

    if (
      validateDateStrong(checkinFromStorage, offsetHours) &&
      validateDateStrong(checkoutFromStorage) &&
      validateDates(checkinFromStorage, checkoutFromStorage)
    ) {
      return { checkin: checkinFromStorage, checkout: checkoutFromStorage };
    }
    const checkin = getInitialCheckin();
    const checkout = getInitialCheckout(checkin);

    return { checkin, checkout };
  } catch (e) {
    const checkin = getInitialCheckin();
    const checkout = getInitialCheckout(checkin);

    return { checkin, checkout };
  }
};
