import { Place } from 'common/backend/api/place/placeModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import { QUERY_STORAGE } from 'backend/localStorageKeys';
import { env } from 'environments/environment';

export interface StoredQuery {
  place: Place;
  checkin: string | undefined;
  checkout: string | undefined;
  occupancy: RoomOccupancy[];
}

export default class SearchStorage {
  private readonly list: StoredQuery[];

  private readonly count: number;

  static readonly instance = new SearchStorage(env.searchBar.queriesToKeepCount);

  constructor(count: number) {
    this.count = count;
    const stored = window.localStorage.getItem(QUERY_STORAGE);

    if (stored) {
      this.list = JSON.parse(stored);
      if (this.list.length > count) {
        while (this.list.length > count) {
          this.list.shift();
        }
        window.localStorage.setItem(QUERY_STORAGE, JSON.stringify(this.list));
      }
    } else {
      this.list = [];
    }
  }

  store(place: Place, checkin: string | undefined, checkout: string | undefined, occupancy: RoomOccupancy[]) {
    this.list.push({
      place,
      checkin,
      checkout,
      occupancy,
    });
    if (this.list.length > this.count) {
      this.list.shift();
    }
    window.localStorage.setItem(QUERY_STORAGE, JSON.stringify(this.list));
  }

  getTop(count: number): StoredQuery[] {
    const ret: StoredQuery[] = [];

    for (let i = 0; i < Math.min(count, this.list.length); i++) {
      ret.unshift(this.list[this.list.length - i]);
    }

    return ret;
  }
}
