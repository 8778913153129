import React from 'react';
import styled from 'styled-components';

interface IconProps {
  src: string;
  color?: string;
  width?: number;
  height?: number;
  className?: string;
}

const StyledSvg = styled.svg<{ src: string; color: string; width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color};
  mask-image: url(${(props) => props.src});
  mask-repeat: no-repeat;
`;

const DEFAULT_SIZE = 24;

const Icon = ({ src, color, width, height, className }: IconProps) => (
  <StyledSvg
    width={width || DEFAULT_SIZE}
    height={height || DEFAULT_SIZE}
    src={src}
    color={color || 'black'}
    className={className}
  />
);

export default Icon;
