import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import mapIcon from 'assets/images/searchForm/map.svg';
import searchIcon from 'assets/images/searchForm/search.svg';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/searchForm/DestinationSearchField/DestinationSearchField.styled';

interface DestinationFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export const DestinationSearchField: React.FC<DestinationFieldProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        e.preventDefault();

        break;
      default:
    }
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Styled.Wrapper>
      <img src={mapIcon} alt="" />
      {isMobileLayout && (
        <span>
          <img src={searchIcon} alt="" />
        </span>
      )}
      <input
        id="searchInput"
        data-testid="location-picker"
        autoComplete="off"
        tabIndex={1}
        // data-hj-allow is here for hotjar to be allowed to record data from this field
        className="data-hj-allow"
        type="text"
        placeholder={t('search-bar.destination-placeholder2', 'What is your destination?')}
        value={value}
        onKeyDown={onKeyDown}
        onChange={handleChange}
      />
    </Styled.Wrapper>
  );
};
