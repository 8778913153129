import React, { useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SettingsContext, SignInDialogContext } from 'TopContexts';
import bookings from 'assets/images/icons/menu-bookmark-test.svg';
import home from 'assets/images/icons/menu-home.svg';
import searchIcon from 'assets/images/icons/menu-search.svg';
import myaccountIcon from 'assets/images/icons/myaccount.svg';
import badge from 'assets/images/icons/nav/badge.svg';
import email from 'assets/images/icons/nav/email.svg';
import globe from 'assets/images/icons/nav/globe.svg';
import goldCoin from 'assets/images/icons/nav/gold-coin.svg';
import newsletter from 'assets/images/icons/nav/newsletter.svg';
import phone from 'assets/images/icons/nav/phone-3.svg';
import privacy from 'assets/images/icons/nav/privacy.svg';
import question from 'assets/images/icons/nav/question.svg';
import signIn from 'assets/images/icons/nav/sign-in.svg';
import signOutIcon from 'assets/images/icons/nav/sign-out.svg';
import suitcase from 'assets/images/icons/nav/suitcase.svg';
import translation from 'assets/images/icons/nav/translation.svg';
import userIcon from 'assets/images/icons/nav/user-icon.svg';
import { useSession } from 'atoms/hooks/useSession';
import { Text } from 'components/common/Text/Text';
import { TextSize } from 'components/common/Text/Text.types';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { CurrencySelector } from 'components/header-footer/CurrencySelector/CurrencySelector';
import { LanguageSelector } from 'components/header-footer/LanguageSelector/LanguageSelector';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import AboutUsLink from 'components/mobile/mobileLinks/AboutUsLink';
import AccountLink from 'components/mobile/mobileLinks/AccountLink';
import BookingLink from 'components/mobile/mobileLinks/BookingLink';
import CareersLink from 'components/mobile/mobileLinks/CareersLink';
import FaqLink from 'components/mobile/mobileLinks/FaqLink';
import PriceGuaranteeLink from 'components/mobile/mobileLinks/PriceGuaranteeLink';
import PrivacyPolicyLink from 'components/mobile/mobileLinks/PrivacyPolicyLink';
import SignInLink from 'components/mobile/mobileLinks/SignInLink';
import SignOutLink from 'components/mobile/mobileLinks/SignOutLink';
import TermsAndConditionsLink from 'components/mobile/mobileLinks/TermsAndConditionsLink';
import Styled from 'components/mobile/offscreen/BurgerMenu/BurgerMenu.styled';
import BurgerMenuElement from 'components/mobile/offscreen/BurgerMenuElement';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import useSignOutCallback from 'components/useSignOutCallback';

export const BurgerMenu = () => {
  const { isTrivago } = useContext(TrivagoContext);
  const { setOffscreenMode } = useContext(OffscreenContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { hideCurrencySelector } = useContext(RouteDataContext);
  const { session } = useSession();

  const contactTranslations = useContactTranslations();
  const signOut = useSignOutCallback();

  const hideOffscreen = useCallback(() => setOffscreenMode(OffscreenMode.hidden), [setOffscreenMode]);

  const { setLanguageCode, setCurrencyCode, languageCode, currencyCode } = useContext(SettingsContext);
  const [localLangCode, setLocalLangCode] = useState(languageCode);
  const [localCurrCode, setLocalCurrCode] = useState(currencyCode);

  const onSave = useCallback(() => {
    setLanguageCode(localLangCode, localLangCode !== languageCode);
    setCurrencyCode(localCurrCode, localCurrCode !== currencyCode);
    setOffscreenMode(OffscreenMode.hidden);
  }, [setLanguageCode, localLangCode, languageCode, setCurrencyCode, localCurrCode, currencyCode, setOffscreenMode]);

  const toHome = (
    <Link id="homeLink" to="/">
      <Trans i18nKey={'mobile-menu.home'}>Home</Trans>
    </Link>
  );

  const toMobileSearch = (
    <a
      id="searchLink"
      href="/"
      onClick={(e) => {
        e.preventDefault();
        setOffscreenMode(OffscreenMode.search);
      }}
    >
      <Trans i18nKey={'search-bar.search'}>Search</Trans>
    </a>
  );

  if (isTrivago) {
    return (
      <Styled.Container>
        <Styled.Content>
          <LanguageSelector isInDarkHeader handledSetLanguage={setLocalLangCode} handledLanguageCode={localLangCode} />
          <CurrencySelector isInDarkHeader handledSetCurrency={setLocalCurrCode} handledCurrencyCode={localCurrCode} />
        </Styled.Content>

        <Styled.ButtonSection>
          <Styled.Button onClick={onSave}>
            <Trans i18nKey={'trivago.v2.burger-menu.save'}>Save</Trans>
          </Styled.Button>
        </Styled.ButtonSection>
      </Styled.Container>
    );
  }

  return (
    <Styled.BurgerScrollContainer>
      <nav className="flex-column">
        <div className="container">
          <Styled.NavList>
            <BurgerMenuElement icon={home}>{toHome}</BurgerMenuElement>
            <BurgerMenuElement icon={searchIcon}>{toMobileSearch}</BurgerMenuElement>
            <BurgerMenuElement icon={bookings}>
              <BookingLink
                toSignInClick={() => {
                  setSignInDialogMode(SignInDialogMode.ViewBooking);
                }}
              />
            </BurgerMenuElement>
            {session.principal && session.principal.hasUser() && (
              <>
                <BurgerMenuElement icon={myaccountIcon}>
                  <AccountLink onClick={hideOffscreen} />
                </BurgerMenuElement>
                <BurgerMenuElement icon={signOutIcon}>
                  <SignOutLink onClick={signOut} />
                </BurgerMenuElement>
              </>
            )}
            {(!session.principal || !session.principal.hasUser()) && (
              <BurgerMenuElement icon={signIn}>
                <SignInLink
                  onClick={() => {
                    setSignInDialogMode(SignInDialogMode.SignIn);
                  }}
                />
              </BurgerMenuElement>
            )}
          </Styled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <Styled.NavList>
            <BurgerMenuElement icon={translation}>
              <div className="width-expand">
                <LanguageSelector />
              </div>
            </BurgerMenuElement>
            {!hideCurrencySelector && (
              <BurgerMenuElement icon={globe}>
                <div className="width-expand">
                  <CurrencySelector />
                </div>
              </BurgerMenuElement>
            )}
          </Styled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <Styled.NavList>
            <Styled.BaseLineHeight as="h3" className="margin-remove-top margin-small-bottom">
              <Trans i18nKey={'mobile-menu.company-section'}>Company</Trans>
            </Styled.BaseLineHeight>
            <BurgerMenuElement icon={userIcon}>
              <AboutUsLink />
            </BurgerMenuElement>
            <BurgerMenuElement icon={badge}>
              <CareersLink />
            </BurgerMenuElement>
          </Styled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <Styled.NavList>
            <Styled.BaseLineHeight as="h3" className="margin-remove-top margin-small-bottom">
              <Trans i18nKey={'mobile-menu.support-section'}>Support</Trans>
            </Styled.BaseLineHeight>
            <BurgerMenuElement icon={question}>
              <FaqLink onClick={hideOffscreen} />
            </BurgerMenuElement>
            <BurgerMenuElement icon={goldCoin}>
              <PriceGuaranteeLink onClick={hideOffscreen} />
            </BurgerMenuElement>
          </Styled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <Styled.NavList>
            <Styled.BaseLineHeight as="h3" className="margin-remove-top margin-small-bottom">
              <Trans i18nKey={'mobile-menu.legal-section'}>Legal</Trans>
            </Styled.BaseLineHeight>

            <BurgerMenuElement icon={newsletter}>
              <TermsAndConditionsLink onClick={hideOffscreen} />
            </BurgerMenuElement>

            <BurgerMenuElement icon={privacy}>
              <PrivacyPolicyLink onClick={hideOffscreen} />
            </BurgerMenuElement>
          </Styled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <Styled.NavList>
            <Styled.BaseLineHeight as="h3" className="margin-remove-top margin-small-bottom">
              <Trans i18nKey={'mobile-menu.connect-with-us'}>Connect with us</Trans>
            </Styled.BaseLineHeight>
            <BurgerMenuElement icon={phone}>
              <a href={`tel:${contactTranslations.phone}`}>{contactTranslations.phone}</a>
            </BurgerMenuElement>

            <BurgerMenuElement icon={suitcase}>
              <a href={`mailto:${contactTranslations.partnerWithUs}`}>
                <Trans i18nKey="footer.partner-with-us">Partner With Us</Trans>
              </a>
            </BurgerMenuElement>

            <BurgerMenuElement icon={email}>
              <a href={`mailto:${contactTranslations.emailUs}`}>
                <Trans i18nKey="footer.email-us">Email Us</Trans>
              </a>
            </BurgerMenuElement>
          </Styled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <Text size={TextSize.Small} className="margin-large-top margin-medium-bottom" tag="div">
            <Trans
              i18nKey="footer.copyright"
              defaults="© {year} Holisto Company LTD. All Rights Reserved."
              values={{ year: new Date().getFullYear() }}
            />
          </Text>
        </div>
      </nav>
    </Styled.BurgerScrollContainer>
  );
};
