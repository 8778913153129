import React, { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import burger_black from 'assets/images/icons/burger.svg';
import algotelsLogo from 'assets/images/logo/logo.svg';
import logoBookAndGo from 'assets/images/logo/trivago-bng-mobile.webp';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { TrivagoPageHeaderStyled } from 'components/layouts/trivago/TrivagoPageHeader.styled';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import { isHotelRoute } from 'utils/uriUtils';

const TrivagoMobilePageHeader = () => {
  const { isTrivagoBookAndGo } = useContext(TrivagoContext);
  const { offscreenMode, setOffscreenMode } = useContext(OffscreenContext);
  const location = useLocation();
  const isHotelPage = useMemo(() => isHotelRoute(location.pathname), [location.pathname]);
  const onBurgerClicked = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      if (offscreenMode === OffscreenMode.hidden) {
        setOffscreenMode(OffscreenMode.burger);
      } else {
        setOffscreenMode(OffscreenMode.hidden);
      }
    },
    [offscreenMode, setOffscreenMode],
  );

  const burgerButton = (
    <TrivagoPageHeaderStyled.BurgerIcon
      data-testid="burger-button"
      id="burgerButton"
      href="/"
      className="padding-remove-vertical"
      onClick={onBurgerClicked}
    >
      <img src={burger_black} alt="Menu" />
    </TrivagoPageHeaderStyled.BurgerIcon>
  );

  return (
    <TrivagoPageHeaderStyled.BgWrapper>
      <TrivagoPageHeaderStyled.Navbar className="container">
        <TrivagoPageHeaderStyled.Content isBNG>
          {isTrivagoBookAndGo && (
            <TrivagoPageHeaderStyled.BookAndGoLogo src={logoBookAndGo} alt={'Trivago Book And Go logo'} />
          )}
          {!isTrivagoBookAndGo && <TrivagoPageHeaderStyled.AlgotelsLogo src={algotelsLogo} alt="Algotels logo" />}
          {isHotelPage && burgerButton}
        </TrivagoPageHeaderStyled.Content>
      </TrivagoPageHeaderStyled.Navbar>
    </TrivagoPageHeaderStyled.BgWrapper>
  );
};

export default TrivagoMobilePageHeader;
