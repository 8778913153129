/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext } from 'react';
import arrowLeftIcon from 'assets/images/icons/mobileSearchBack.svg';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { SearchFormProvider } from 'components/contexts/SearchFormContext';
import MobileOffscreen from 'components/mobile/offscreen/MobileOffscreen';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import { SearchForm } from 'components/searchForm/SearchForm/SearchForm';

const OffscreenSearchMenu = () => {
  const { setOffscreenMode, goToPreviousOffscreenMode } = useContext(OffscreenContext);

  return (
    <MobileOffscreen>
      <SearchFormProvider>
        <div className="margin-large-top margin-bottom">
          <a
            data-testid="back-link"
            id="backLink"
            href="/"
            className="padding"
            onClick={(e) => {
              e.preventDefault();
              goToPreviousOffscreenMode();
            }}
          >
            <img src={arrowLeftIcon} alt="Close" className="" />
          </a>
        </div>
        <SearchForm onAfterSearch={() => setOffscreenMode(OffscreenMode.hidden)} />
      </SearchFormProvider>
    </MobileOffscreen>
  );
};

export default OffscreenSearchMenu;
