export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Text = 'text',
  Outlined = 'outlined',
}

export enum ButtonSize {
  Small = 'small',
  Normal = 'normal',
  Large = 'large',
  XLarge = 'xlarge',
}
