/**
 * @category Hotel Components
 * @packageDocumentation
 */
import React, {
  ForwardRefRenderFunction,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InputFieldRef } from 'components/common/InputField/InputField';
import { Placeholder } from 'components/common/Placeholder/Placeholder';
import { ThemeContext } from 'components/contexts/ThemeContext';
import { placeholderInput } from 'style/placeholderStyles';
import { focusInput } from 'utils/inputValidationUtils';

export type Key = string | undefined;

export type Item = {
  key: Key;
  value: string;
};

export type Items = Item[];

export type Group = {
  key: string;
  name: string;
  items: Items;
};

interface SelectInputCommonProps {
  id: string;
  name?: string;
  label?: string | React.ReactElement;
  placeholder?: string;
  selectedKey: Key;
  onSelect: (key: Key) => void;
  /**
   * Values reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete?: string;
  isLoadingExternal?: boolean;
  displayValue?: React.ReactElement | ((focus: boolean, error: boolean) => React.ReactElement);
  required?: boolean;
  testId?: string;
  overwrite?: boolean;
  errorMessage?: string;
}

interface SelectGroupsInputProps extends SelectInputCommonProps {
  groups: Group[];
  options?: never;
}

interface SelectOptionsInputProps extends SelectInputCommonProps {
  options: Item[];
  groups?: never;
}

type SelectInputProps = SelectGroupsInputProps | SelectOptionsInputProps;

const SelectInput: ForwardRefRenderFunction<InputFieldRef, SelectInputProps> = (
  {
    testId,
    id,
    name,
    selectedKey,
    options,
    onSelect,
    autocomplete,
    isLoadingExternal,
    label,
    groups,
    displayValue,
    required,
    overwrite = true,
    errorMessage,
  },
  ref,
) => {
  const { t } = useTranslation();
  const { overrideTheme } = useContext(ThemeContext);
  const [errorText, setErrorText] = useState<string>();
  const commonRef = useRef<HTMLLabelElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const [selected, setSelected] = useState<Item | undefined>();

  const Styled = useMemo(() => overrideTheme.SelectInput, [overrideTheme]);
  const [focus, setFocus] = useState(false);

  const justOptions = useMemo<Item[]>(
    () => (groups ? groups.flatMap((group) => group.items) : options),
    [groups, options],
  );

  const innerValidation = useCallback(() => {
    if (required && !selected?.key) {
      const _errorMessage = errorMessage || t('validation.emptyField', '{field} is required', { field: label || '' });

      setErrorText(_errorMessage);

      return _errorMessage;
    }

    return undefined;
  }, [errorMessage, label, required, selected?.key, t]);

  useImperativeHandle<InputFieldRef, InputFieldRef>(ref, () => ({
    invalidate(_focus: boolean) {
      const _errorMessage = innerValidation();

      if (_errorMessage && _focus) {
        focusInput(commonRef);
      }

      return _errorMessage;
    },
    setIsError: (i18nMessage?: string, _focus = true) => {
      setErrorText(i18nMessage);
      if (_focus) {
        focusInput(selectRef);
      }
    },
  }));

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onSelect(e.target.value);
      setErrorText(undefined);
    },
    [onSelect],
  );

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    innerValidation();
    setFocus(false);
  }, [innerValidation]);

  useEffect(() => {
    if (justOptions.length > 0 && justOptions.every((o) => o.key !== selectedKey)) {
      onSelect(justOptions[0].key?.toString());
    }
  }, [justOptions, onSelect, selectedKey]);

  useEffect(() => {
    setSelected(justOptions.find((o) => o.key === selectedKey));
  }, [justOptions, selectedKey]);

  const optionList = useMemo(() => {
    if (groups) {
      return groups.map((group) => (
        <optgroup label={group.name} key={group.key}>
          {group.items.map((item) => (
            <option id={`${id}-item-${item.key}`} key={`${id}-item-${item.key}`} value={item.key}>
              {item.value}
            </option>
          ))}
        </optgroup>
      ));
    }

    return options.map((el) => (
      <option id={`${id}-item-${el.key}`} key={`${id}-item-${el.key}`} value={el.key}>
        {el.value}
      </option>
    ));
  }, [groups, id, options]);

  const displayValueElement = useMemo(() => {
    if (typeof displayValue === 'function') {
      return displayValue(focus, !!errorText);
    }

    return displayValue;
  }, [displayValue, errorText, focus]);

  return (
    <Placeholder ready={!isLoadingExternal} type="rect" showLoadingAnimation style={placeholderInput}>
      <Styled.SelectField as="label" empty={!selected} ref={commonRef}>
        <Styled.Select
          data-testid={testId}
          id={id}
          name={name}
          ref={selectRef}
          value={selectedKey}
          onChange={onChange}
          disabled={!justOptions.length}
          autoComplete={autocomplete}
          className={selected?.key?.toString().toLowerCase()}
          hasLabel={!!label}
          error={!!errorText}
          fieldOverwrite={overwrite && !!displayValue}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {optionList}
        </Styled.Select>
        {/* hide if fieldOverwrite */}
        {overwrite && !!displayValue ? null : <Styled.SelectArrow />}
        <Styled.DisplayValue overwrite={overwrite}>{displayValueElement}</Styled.DisplayValue>
        {label && <Styled.Label htmlFor={id}>{label}</Styled.Label>}
        {errorText && <Styled.ErrorLabel>{errorText}</Styled.ErrorLabel>}
      </Styled.SelectField>
    </Placeholder>
  );
};

export default React.memo(React.forwardRef(SelectInput));
