import React from 'react';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { LayoutProvider } from 'components/contexts/LayoutContext';
import { DataProvider } from 'contexts/DataProvider';
import ModalAlertProvider from 'contexts/ModalAlertProvider';
import SettingsProvider from 'contexts/SettingsProvider';
import SignInDialogProvider from 'contexts/SigninDialogProvider';
import ToastProvider from 'contexts/ToastProvider';

const TopProviders: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isPending } = useUserLocation();

  if (isPending) {
    return null;
  }

  return (
    <ToastProvider>
      <ModalAlertProvider>
        <SignInDialogProvider>
          <LayoutProvider>
            <SettingsProvider>
              <DataProvider>{children}</DataProvider>
            </SettingsProvider>
          </LayoutProvider>
        </SignInDialogProvider>
      </ModalAlertProvider>
    </ToastProvider>
  );
};

export default TopProviders;
