import React, { useContext } from 'react';
import algotelsLogo from 'assets/images/logo/logo.svg';
import logoBookAndGo from 'assets/images/logo/trivago-bng-desktop.webp';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { CurrencySelector } from 'components/header-footer/CurrencySelector/CurrencySelector';
import { LanguageSelector } from 'components/header-footer/LanguageSelector/LanguageSelector';
import { TrivagoPageHeaderStyled } from 'components/layouts/trivago/TrivagoPageHeader.styled';

const TrivagoDesktopPageHeader = () => {
  const { hideCurrencySelector, hideLanguageSelector } = useContext(RouteDataContext);
  const { isTrivagoBookAndGo } = useContext(TrivagoContext);

  const trivagoBookAndGoContent = (
    <>
      <TrivagoPageHeaderStyled.StartBlock isBng />
      <TrivagoPageHeaderStyled.Content isBNG>
        <TrivagoPageHeaderStyled.BookAndGoLogo src={logoBookAndGo} alt={'Trivago Book And Go logo'} />
      </TrivagoPageHeaderStyled.Content>
      <TrivagoPageHeaderStyled.EndBlock isBng>
        {!hideLanguageSelector && <LanguageSelector />}
        {!hideCurrencySelector && <CurrencySelector />}
      </TrivagoPageHeaderStyled.EndBlock>
    </>
  );

  const trivagoContent = (
    <>
      <TrivagoPageHeaderStyled.StartBlock>
        <TrivagoPageHeaderStyled.AlgotelsLogo src={algotelsLogo} alt="Algotels logo" />
      </TrivagoPageHeaderStyled.StartBlock>
      <TrivagoPageHeaderStyled.Content />
      <TrivagoPageHeaderStyled.EndBlock>
        {!hideLanguageSelector && <LanguageSelector />}
        {!hideCurrencySelector && <CurrencySelector />}
      </TrivagoPageHeaderStyled.EndBlock>
    </>
  );

  return (
    <TrivagoPageHeaderStyled.BgWrapper>
      <TrivagoPageHeaderStyled.Navbar className="container">
        {isTrivagoBookAndGo ? trivagoBookAndGoContent : trivagoContent}
      </TrivagoPageHeaderStyled.Navbar>
    </TrivagoPageHeaderStyled.BgWrapper>
  );
};

export default TrivagoDesktopPageHeader;
