import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useSearchParameter<X, T>(
  paramName: keyof X,
  initialOptions?: { defaultValue?: T; raw?: boolean; replace?: boolean; preventScrollUp?: boolean },
) {
  const history = useHistory();
  const location = useLocation();
  const [options] = useState(initialOptions || {});
  const [defaultInitial, setDefaultInitial] = useState(false);

  const setParameter = useCallback(
    (opened?: T) => {
      const currentSearch = history.location.search;
      const currentState = history.location.state;
      const query = qs.parse(currentSearch, { ignoreQueryPrefix: true });

      const newParam = options.raw ? opened : JSON.stringify(opened);

      if (query[paramName as string] !== newParam) {
        query[paramName as string] = newParam as string;

        const search = qs.stringify(query);

        if (options?.replace) {
          history.replace({ search });
        } else {
          history.push({
            search,
            state: { ...(currentState || {}), preventScrollUp: options.preventScrollUp },
          });
        }
      }
    },
    [history, options, paramName],
  );

  const getParameter = useMemo(() => {
    if (!defaultInitial) {
      return undefined;
    }

    const currentSearch = location.search;
    const query = qs.parse(currentSearch, { ignoreQueryPrefix: true });

    const val = query[paramName as string];

    if (val) {
      if (options.raw) {
        return val as T;
      }

      return JSON.parse(val as string) as T;
    }

    return undefined;
  }, [defaultInitial, location.search, options, paramName]);

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (!defaultInitial && options?.hasOwnProperty('defaultValue')) {
      setParameter(options.defaultValue);
    }
    setDefaultInitial(true);
  }, [defaultInitial, options, setParameter]);

  const ret: [T | undefined, (value: T | undefined) => void] = [getParameter, setParameter];

  return ret;
}

export default useSearchParameter;
