/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import plusIcon from 'assets/images/icons/trivagoV2/plus-button-icon.svg';
import ButtonStyled from 'components/common/Button/ButtonStyled/DefaultButton.styled';
import DropdownStyled from 'components/common/Dropdown/Dropdown.styled';
import media from 'style/media';
import { ifTrivago } from 'style/trivago';
import { landingBngBlueButtonColor, parisianBlue, trivagoBlackV2 } from 'style/trivagoVaribles';
import {
  black,
  borderColor,
  borderRadius,
  boxShadowColor,
  charcoalGrey,
  lightGray,
  lightRoyalBlue,
  superDarkGrey,
  veryLightGray,
} from 'style/variables';

const Container = styled.div<{ active?: boolean }>`
  ${media.desktop} {
    ${({ active }) => active && 'background:  rgba(29, 127, 190, 0.1);'}
  }

  ${media.mobile} {
    padding: 15px 24px 5px;
    box-sizing: border-box;
  }
`;

const MobileContainer = styled.div`
  background-color: ${lightGray};
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};

  &:hover &:focus {
    color: black;
    border-color: ${boxShadowColor};
  }
`;

const TrivagoPickerButton = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 19px 38px;
  box-sizing: border-box;

  cursor: pointer;
  outline: none;

  font-size: 18px;
  color: ${superDarkGrey};
`;

const PickerButton = styled(ButtonStyled.DefaultButton)`
  height: 48px;
  width: 100%;
  justify-content: start;
  font-size: 15px;
  font-weight: normal;
  color: ${charcoalGrey};
`;

const Label = styled.label`
  font-weight: bold;
  line-height: 1;
  margin-right: 40px;
`;

const Info = styled.div`
  display: flex;
  min-width: 130px;
  margin-right: 10px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 10px;
`;

const RoomLabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RoomLabel = styled.label`
  font-weight: bold;
  color: ${trivagoBlackV2};
  font-size: 18px;
  margin-bottom: 18px;
`;

const RemoveButton = styled(ButtonStyled.TextButton)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: ${landingBngBlueButtonColor};

  text-decoration: underline;
  text-underline-offset: 2px;

  :disabled {
    visibility: hidden;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddButton = styled(RemoveButton)`
  align-items: center;

  ${media.desktop} {
    font-size: 16px;
  }

  ::before {
    width: 20px;
    height: 20px;
    content: '';
    background-image: url(${plusIcon});
    background-size: 20px 20px;
    background-repeat: no-repeat;
    margin-right: 8px;
  }
`;

const CounterButton = styled.button`
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;

  svg {
    ${ifTrivago(
      css`
        background-color: ${parisianBlue};
      `,
      css`
        background-color: ${lightRoyalBlue};
      `,
    )}
  }
`;

const Main = styled.div<{ $overflow?: boolean; offset?: number }>`
  padding: 12px 17px;
  box-sizing: border-box;
  width: 266px;
  color: ${black};

  ${({ $overflow, offset }) =>
    !$overflow &&
    css`
      border-radius: 2px;
      max-height: calc(100vh - ${offset}px - 10px);
      overflow: visible auto;
    `}
`;

const Content = styled.div`
  ${media.mobile} {
    padding: 20px 14px;
  }

  ${ifTrivago(css`
    padding: 0 43px;
  `)}
`;

const People = styled.span`
  white-space: nowrap;
`;

const OccupancyDropdown = styled(DropdownStyled.Dropdown)`
  padding: 15px 10px;
  background-color: ${veryLightGray};
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
`;

const Text = styled.div`
  color: ${superDarkGrey};
`;

const OccupancyNumber = styled.div`
  font-size: 20px;
  color: ${superDarkGrey};
`;

export default {
  Container,
  MobileContainer,
  TrivagoPickerButton,
  PickerButton,
  Label,
  Info,
  Image,
  RoomLabel,
  Actions,
  AddButton,
  RoomLabelRow,
  RemoveButton,
  CounterButton,
  Main,
  Content,
  People,
  OccupancyDropdown,
  Text,
  OccupancyNumber,
};
