import React, { useContext } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { Props as ReactPlaceholderProps } from 'react-placeholder/lib/ReactPlaceholder';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { placeholderBackground } from 'style/trivagoVaribles';
import 'react-placeholder/lib/reactPlaceholder.css';

type Props = {
  children: React.ReactNode;
} & ReactPlaceholderProps;

export const Placeholder = ({ children, color, ...rest }: Props) => {
  const { isTrivago } = useContext(TrivagoContext);

  const colorProp = color || (isTrivago ? placeholderBackground : undefined);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ReactPlaceholder color={colorProp} {...rest}>
      {children}
    </ReactPlaceholder>
  );
};
