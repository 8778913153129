export const weekdayNarrowFormat = { weekday: 'narrow' } as Intl.DateTimeFormatOptions;
export const monthLongFormat = { month: 'long' } as Intl.DateTimeFormatOptions;
export const yearNumericMonthShortDayNumeric = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
} as Intl.DateTimeFormatOptions;
export const yearNumericMonthShortDayNumericWeekdayShort = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
} as Intl.DateTimeFormatOptions;
export const monthLongDayNumeric = { month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions;
export const yearNumericMonthLongDayNumeric = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
} as Intl.DateTimeFormatOptions;
export const dayNumericMonthLongYearNumericOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
} as Intl.DateTimeFormatOptions;
