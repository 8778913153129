import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import bestPrice2 from 'assets/images/home/best-price-icon.svg';
import { Text } from 'components/common/Text/Text';
import { TextColor } from 'components/common/Text/Text.types';
import BenefitSectionStyled from 'components/home/BenefitSection.styled';
import Styled from 'components/home/HomePriceGuaranteePanel/HomePriceGuaranteePanel.styled';
import { PRICE_GUARANTEE_ROUTE } from 'routeList';

export const HomePriceGuaranteePanel = () => (
  <BenefitSectionStyled.GuaranteeItem>
    <BenefitSectionStyled.BenefitImage src={bestPrice2} />
    <Styled.Text className="width-2-3">
      <Styled.BenefitLabel className="margin-small-bottom">
        <Trans i18nKey={'home.guarantee.title'}>Best Price Guarantee</Trans>
      </Styled.BenefitLabel>
      <div>
        <Trans i18nKey={'price-guarantee.found-lower-price'}>Found a lower price on your deal?</Trans>{' '}
      </div>
      <div>
        <Trans i18nKey={'price-guarantee.let-us-know'}>Let us know, get a $50 coupon AND a refund</Trans>
      </div>
      <Link to={PRICE_GUARANTEE_ROUTE}>
        <Text color={TextColor.Emphasis} className="margin-small-top" tag="div">
          <Trans i18nKey={'price-guarantee.how'}>How does this work?</Trans>
        </Text>
      </Link>
    </Styled.Text>
  </BenefitSectionStyled.GuaranteeItem>
);
