import React, { useContext, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ButtonStyledModel } from 'components/common/Button/ButtonStyled/Button.styled-model';
import DefaultButtonStyled from 'components/common/Button/ButtonStyled/DefaultButton.styled';
import TrivagoButtonStyled from 'components/common/Button/ButtonStyled/TrivagoButton.styled';
import CheckoutInputCheckboxStyled from 'components/common/InputCheckbox/InputCheckboxStyled/CheckoutInputCheckbox.styled';
import DefaultInputCheckboxStyled from 'components/common/InputCheckbox/InputCheckboxStyled/DefaultInputCheckbox.styled';
import { InputCheckboxStyledModel } from 'components/common/InputCheckbox/InputCheckboxStyled/InputCheckbox.styled-model';
import TrivagoInputCheckboxStyled from 'components/common/InputCheckbox/InputCheckboxStyled/TrivagoInputCheckbox.styled';
import CheckoutInputFieldStyled from 'components/common/InputField/InputFieldStyled/CheckoutInputField.styled';
import DefaultInputFieldStyled from 'components/common/InputField/InputFieldStyled/DefaultInputField.styled';
import { InputFieldStyledModel } from 'components/common/InputField/InputFieldStyled/InputField.styled-model';
import TrivagoInputFieldStyled from 'components/common/InputField/InputFieldStyled/TrivagoInputField.styled';
import CheckoutSelectInputStyled from 'components/common/SelectInput/SelectInputStyled/CheckoutSelectInput.styled';
import DefaultSelectInputStyled from 'components/common/SelectInput/SelectInputStyled/DefaultSelectInput.styled';
import { SelectInputStyledModel } from 'components/common/SelectInput/SelectInputStyled/SelectInput.styled-model';
import TrivagoSelectInputStyled from 'components/common/SelectInput/SelectInputStyled/TrivagoSelectInput.styled';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { routes } from 'routeList';

interface OverrideTheme {
  InputField: InputFieldStyledModel;
  InputCheckbox: InputCheckboxStyledModel;
  SelectInput: SelectInputStyledModel;
  Button: ButtonStyledModel;
}

interface Context {
  trivagoMode: boolean;
  overrideTheme: OverrideTheme;
}

export const ThemeContext = React.createContext<Context>({
  trivagoMode: false,
  overrideTheme: {
    InputField: DefaultInputFieldStyled,
    InputCheckbox: DefaultInputCheckboxStyled,
    SelectInput: DefaultSelectInputStyled,
    Button: DefaultButtonStyled,
  },
});

interface ThemeContextProps {
  children?: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeContextProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { isTrivago } = useContext(TrivagoContext);

  const isCheckoutPage = useMemo(() => matchPath(pathname, routes.checkout), [pathname]);

  const overrideTheme = useMemo<OverrideTheme>(() => {
    if (isTrivago) {
      return {
        InputField: TrivagoInputFieldStyled,
        InputCheckbox: TrivagoInputCheckboxStyled,
        SelectInput: TrivagoSelectInputStyled,
        Button: TrivagoButtonStyled,
      };
    }

    if (isCheckoutPage) {
      return {
        InputField: CheckoutInputFieldStyled,
        InputCheckbox: CheckoutInputCheckboxStyled,
        SelectInput: CheckoutSelectInputStyled,
        Button: DefaultButtonStyled,
      };
    }

    return {
      InputField: DefaultInputFieldStyled,
      InputCheckbox: DefaultInputCheckboxStyled,
      SelectInput: DefaultSelectInputStyled,
      Button: DefaultButtonStyled,
    };
  }, [isCheckoutPage, isTrivago]);

  const themeToApply = useMemo<Context>(
    () => ({
      trivagoMode: isTrivago,
      overrideTheme,
    }),
    [isTrivago, overrideTheme],
  );

  return (
    <ThemeContext.Provider value={themeToApply}>
      <StyledThemeProvider theme={themeToApply}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
