import { useSessionStorageWithObject } from 'common/hooks/useSessionStorage';
import React from 'react';
import { Trans } from 'react-i18next';
import closeIcon from 'assets/images/icons/close-icon.svg';
import { SHOW_COVID } from 'backend/sessionStorageKeys';
import { CovidBannerStyled } from 'components/widgets/CovidBanner.styled';
import { env } from 'environments/environment';
import { FAQ_ROUTE } from 'routeList';

export const CovidBanner = () => {
  const [showCovid, setShowCovid] = useSessionStorageWithObject(SHOW_COVID, env.misc.showCovid);

  if (!showCovid) {
    return <React.Fragment />;
  }

  return (
    <CovidBannerStyled.Container>
      <div>
        <Trans i18nKey="common.menu.covid-header">
          Important information about <a href={FAQ_ROUTE}>COVID-19 (coronavirus)</a> and your travel plans
        </Trans>
      </div>
      <CovidBannerStyled.CloseButton type="button" onClick={() => setShowCovid(false)}>
        <CovidBannerStyled.ButtonImage src={closeIcon} alt="close" />
      </CovidBannerStyled.CloseButton>
    </CovidBannerStyled.Container>
  );
};
