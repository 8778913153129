/**
 * Amount of milliseconds in a day, used in calculateNights
 */
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const MS_IN_SECOND = 1000;

export const MS_IN_DAY = MS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY;

/**
 * Tries to parse date.
 * @param s - string representation of a date
 * @returns undefined if date is unparseable
 */
export function parseDate(s: string | undefined | null): Date | undefined {
  if (!s) {
    return undefined;
  }
  try {
    const parts = s.split('-');
    let d: Date;

    if (parts[DatePart.year] && parts[DatePart.month] && parts[DatePart.day]) {
      d = new Date();
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setFullYear(Number.parseInt(parts[DatePart.year], 10));
      d.setMonth(Number.parseInt(parts[DatePart.month], 10) - 1, Number.parseInt(parts[DatePart.day], 10));
    } else {
      d = new Date(s);
    }

    if (!Number.isNaN(d.getTime())) {
      return d;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
}

/**
 * Calculates number of nights between two dates
 * @param checkin
 * @param checkout
 */
export function calculateNightCount(checkin: string | undefined, checkout: string | undefined): number {
  const begin = parseDate(checkin);
  const end = parseDate(checkout);

  if (begin && end) {
    return Math.floor((end.getTime() - begin.getTime()) / MS_IN_DAY);
  }

  return 0;
}

export enum DatePart {
  year,
  month,
  day,
}

/* eslint-disable no-magic-numbers */
export const safariSafeDateParse = (strDate: string) => {
  const tmp = strDate
    .split(/[^0-9]/)
    .map((el) => Number.parseInt(el, 10))
    .slice(0, 6);

  if (tmp.length > 0 && tmp.length < 8) {
    return new Date(tmp[0], tmp[1] - 1, tmp[2], 0, 0, 0).getTime();
  }

  return NaN;
};

/**
 * Calculates amount of nights between two set dates, passed as date strings
 * @param firstDate
 * @param secondDate
 */
export const calculateNights = (firstDate: string | undefined, secondDate: string | undefined) => {
  if (!firstDate || !secondDate) {
    return 0;
  }

  const result: number = Math.round((safariSafeDateParse(secondDate) - safariSafeDateParse(firstDate)) / MS_IN_DAY);

  // may be NaN if something is parsed incorrectly
  return result > 0 ? result : 0;
};