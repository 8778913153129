import { FlattenSimpleInterpolation, css } from 'styled-components';

interface Theme {
  trivagoMode: boolean;
}

export const ifTrivago = (
  trivagoStyle: FlattenSimpleInterpolation,
  notTrivagoStyles?: FlattenSimpleInterpolation,
) => css`
  ${({ theme }: { theme: Theme }) => (theme.trivagoMode ? trivagoStyle : notTrivagoStyles)}
`;
