import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import useOutsideClick from 'common/hooks/useOutsideClick';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import closeIcon from 'assets/images/icons/close-icon-v2.svg';
import { ButtonType } from 'components/common/Button/Button.types';
import { CustomAbstractModal } from 'components/common/modal/CustomAbstractModal';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import Styled from 'components/searchForm/OccupancyPicker/OccupancyPicker.styled';
import StyledModal from 'components/searchForm/OccupancyPickerModal.styled';
import PeoplePicker from 'components/searchForm/PeoplePicker/PeoplePicker';
import { env } from 'environments/environment';
import { adultChanger, addOccupancy, childrenChanger, removeOccupancy, setAge } from 'utils/occupancyUtils';

interface ModalWithCloseProps {
  visible: boolean;
  onClose: () => void;
  onDone: (occupancy: RoomOccupancy[]) => void;
}

const OccupancyPickerModal: React.FC<ModalWithCloseProps> = ({ visible, onClose, onDone }) => {
  const { occupancy, setOccupancy } = useContext(SearchFormContext);
  const [initialOccupancy] = useState(occupancy);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    disableBodyScroll(document.body, {
      reserveScrollBarGap: true,
    });

    return () => {
      enableBodyScroll(document.body);
    };
  }, []);

  const closeWithoutChange = () => {
    setOccupancy(initialOccupancy);
    onClose();
  };

  const closeWithChange = () => {
    onDone(occupancy);
  };

  useOutsideClick(ref, closeWithoutChange, false);

  const contentBng = useMemo(
    () =>
      occupancy.map((roomOccupancy, roomIdx) => (
        <StyledModal.Occupancy key={['occupancy', roomIdx].join('-')}>
          <Styled.RoomLabelRow>
            <Styled.RoomLabel>
              <Trans i18nKey="search-bar.room-number" defaults="Room {roomIdx}" values={{ roomIdx: roomIdx + 1 }} />
            </Styled.RoomLabel>
            <Styled.RemoveButton
              disabled={roomIdx === 0}
              onClick={() => setOccupancy(removeOccupancy(roomIdx, occupancy))}
            >
              <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.remove-room'}>Remove</Trans>
            </Styled.RemoveButton>
          </Styled.RoomLabelRow>
          <PeoplePicker
            roomNumber={roomIdx}
            adults={roomOccupancy.adults}
            adultChanger={(increase) => setOccupancy(adultChanger(roomIdx, increase, occupancy))}
            childrenAges={roomOccupancy.children || []}
            childrenChanger={(increase) => setOccupancy(childrenChanger(roomIdx, increase, occupancy))}
            setAge={(index, age) => setOccupancy(setAge(roomIdx, index, age, occupancy))}
          />
        </StyledModal.Occupancy>
      )),
    [occupancy, setOccupancy],
  );

  return (
    <CustomAbstractModal visible={visible} positionToCenter trivagoV2 omitPadding>
      <StyledModal.Modal ref={ref}>
        <StyledModal.ModalCloseAction src={closeIcon} alt="close-icon" onClick={closeWithoutChange} />
        <StyledModal.Scrollable>
          <Styled.Content>{contentBng}</Styled.Content>
        </StyledModal.Scrollable>
        <StyledModal.Actions>
          <Styled.AddButton
            disabled={occupancy.length === env.searchBar.maxOccupancy}
            onClick={() => setOccupancy(addOccupancy(occupancy))}
          >
            <span>
              <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.add-room'}>Add room</Trans>
            </span>
          </Styled.AddButton>
          <StyledModal.DoneButton styleType={ButtonType.Primary} onClick={closeWithChange}>
            <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.done-button'}>Done</Trans>
          </StyledModal.DoneButton>
        </StyledModal.Actions>
      </StyledModal.Modal>
    </CustomAbstractModal>
  );
};

export default OccupancyPickerModal;
