export enum ToastType {
  Info = 'Info',
  Error = 'Error',
  Success = 'Success',
  Warn = 'Warn',
}

export enum ToastState {
  Opening = 'Opening',
  Opened = 'Opened',
  Closing = 'Closing',
  Closed = 'Closed',
}
