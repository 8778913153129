/* eslint-disable no-magic-numbers */
/**
 * @category Configuration
 * @packageDocumentation
 */
import { RateDisplayModel } from 'common/backend/api/general/generalModel';
import { CurrencyCode } from 'common/backend/api/model';
import { PriceRoundingMethod } from 'backend/api/general/generalModel';
import { Configuration } from 'environments/configuration';
import currencies from 'environments/defaultCurrencies';
import localeCurrencySeparators from 'environments/localeCurrencySeparators';
import supportLanguages from 'environments/supportLanguages';

/**
 * Development configuration, used for  https://client.holistoqa.com.
 */
const conf: Configuration = {
  misc: {
    showCovid: false,
  },
  layouts: {
    desktopWidth: 1280,
    smallSmartphoneWidth: 390,
  },
  googleTagManager: {
    id: 'GTM-K32LSLW',
  },
  hotJar: {
    id: 3090327,
    snippetVersion: 6,
  },
  facebookPixel: {
    id: 880749375941500,
  },
  riskified: {
    shopName: 'www.holisto.com',
  },

  social: {
    trustpilot: 'https://www.trustpilot.com/evaluate/splittytravel.com',
  },

  inputs: {
    specialRequestMaxLength: 1000,
    phoneMinLength: 6,
    phoneMaxLength: 14,
    basicMaxLength: 100,
    basicMinLength: 2,
  },

  searchBar: {
    searchDateRange: 450,
    maxNights: 30,
    maxAdults: 4,
    maxChildren: 4,
    maxOccupancy: 4,
    defaultOccupancyCount: 1,
    checkinShiftDays: 7,
    defaultAdultsCount: 2,
    maxChildAge: 17,
    minChildAge: 0,
    defaultChildAge: 9,
    maxSuggestions: 20,
    minimalTermLength: 3,
    queriesToKeepCount: 20,
    lengthOfStay: 2,
  },

  googleMap: {
    scale: 17,
    apiKey: 'AIzaSyA0ERNk4ZcIzDs5uGsal-d7r4RzGCOE5Nw',
    hotelListZoomMobile: 10,
    hotelListZoomPC: 10,
    oneHotelZoomPC: 15,
    maxHotelsToDisplay: 50,
  },

  hotelList: {
    itemsPerPage: 20,
    visibleAmenitiesCount: 3,
    maxSpecialProposalsPerHotel: 3,
    maxSpecialProposalsPerHotelMobile: 3,
  },

  hotel: {
    maxTopAmenitiesToDisplay: 6,
  },

  amplitude: {
    apiKey: 'c33bda64c256859cc7952224b9d6083c',
    serverUrl: `${document.location.origin}/amplitude`,
  },

  developers: {
    noRedirectOnError: false,
    showTemporaryElements: false,
    logging: true,
  },
  name: 'production',
  api: {
    endpoint: 'https://client.algotels-services.com',
    urlConfiguration: {
      AUTHORIZE_TRIP_PAYMENT: '/2.0/trips/{encryptedTripId}/authorize',
      CANCEL_TRIP: '/2.0/trips/{encryptedTripId}',
      CREATE_TRACK_HOTEL_OFFER: '/2.0/hotels/offers/track',
      CREATE_TRIP: '/2.0/trips',
      CREATE_TRIP_CHARGE: '/2.0/trips/{encryptedTripId}/charges',
      EMAIL_TRIP_INFO: '/2.0/trips/{encryptedTripId}/emails',
      ENCRYPT_TRIP_ID: '/2.0/trips/{tripId}/encrypt',
      FIND_CAMPAIGN: '/2.0/campaigns',
      FIND_CLIENT_LOCATION: '/2.0/client/location',
      FIND_HOTELS: '/2.0/hotels',
      FIND_PLACES: '/2.0/places',
      FIND_TRANSLATIONS: '/2.0/translations',
      GET_CHECKOUT: '/2.0/checkouts/{checkoutId}',
      GET_TRIP_INSURANCE_QUOTE: '/2.0/checkouts/{checkoutId}/insurance',
      GET_DICTIONARY: '/2.0/dictionary',
      GET_HOTEL_DEALS: '/2.0/hotels/{hotelId}/deals',
      GET_HOTEL_OFFERS: '/2.0/hotels/offers',
      GET_HOTEL_INFO: '/2.0/hotels/{hotelId}/info',
      GET_PLACE: '/2.0/places/{placeId}',
      GET_SERVICE_MAP: '/2.0/services',
      GET_STATUS: '/2.0/status',
      GET_TRIP: '/2.0/trips/{encryptedTripId}',
      GET_TRIP_CANCELLATION_FEE: '/2.0/trips/{encryptedTripId}/cancellation-fee',
      GET_TRIP_PAYMENT_REQUEST: '/2.0/trips/{encryptedTripId}/payment-requests/{paymentRequestId}',
      GET_USER_DETAILS: '/2.0/users/{userId}',
      GET_USER_TRIPS: '/2.0/users/{userId}/trips',
      LOGIN_OAUTH_USER: '/2.0/users/login/oauth',
      LOGIN_USER: '/2.0/users/login',
      LOGOUT_USER: '/2.0/users/{userId}/logout',
      RECOVER_USER_PASSWORD: '/2.0/users/password',
      SIGNUP_OAUTH_USER: '/2.0/users/signup/oauth',
      SIGNUP_USER: '/2.0/users/signup',
      UPDATE_USER_DETAILS: '/2.0/users/{userId}',
      UPDATE_USER_PASSWORD: '/2.0/users/{userId}/password',
      VERIFY_USER: '/2.0/users/{userId}/verify',
      GET_AB_TESTS: '/1.0/abtesting',
    },
  },
  campaign: {
    callCenter: [],
    fallbackName: 'Algotels_Organic_US',
    fallbackRateDisplayModel: RateDisplayModel.Net,
    fallbackPriceRoundingMethod: PriceRoundingMethod.Round,
    tripAdvisorNamePattern: /^Algotels_TripAdvisor_/i,
    findHotelNamePattern: /^Algotels_FindHotel_/i,
    skyscannerNamePattern: /^Algotels_skyscanner_/i,
  },
  location: {
    fallbackCountryCode: 'US',
  },
  currencies,
  localeCurrencySeparators,
  times: {
    abTestsCookieMaxAge: 60 * 60 * 1000,
    showProcessingDialogFrom: 750,
    suggestionsDebounce: 500,
    initiateHotelSearchTimeout: 10000,
    priceOutdatedAlert: 30 * 60 * 1000,
    expiredOfferAlertHotel: 30 * 60 * 1000,
    expiredOfferAlertCheckout: 60 * 60,
    expiredOfferCountdown: 30 * 60,
    listUpdateInterval: 5000,
    currencyRatesMaxAge: 60000,
    campaignMaxAge: 14 * 24 * 3600 * 1000,
    i18nrequestTimeout: 3000,
    filterByNameDebounce: 300,
    filtersWithCheckboxDebounce: 500,
    recentBookingsCheckInMinutes: 120,
    urlCookieMaxAge: 365 * 24 * 3600 * 1000,
    tokenMaxAge: 7 * 24 * 3600 * 1000,
    userIdMaxAge: 7 * 24 * 3600 * 1000,
    locationMaxAge: 3600 * 1000,
    offsetTimezoneHours: 12,
    mapBoundsDebounce: 100,
  },
  i18n: {
    packs: ['Algotels'],
    debug: false,
    loadNotConfirmed: true,
    fallbackLanguage: 'EN',
    languages: supportLanguages,
  },
  oauth: {
    googleAppId: '67897298395-ccsrvdp93ksgrg4iu0gkk5ojug71i795.apps.googleusercontent.com',
    facebookAppId: '454262956185014',
  },
  agent: {
    domain: 'agent.gosplitty.com',
    campaignName: 'GoSplitty_CallCenter_US',
  },
  vipService: {
    endpoint: 'https://forex.holisto.com',
    currency: CurrencyCode.USD,
    fee: 4.99,
  },
  trivagoSettings: {
    campaignPattern: /^Algotels_Trivago_/i,
    campaignBookAndGoPattern: /^Algotels_Trivago_BnG_/i,
    maxRoomPreviewCount: 3,
  },
  metaSearch: {
    organicCampaignPattern: /^Algotels_Organic_/i,
    enabled: true,
  },
  mediaAlpha: {
    organicCampaignPattern: /^Algotels_Organic_/i,
    placements: {
      organic: [
        {
          id: 'RqdvUMj0lUjeEfTudbvP0MjA2WKGLg',
          target: 'ORGANIC_LEAVE_BEHIND',
        },
      ],
      search: [
        {
          id: 'VNVpM7Uwb6PlgUPNd5pyCDxv_KI-DA',
          target: 'SEARCH_LEAVE_BEHIND',
        },
      ],
    },
    mobilePlacements: {
      organic: [],
      search: [],
    },
  },
  canonicalLinks: {
    hotelPage: '/hotels/united-states-of-america_ca-_san-francisco/133991_hotel-adagio-autograph-collection',
    destinationsPage: '/destinations/united-states-of-america_nevada_las-vegas?placeId=R2008',
  },
};

export default conf;
