import React, { useContext } from 'react';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/home/BenefitSection.styled';
import { HomeAboutPanel } from 'components/home/HomeAboutPanel/HomeAboutPanel';
import { HomePriceGuaranteePanel } from 'components/home/HomePriceGuaranteePanel/HomePriceGuaranteePanel';
import LeadingHotelPanel from 'components/home/LeadingHotelPanel/LeadingHotelPanel';
import { SavingsCounterPanel } from 'components/home/SavingsCounterPanel/SavingsCounterPanel';

const BenefitSection: React.FC = () => {
  const { isMobileLayout } = useContext(LayoutContext);

  return (
    <Styled.Container>
      <Styled.Grid>
        <Styled.Section gridArea="price">
          <HomePriceGuaranteePanel />
        </Styled.Section>
        <Styled.Section gridArea="awards">
          <LeadingHotelPanel />
        </Styled.Section>
        <Styled.Section gridArea="counter">
          <SavingsCounterPanel />
        </Styled.Section>
        {!isMobileLayout && (
          <Styled.Section gridArea="mission">
            <HomeAboutPanel />
          </Styled.Section>
        )}
      </Styled.Grid>
    </Styled.Container>
  );
};

export default BenefitSection;
