/* eslint-disable no-magic-numbers */
import { baseFontSize, rem } from 'style/functions';

export { baseFontSize };

export const fontSizeSmall = rem(12);
export const fontSizeLarge = rem(16);
export const fontSizeHeadingS = rem(14);
export const fontSizeHeadingM = rem(20);
export const fontSizeHeadingL = rem(20);
export const fontSizeHeadingLarge = rem(24);
export const fontSizeHeadingXL = rem(20);
export const fontSizeHeadingXLarge = rem(30);
export const fontSizeMeta = rem(11);
export const fontSizeH1Large = rem(48);
export const lineHeightLarge = 1.5;
export const lineHeightSmall = rem(20);
export const lineHeightHeadingS = 1.5;
export const lineHeightHeadingM = 1.1;
export const lineHeightHeadingL = 1.3;
export const lineHeightHeadingXL = 1;
export const lineHeightMeta = 1.4;

export const tinyText = rem(10);
export const brandColor = '#ec018c';
export const brandColorLight = '#ec018c0d';
export const textColorMuted = '#8C8C8C';
export const textColorEmphasis = '#595959';
export const textColorSecondary = '#1890FF';
export const textColorSuccess = '#52C41A';
export const textColorDanger = '#F5222D';
export const primaryBackground = '#FFF0F6';
export const successBackground = '#F6FFED';
export const dangerColor = '#F90F35';
export const errorColor = '#f0506e';
export const purple = '#722ED1';

export const dangerBackground = '#FFF1F0';

export const brandColorDark = '#B10169';
export const brandColorDarker = '#950057';
export const brandColorDarkFilter =
  'invert(19%) sepia(99%) saturate(7287%) hue-rotate(316deg) brightness(71%) contrast(104%)';
export const borderColor = '#CCCCCC';
export const borderColorLight = '#D9D9D9';
export const borderColorLightest = '#E8E8E8';
export const borderGrey = '#e6e6e6';
export const borderLightViolet = '#d3adf7';
export const borderRadius = rem(4);
export const lightGray = '#EFEFEF';
export const lightGreyTranslucent = '#efefef5e';
export const light = '#fefefe';

export const black = '#000000';
export const black2 = '#383838';
export const black3 = '#1c1c1b';
export const middleGray = '#333333';
export const white = '#FFFFFF';
export const gray = '#999999';
export const gray2 = '#938f8f';
export const darkGray = '#666666';
export const veryLightGray = '#FAFAFA';
export const backgroundGray = '#F2F2F2';
export const veryDarkGray = '#343434';
export const pink = '#f364a2';
export const lightPink = '#ffadd2';
export const brown = '#620042';
export const brown2 = '#6e0041';
export const brown3 = '#750045';
export const violet = '#be98fe';
export const darkViolet = '#9c48c5';
export const orange = '#ff9f1e';
export const lightYellow = '#fff4d9';
export const yellow = '#f7d070';

export const mutedColor = '#B7B7B7';
export const tripAdvisorColor = '#01aa6c';
export const textColor = '#3B3B3B';

export const formCheckboxImage = '/images/checked-checkbox.svg';

export const LoaderBackgroundColor = '#FBFBFB';
export const disabledBackgroundGray = '#E5E5E5';

export const overlayBackground1 = '#2626261a';
export const overlayBackground2 = '#FFFFFFCC';
export const boxShadowColor = '#b2b2b2';
export const boxShadowLightGray = '#dcdfe6';
export const boxShadowLightGray2 = '#ced6e0';
export const boxShadowPink = '#ec018c33';
export const boxShadowLightPink = '#ec018c1f';
export const textShadow = '#00000014';
export const pinkHoveredBackgroundColor = '#d3017d';
export const modalBackgroundColor = '#F8F8F8';
export const notificationSuccess = '#B7EB8F';
export const notificationError = '#FFA39E';
export const notificationWarn = '#FFE58F';
export const notificationWarnBackground = '#FFFBE6';
export const notificationInfoBackground = '#E6F7FF';

export const green = '#226600';
export const lightGreen = '#e3f8cd';
export const dirtyGreen = '#276749';
export const red2 = '#e12d39';
export const footerBackgroundColor = '#34001F';

export const selectOptionColor = '#444444';

export const selectedFocusCheckboxColor = '#b9016e';

export const mutedCheckboxBorderColor = '#c4c4c4';

export const activeDropdownPeoplePickerBackgroundColor = '#dddddd';

export const backgroundSelection = '#39f';
export const backgroundLightViolet = '#f9f0ff';
export const backgroundLightViolet2 = '#f2ebfe';
export const backgroundLightViolet3 = '#e5d5ff';
export const backgroundLightGrey = '#eee';
export const backgroundMediumGray = '#d3d3d3';
export const backgroundGrayBlue = '#c9dde8';
export const backgroundVeryLightBlue = '#f3f8ff';
export const backgroundLightPink = '#ffe3ec';
export const backgroundPink = '#ffd6d6';
export const backgroundLightPeachPink = '#ffebeb';
export const backgroundVeryLight = '#fff8fb';
export const backgroundVeryLight2 = '#f7fafc';
export const superDarkGrey = '#191919';
export const charcoalGrey = '#4c4f50';
export const battleshipGrey = '#6f787a';
export const radioButtonBorder = '#c5cad1';

export const insuranceBorderColor = '#e3e3e3';
export const insuranceRadioButtonBorder = '#c5cad1';
export const insuranceMutedText = '#73777c';
export const insurancePromoBG = '#a22367';
