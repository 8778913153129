import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import { SearchBarConf } from 'environments/configuration';
import { env } from 'environments/environment';
import { validateNumber } from 'utils/validation';

export function defaultOccupancy(conf: SearchBarConf): RoomOccupancy[] {
  return Array(conf.defaultOccupancyCount).fill({ adults: conf.defaultAdultsCount, children: [] });
}

export const createOccupancyOrDefault = (adults?: number, children?: number[]): RoomOccupancy => ({
  adults: adults || env.searchBar.defaultAdultsCount,
  children: children || [],
});

export function occupancyAreEqual(o1?: RoomOccupancy[], o2?: RoomOccupancy[]): boolean {
  if (o1 && o2) {
    return (
      o1.length === o2.length &&
      o1.every((v, i) => {
        const children1 = v.children || [];
        const children2 = o2[i].children || [];

        return (
          v.adults === o2[i].adults &&
          children1.length === children2.length &&
          children2.every((age, idx) => age === children1[idx])
        );
      })
    );
  }

  return !o1 && !o2;
}

export function sumGuests(occupancy: RoomOccupancy[]): number {
  return occupancy.reduce((guests, o) => guests + o.adults + (o.children || []).length, 0);
}

export function sumAdults(occupancy: RoomOccupancy[]): number {
  return occupancy.reduce((adults, o) => adults + o.adults, 0);
}

export function sumChildren(occupancy: RoomOccupancy[]): number {
  return occupancy.reduce((children, o) => children + (o.children || []).length, 0);
}

export const validateOccupancy = (occupancy: RoomOccupancy[] | undefined): occupancy is RoomOccupancy[] =>
  !!occupancy &&
  Array.isArray(occupancy) &&
  validateNumber(occupancy.length, 1, env.searchBar.maxOccupancy) &&
  occupancy.every(
    (o) =>
      validateNumber(o.adults, 1, env.searchBar.maxAdults) &&
      (o.children || []).length <= env.searchBar.maxChildren &&
      (o.children || []).every((age) => validateNumber(age, env.searchBar.minChildAge, env.searchBar.maxChildAge)),
  );

export const adultChanger = (index: number, increase: boolean, occupancy: RoomOccupancy[]): RoomOccupancy[] => {
  if (increase) {
    if (occupancy[index].adults < env.searchBar.maxAdults) {
      return occupancy.map((o, idx) => (idx === index ? { ...o, adults: o.adults + 1 } : o));
    }
  } else if (occupancy[index].adults > 1) {
    return occupancy.map((o, idx) => (idx === index ? { ...o, adults: o.adults - 1 } : o));
  }

  return occupancy;
};

export const addOccupancy = (occupancy: RoomOccupancy[]): RoomOccupancy[] => {
  if (occupancy.length < env.searchBar.maxOccupancy) {
    return [...occupancy, createOccupancyOrDefault(env.searchBar.defaultAdultsCount)];
  }

  return occupancy;
};

export const childrenChanger = (index: number, increase: boolean, occupancy: RoomOccupancy[]): RoomOccupancy[] => {
  const children = occupancy[index].children || [];

  if (increase) {
    if (children.length < env.searchBar.maxChildren) {
      return occupancy.map((o, idx) =>
        idx === index
          ? {
              ...o,
              children: [...children, env.searchBar.defaultChildAge],
            }
          : o,
      );
    }
  } else if (children.length > 0) {
    return occupancy.map((o, idx) =>
      idx === index
        ? {
            ...o,
            children: children.slice(0, -1),
          }
        : o,
    );
  }

  return occupancy;
};

export const removeOccupancy = (index: number, occupancy: RoomOccupancy[]) => {
  if (index > 0) {
    return [...occupancy.slice(0, index), ...occupancy.slice(index + 1)];
  }

  return occupancy;
};

export const setAge = (index: number, childIndex: number, newAge: string, occupancy: RoomOccupancy[]) => {
  const newChildren = (occupancy[index].children || []).map((age, i) => (i === childIndex ? +newAge : age));

  return occupancy.map((o, i) => (i === index ? { ...o, children: newChildren } : o));
};
