import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSession } from 'atoms/hooks/useSession';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { BOOKINGS_ROUTE } from 'routeList';

interface BookingLinkProps {
  toSignInClick: () => void;
  toBookingClick?: () => void;
}

const BookingLink: React.FC<BookingLinkProps> = ({ toSignInClick, toBookingClick }) => {
  const { session } = useSession();
  const history = useHistory();
  const [t] = useTranslation();
  const text = useMemo(() => t('common.menu.my-booking', 'Booking'), [t]);

  return (
    <a
      onClick={(e) => {
        hotjarEvent(HotjarEvent.MyBookingClicked);
        e.preventDefault();
        if (toBookingClick) {
          toBookingClick();
        }
        if (!session.hasUser) {
          toSignInClick();
        } else {
          history.push(BOOKINGS_ROUTE);
        }
      }}
      href="/"
    >
      {text}
    </a>
  );
};

export default BookingLink;
