import { Place } from 'common/backend/api/place/placeModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import { parseDate } from 'common/utils/date';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { useSearchState } from 'atoms/hooks/useSearchState';
import { useSearchStateCallbacks } from 'atoms/hooks/useSearchStateCallbacks';
import { ToastType } from 'components/common/Toast/Toast.types';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { processError } from 'errors/errorUtils';
import { getPeriodOverMaxNightsMessage, isPeriodOverMaxNights } from 'utils/dateUtils';

interface Context {
  submitForm: (disableToastMessage?: boolean, occupancy?: RoomOccupancy[]) => void;
  onSearchTermChange: (s: string) => void;
  onSuggestion: (d: Place | undefined) => void;
  onAutoSuggestion: (d: Place | undefined) => void;
  destinationTerm: string;
  place: Place | undefined;
  setDates: (s: string, e: string) => void;
  checkin: string | undefined;
  checkout: string | undefined;
  occupancy: RoomOccupancy[];
  setOccupancy: React.Dispatch<React.SetStateAction<RoomOccupancy[]>>;
}

export const SearchFormContext = React.createContext<Context>({
  submitForm: () => undefined,
  occupancy: [],
  checkin: '',
  checkout: '',
  place: undefined,
  destinationTerm: '',
  onSearchTermChange: () => undefined,
  onSuggestion: () => undefined,
  onAutoSuggestion: () => undefined,
  setOccupancy: () => undefined,
  setDates: () => undefined,
});

export const SearchFormProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [t] = useTranslation();
  const setToast = useContext(ToastContext);
  const {
    occupancy: occupancyExternal,
    checkin: checkinExternal,
    checkout: checkoutExternal,
    destination: destinationExternal,
  } = useSearchState();
  const { submit } = useSearchStateCallbacks();

  const [place, setPlace] = useState(destinationExternal);
  const [autoPlace, setAutoPlace] = useState<Place | undefined>(undefined);

  const [destinationTerm, setDestinationTerm] = useState(place?.name || '');

  const [occupancy, setOccupancy] = useState(occupancyExternal);
  const [checkin, setCheckin] = useState(checkinExternal);
  const [checkout, setCheckout] = useState(checkoutExternal);
  const setDates = useCallback((_checkin: string, _checkout: string) => {
    setCheckin(_checkin);
    setCheckout(_checkout);
  }, []);

  useEffect(() => {
    setPlace(destinationExternal);
  }, [destinationExternal]);

  useEffect(() => {
    setOccupancy(occupancyExternal);
  }, [occupancyExternal]);

  useEffect(() => {
    setCheckin(checkinExternal);
  }, [checkinExternal]);

  useEffect(() => {
    setCheckout(checkoutExternal);
  }, [checkoutExternal]);

  useEffect(() => {
    setDestinationTerm(place?.name || '');
  }, [place]);

  const submitForm = useCallback(
    (disableToastMessage?: boolean, currentOccupancy: RoomOccupancy[] = occupancy) => {
      const currentPlace = place || autoPlace;

      if (!currentPlace) {
        const term = destinationTerm.trim();

        if (term.length === 0) {
          setToast(t('index.search.not-found', 'Please enter destination!'), ToastType.Warn);
        } else if (term.length < env.searchBar.minimalTermLength) {
          setToast(t('search-bar.destination-too-short', 'Please type more characters'), ToastType.Warn);
        } else {
          const message = t(
            'errors.hotels.destination-missing',
            'Destination not supported. We keep adding new destinations every day!',
          );
          const error = {
            clientCodes: [ClientErrorCode.UnsupportedDestination],
            action: () => setToast(message, ToastType.Error),
          };

          processError(new ClientError(ClientErrorCode.UnsupportedDestination, [message]), {
            known: [error],
            default: error,
          });
        }

        return;
      }

      if (isPeriodOverMaxNights(parseDate(checkin), parseDate(checkout))) {
        if (!disableToastMessage) {
          setToast(getPeriodOverMaxNightsMessage(t), ToastType.Error);
        }

        return;
      }

      submit(currentPlace, undefined, checkin, checkout, currentOccupancy);
    },
    [place, autoPlace, submit, checkin, checkout, occupancy, destinationTerm, setToast, t],
  );

  return (
    <SearchFormContext.Provider
      value={{
        occupancy,
        setOccupancy,
        submitForm,
        checkin,
        checkout,
        setDates,
        place,
        destinationTerm,
        onSuggestion: setPlace,
        onAutoSuggestion: setAutoPlace,
        onSearchTermChange: setDestinationTerm,
      }}
    >
      {children}
    </SearchFormContext.Provider>
  );
};
