import { useAtomValue } from 'jotai';
import { useContext, useMemo } from 'react';
import { campaignAtom, campaignIsReadyAtom } from 'atoms/campaignAtom';
import { Campaign } from 'backend/api/general/generalModel';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import cookieBasedCampaignStorage from 'utils/storage/cookie/CookieBasedCampaignStorage';

export const useCampaign = () => {
  const { isTrivago, isTrivagoBookAndGo } = useContext(TrivagoContext);
  const campaign = useAtomValue<Campaign | undefined>(campaignAtom);
  const ready = useAtomValue(campaignIsReadyAtom);

  const origin = useMemo(
    () =>
      campaign?.name ? campaign.name === cookieBasedCampaignStorage.get(isTrivago, isTrivagoBookAndGo)?.name : false,
    [campaign?.name, isTrivago, isTrivagoBookAndGo],
  );

  return { campaign, loading: !ready, origin };
};
