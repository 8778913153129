import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import { unescapeHTML } from 'utils/stringUtils';

interface SanitizedInnerHTMLProps {
  tag: string;
  rawHTML?: string;
  className?: string;
  style?: Record<string, string>;
}

const purify = DOMPurify(window);

purify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

export const SanitizedHTML = ({ tag, rawHTML, className, style }: SanitizedInnerHTMLProps) => {
  return useMemo(() => {
    return React.createElement(tag, {
      dangerouslySetInnerHTML: { __html: purify.sanitize(unescapeHTML(rawHTML)) },
      className,
      style,
    });
  }, [className, rawHTML, style, tag]);
};
