import styled from 'styled-components';
import {
  grey2,
  battleshipGrey,
  disabledInputBackgroundColor,
  lightRoyalBlue,
  strongPink,
  superDarkGrey,
  violet,
} from 'style/variables';

const Header = styled.div`
  font-size: 32px;
  line-height: normal;
  font-weight: 600;
  color: ${superDarkGrey};
  text-align: center;
  margin-bottom: 40px;
`;

const Field = styled.div`
  width: 100%;

  & + & {
    margin-top: 20px;
  }
`;

const Or = styled.div`
  padding: 24px 0 16px;
`;

const PasswordForgot = styled.div`
  padding: 28px 0;
`;

const OauthButton = styled.button`
  box-sizing: border-box;
  border-radius: 3px;
  border: 0;
  cursor: pointer;

  text-align: center;
  font-weight: bold;
  font-size: 20px;

  &:focus {
    outline: 0;
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 50px;
  max-width: calc(100% - 4px);
  margin: 2px;
  color: ${superDarkGrey};

  background-clip: padding-box;

  &:disabled {
    background-color: ${disabledInputBackgroundColor};
    color: ${battleshipGrey};

    &::after {
      background: ${grey2};
    }

    img {
      opacity: 0.5;
    }
  }

  &::after {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;

    background: linear-gradient(to right, ${lightRoyalBlue} 0%, ${violet} 97%);
    content: '';
    z-index: -1;
    border-radius: 5px;
  }

  &:hover {
    &::after {
      background: linear-gradient(to right, ${lightRoyalBlue} 0%, ${strongPink} 97%);
    }
  }

  img {
    position: absolute;
    height: 24px;
    left: 14px;
  }

  &:disabled {
    border-color: ${grey2};
    background: ${disabledInputBackgroundColor};

    &::after {
      background: ${grey2};
    }
  }

  & + & {
    margin-top: 18px;
  }

  @media (min-width: 480px) {
    white-space: nowrap;
  }
`;

export default {
  Header,
  OauthButton,
  Field,
  Or,
  PasswordForgot,
};
