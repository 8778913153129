/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import expandIcon from 'assets/images/icons/select-expand-icon.svg';
import arrowIcon from 'assets/images/icons/trivagoV2/select-arrow-icon.svg';
import SelectInput, { Group, Key } from 'components/common/SelectInput/SelectInput';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import Styled from 'components/header-footer/CurrencySelector/InternalCurrencySelector.styled';
import Icon from 'components/widgets/Icon';
import { CurrencyGroups, getCurrencyByCodeOrDefault, getCurrencyText, TOP_CURRENCIES } from 'utils/currencyUtils';
import useTranslatedCurrencyList from 'utils/translate/useTranslatedCurrencyList';

/**
 * Shows current currency.
 * Clicking on it opens dropdown where the currency can be changed.
 * (the component only calls it and does nothing else)
 */
interface InternalCurrencySelectorProps {
  isInDarkHeader?: boolean;
  currencyCode: string;
  onCurrencyChange: (value: Key) => void;
}

export const InternalCurrencySelector: React.FC<InternalCurrencySelectorProps> = ({
  isInDarkHeader,
  currencyCode,
  onCurrencyChange,
}) => {
  const { t } = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { isTrivago } = useContext(TrivagoContext);
  const currencies = useTranslatedCurrencyList();

  const selectedOptionText = useMemo(
    () => getCurrencyText(getCurrencyByCodeOrDefault(currencyCode), ' '),
    [currencyCode],
  );

  const currencyGroups = useMemo(() => {
    const topCurrencies: Group = {
      key: CurrencyGroups.topCurrencies,
      name: t('common.currency.top-currencies', 'Top currencies'),
      items: TOP_CURRENCIES.map((topCurrencyCode) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const currency = currencies.find((cur) => cur.code === topCurrencyCode)!;

        return {
          value: `${currency.code} - ${currency.name}`,
          key: currency.code,
        };
      }),
    };
    const allCurrencies: Group = {
      key: CurrencyGroups.allCurrencies,
      name: t('common.currency.all-currencies', 'All currencies'),
      items: currencies
        .sort((cur1, cur2) => cur1.code.localeCompare(cur2.code))
        .map((cur) => ({
          value: `${cur.code} - ${cur.name}`,
          key: cur.code,
        })),
    };

    return [topCurrencies, allCurrencies];
  }, [currencies, t]);

  const trivagoDisplayValue = useMemo(
    () => (
      <>
        <Styled.TrivagoDisplayValue invertColor={isInDarkHeader && !isMobileLayout}>
          {getCurrencyText(getCurrencyByCodeOrDefault(currencyCode), ' ')}
        </Styled.TrivagoDisplayValue>
        <Styled.ExpandIcon>
          <Icon src={arrowIcon} color={isInDarkHeader && !isMobileLayout ? 'white' : 'black'} width={17} height={17} />
        </Styled.ExpandIcon>
      </>
    ),
    [currencyCode, isInDarkHeader, isMobileLayout],
  );

  if (isMobileLayout) {
    if (isTrivago) {
      const label = <Trans i18nKey={'trivago.v2.burger-menu.currency'}>Currency</Trans>;

      return (
        <Styled.TrivagoMobileContainer>
          <SelectInput
            testId="currency-selector"
            label={label}
            id="id-currency-dropdown"
            selectedKey={currencyCode}
            displayValue={trivagoDisplayValue}
            groups={currencyGroups}
            onSelect={onCurrencyChange}
          />
        </Styled.TrivagoMobileContainer>
      );
    }

    return (
      <div className="flex flex-between">
        <span className="margin-auto-vertical">Currency</span>

        <div className="notranslate">
          <SelectInput
            testId="currency-selector"
            id="id-currency-dropdown"
            selectedKey={currencyCode}
            displayValue={
              <Styled.DisplayValue>
                {selectedOptionText} <Styled.DropIcon src={expandIcon} />
              </Styled.DisplayValue>
            }
            groups={currencyGroups}
            onSelect={onCurrencyChange}
          />
        </div>
      </div>
    );
  }

  if (isTrivago) {
    return (
      <Styled.HeaderItem>
        <SelectInput
          testId="currency-selector"
          id="id-currency-dropdown"
          selectedKey={currencyCode}
          displayValue={trivagoDisplayValue}
          groups={currencyGroups}
          onSelect={onCurrencyChange}
        />
      </Styled.HeaderItem>
    );
  }

  return (
    <Styled.SelectWrapper>
      <SelectInput
        testId="currency-selector"
        id="id-currency-dropdown"
        selectedKey={currencyCode}
        displayValue={<Styled.DisplayValue>{selectedOptionText}</Styled.DisplayValue>}
        groups={currencyGroups}
        onSelect={onCurrencyChange}
      />
    </Styled.SelectWrapper>
  );
};
