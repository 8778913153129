import styled from 'styled-components';
import { superDarkGrey } from 'style/variables';
import HomeViewStyled from 'views/home/HomeView.styled';

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${superDarkGrey};
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -19px;
  width: 100%;
`;

const Item = styled.div`
  width: 100%;
  margin-top: 19px;
`;

export default {
  Title,
  Content,
  Item,
  Container: HomeViewStyled.Container,
};
