import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Main = styled.main`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
`;

export const MobilePageLayoutStyled = {
  Root,
  Main,
};
