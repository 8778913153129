import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { restoreUserDetailsAtom, sessionForceUpdateFlagAtom } from 'atoms/sessionAtom';

export const useWatchSession = () => {
  const restoreUserDetails = useSetAtom(restoreUserDetailsAtom);
  const [dirty, setDirty] = useAtom(sessionForceUpdateFlagAtom);

  useEffect(() => {
    if (dirty) {
      restoreUserDetails();
      setDirty(false);
    }
  }, [dirty, restoreUserDetails, setDirty]);

  useEffect(() => {
    restoreUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
