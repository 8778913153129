/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

export default {
  Container,
};
