import React, { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo/logo-white-text.svg';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/header-footer/TrivagoPageFooter.styled';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import CareersLink from 'components/mobile/mobileLinks/CareersLink';
import { ABOUTUS_ROUTE, FAQ_ROUTE, PRICE_GUARANTEE_ROUTE, PRIVACY_ROUTE, TERMS_ROUTE } from 'routeList';

const TrivagoPageFooter: React.FC = () => {
  const contactTranslations = useContactTranslations();
  const { isMobileLayout } = useContext(LayoutContext);

  const topRowContent = useMemo(
    () => (
      <>
        <Styled.Section>
          <Styled.SectionLabel>
            <Trans i18nKey="footer.sections.legal">Legal</Trans>
          </Styled.SectionLabel>
          <Styled.SectionLink>
            <Link to={TERMS_ROUTE}>
              <Trans i18nKey="footer.terms">Terms and Conditions</Trans>
            </Link>
          </Styled.SectionLink>
          <Styled.SectionLink>
            <Link to={PRIVACY_ROUTE}>
              <Trans i18nKey="footer.privacy">Privacy Policy</Trans>
            </Link>
          </Styled.SectionLink>
        </Styled.Section>
        <Styled.Section>
          <Styled.SectionLabelCapitalized>
            <Trans i18nKey="footer.sections.company">Company</Trans>
          </Styled.SectionLabelCapitalized>
          <Styled.SectionLink>
            <Link to={ABOUTUS_ROUTE}>
              <Trans i18nKey="footer.about">About Us</Trans>
            </Link>
          </Styled.SectionLink>
          <Styled.SectionLink>
            <CareersLink />
          </Styled.SectionLink>
        </Styled.Section>
      </>
    ),
    [],
  );

  const bottomRowContent = useMemo(
    () => (
      <>
        <Styled.Section>
          <Styled.SectionLabel>
            <Trans i18nKey="footer.sections.support">Support</Trans>
          </Styled.SectionLabel>
          <Styled.SectionLink>
            <Link to={FAQ_ROUTE}>
              <Trans i18nKey="footer.faq">FAQ</Trans>
            </Link>
          </Styled.SectionLink>
          <Styled.SectionLink>
            <Link to={PRICE_GUARANTEE_ROUTE}>
              <Trans i18nKey="footer.price-guarantee">Price Guarantee</Trans>
            </Link>
          </Styled.SectionLink>
        </Styled.Section>
        <Styled.Section>
          <Styled.SectionLabel>
            <Trans i18nKey="footer.sections.connect">CONNECT WITH US</Trans>
          </Styled.SectionLabel>
          <Styled.SectionLink>
            <a href={`tel:${contactTranslations.phone}`}>{contactTranslations.phoneTrivago}</a>
          </Styled.SectionLink>
          <Styled.SectionLink>
            <a href={`mailto:${contactTranslations.partnerWithUs}`}>
              <Trans i18nKey="footer.partner-with-us">Partner With Us</Trans>
            </a>
          </Styled.SectionLink>

          <Styled.SectionLink>
            <a href={`mailto:${contactTranslations.emailUs}`}>
              <Trans i18nKey="footer.email-us">Email Us</Trans>
            </a>
          </Styled.SectionLink>
        </Styled.Section>
      </>
    ),
    [
      contactTranslations.emailUs,
      contactTranslations.partnerWithUs,
      contactTranslations.phone,
      contactTranslations.phoneTrivago,
    ],
  );

  return (
    <Styled.Footer>
      <div className="container">
        {isMobileLayout && (
          <>
            <Styled.Block>{topRowContent}</Styled.Block>
            <Styled.Block>{bottomRowContent}</Styled.Block>
          </>
        )}
        {!isMobileLayout && (
          <Styled.Block>
            {topRowContent}
            {bottomRowContent}
          </Styled.Block>
        )}
      </div>
      <Styled.Additional>
        <img src={logo} alt={'Algotels Logo'} />
        <Trans
          i18nKey="footer.copyright"
          defaults="© {year} Holisto LTD. All Rights Reserved."
          values={{ year: new Date().getFullYear() }}
        />
      </Styled.Additional>
    </Styled.Footer>
  );
};

export default TrivagoPageFooter;
