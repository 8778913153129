/**
 * @category Search Form
 * @packageDocumentation
 */
import React, { useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import minusIcon from 'assets/images/icons/searchbar/feather-minus-square.svg';
import plusIcon from 'assets/images/icons/searchbar/feather-plus-square.svg';
import trivagoMinusIcon from 'assets/images/icons/trivagoV2/minus-button-icon.svg';
import trivagoPlusIcon from 'assets/images/icons/trivagoV2/plus-button-icon.svg';
import { Text } from 'components/common/Text/Text';
import { TextWeight } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import Styled from 'components/searchForm/PeoplePicker/PeoplePicker.styled';
import Icon from 'components/widgets/Icon';
import { env } from 'environments/environment';

export interface PeoplePickerPrp {
  roomNumber: number;
  adults: number;
  adultChanger: (increase: boolean) => void;
  childrenAges: number[];
  childrenChanger: (increase: boolean) => void;
  setAge: (index: number, age: string) => void;
}

/**
 * Allows to select a count of people.
 */

const PeoplePicker: React.FC<PeoplePickerPrp> = ({
  roomNumber,
  adults,
  adultChanger,
  childrenAges,
  childrenChanger,
  setAge,
}) => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);

  const { isTrivago } = useContext(TrivagoContext);

  const getOptions = useCallback(
    (index: number) =>
      [...Array(env.searchBar.maxChildAge - env.searchBar.minChildAge + 1)].map((_, j) => (
        <option
          key={['occupancy', roomNumber, 'child', index, 'option', j].join('-')}
          value={env.searchBar.minChildAge + j}
        >
          {isTrivago &&
            t('trivago.v2.search-bar.occupancy-picker.years-old', '{ age, plural, =0 {Age <1} other {Age #} }', {
              age: env.searchBar.minChildAge + j,
            })}
          {!isTrivago &&
            t('search-bar.years-old', '{ age, plural, =0 {<1 year old} =1 {1 year old} other {# years old} }', {
              age: env.searchBar.minChildAge + j,
            })}
        </option>
      )),
    [isTrivago, roomNumber, t],
  );

  if (isTrivago) {
    return (
      <Styled.Content removeBorderBottom={isMobileLayout && roomNumber === env.searchBar.maxOccupancy - 1}>
        <Styled.Row>
          <Styled.RowLabel>
            <Trans i18nKey="search-bar.adults">Adults</Trans>
          </Styled.RowLabel>
          <Styled.Counter>
            <Styled.TrivagoButton disabled={adults <= 1} onClick={() => adultChanger(false)}>
              <img src={trivagoMinusIcon} alt={'less'} />
            </Styled.TrivagoButton>
            {adults}
            <Styled.TrivagoButton disabled={adults >= env.searchBar.maxAdults} onClick={() => adultChanger(true)}>
              <img src={trivagoPlusIcon} alt={'more'} />
            </Styled.TrivagoButton>
          </Styled.Counter>
        </Styled.Row>
        <Styled.Row>
          <Styled.LabelBlock>
            <Styled.RowLabel>
              <Trans i18nKey="search-bar.children">Children</Trans>
            </Styled.RowLabel>
            <Styled.SubLabel>
              <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.children-age-range'}>(0-17 years old)</Trans>
            </Styled.SubLabel>
          </Styled.LabelBlock>
          <Styled.Counter>
            <Styled.TrivagoButton disabled={childrenAges.length <= 0} onClick={() => childrenChanger(false)}>
              <img src={trivagoMinusIcon} alt={'less'} />
            </Styled.TrivagoButton>
            <span>{childrenAges.length}</span>
            <Styled.TrivagoButton
              disabled={childrenAges.length >= env.searchBar.maxChildren}
              onClick={() => childrenChanger(true)}
            >
              <img src={trivagoPlusIcon} alt={'more'} />
            </Styled.TrivagoButton>
          </Styled.Counter>
        </Styled.Row>
        <Styled.Children>
          {childrenAges.length > 0 && (
            <Styled.SelectLabel>
              <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.childrens-age'}>Children’s age</Trans>
            </Styled.SelectLabel>
          )}
          <Styled.Selects>
            {childrenAges.map((age, index) => (
              <Styled.SelectOccupancy
                key={['occupancy', roomNumber, 'child', index].join('-')}
                value={age}
                onChange={(e) => setAge(index, e.target.value)}
              >
                {getOptions(index)}
              </Styled.SelectOccupancy>
            ))}
          </Styled.Selects>
        </Styled.Children>
      </Styled.Content>
    );
  }

  return (
    <>
      <Text weight={TextWeight.SemiBold} tag="div" className="flex flex-middle flex-between width-1-1">
        <Styled.Text className="width-1-2">
          <Trans i18nKey="search-bar.adults">Adults</Trans>
        </Styled.Text>
        <div className="width-1-2 flex flex-middle flex-between" style={{ maxWidth: '8rem' }}>
          <Styled.Button type="button" onClick={() => adultChanger(false)}>
            <Icon src={minusIcon} />
          </Styled.Button>
          <Styled.OccupancyNumber>{adults}</Styled.OccupancyNumber>
          <Styled.Button type="button" onClick={() => adultChanger(true)}>
            <Icon src={plusIcon} />
          </Styled.Button>
        </div>
      </Text>

      <hr className="margin-medium-top margin-medium-bottom" />

      <Text weight={TextWeight.SemiBold} tag="div" className="flex flex-middle flex-between width-1-1">
        <Styled.Text className="width-1-2">
          <Trans i18nKey="search-bar.children">Children</Trans>
        </Styled.Text>
        <div className="width-1-2 flex flex-middle flex-between" style={{ maxWidth: '8rem' }}>
          <Styled.Button tabIndex={0} type="button" onClick={() => childrenChanger(false)}>
            <Icon src={minusIcon} />
          </Styled.Button>
          <Styled.OccupancyNumber>{childrenAges.length}</Styled.OccupancyNumber>
          <Styled.Button tabIndex={0} type="button" onClick={() => childrenChanger(true)}>
            <Icon src={plusIcon} />
          </Styled.Button>
        </div>
      </Text>

      {childrenAges.length > 0 && <hr className="margin-medium-top margin-medium-bottom" />}

      {
        /* eslint-disable react/no-array-index-key */
        childrenAges.map((age, index) => (
          <div key={index} className={`flex flex-middle${index > 0 ? ' margin-medium-top' : ''}`}>
            <div className="width-1-2 margin-small-right">
              <Trans i18nKey="search-bar.child-age" defaults="Child {num} Age" values={{ num: index + 1 }} />
            </div>
            <div className="width-1-2 position-relative flex flex-right">
              <Styled.SelectChildren
                value={age}
                onChange={(e) => {
                  setAge(index, e.target.value);
                }}
              >
                {getOptions(index)}
              </Styled.SelectChildren>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default PeoplePicker;
