import { Place } from 'common/backend/api/place/placeModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import {
  backDestinationsUrlAtom,
  checkinFromStorageAtom,
  checkoutCheckinInnerAtom,
  checkoutFromStorageAtom,
  destinationAtom,
  occupancyStorageAtom,
  placeIdAtom,
} from 'atoms/searchStateAtoms';
import { SEARCH_OCCUPANCY } from 'backend/localStorageKeys';
import useSearchFormCallback from 'components/searchForm/useSearchFormCallback';
import { routes } from 'routeList';
import { shiftDays, stringifyDate } from 'utils/dateUtils';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';
import { occupancyAreEqual } from 'utils/occupancyUtils';
import useLocalStorage from 'utils/useLocalStorage';

export const useSearchStateCallbacks = () => {
  const { pathname } = useLocation();
  const [placeId, setPlaceId] = useAtom(placeIdAtom);
  const setBackDestinationsUrl = useSetAtom(backDestinationsUrlAtom);
  const setDestination = useSetAtom(destinationAtom);
  const setCheckinFromStorage = useSetAtom(checkinFromStorageAtom);
  const setCheckoutFromStorage = useSetAtom(checkoutFromStorageAtom);
  const [innerCheckinCheckout, setInnerCheckinCheckout] = useAtom(checkoutCheckinInnerAtom);
  const { checkin, checkout } = innerCheckinCheckout || {};
  const [occupancy, setOccupancy] = useAtom(occupancyStorageAtom);
  const { onSearch } = useSearchFormCallback(placeId);
  const [, setOccupancyLocalStorage] = useLocalStorage<RoomOccupancy[]>(SEARCH_OCCUPANCY, []);

  const submit = useCallback(
    (
      place: Place | undefined,
      bounds: string | undefined,
      _checkin: string | undefined,
      _checkout: string | undefined,
      _occupancy: RoomOccupancy[],
    ) => {
      const hotelPage = !!matchPath(pathname, routes.hotels);
      const destinationPage = !!matchPath(pathname, routes.destination);
      const homePage = !!matchPath(pathname, routes.home);

      const fixedCheckin = _checkin || stringifyDate(new Date());
      const fixedCheckout = _checkout || stringifyDate(shiftDays(new Date(), 1));

      if (
        !(hotelPage || destinationPage) ||
        checkin !== fixedCheckin ||
        checkout !== fixedCheckout ||
        placeId !== place?.id ||
        bounds ||
        !occupancyAreEqual(occupancy, _occupancy)
      ) {
        if (homePage) {
          AmplitudeLogging.pushSearchInitiatedEvent({
            checkin: fixedCheckin,
            checkout: fixedCheckout,
            occupancy,
            destination: place,
          });
        }

        if (place || bounds) {
          setPlaceId(place?.id);
          setDestination(place);
          setInnerCheckinCheckout({ checkin: fixedCheckin, checkout: fixedCheckout });
          setCheckinFromStorage(fixedCheckin);
          setCheckoutFromStorage(fixedCheckout);
          setOccupancy(_occupancy);
          setOccupancyLocalStorage(_occupancy);
          onSearch(place, bounds, fixedCheckin, fixedCheckout, _occupancy);
        }
      }
    },
    [
      pathname,
      checkin,
      checkout,
      placeId,
      occupancy,
      setPlaceId,
      setDestination,
      setInnerCheckinCheckout,
      setCheckinFromStorage,
      setCheckoutFromStorage,
      setOccupancy,
      setOccupancyLocalStorage,
      onSearch,
    ],
  );

  const submitDestination = useCallback(
    (_destination?: Place, bounds?: string) => {
      submit(_destination, bounds, checkin, checkout, occupancy);
    },
    [checkin, checkout, occupancy, submit],
  );

  return {
    submit,
    submitDestination,
    setPlaceId,
    setBackDestinationsUrl,
  };
};
