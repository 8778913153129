import styled, { css } from 'styled-components';
import DefaultStyled from 'components/common/InputField/InputFieldStyled/DefaultInputField.styled';
import { InputFieldStyledModel } from 'components/common/InputField/InputFieldStyled/InputField.styled-model';
import CommonStyled from 'style/Common.styled';
import media from 'style/media';
import { strongPink } from 'style/variables';

const Input = styled(DefaultStyled.Input)`
  border-radius: 3px;
  padding: 24px 13px 13px;
  font-size: 18px;
`;

const InputLabel = styled(DefaultStyled.InputLabel)`
  font-size: 18px;
  line-height: 1.15;
  padding: 0 13px;

  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const notEmptyStyle = css`
  font-size: 14px;
  transform: translateY(40%);
  ${media.mobile} {
    font-size: 13px;
  }
`;

const Container = styled(DefaultStyled.Container)`
  width: 100%;

  ${Input}:focus + ${InputLabel},
  ${Input}:-webkit-autofill:hover + ${InputLabel},
  ${Input}:-webkit-autofill:focus + ${InputLabel},
  ${Input}:-webkit-autofill:active + ${InputLabel} {
    ${notEmptyStyle};
  }

  input:not(:placeholder-shown) + ${InputLabel} {
    ${notEmptyStyle};
  }

  ${({ empty, checkEmptyWithCss }) => {
    if (checkEmptyWithCss) {
      return css`
        ${Input}:not(:placeholder-shown) + ${InputLabel} {
          ${notEmptyStyle}
        }
      `;
    }

    return (
      !empty &&
      css`
        ${Input} + ${InputLabel} {
          ${notEmptyStyle}
        }
      `
    );
  }}
`;

const ErrorText = styled(CommonStyled.ErrorLabel)`
  font-size: 14px;
  color: ${strongPink};
`;

const Styled: InputFieldStyledModel = {
  Input,
  TopItem: DefaultStyled.TopItem,
  InputBlock: DefaultStyled.InputBlock,
  InputLabel,
  Container,
  ErrorText,
};

export default Styled;
