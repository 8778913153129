import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { SuspenseContext } from 'TopContexts';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { TrivagoBngLoader } from 'components/pages/checkout/TrivagoBngLoader/TrivagoBngLoader';
import { FullscreenLoader } from 'components/widgets/FullscreenLoader';
import { routes } from 'routeList';

const isRedirectPage = (pathname: string) =>
  !!matchPath(pathname, routes.hotelsRedirect.path || '') ||
  !!matchPath(pathname, routes.destinationsRedirect.path || '');

export const SuspenseProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isTrivago, isTrivagoBookAndGo } = useContext(TrivagoContext);
  const { pathname } = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [ready, setReady] = useState(false);
  const [prolongedReady, setProlongedReady] = useState(false);

  const notifyReady = useCallback(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    setRedirect(isRedirectPage(pathname));
  }, [pathname]);

  const requiresProlongedSuspense = useMemo(() => {
    const isCheckoutRoute = !!matchPath(pathname, routes.checkout.path || '');

    return isCheckoutRoute && (isTrivagoBookAndGo || !isTrivago);
  }, [isTrivago, isTrivagoBookAndGo, pathname]);

  const loader = useMemo(() => {
    if (isTrivagoBookAndGo) {
      return <TrivagoBngLoader />;
    }

    return <FullscreenLoader />;
  }, [isTrivagoBookAndGo]);

  const isNotReady = useMemo(() => {
    return !ready || (requiresProlongedSuspense && !prolongedReady) || redirect;
  }, [prolongedReady, ready, redirect, requiresProlongedSuspense]);

  return (
    <SuspenseContext.Provider value={{ notifyReady, setProlongedReady }}>
      <Suspense fallback={loader}>
        {isNotReady && loader}
        {children}
      </Suspense>
    </SuspenseContext.Provider>
  );
};
