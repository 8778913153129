import authProviderStorage, { AuthProvider, SplittyAuthProvider } from 'backend/AuthProviderStorage';
import { UserDetails } from 'backend/api/user/userModel';

export class SplittyPrincipal {
  private readonly user: UserDetails;

  private readonly provider: AuthProvider;

  constructor(user: UserDetails, provider?: AuthProvider) {
    this.user = user;
    if (provider) {
      authProviderStorage.set(provider);
    }
    this.provider = provider || authProviderStorage.get() || SplittyAuthProvider.Basic;
  }

  hasUser(): UserDetails | undefined {
    return this.user as UserDetails;
  }

  getProvider(): AuthProvider {
    return this.provider;
  }
}
