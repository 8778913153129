import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import callCenterPhone2 from 'assets/images/icons/call-center-phone-2.svg';
import callCenterPhone from 'assets/images/icons/call-center-phone.svg';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/header-footer/CallCenterBanner/CallCenterBanner.styled';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import { env } from 'environments/environment';

const CallCenterBanner = () => {
  const contactTranslations = useContactTranslations();
  const { isMobileLayout } = useContext(LayoutContext);
  const { campaign } = useCampaign();
  const isSkyscannerCampaign = env.campaign.skyscannerNamePattern.test(campaign?.name || '');

  const phone = isMobileLayout ? (
    <Styled.Phone>{contactTranslations.discountPhone}</Styled.Phone>
  ) : (
    <Styled.PhoneLink href={`tel:${contactTranslations.discountPhone}`} rel="noopener noreferrer">
      <Styled.Phone>{contactTranslations.discountPhone}</Styled.Phone>
    </Styled.PhoneLink>
  );

  const content = (
    <Styled.Banner isSkyscanner={isSkyscannerCampaign}>
      <img src={isSkyscannerCampaign ? callCenterPhone2 : callCenterPhone} alt="phone" />
      <Styled.TextContainer>
        <Styled.Text>
          {isSkyscannerCampaign ? (
            <Trans i18nKey="hotel.special-discount-skyscanner">Looking for help? 24/7 hotline:</Trans>
          ) : (
            <Trans i18nKey="hotel.special-discount">Special discount when you call</Trans>
          )}
        </Styled.Text>
        {phone}
      </Styled.TextContainer>
      {isMobileLayout && !isSkyscannerCampaign && <Styled.MobileGap />}
    </Styled.Banner>
  );

  return isMobileLayout ? (
    <Styled.PhoneLink
      href={`tel:${contactTranslations.discountPhone}`}
      rel="noopener noreferrer"
      className="margin-bottom"
    >
      {content}
    </Styled.PhoneLink>
  ) : (
    content
  );
};

export default CallCenterBanner;
