import { css } from 'styled-components';
import { rem } from 'style/functions';
import media from 'style/media';
import {
  backgroundSelection,
  colorBlue,
  disabledBackgroundGray,
  errorColor,
  charcoalGrey,
  white,
  superDarkGrey,
} from 'style/variables';

const fontSize1 = 48;
const coefficient = 0.85;
const fontSize2 = 24;
const fontSize2L = 40;

export const tagStyles = css`
  /*
 * 1. Set \`font-size\` to support \`rem\` units
 *    Not using \`font\` property because a leading hyphen (e.g. -apple-system) causes the font to break in IE11 and Edge
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */

  html {
    background: ${white};
    color: ${charcoalGrey};
    font-family:
      League Spartan,
      Calibri,
      Arial,
      sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  select,
  button,
  textarea {
    font: inherit;
  }

  /*
 * Remove the margin in all browsers.
 */

  body {
    margin: 0;
  }

  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${white} inset !important;
  }

  /* Links
 ========================================================================== */

  /*
   * Remove the outline on focused links when they are also active or hovered
   */

  a:active,
  a:hover {
    outline: none;
    color: ${colorBlue};
    text-decoration: underline;
  }

  /*
 * Style
 */

  a {
    color: ${charcoalGrey};
    text-decoration: none;
    cursor: pointer;
  }

  /*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

  b,
  strong {
    font-weight: bolder;
  }

  /*
 * Emphasize
 */

  em {
    color: ${errorColor};
  }

  /*
 * Remove the gap between embedded content and the bottom of their containers.
 */

  iframe,
  img,
  svg {
    vertical-align: middle;
  }

  /*
   * 1. Add responsiveness.
   * 2. Auto-scale the height. Only needed if \`height\` attribute is present.
   * 3. Corrects responsive \`max-width\` behavior if padding and border are used.
   */

  img {
    /* 1 */
    max-width: 100%;
    /* 2 */
    height: auto;
    /* 3 */
    box-sizing: border-box;
  }

  svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  /*
 * Hide the overflow in IE.
 */

  svg:not(:root) {
    overflow: hidden;
  }

  /*
   * 1. Fix lazy loading images if parent element is set to \`display: inline\` and has \`overflow: hidden\`.
   * 2. Hide \`alt\` text for lazy loading images.
   * Note: Selector for background while loading img[data-src*='.jpg'][src*='data:image'] { background: grey; }
   */

  img:not([src]) {
    /* 1 */
    min-width: 1px;
    /* 2 */
    visibility: hidden;
  }

  /*
 * Iframe
 * Remove border in all browsers
 */

  iframe {
    border: 0;
  }

  /*
 * Margins
 */

  p,
  ul,
  ol,
  dl,
  pre,
  address,
  fieldset,
  figure {
    margin: 0 0 20px 0;
  }

  /* Add margin if adjacent element */
  * + p,
  * + ul,
  * + ol,
  * + dl,
  * + pre,
  * + address,
  * + fieldset,
  * + figure {
    margin-top: 20px;
  }

  /* Headings
 ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px 0;
    font-weight: 700;
    color: ${superDarkGrey};
    text-transform: none;
  }

  /* Add margin if adjacent element */
  * + h1 * + h2 * + h3 * + h4 * + h5 * + h6 {
    margin-top: 40px;
  }

  /*
   * Sizes
   */

  h1 {
    font-size: ${rem(fontSize1 * coefficient)};
    line-height: 1.2;
  }

  h2 {
    font-size: ${rem(fontSize2)};
    line-height: 1.25;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  h5 {
    font-size: 14px;
    line-height: 1.4;
  }

  h6 {
    font-size: 14px;
    line-height: 1.4;
  }

  /* Tablet landscape and bigger */
  ${media.desktop} {
    h1 {
      font-size: ${rem(fontSize1)};
    }
    h2 {
      font-size: ${rem(fontSize2L)};
    }
  }

  /* Lists
 ========================================================================== */

  ul,
  ol {
    padding-left: 30px;
  }

  /*
   * Reset margin for nested lists
   */

  ul > li > ul,
  ul > li > ol,
  ol > li > ol,
  ol > li > ul {
    margin: 0;
  }

  /*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */

  hr {
    /* 1 */
    overflow: visible;
    /* 2 */
    text-align: inherit;
    /* 3 */
    margin: 0 0 20px 0;
    border: 0;
    border-top: 1px solid ${disabledBackgroundGray};
  }

  /* Add margin if adjacent element */
  * + hr {
    margin-top: 20px;
  }

  /* Selection pseudo-element
 ========================================================================== */

  ::selection {
    background: ${backgroundSelection};
    color: ${white};
    text-shadow: none;
  }

  main {
    display: block;
  }
`;
