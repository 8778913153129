import useKeyPress, { Keys } from 'common/hooks/useKeyPress';
import { useCallback, useEffect, useState } from 'react';
import { Place } from 'common/backend/api/place/placeModel';

export const useKeyNavigation = (places: Place[] | undefined, disabled: boolean) => {
  const [index, setIndex] = useState<number | undefined>(undefined);

  const onArrowUp = useCallback(() => {
    setIndex((prevIndex) => {
      if (prevIndex === undefined || (places?.length || 0) === 0) {
        return undefined;
      }

      return Math.max(prevIndex - 1, 0);
    });
  }, [places?.length]);

  const onArrowDown = useCallback(() => {
    setIndex((prevIndex) => {
      if (!places || places.length === 0) {
        return undefined;
      }

      if (prevIndex === undefined) {
        return 0;
      }

      return Math.min(prevIndex + 1, places.length - 1);
    });
  }, [places]);

  useKeyPress([Keys.ArrowDown], onArrowDown, disabled);
  useKeyPress([Keys.ArrowUp], onArrowUp, disabled);

  useEffect(() => {
    setIndex(undefined);
  }, [places]);

  return index;
};
