import styled, { css } from 'styled-components';
import { Button } from 'components/common/Button/Button';
import media from 'style/media';
import { flexGapRow } from 'style/polyfills/flexGap';
import { ifTrivago } from 'style/trivago';
import { parisianBlue } from 'style/trivagoVaribles';
import { borderColorLightest, veryLightGray } from 'style/variables';

const TopLineStyle = styled.div`
  padding: 15px 21px;

  ${ifTrivago(
    css`
      ${media.desktop} {
        background: ${parisianBlue};
      }

      border-radius: 4px;
      max-width: 1200px;
      padding: 20px;
      margin: 0 auto;
    `,
    css`
      background-color: ${veryLightGray};
      border-bottom: 1px solid ${borderColorLightest};
      z-index: 10;
    `,
  )}

  box-sizing: border-box;
`;

const TopLineRelativeContainer = styled.section`
  position: relative;
  min-height: 81px;
`;

const TopLineStyleRelative = styled(TopLineStyle)`
  position: relative;
  border: none;
`;

const FixedToplineDatepicker = styled.div`
  width: 22rem;
`;

const FixedSearchForm = styled.form`
  display: flex;
  flex-wrap: nowrap;
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;

  ${media.desktop} {
    ${flexGapRow('16px')}
    & > * {
      flex-grow: 1;
    }
  }
`;

const FixedWidthSearchfield = styled.div`
  width: 350px;
`;
const FixedWidthDatepicker = styled.div`
  width: 320px;
`;
const FixedWidthPeoplepicker = styled.div`
  width: 230px;
`;
const FixedWidthSearchButton = styled.div`
  width: 220px;
`;

const SearchButton = styled(Button)`
  height: 48px;
`;

export default {
  TopLineStyle,
  FixedToplineDatepicker,
  FixedSearchForm,
  FixedWidthSearchfield,
  FixedWidthDatepicker,
  FixedWidthPeoplepicker,
  FixedWidthSearchButton,
  TopLineStyleRelative,
  TopLineRelativeContainer,
  SearchButton,
};
