import useQuery, { UrlParameters } from 'common/hooks/useQuery';
import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { env } from 'environments/environment';
import { routes, TRIVAGO_ROUTE, TRIVAGO_TYPE } from 'routeList';
import { CampaignNameQuery } from 'utils/queryParameters';
import cookieBasedTrivagoReferenceStorage from 'utils/storage/cookie/CookieBasedTrivagoReferenceStorage';

interface Context {
  isTrivago: boolean;
  isTrivagoOnly: boolean;
  isTrivagoOnlyCampaign: boolean;
  isTrivagoBnGCampaign: boolean;
  isTrivagoBookAndGo: boolean;
  trivagoReference: string | undefined;
  isConfirmationPage: boolean;
}

export interface TrivagoQueryParameters extends UrlParameters {
  trv_reference?: string;
}

export const TrivagoContext = React.createContext<Context>({
  isTrivago: false,
  isTrivagoOnly: false,
  isTrivagoOnlyCampaign: false,
  isTrivagoBnGCampaign: false,
  isTrivagoBookAndGo: false,
  trivagoReference: undefined,
  isConfirmationPage: false,
});

interface TrivagoProps {
  trivago?: TRIVAGO_TYPE;
}

export const TrivagoProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { campaignName: campaignNameParam } = useQuery<CampaignNameQuery>();
  const { pathname } = useLocation();
  const trivagoRoute = useMemo(() => matchPath<TrivagoProps>(pathname, TRIVAGO_ROUTE)?.params.trivago, [pathname]);

  // TODO Remove when confirmation will has new trivago design
  const location = useLocation();
  const isConfirmationPage = useMemo(() => !!matchPath(location.pathname, routes.confirmation), [location.pathname]);

  // TODO Remove after migration
  const isTrivagoRedirect = useMemo(() => {
    const isTrivagoCampaign = env.trivagoSettings.campaignPattern.test(campaignNameParam || '');
    const isRedirectRoute = !!matchPath(pathname, routes.hotelsRedirect.path || '');

    return isTrivagoCampaign && isRedirectRoute;
  }, [campaignNameParam, pathname]);

  const isTrivagoBnGCampaign = useMemo(
    () => env.trivagoSettings.campaignBookAndGoPattern.test(campaignNameParam || ''),
    [campaignNameParam],
  );

  const isTrivagoOnlyCampaign = useMemo(
    () =>
      !env.trivagoSettings.campaignBookAndGoPattern.test(campaignNameParam || '') &&
      env.trivagoSettings.campaignPattern.test(campaignNameParam || ''),
    [campaignNameParam],
  );

  // TODO Remove after migration
  const isTrivagoBngRedirect = useMemo(() => {
    const isRedirectRoute = !!matchPath(pathname, routes.hotelsRedirect.path || '');

    return isTrivagoBnGCampaign && isRedirectRoute;
  }, [isTrivagoBnGCampaign, pathname]);

  const { trv_reference: trivagoReferenceQueryParameter } = useQuery<TrivagoQueryParameters>();

  const isTrivago = useMemo(
    () =>
      trivagoRoute === TRIVAGO_TYPE.TRIVAGO ||
      isTrivagoRedirect ||
      trivagoRoute === TRIVAGO_TYPE.TRIVAGO_BNG ||
      isTrivagoBngRedirect,
    [isTrivagoBngRedirect, isTrivagoRedirect, trivagoRoute],
  );

  const isTrivagoOnly = useMemo(
    () => trivagoRoute === TRIVAGO_TYPE.TRIVAGO || isTrivagoRedirect,
    [isTrivagoRedirect, trivagoRoute],
  );

  const isTrivagoBookAndGo = useMemo(
    () => trivagoRoute === TRIVAGO_TYPE.TRIVAGO_BNG || isTrivagoBngRedirect,
    [isTrivagoBngRedirect, trivagoRoute],
  );

  const trivagoReference = useMemo(() => {
    if (trivagoReferenceQueryParameter) {
      cookieBasedTrivagoReferenceStorage.set(trivagoReferenceQueryParameter);
    }

    return cookieBasedTrivagoReferenceStorage.get();
  }, [trivagoReferenceQueryParameter]);

  return (
    <TrivagoContext.Provider
      value={{
        isTrivago,
        isTrivagoOnly,
        isTrivagoOnlyCampaign,
        isTrivagoBnGCampaign,
        isTrivagoBookAndGo,
        trivagoReference,
        isConfirmationPage,
      }}
    >
      {children}
    </TrivagoContext.Provider>
  );
};
