/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import media from 'style/media';
import { ifTrivago } from 'style/trivago';
import { trivagoBlackV2, trivagoDarkGrey } from 'style/trivagoVaribles';
import { charcoalGrey } from 'style/variables';

const MonthLabel = styled.div`
  margin: 0 0 1rem;
  font-size: 18px;
  font-weight: bold;
  padding: 0.5rem;
  color: ${trivagoBlackV2};
  text-transform: uppercase;
  letter-spacing: 2.67px;

  span {
    font-size: 20px;
    line-height: 18px;
    font-weight: 400;
    color: ${trivagoDarkGrey};
    letter-spacing: normal;
  }

  ${ifTrivago(css`
    font-size: 18px;
    text-transform: none;
    letter-spacing: normal;
    padding: 7px;
    margin-bottom: 14px;

    text-align: center;
    color: ${trivagoBlackV2};

    span {
      color: inherit;
      font: inherit;
    }

    ${media.mobile} {
      text-align: left;
      margin: 20px 0 0;
    }
  `)}
}`;

const Weekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  margin-bottom: 0.8rem;
  color: ${charcoalGrey};

  div {
    text-align: center;
  }

  ${ifTrivago(css`
    font-size: 11.2px;
    line-height: 16.8px;
    margin-bottom: 11.2px;
  `)}
`;

const DayLabel = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
`;

export default { MonthLabel, Weekdays, DayLabel };
