import { LocaleCurrencySeparatorsConf } from 'environments/configuration';

const localeCurrencySeparators: LocaleCurrencySeparatorsConf = {
  locales: [
    {
      locale: 'FR',
      thousandsSeparator: ' ',
      decimalSeparator: ',',
    },
    {
      locale: 'ES',
      thousandsSeparator: ' ',
      decimalSeparator: ',',
    },
    {
      locale: 'IT',
      thousandsSeparator: '.',
      decimalSeparator: ',',
    },
    {
      locale: 'DE',
      thousandsSeparator: '.',
      decimalSeparator: ',',
    },
    {
      locale: 'EN',
      thousandsSeparator: ',',
      decimalSeparator: '.',
    },
  ],
  default: {
    locale: 'EN',
    thousandsSeparator: ',',
    decimalSeparator: '.',
  },
};

export default localeCurrencySeparators;
