import styled, { css } from 'styled-components';
import media from 'style/media';
import { flexGapColumn } from 'style/polyfills/flexGap';
import { backgroundPeach, black } from 'style/variables';

const ImageSize = css`
  ${media.desktop} {
    width: 24px;
    height: 24px;
  }

  ${media.mobile} {
    width: 33px;
    height: 33px;
  }
`;

const TextContainer = styled.div`
  font-size: 13px;

  flex-grow: 1;
  display: flex;
  align-items: center;

  ${media.desktop} {
    ${flexGapColumn('8px')}
  }

  ${media.mobile} {
    flex-direction: column;
  }
`;

const Text = styled.span`
  color: ${black};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
`;

const Phone = styled.span`
  color: ${black};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
`;

const Banner = styled.div<{ isSkyscanner: boolean }>`
  background-color: ${backgroundPeach};
  border-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 4px 25px;

  ${flexGapColumn('12px')}
  img {
    ${ImageSize}
  }

  ${media.desktop} {
    height: 33px;
  }

  ${media.mobile} {
    height: 48px;
  }

  ${({ isSkyscanner }) =>
    isSkyscanner &&
    css`
      box-sizing: border-box;
      padding: 9px 20px;
      ${flexGapColumn('15px')}

      ${TextContainer}, ${Text} {
        font-size: 15px;
        line-height: 15px;
        font-weight: 400;
      }

      ${media.desktop} {
        height: 42px;
      }

      ${media.mobile} {
        height: 42px;
        padding: 9px 15px;
        ${flexGapColumn('10px')}

        ${TextContainer}, ${Text}, ${Phone} {
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }
    `}
`;

const MobileGap = styled.div`
  ${ImageSize}
`;

const PhoneLink = styled.a`
  display: block;
  color: ${black};
  &:hover {
    color: ${black};
  }
`;

export default {
  Banner,
  TextContainer,
  Text,
  Phone,
  PhoneLink,
  MobileGap,
};
