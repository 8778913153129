import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import { ToastType } from 'components/common/Toast/Toast.types';
import { AvailableErrors } from 'errors/errorData';

const useSignUpErrors = (recordAlreadyExistErrorCallback: () => void): AvailableErrors => {
  const [t] = useTranslation();
  const setToast = useContext(ToastContext);

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.RecordAlreadyExists],
          action: () => recordAlreadyExistErrorCallback(),
        },
        {
          serverCodes: [ServerErrorCode.Forbidden],
          action: () =>
            setToast(
              t('login-popup.recaptcha-failed', 'Captcha verification failed. Please try again'),
              ToastType.Error,
            ),
        },
      ],
      default: {
        action: () =>
          setToast(t('signup-popup.internal-error', 'Error signing up. Please try again later'), ToastType.Error),
      },
    }),
    [recordAlreadyExistErrorCallback, setToast, t],
  );
};

export default useSignUpErrors;
