/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import media from 'style/media';
import { landingBngFooterHeaderBackgroundColor } from 'style/trivagoVaribles';
import { white } from 'style/variables';

const Footer = styled.div`
  padding: 40px 0 0;
  background: ${landingBngFooterHeaderBackgroundColor};
  font-family: Lato, serif !important;
`;

const Block = styled.div`
  ${media.desktop} {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  ${media.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  & + & {
    margin-left: 20px;
  }
`;

const SectionLabel = styled.label`
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${white};
`;

const SectionLabelCapitalized = styled(SectionLabel)`
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const SectionLink = styled.div`
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07;
  letter-spacing: normal;

  a {
    color: ${white};
  }
`;

const Additional = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${landingBngFooterHeaderBackgroundColor};
  color: ${white};

  font-size: 12px;
  line-height: 1.5;
`;

export default { Footer, Block, Section, SectionLabel, SectionLabelCapitalized, SectionLink, Additional };
