import useCookie from 'common/hooks/useCookie';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SHOW_COOKIES_BANNER } from 'backend/localStorageKeys';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import Styled from 'components/layouts/CookiePanel/CookiePanel.styled';

const BANNER_COOKIE_LIFETIME_DAYS = 365;

export const CookiePanel = () => {
  const [t] = useTranslation();
  const { isTrivago } = useContext(TrivagoContext);
  const { cookie, updateCookie } = useCookie(SHOW_COOKIES_BANNER, 'none');

  const hideCookieBanner = useCallback(() => {
    updateCookie(SHOW_COOKIES_BANNER, BANNER_COOKIE_LIFETIME_DAYS);
  }, [updateCookie]);

  return (
    <>
      {cookie === 'none' && (
        <Styled.Cookie>
          {t(
            'common.misc.cookie-panel',
            'We use cookies on our site, to ensure you will get the best experience, click accept to continue',
          )}
          {isTrivago && (
            <Styled.TrivagoButton id="cookieAccept" onClick={hideCookieBanner}>
              {t('common.misc.accept', 'Accept')}
            </Styled.TrivagoButton>
          )}
          {!isTrivago && (
            <Styled.ButtonOutline id="cookieAccept" onClick={hideCookieBanner}>
              {t('common.misc.accept', 'Accept')}
            </Styled.ButtonOutline>
          )}
        </Styled.Cookie>
      )}
    </>
  );
};
