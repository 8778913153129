/**
 * @category Search Form
 * @packageDocumentation
 */
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect } from 'react';
import { MobileOffscreenStyled } from 'components/mobile/offscreen/MobileOffscreen.styled';

/**
 * A wrapper that displays the mobile offscreen with mobile version of search form
 * @param children
 * @constructor
 */
const MobileOffscreen: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  useEffect(() => {
    disableBodyScroll(document.body, {
      reserveScrollBarGap: true,
      allowTouchMove: (el) => el !== document.body,
    });

    return () => {
      enableBodyScroll(document.body);
    };
  }, []);

  return <MobileOffscreenStyled.OffscreenBar>{children}</MobileOffscreenStyled.OffscreenBar>;
};

export default MobileOffscreen;
