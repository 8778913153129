import jsc from 'js-cookie';
import { env } from 'environments/environment';

/**
 * Name of the cookie to store campaign name
 */
const TRIVAGO_REFERENCE = 'TRIVAGO_REFERENCE';

class CookieBasedTrivagoLandingPageStorage {
  set(landingPage: string): void {
    jsc.set(TRIVAGO_REFERENCE, landingPage, {
      expires: new Date(new Date().getTime() + env.times.campaignMaxAge),
      sameSite: 'Lax',
    });
  }

  remove(): void {
    jsc.remove(TRIVAGO_REFERENCE);
  }

  get(): string | undefined {
    return jsc.get(TRIVAGO_REFERENCE);
  }
}

const cookieBasedTrivagoReferenceStorage = new CookieBasedTrivagoLandingPageStorage();

export default cookieBasedTrivagoReferenceStorage;
