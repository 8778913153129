/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { LabelTypes } from 'components/common/Label/constants';
import { rem } from 'style/functions';
import {
  borderColorLight,
  emphasisColor,
  errorColor,
  successBackground,
  successBorder,
  dangerBackground,
  warningColor,
  successColor,
  white,
} from 'style/variables';

const Label = styled.span<{ type?: LabelTypes }>`
  background: ${white};
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid ${borderColorLight};
  border-radius: 3px;
  color: ${emphasisColor};
  display: inline-block;
  font-size: ${rem(12)};
  line-height: 1.5;
  padding: ${rem(2)} 10px;
  ${({ type }) => {
    switch (type) {
      case LabelTypes.Success:
        return css`
          background-color: ${successBackground};
          border-color: ${successBorder};
          color: ${successColor};
        `;
      case LabelTypes.Warning:
        return css`
          background-color: ${dangerBackground};
          color: ${warningColor};
        `;
      case LabelTypes.Danger:
        return css`
          background-color: ${errorColor};
        `;
      default:
        return '';
    }
  }}
`;

export default {
  Label,
};
