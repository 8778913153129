import React, { useCallback, useContext } from 'react';
import { SettingsContext } from 'TopContexts';
import { Key } from 'components/common/SelectInput/SelectInput';
import { InternalLanguageSelector } from 'components/header-footer/LanguageSelector/InternalLanguageSelector';

interface LanguageSelectorProps {
  isInDarkHeader?: boolean;
}

interface HandledLanguageSelectorProps extends LanguageSelectorProps {
  handledLanguageCode: string;
  handledSetLanguage: (value: string) => void;
}

interface AutoLanguageSelectorProps extends LanguageSelectorProps {
  handledLanguageCode?: never;
  handledSetLanguage?: never;
}

export const LanguageSelector: React.FC<HandledLanguageSelectorProps | AutoLanguageSelectorProps> = ({
  handledSetLanguage,
  handledLanguageCode,
  isInDarkHeader,
}) => {
  const { languageCode, setLanguageCode } = useContext(SettingsContext);

  const onLanguageChange = useCallback(
    (langCode: Key) => {
      if (!langCode) {
        return;
      }

      if (handledSetLanguage) {
        handledSetLanguage(langCode);

        return;
      }

      setLanguageCode(langCode, true);
    },
    [handledSetLanguage, setLanguageCode],
  );

  return (
    <InternalLanguageSelector
      isInDarkHeader={isInDarkHeader}
      languageCode={handledLanguageCode || languageCode}
      onLanguageChange={onLanguageChange}
    />
  );
};
