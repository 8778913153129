import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ABOUTUS_ROUTE } from 'routeList';

const AboutUsLink = () => (
  <Link to={ABOUTUS_ROUTE}>
    <Trans i18nKey="common.menu.about">About</Trans>
  </Link>
);

export default AboutUsLink;
