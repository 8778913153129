/* eslint-disable no-magic-numbers */
import { baseFontSize, rem } from 'style/functions';

export { baseFontSize };

// Font sizes
export const fontSizeSmall = '15p';
export const fontSizeLarge = '18px';
export const fontSizeHeadingS = rem(14);
export const fontSizeHeadingM = rem(20);
export const fontSizeHeadingL = rem(20);
export const fontSizeHeadingLarge = '32px';
export const fontSizeHeadingXL = rem(20);
export const fontSizeHeadingXLarge = rem(30);
export const fontSizeH1Large = '76px';
export const lineHeightLarge = '24px';
export const lineHeightSmall = '22px';
export const lineHeightHeadingS = 1.5;
export const lineHeightHeadingM = 1.1;
export const lineHeightHeadingL = 1.3;
export const lineHeightHeadingXL = 1;
export const lineHeightH1Large = '69px';
export const tinyText = rem(10);

// colors
export const brandColor = '#ec018c';
export const brandColorDark = '#B10169';
export const successColor = '#52C41A';
export const warningColor = '#F5222D';
export const dangerColor = '#F90F35';
export const errorColor = '#f0506e';
export const emphasisColor = '#595959';
export const headerColor = '#750045';
export const colorMuted = '#8C8C8C';
export const mutedColor = '#B7B7B7';
export const tripAdvisorColor = '#01aa6c';
export const textColor = '#3B3B3B';
export const selectOptionColor = '#444444';
export const notificationError = '#FFA39E';
export const notificationWarn = '#FFE58F';
export const charcoalGreyItem = '#c4cbce';
export const charcoalGreyItemColor = '#e6e6f7';
export const insuranceMutedText = '#73777c';

// common colors
export const lightRoyalBlue = '#312ff7';
export const battleshipGrey = '#6f787a';
export const charcoalGrey = '#4c4f50';
export const purple = '#722ED1';
export const orchid = '#9218b6';
export const violet = '#9319b6';
export const lightPurple = '#be98fe';
export const strongPink = '#ff006e';
export const white = '#FFFFFF';
export const black = '#000000';
export const gray = '#999999';
export const middleGray = '#333333';
export const veryLightGray = '#FAFAFA';
export const veryDarkGray = '#343434';
export const lightGray = '#fefefe';
export const darkGray = '#666666';
export const pear = '#B3FF009E';
export const pineGreen = '#3f8700';
export const superDarkGrey = '#191919';
export const paleSkyBlue = '#dadcdd';
export const lightGrey = '#efefef';
export const lightGreyTranslucent = '#efefef5e';
export const brightOrange = '#fc5606';
export const grey2 = '#a6acae';
export const colorPink = '#dc005f';
export const colorPink3 = '#f364a2';
export const colorGreen = '#276749';
export const colorOrange = '#ff9f1e';
export const colorBlack = '#1c1c1b';
export const colorBlack2 = '#262626';
export const colorRed = '#ca2e2b';
export const colorRed2 = '#fe2541';
export const colorGrey = '#938f8f';
export const colorPurple2 = '#d3adf7';
export const colorPurple3 = '#f9f0ff';
export const colorGrey4 = '#fff8fb';
export const colorPink5 = '#ffebeb';
export const colorPink6 = '#ffd6d6';
export const colorGrey7 = '#f2ebfe';
export const colorPurple4 = '#e5d5ff';
export const colorBlue = '#0f6ecd';
export const veryDarkGray2 = '#292929';

// background colors
export const primaryBackground = '#FFF0F6';
export const successBackground = '#F6FFED';
export const dangerBackground = '#FFF1F0';
export const backgroundGray = '#F2F2F2';
export const backgroundGray2 = '#f2f3f4';
export const disabledBackgroundGray = '#e5e5e5';
export const loaderBackgroundColor = '#FBFBFB';
export const overlayBackground1 = '#26262680';
export const overlayBackground2 = '#FFFFFFCC';
export const modalBackground = '#F8F8F8';
export const disabledInputBackgroundColor = '#f6f6f7';
export const pinkActiveBackgroundColor = '#b9016e';
export const notificationWarnBackground = '#FFFBE6';
export const notificationInfoBackground = '#E6F7FF';
export const pinkHoveredBackgroundColor = '#d3017d';
export const insurancePrimaryBackgroundColor = '#e8f3e9';
export const backgroundGreen = '#e3fce4';
export const backgroundGreen2 = '#99ea4b';
export const backgroundGreen3 = '#e3f8cd';
export const backgroundGreen4 = '#008100';
export const backgroundGrey2 = '#e3eaed';
export const backgroundGrey3 = '#d8d8fd';
export const backgroundGrey4 = '#f7f8f9';
export const backgroundGrey5 = '#f3f8f3';
export const backgroundGrey6 = '#f9fafa';
export const backgroundGrey7 = '#7a878b';
export const backgroundGrey8 = '#6e7a7e';
export const backgroundGrey9 = '#eee';
export const backgroundPeach = '#fedfd0';
export const backgroundPink = '#ffcfe4';
export const backgroundPink2 = '#ffe3ec';
export const backgroundBlue = '#e8f4fa';
export const backgroundBlue2 = '#e7f2f5';

// border colors
export const borderColor = '#CCCCCC';
export const borderColor2 = '#e4e4e4';
export const borderColorLight = '#D9D9D9';
export const borderColorLightest = '#E8E8E8';
export const cardBorderColor = '#E6E6E6';
export const successBorder = '#B7EB8F';
export const focusSelectPeoplePickerBorderColor = '#1e87f0';
export const peoplePickerBorderColor = '#e2e2e2';
export const occupancySelectBorderColor = '#bcbcbc';
export const occupancySelectBorderColorActive = '#acacac';
export const insuranceBorderColor = '#e3e3e3';
export const insuranceRadioButtonBorder = '#c5cad1';
export const borderGreen = '#d0f4ce';
export const borderGrey = '#ececec';
export const borderGrey2 = '#6c7272';
export const borderGrey3 = '#b5b5b5';
export const borderGrey4 = '#dddddd';
export const borderRadius = rem(4);

// box shadow colors
export const boxShadowColor = '#b2b2b2';
export const boxShadowColor2 = '#ebeced';
export const boxShadowColor3 = '#cdd1d1';
export const boxShadowColor4 = '#dcdfe6';

export const backgroundSelection = '#39f';
export const avuxiAccentColor = '#4daa46';
