import styled from 'styled-components';
import ButtonStyled from 'components/common/Button/ButtonStyled/DefaultButton.styled';
import { black, brightOrange, lightRoyalBlue, strongPink, superDarkGrey } from 'style/variables';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
  color: ${superDarkGrey};
  padding: 12px;

  a {
    color: ${black};
    text-decoration: underline;
  }

  &:before {
    display: block !important;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    opacity: 0.32;
    background-image: ${`linear-gradient(to right, ${brightOrange} 2%, ${strongPink} 51%, ${lightRoyalBlue})`};
  }
`;

const CloseButton = styled(ButtonStyled.TextButton)`
  margin-left: 14px;
  width: 20px;
  height: 20px;
`;

const ButtonImage = styled.img`
  min-width: 18px;
  min-height: 18px;
`;

export const CovidBannerStyled = {
  Container,
  CloseButton,
  ButtonImage,
};
