import { useEffect, useRef } from 'react';

export function useUnmountAbortController() {
  const unmountAbortController = useRef(new AbortController());

  useEffect(
    () => () => {
      unmountAbortController.current.abort();
    },
    [],
  );

  return unmountAbortController.current;
}
