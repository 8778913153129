import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SettingsContext } from 'TopContexts';
import { LoadingModal } from 'components/LoadingModal';
import { HotelRedirectQueryUrlParameters } from 'utils/queryParameters';
import { createHotelSearchRedirectUri } from 'utils/uriUtils';

interface DestinationRedirectProps {
  query: HotelRedirectQueryUrlParameters;
  occupancy: RoomOccupancy[];
  checkin: string;
  checkout: string;
}

const DestinationRedirect: React.FC<DestinationRedirectProps> = ({ query, occupancy, checkin, checkout }) => {
  const history = useHistory();
  const { setLanguageCode } = useContext(SettingsContext);

  useEffect(() => {
    if (query.userLanguage) {
      setLanguageCode(query.userLanguage);
    }

    history.replace(
      createHotelSearchRedirectUri(query.destinationName, query.placeId, undefined, checkin, checkout, occupancy),
    );
  }, [
    checkin,
    checkout,
    history,
    occupancy,
    query.destinationName,
    query.language,
    query.placeId,
    query.userLanguage,
    setLanguageCode,
  ]);

  return <LoadingModal visible={true} />;
};

export default DestinationRedirect;
