import React, { useCallback, useContext } from 'react';
import burger from 'assets/images/icons/burger.svg';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { PageHeaderLogo } from 'components/header-footer/PageHeaderLogo';
import { MobilePageHeaderStyled } from 'components/layouts/mobile/MobilePageHeader.styled';
import TrivagoMobilePageHeader from 'components/layouts/trivago/TrivagoMobilePageHeader';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';

const MobilePageHeader = () => {
  const { isTrivago } = useContext(TrivagoContext);
  const { offscreenMode, setOffscreenMode } = useContext(OffscreenContext);
  const onBurgerClicked = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      if (offscreenMode === OffscreenMode.hidden) {
        setOffscreenMode(OffscreenMode.burger);
      } else {
        setOffscreenMode(OffscreenMode.hidden);
      }
    },
    [offscreenMode, setOffscreenMode],
  );

  if (isTrivago) {
    return <TrivagoMobilePageHeader />;
  }

  return (
    <MobilePageHeaderStyled.Container>
      <MobilePageHeaderStyled.Header className="flex flex-middle flex-between width-1-1 padding-small padding-remove-vertical padding-remove-right">
        <PageHeaderLogo />
        <MobilePageHeaderStyled.BurgerIcon
          data-testid="burger-button"
          id="burgerButton"
          href="/"
          className="padding-small padding-remove-vertical"
          onClick={onBurgerClicked}
        >
          <img src={burger} alt="Menu" />
        </MobilePageHeaderStyled.BurgerIcon>
      </MobilePageHeaderStyled.Header>
    </MobilePageHeaderStyled.Container>
  );
};

export default MobilePageHeader;
