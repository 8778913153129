import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { ToastContext } from 'TopContexts';
import {
  AbstractModalVeil,
  BodyOverflowGlobalStyle,
  MODAL_ANIMATION_MS,
} from 'components/common/modal/CustomAbstractModal.styles';

export interface CustomAbstractModalProps extends PropsWithChildren<Record<string, unknown>> {
  visible: boolean;
  positionToCenter: boolean;
  onHide?: () => void;
  onShow?: () => void;
  updated?: boolean;
  omitPadding?: boolean;
  onClick?: () => void;
}

export const CustomAbstractModal = ({
  children,
  visible,
  onHide,
  onShow,
  positionToCenter,
  updated,
  omitPadding,
  onClick,
}: CustomAbstractModalProps) => {
  const setToast = useContext(ToastContext);
  const [innerVisible, setInnerVisible] = useState<boolean>(visible);
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  // animations start working the moment when their classes are added
  // because of this, we need such workaround, in particular to display
  // the hiding animation before applying the 'hidden' class
  useEffect(() => {
    if (innerVisible === visible) {
      return;
    }
    if (visible) {
      setTimeout(() => setInnerVisible(visible), MODAL_ANIMATION_MS);
      if (onShow) {
        onShow();
      }
    } else {
      setTimeout(() => setInnerVisible(visible), MODAL_ANIMATION_MS);
      if (onHide) {
        onHide();
      }
    }
  }, [innerVisible, onHide, onShow, visible]);

  useEffect(() => {
    if (visible) {
      setToast(undefined);
    }
  }, [setToast, visible]);

  return (
    <AbstractModalVeil
      onClick={handleClick}
      omitPadding={omitPadding}
      positionToCenter={positionToCenter}
      updated={updated}
      visible={visible}
      innerVisible={innerVisible}
    >
      <BodyOverflowGlobalStyle visible={visible} />
      {children}
    </AbstractModalVeil>
  );
};
