import { Hotel } from 'common/backend/api/hotel/hotelModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDataProvider } from 'backend/dataProvider';
import { LoadingModal } from 'components/LoadingModal';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { TrivagoBngLoader } from 'components/pages/checkout/TrivagoBngLoader/TrivagoBngLoader';
import { processError } from 'errors/errorUtils';
import useHotelInfoErrors from 'errors/useHotelInfoErrors';
import { HotelRedirectQueryUrlParameters } from 'utils/queryParameters';
import { buildHotelPathname, createHotelDetailsUri } from 'utils/uriUtils';
import { getHotelQueryParams } from 'views/redirect/redirectUtils';

interface HotelRedirectProps {
  query: HotelRedirectQueryUrlParameters;
  occupancy: RoomOccupancy[];
  checkin: string;
  checkout: string;
}

const TRIPADVISOR_CAMPAIGN = /^Algotels_Tripadvisor_/i;
const SKY_SCANNER_CAMPAIGN = /^Algotels_Skyscanner_/i;

const HotelRedirect: React.FC<HotelRedirectProps> = ({ query, occupancy, checkin, checkout }) => {
  const { isTrivago, isTrivagoBookAndGo } = useContext(TrivagoContext);
  const history = useHistory();
  const errors = useHotelInfoErrors();

  const [hotelInfo, setHotelInfo] = useState<Hotel>();

  const useAllQuery = useMemo(() => {
    const isSpecialCampaign =
      TRIPADVISOR_CAMPAIGN.test(query.campaignName) || SKY_SCANNER_CAMPAIGN.test(query.campaignName);

    return isSpecialCampaign && !isTrivago;
  }, [isTrivago, query.campaignName]);

  useEffect(() => {
    if (query.hotelId) {
      getDataProvider()
        .then((dataProvider) => dataProvider.getHotelInfo(+query.hotelId))
        .then(setHotelInfo)
        .catch((reason) => processError(reason, errors));
    }
  }, [errors, query.hotelId]);

  useEffect(() => {
    if (!hotelInfo) {
      return;
    }

    const params = getHotelQueryParams(useAllQuery, query);

    const hotelUrl = createHotelDetailsUri(
      buildHotelPathname(
        hotelInfo.location.countryName,
        hotelInfo.location.stateCode || '',
        hotelInfo.location.cityName,
        hotelInfo.id,
        hotelInfo.name,
        isTrivago,
        isTrivagoBookAndGo,
      ),
      checkin,
      checkout,
      occupancy,
      undefined,
      query.dealKey,
      params,
    );

    history.replace(hotelUrl);
  }, [checkin, checkout, history, hotelInfo, isTrivago, isTrivagoBookAndGo, occupancy, query, useAllQuery]);

  if (isTrivagoBookAndGo) {
    return <TrivagoBngLoader />;
  }

  return <LoadingModal visible={true} />;
};

export default HotelRedirect;
