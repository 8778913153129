import { FirstDayOfWeek, useMonth } from '@datepicker-react/hooks';
import useDateCallback from 'common/hooks/useDateCallback';
import { dayNumericFormat, monthShortFormat, weekdayShortFormat, yearNumericFormat } from 'common/utils/dateFormats';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { TrivagoContext } from 'components/contexts/TrivagoContext';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';
import Day from 'components/datePicker/customDatepicker/Day';
import Styled from 'components/datePicker/customDatepicker/Month.styled';
import { monthLongFormat, weekdayNarrowFormat } from 'components/dates/dateFormats';
import useCurrentLanguage from 'components/header-footer/useCurrentLanguage';
import { SanitizedHTML } from 'components/widgets/SanitizedInnerHTML';

interface MonthProps {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek;
  setStartMonthRef: (startMonth: HTMLDivElement | undefined) => void;
}

export const Month: React.FC<MonthProps> = ({ year, month, firstDayOfWeek, setStartMonthRef }) => {
  const { isStartMonthSelected } = useContext(DatepickerContext);
  const { isTrivago } = useContext(TrivagoContext);

  const lang = useCurrentLanguage();
  const dayCallback = useDateCallback({ lang: lang.key, options: dayNumericFormat as Intl.DateTimeFormatOptions });
  const weekdayCallback = useDateCallback({
    lang: lang.key,
    options: (isTrivago ? weekdayNarrowFormat : weekdayShortFormat) as Intl.DateTimeFormatOptions,
  });
  const monthCallback = useDateCallback({
    lang: lang.key,
    options: (isTrivago ? monthLongFormat : monthShortFormat) as Intl.DateTimeFormatOptions,
  });
  const yearCallback = useDateCallback({ lang: lang.key, options: yearNumericFormat as Intl.DateTimeFormatOptions });
  const formatMonth = useCallback(
    (date: Date) => {
      const m = monthCallback(date);
      const y = yearCallback(date);

      return `${m} <span>${y}</span>`;
    },
    [monthCallback, yearCallback],
  );
  const ref = useRef<HTMLDivElement>(null);

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    monthLabelFormat: formatMonth,
    weekdayLabelFormat: weekdayCallback,
    dayLabelFormat: isTrivago ? dayCallback : undefined,
  });

  useEffect(() => {
    if (isStartMonthSelected(year, month) && ref.current) {
      setStartMonthRef(ref.current);

      return () => setStartMonthRef(undefined);
    }

    return undefined;
  }, [isStartMonthSelected, month, setStartMonthRef, year]);

  return (
    <div ref={ref}>
      <Styled.MonthLabel>
        <SanitizedHTML tag="div" rawHTML={monthLabel} />
      </Styled.MonthLabel>
      <Styled.Weekdays>
        {weekdayLabels.map((dayLabel, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${dayLabel}_${index}`}>{dayLabel}</div>
        ))}
      </Styled.Weekdays>
      <Styled.DayLabel>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return <Day date={day.date} key={`datepickerDayObj-${day.date.toString()}`} dayLabel={day.dayLabel} />;
          }

          // we have nothing better than an array index for the empty placeholders
          // eslint-disable-next-line react/no-array-index-key
          return <div key={`datepickerDay-${month}-${index}`} />;
        })}
      </Styled.DayLabel>
    </div>
  );
};
