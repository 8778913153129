import styled from 'styled-components';
import {
  landingBngBlueButtonColor,
  landingBngBlueHoveredButtonColor,
  landingBngGreenButtonColor,
  landingBngGreenHoveredButtonColor,
} from 'style/trivagoVaribles';
import { battleshipGrey, paleSkyBlue, white } from 'style/variables';

const Button = styled.button`
  height: 54px;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 0;
  cursor: pointer;

  text-align: center;
  font-weight: bold;
  font-size: 18px;

  &:focus {
    outline: 0;
  }
`;

const PrimaryButton = styled(Button)`
  color: ${white};

  &:disabled {
    color: ${battleshipGrey};
    background: ${paleSkyBlue};
    cursor: auto;
  }
`;

const GreenButton = styled(PrimaryButton)`
  background: ${landingBngGreenButtonColor};

  :hover:not(:disabled),
  :active:not(:disabled) {
    background: ${landingBngGreenHoveredButtonColor};
  }
`;

const BlueButton = styled(PrimaryButton)`
  background: ${landingBngBlueButtonColor};

  :hover:not(:disabled),
  :active:not(:disabled) {
    background: ${landingBngBlueHoveredButtonColor};
  }
`;

export default { GreenButton, BlueButton };
