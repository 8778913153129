import React from 'react';
import { useTranslation } from 'react-i18next';
import wta from 'assets/images/icons/wta.png';
import BenefitSectionStyled from 'components/home/BenefitSection.styled';
import Styled from 'components/home/LeadingHotelPanel/LeadingHotelPanel.styled';

const LeadingHotelPanel = () => {
  const [t] = useTranslation();

  return (
    <BenefitSectionStyled.LeadingItem>
      <BenefitSectionStyled.BenefitImage src={wta} />
      <div>
        <Styled.BenefitLabel>{t('home.awards.awards', 'World Travels Awards')}</Styled.BenefitLabel>
        <Styled.Text>{t('home.awards.leading', "World's Leading hotel booking site")}</Styled.Text>
      </div>
    </BenefitSectionStyled.LeadingItem>
  );
};

export default LeadingHotelPanel;
