import { HotelImage } from 'common/backend/api/hotel/hotelModel';
import React, { useCallback, useRef } from 'react';

type StorageType = HotelImage[] | boolean | undefined;

export type DataContextProps = {
  getData: (key: string) => StorageType;
  setData: (key: string, value: StorageType) => void;
};

export const DataContext = React.createContext<DataContextProps>({
  getData: () => undefined,
  setData: () => undefined,
});

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const storage = useRef<Record<string, StorageType>>({});

  const getData = useCallback((key: string) => storage.current[key], []);

  const setData = useCallback((key: string, value: StorageType) => {
    storage.current = { ...storage.current, [key]: value };
  }, []);

  return (
    <DataContext.Provider
      value={{
        getData,
        setData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const DATA_PROVIDER_HOTEL_IMAGES_KEY = 'DATA_PROVIDER_HOTEL_IMAGES_KEY';
export const DATA_PROVIDER_WITH_INSURANCE = 'DATA_PROVIDER_WITH_INSURANCE';
