/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MetaContext, SignInDialogContext } from 'TopContexts';
import mobileHeader from 'assets/images/home/group.svg';
import { CardTypes } from 'components/common/Card/types';
import { TextAlignment, TextSize, TextWeight } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import BenefitSection from 'components/home/BenefitSection';
import DesktopHomeLocations from 'components/home/DesktopHomeLocations';
import { MobileHomeLocations } from 'components/home/MobileHomeLocations/MobileHomeLocations';
import { SearchForm } from 'components/searchForm/SearchForm/SearchForm';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS } from 'contexts/MetaProvider';
import Styled from 'views/home/HomeView.styled';

const useHomeMetadata = () => {
  const [t] = useTranslation();
  const { setDescription, setKeywords } = useContext(MetaContext);

  useEffect(() => {
    setDescription(
      t(
        'metadata.home.description',
        'We create unbeatable deals you will not get anywhere else - so you can save your money for an unforgettable travel experience.',
      ),
    );
    setKeywords([
      t('metadata.home.keyword.hotel', 'hotel'),
      t('metadata.home.keyword.hotel-deals', 'hotel deals'),
      t('metadata.home.keyword.hotel-rooms', 'hotel rooms'),
      t('metadata.home.keyword.hotels', 'hotels'),
      t('metadata.home.keyword.lodging', 'lodging'),
      t('metadata.home.keyword.accommodation', 'accommodation'),
      t('metadata.home.keyword.special-deals', 'special deals'),
      t('metadata.home.keyword.weekend-breaks', 'weekend breaks'),
      t('metadata.home.keyword.city-breaks', 'city breaks'),
      t('metadata.home.keyword.deals', 'deals'),
      t('metadata.home.keyword.budget', 'budget'),
      t('metadata.home.keyword.cheap', 'cheap'),
      t('metadata.home.keyword.discount', 'discount'),
      t('metadata.home.keyword.savings', 'savings'),
      t('metadata.home.keyword.hotels-near', 'hotels near'),
      t('metadata.home.keyword.near-me-hotel', 'near me hotel'),
      t('metadata.home.keyword.bookings', 'bookings'),
      t('metadata.home.keyword.reservations', 'reservations'),
      t('metadata.home.keyword.hotels-near', 'hotels near'),
    ]);

    return () => {
      setDescription(DEFAULT_DESCRIPTION);
      setKeywords(DEFAULT_KEYWORDS);
    };
  }, [setDescription, setKeywords, t]);
};

interface HomePageProps {
  resetPassword?: boolean;
}

/**
 * Home page
 *
 */
const HomeView: React.FC<HomePageProps> = ({ resetPassword }) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  useHomeMetadata();

  useEffect(() => {
    if (resetPassword) {
      setSignInDialogMode(SignInDialogMode.ResetPassword);
    }
  }, [resetPassword, setSignInDialogMode]);

  return (
    <div>
      {isMobileLayout && (
        <>
          <Styled.Hero>
            <Styled.Container>
              <Styled.Title
                size={TextSize.HeadingM}
                alignment={TextAlignment.Center}
                className="position-z-index position-relative"
                tag="h1"
              >
                <Trans i18nKey="algotels.home.main.caption-1">The Best Hotel Deals Algorithms Can Find</Trans>
              </Styled.Title>
            </Styled.Container>
            <Styled.MobileBackgroundImage src={mobileHeader} />
          </Styled.Hero>
          <Styled.MobileSearch type={CardTypes.Main}>
            <SearchForm sticky={false} showOnlyDatepickerWidget={true} />
          </Styled.MobileSearch>
        </>
      )}

      {!isMobileLayout && (
        <Styled.Hero>
          <Styled.Greeting>
            <Styled.Title
              alignment={TextAlignment.Center}
              size={TextSize.HeadingH1}
              weight={TextWeight.SemiBold}
              tag="h1"
            >
              <Trans i18nKey="algotels.home.main.caption-1">The Best Hotel Deals Algorithms Can Find</Trans>
            </Styled.Title>
            <Styled.Subtitle size={TextSize.Large} alignment={TextAlignment.Center} className="width-xlarge" tag="div">
              <Trans i18nKey="algotels.home.main.caption-2">
                Let powerful travel algorithms do the heavy lifting by searching through millions of possibilities to
                get you the best hotel deal possible
              </Trans>
            </Styled.Subtitle>

            <SearchForm sticky={false} />
          </Styled.Greeting>
        </Styled.Hero>
      )}

      <Styled.HomeLocationsWrapper>
        {!isMobileLayout && <DesktopHomeLocations />}
        {isMobileLayout && <MobileHomeLocations />}
      </Styled.HomeLocationsWrapper>
      <Styled.BenefitWrapper>
        <BenefitSection />
      </Styled.BenefitWrapper>
    </div>
  );
};

export default HomeView;
