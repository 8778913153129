import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerErrorCode } from 'backend/serverError';
import { AvailableErrors } from 'errors/errorData';

const useEncryptTripIdErrors = (loginErrorCallback: (message: string) => void): AvailableErrors => {
  const [t] = useTranslation();

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.Unauthorized, ServerErrorCode.NotFound],
          action: () => loginErrorCallback(t('checkout.errors.trip-not-found', 'Invalid email or trip number')),
        },
      ],
      default: {
        action: () => loginErrorCallback(t('checkout.errors.invalidTrip', 'Invalid email or trip number')),
      },
    }),
    [loginErrorCallback, t],
  );
};

export default useEncryptTripIdErrors;
