/**
 * @category Universal Components
 * @packageDocumentation
 */
import React from 'react';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import StyledModal from 'components/StyledModal';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor } from 'components/common/Text/Text.types';

export interface ModalDialogProps {
  /**
   * Head of the dialog
   */
  headline?: string;
  /**
   * Body of the dialog
   */
  message?: string;
  /**
   * if it is show or hidded
   */
  visible: boolean;
}

/**
 * loading dialog
 * @param param {@link ModalDialogProps}
 */
export const LoadingModal = ({ headline, message, visible }: ModalDialogProps) => (
  <StyledModal visible={visible} positionToCenter={true}>
    <LoadingScreen />
    {headline && (
      <Text color={TextColor.Primary} alignment={TextAlignment.Center} tag="h3">
        {headline}
      </Text>
    )}
    {message && (
      <Text alignment={TextAlignment.Center} tag="p">
        {message}
      </Text>
    )}
  </StyledModal>
);
