import styled from 'styled-components';
import { Text } from 'components/common/Text/Text';
import BenefitSectionStyled from 'components/home/BenefitSection.styled';
import { superDarkGrey } from 'style/variables';

const BenefitLabel = styled(BenefitSectionStyled.BenefitLabel)`
  font-size: 32px;
`;

const Subtitle = styled(Text)`
  font-size: 18px;
  line-height: 20px;
  color: ${superDarkGrey};
`;

const Text1 = styled(Text)`
  margin-top: 17px;
`;

const Text2 = styled(Text)`
  margin-top: 12px;
`;

export default {
  BenefitLabel,
  Subtitle,
  Text1,
  Text2,
};
