import { useModalCloseHelper } from 'common/hooks/useModalCloseHelper';
import React from 'react';
import { Trans } from 'react-i18next';
import Styled from 'components/AlertModal.styled';
import { CustomAbstractModal } from 'components/common/modal/CustomAbstractModal';
import { CustomButtons } from 'contexts/ModalAlertProvider';

interface AlertModalPops {
  visible: boolean;
  onClose: () => void;
  buttons?: CustomButtons;
  children?: React.ReactNode;
}

const AlertModal: React.FC<AlertModalPops> = ({ visible, onClose, children, buttons }) => {
  const dialogRef = useModalCloseHelper(onClose, !visible);

  const actionButtons = buttons ? (
    <Styled.ButtonsContainer>
      <Styled.ButtonOutlined onClick={buttons.cancel.cb}>{buttons.cancel.label}</Styled.ButtonOutlined>
      <Styled.Button onClick={buttons.ok.cb}>{buttons.ok.label}</Styled.Button>
    </Styled.ButtonsContainer>
  ) : (
    <Styled.Button data-testid="alert-modal-button" onClick={onClose}>
      <Trans>Ok</Trans>
    </Styled.Button>
  );

  return (
    <CustomAbstractModal data-testid="alert-modal" visible={visible} positionToCenter={false}>
      <Styled.Container>
        <Styled.Modal ref={dialogRef}>
          <Styled.Content data-testid="alert-modal-content">{children}</Styled.Content>
          <Styled.Actions>{actionButtons}</Styled.Actions>
        </Styled.Modal>
      </Styled.Container>
    </CustomAbstractModal>
  );
};

export default AlertModal;
