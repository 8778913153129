import { LoadScript } from '@react-google-maps/api';
import useOnRouteScrollingRestore from 'common/hooks/useOnRouteScrollingRestore';
import React, { useContext, useEffect } from 'react';
import { DebugContext, SuspenseContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useSession } from 'atoms/hooks/useSession';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { useWatchSearchState } from 'atoms/hooks/useWatchSearchState';
import { hotjarUser } from 'backend/hotjarTags';
import { DEBUG_MODE_KEY } from 'backend/localStorageKeys';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OAuthProvider } from 'components/contexts/OAuthContext';
import { OffscreenProvider } from 'components/contexts/OffscreenProvider';
import { SearchFormProvider } from 'components/contexts/SearchFormContext';
import { ThemeProvider } from 'components/contexts/ThemeContext';
import { DesktopPageLayout } from 'components/layouts/desktop/DesktopPageLayout';
import { MobilePageLayout } from 'components/layouts/mobile/MobilePageLayout';
import { env } from 'environments/environment';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';
import useLocalStorage from 'utils/useLocalStorage';
import { useClearRokt } from 'utils/useRokt';

const AppTop: React.FC = () => {
  const { notifyReady } = useContext(SuspenseContext);
  const [isDebugMode, setDebugMode] = useLocalStorage(DEBUG_MODE_KEY, false);
  const { isMobileLayout } = useContext(LayoutContext);
  const { data } = useUserLocation();
  const { session } = useSession();
  const { loading } = useCampaign();

  useWatchSearchState();
  useEffect(() => {
    const userId = session.principal?.hasUser()?.id;

    LogInfoMemoryStorage.userId = userId;

    if (session.initialized) {
      hotjarUser(userId ? userId.toString(10) : null);
    }
  }, [session.initialized, session.principal]);

  useEffect(() => {
    LogInfoMemoryStorage.clientLocation = data;
  }, [data]);

  useClearRokt();
  useOnRouteScrollingRestore();

  if (!session.initialized || loading) {
    return null;
  }

  return (
    <ThemeProvider>
      <DebugContext.Provider value={{ isDebugMode, setDebugMode }}>
        <SearchFormProvider>
          <LoadScript googleMapsApiKey={env.googleMap.apiKey} onLoad={notifyReady}>
            <OAuthProvider>
              <OffscreenProvider>{isMobileLayout ? <MobilePageLayout /> : <DesktopPageLayout />}</OffscreenProvider>
            </OAuthProvider>
          </LoadScript>
        </SearchFormProvider>
      </DebugContext.Provider>
    </ThemeProvider>
  );
};

export default AppTop;
