/**
 * @category Utils
 * @packageDocumentation
 */
import { useEffect } from 'react';

export enum Keys {
  Escape = 'Escape',
  Enter = 'Enter',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
}

/**
 * Hook that alerts presses of the specified keys
 * @param keys - a list of key codes to be listened
 * @param callback - callback to call when user press one of the keys
 * @param disabled - state when callback disabled
 */
const useKeyPress = (keys: Keys[], callback: () => void, disabled?: boolean) => {
  useEffect(() => {
    if (disabled) {
      return () => null;
    }

    const handler = (event: KeyboardEvent) => {
      if (keys.find((key) => key === event.key)) {
        callback();
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [keys, disabled, callback]);
};

export default useKeyPress;
