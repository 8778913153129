import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface SignInLinkProps {
  onClick: () => void;
}

const SignInLink: React.FC<SignInLinkProps> = ({ onClick }) => {
  const [t] = useTranslation();
  const text = useMemo(() => t('common.menu.signin', 'Sign In'), [t]);

  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      href="/"
    >
      {text}
    </a>
  );
};

export default SignInLink;
