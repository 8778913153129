import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import arrowLeftIcon from 'assets/images/icons/trivagoV2/mobile-back-icon.svg';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import MobileOffscreen from 'components/mobile/offscreen/MobileOffscreen';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import { OccupancyPicker } from 'components/searchForm/OccupancyPicker/OccupancyPicker';
import { sumGuests } from 'utils/occupancyUtils';
import Styled from 'views/offscreens/TrivagoOffscreenDatepickerMenu.styled';

const OffscreenOccupancyMenu: React.FC = () => {
  const { goToPreviousOffscreenMode, setOffscreenMode } = useContext(OffscreenContext);

  const { occupancy, setOccupancy, submitForm } = useContext(SearchFormContext);
  const [initOccupancy] = useState(occupancy);
  const [shouldResetOccupancy, setShouldResetOccupancy] = useState(false);

  useEffect(() => {
    return () => (shouldResetOccupancy ? setOccupancy(initOccupancy) : undefined);
  }, [initOccupancy, setOccupancy, shouldResetOccupancy]);

  const submit = useCallback(() => {
    hotjarEvent(HotjarEvent.SearchClicked);
    submitForm(true);
    setOffscreenMode(OffscreenMode.hidden);
  }, [submitForm, setOffscreenMode]);

  const onBackButton = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      setShouldResetOccupancy(true);
      goToPreviousOffscreenMode();
    },
    [goToPreviousOffscreenMode],
  );

  return (
    <MobileOffscreen>
      <Styled.Container>
        <Styled.Header>
          <Styled.HeaderAction>
            <a id="backLink" href="/" onClick={onBackButton}>
              <img src={arrowLeftIcon} alt="Close" className="" />
            </a>
          </Styled.HeaderAction>
          <Styled.HeaderContent>
            <Styled.HeaderLabel>
              <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.header-label'}>Who’s coming?</Trans>
            </Styled.HeaderLabel>
            <Styled.HeaderText>
              <Trans
                i18nKey="search-bar.rooms-with-param"
                defaults="{ rooms, plural, =1 {# room} other {# rooms} }"
                values={{ rooms: occupancy.length }}
              />{' '}
              <Trans
                i18nKey="trivago.v2.search-bar.occupancy-picker.guests-total"
                defaults="{ guests, plural, =1 {# guest} other {# guests} }"
                values={{ guests: sumGuests(occupancy) }}
              />
            </Styled.HeaderText>
          </Styled.HeaderContent>
          <Styled.HeaderAction />
        </Styled.Header>
        <Styled.Content>
          <OccupancyPicker />
        </Styled.Content>
        <Styled.ButtonSection>
          <Styled.Button onClick={submit}>
            <Trans i18nKey={'trivago.v2.search-bar.occupancy-picker.done-button'}>Done</Trans>
          </Styled.Button>
        </Styled.ButtonSection>
      </Styled.Container>
    </MobileOffscreen>
  );
};

export default OffscreenOccupancyMenu;
