/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import StyledBng from 'style/CommonTrivago.styled';
import { flexGapColumn } from 'style/polyfills/flexGap';
import { trivagoBlackV2 } from 'style/trivagoVaribles';
import { colorRed, white } from 'style/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  height: auto;

  z-index: 100;
  box-shadow:
    0 3px 7px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(203, 203, 203, 0.5);
  background-color: ${white};
`;

const HeaderAction = styled.div`
  width: 40px;
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${flexGapColumn('20px')};
  color: ${trivagoBlackV2};
`;

const HeaderLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
`;

const HeaderText = styled.div`
  text-align: center;
  font-size: 14px;
`;

const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: sticky;
  bottom: 0;

  width: 100%;
  padding: 14px 24px 19px;
  box-sizing: border-box;
  box-shadow:
    0 3px 7px 0 rgba(0, 0, 0, 0.06),
    0 -2px 0 0 rgba(203, 203, 203, 0.5);
  background-color: ${white};

  z-index: 100;
`;

const Button = styled(StyledBng.BlueButton)`
  width: 100%;
  height: 50px;
`;

const ErrorToast = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 110;

  transition: 200ms;
  transform: translateY(${({ show }) => (show ? '0' : '-110%')});

  padding: 16px 10px;
  box-sizing: border-box;

  background-color: ${colorRed};

  line-height: 1.5;
  text-align: center;
  color: ${white};
`;

export default {
  Container,
  Header,
  HeaderAction,
  HeaderContent,
  HeaderLabel,
  HeaderText,
  Content,
  ButtonSection,
  Button,
  ErrorToast,
};
